import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import { UserFragmentDoc } from '../users/queries.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type StudentSelectItemFragment = { id: string, studentId?: string | null | undefined, user: { id: string, name: string, lastName: string, firstName: string, email: string }, school?: { id: string, name: string } | null | undefined };

export type StudentsPaginatedItemFragment = { id: string, studentId?: string | null | undefined, accountStatus: Types.AccountStatus, createdAt: any, updatedAt: any, schoolSystem?: { id: string, name: string } | null | undefined, school?: { id: string, name: string } | null | undefined, user: { name: string, firstName: string, lastName: string, email: string, role: string, id: string, createdAt: any, updatedAt: any, isDisabled: boolean, family?: { id: string, name: string } | null | undefined } };

export type GetStudentsPaginatedQueryVariables = Types.Exact<{
  search?: Types.Maybe<Types.Scalars['String']>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  sortBy?: Types.Maybe<Types.Scalars['String']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  sortDirection?: Types.Maybe<Types.Scalars['String']>;
  schoolSystemId?: Types.Maybe<Types.Scalars['String']>;
  schoolId?: Types.Maybe<Types.Scalars['String']>;
}>;


export type GetStudentsPaginatedQuery = { studentsPaginated: { totalsCount: number, nodes?: Array<{ id: string, studentId?: string | null | undefined, accountStatus: Types.AccountStatus, createdAt: any, updatedAt: any, schoolSystem?: { id: string, name: string } | null | undefined, school?: { id: string, name: string } | null | undefined, user: { name: string, firstName: string, lastName: string, email: string, role: string, id: string, createdAt: any, updatedAt: any, isDisabled: boolean, family?: { id: string, name: string } | null | undefined } }> | null | undefined } };

export type GetStudentQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetStudentQuery = { student: { id: string, studentId?: string | null | undefined, accountStatus: Types.AccountStatus, createdAt: any, updatedAt: any, schoolSystem?: { id: string, name: string } | null | undefined, school?: { id: string, name: string } | null | undefined, user: { name: string, firstName: string, lastName: string, email: string, role: string, id: string, createdAt: any, updatedAt: any, isDisabled: boolean, family?: { id: string, name: string } | null | undefined } } };

export type CreateStudentMutationVariables = Types.Exact<{
  data: Types.CreateStudentDto;
}>;


export type CreateStudentMutation = { createStudent: { id: string, createdAt: any, updatedAt: any } };

export type UpdateStudentMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  data: Types.UpdateStudentDto;
}>;


export type UpdateStudentMutation = { updateStudent: { id: string } };

export type DeleteStudentMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteStudentMutation = { deleteStudent: boolean };

export type CreateStudentAccountMutationVariables = Types.Exact<{
  data: Types.CreateStudentAccountDto;
}>;


export type CreateStudentAccountMutation = { createStudentAccount: { id: string } };

export type GetStudentsMultipleSchoolsQueryVariables = Types.Exact<{
  data: Types.StudentsSelectedSchoolsDto;
}>;


export type GetStudentsMultipleSchoolsQuery = { studentsMultipleSchools: { nodes?: Array<{ id: string, studentId?: string | null | undefined, user: { id: string, name: string, lastName: string, firstName: string, email: string }, school?: { id: string, name: string } | null | undefined }> | null | undefined } };

export const StudentSelectItemFragmentDoc = gql`
    fragment StudentSelectItem on StudentDTO {
  id
  studentId
  user {
    id
    name
    lastName
    firstName
    email
  }
  school {
    id
    name
  }
}
    `;
export const StudentsPaginatedItemFragmentDoc = gql`
    fragment StudentsPaginatedItem on StudentDTO {
  id
  studentId
  accountStatus
  schoolSystem {
    id
    name
  }
  school {
    id
    name
  }
  user {
    family {
      id
      name
    }
    ...User
  }
  createdAt
  updatedAt
}
    ${UserFragmentDoc}`;
export const GetStudentsPaginatedDocument = gql`
    query getStudentsPaginated($search: String, $limit: Int, $sortBy: String, $offset: Int, $sortDirection: String, $schoolSystemId: String, $schoolId: String) {
  studentsPaginated(
    search: $search
    limit: $limit
    offset: $offset
    sortBy: $sortBy
    sortDirection: $sortDirection
    schoolSystemId: $schoolSystemId
    schoolId: $schoolId
  ) {
    nodes {
      ...StudentsPaginatedItem
    }
    totalsCount
  }
}
    ${StudentsPaginatedItemFragmentDoc}`;

/**
 * __useGetStudentsPaginatedQuery__
 *
 * To run a query within a React component, call `useGetStudentsPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentsPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentsPaginatedQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      offset: // value for 'offset'
 *      sortDirection: // value for 'sortDirection'
 *      schoolSystemId: // value for 'schoolSystemId'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetStudentsPaginatedQuery(baseOptions?: Apollo.QueryHookOptions<GetStudentsPaginatedQuery, GetStudentsPaginatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentsPaginatedQuery, GetStudentsPaginatedQueryVariables>(GetStudentsPaginatedDocument, options);
      }
export function useGetStudentsPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentsPaginatedQuery, GetStudentsPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentsPaginatedQuery, GetStudentsPaginatedQueryVariables>(GetStudentsPaginatedDocument, options);
        }
export type GetStudentsPaginatedQueryHookResult = ReturnType<typeof useGetStudentsPaginatedQuery>;
export type GetStudentsPaginatedLazyQueryHookResult = ReturnType<typeof useGetStudentsPaginatedLazyQuery>;
export type GetStudentsPaginatedQueryResult = Apollo.QueryResult<GetStudentsPaginatedQuery, GetStudentsPaginatedQueryVariables>;
export const GetStudentDocument = gql`
    query getStudent($id: String!) {
  student(id: $id) {
    ...StudentsPaginatedItem
  }
}
    ${StudentsPaginatedItemFragmentDoc}`;

/**
 * __useGetStudentQuery__
 *
 * To run a query within a React component, call `useGetStudentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStudentQuery(baseOptions: Apollo.QueryHookOptions<GetStudentQuery, GetStudentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentQuery, GetStudentQueryVariables>(GetStudentDocument, options);
      }
export function useGetStudentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentQuery, GetStudentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentQuery, GetStudentQueryVariables>(GetStudentDocument, options);
        }
export type GetStudentQueryHookResult = ReturnType<typeof useGetStudentQuery>;
export type GetStudentLazyQueryHookResult = ReturnType<typeof useGetStudentLazyQuery>;
export type GetStudentQueryResult = Apollo.QueryResult<GetStudentQuery, GetStudentQueryVariables>;
export const CreateStudentDocument = gql`
    mutation createStudent($data: CreateStudentDTO!) {
  createStudent(data: $data) {
    id
    createdAt
    updatedAt
  }
}
    `;
export type CreateStudentMutationFn = Apollo.MutationFunction<CreateStudentMutation, CreateStudentMutationVariables>;

/**
 * __useCreateStudentMutation__
 *
 * To run a mutation, you first call `useCreateStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStudentMutation, { data, loading, error }] = useCreateStudentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateStudentMutation(baseOptions?: Apollo.MutationHookOptions<CreateStudentMutation, CreateStudentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStudentMutation, CreateStudentMutationVariables>(CreateStudentDocument, options);
      }
export type CreateStudentMutationHookResult = ReturnType<typeof useCreateStudentMutation>;
export type CreateStudentMutationResult = Apollo.MutationResult<CreateStudentMutation>;
export type CreateStudentMutationOptions = Apollo.BaseMutationOptions<CreateStudentMutation, CreateStudentMutationVariables>;
export const UpdateStudentDocument = gql`
    mutation updateStudent($id: String!, $data: UpdateStudentDTO!) {
  updateStudent(id: $id, data: $data) {
    id
  }
}
    `;
export type UpdateStudentMutationFn = Apollo.MutationFunction<UpdateStudentMutation, UpdateStudentMutationVariables>;

/**
 * __useUpdateStudentMutation__
 *
 * To run a mutation, you first call `useUpdateStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStudentMutation, { data, loading, error }] = useUpdateStudentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateStudentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStudentMutation, UpdateStudentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStudentMutation, UpdateStudentMutationVariables>(UpdateStudentDocument, options);
      }
export type UpdateStudentMutationHookResult = ReturnType<typeof useUpdateStudentMutation>;
export type UpdateStudentMutationResult = Apollo.MutationResult<UpdateStudentMutation>;
export type UpdateStudentMutationOptions = Apollo.BaseMutationOptions<UpdateStudentMutation, UpdateStudentMutationVariables>;
export const DeleteStudentDocument = gql`
    mutation deleteStudent($id: String!) {
  deleteStudent(id: $id)
}
    `;
export type DeleteStudentMutationFn = Apollo.MutationFunction<DeleteStudentMutation, DeleteStudentMutationVariables>;

/**
 * __useDeleteStudentMutation__
 *
 * To run a mutation, you first call `useDeleteStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStudentMutation, { data, loading, error }] = useDeleteStudentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStudentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStudentMutation, DeleteStudentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStudentMutation, DeleteStudentMutationVariables>(DeleteStudentDocument, options);
      }
export type DeleteStudentMutationHookResult = ReturnType<typeof useDeleteStudentMutation>;
export type DeleteStudentMutationResult = Apollo.MutationResult<DeleteStudentMutation>;
export type DeleteStudentMutationOptions = Apollo.BaseMutationOptions<DeleteStudentMutation, DeleteStudentMutationVariables>;
export const CreateStudentAccountDocument = gql`
    mutation createStudentAccount($data: CreateStudentAccountDTO!) {
  createStudentAccount(data: $data) {
    id
  }
}
    `;
export type CreateStudentAccountMutationFn = Apollo.MutationFunction<CreateStudentAccountMutation, CreateStudentAccountMutationVariables>;

/**
 * __useCreateStudentAccountMutation__
 *
 * To run a mutation, you first call `useCreateStudentAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStudentAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStudentAccountMutation, { data, loading, error }] = useCreateStudentAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateStudentAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateStudentAccountMutation, CreateStudentAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStudentAccountMutation, CreateStudentAccountMutationVariables>(CreateStudentAccountDocument, options);
      }
export type CreateStudentAccountMutationHookResult = ReturnType<typeof useCreateStudentAccountMutation>;
export type CreateStudentAccountMutationResult = Apollo.MutationResult<CreateStudentAccountMutation>;
export type CreateStudentAccountMutationOptions = Apollo.BaseMutationOptions<CreateStudentAccountMutation, CreateStudentAccountMutationVariables>;
export const GetStudentsMultipleSchoolsDocument = gql`
    query getStudentsMultipleSchools($data: StudentsSelectedSchoolsDTO!) {
  studentsMultipleSchools(data: $data) {
    nodes {
      ...StudentSelectItem
    }
  }
}
    ${StudentSelectItemFragmentDoc}`;

/**
 * __useGetStudentsMultipleSchoolsQuery__
 *
 * To run a query within a React component, call `useGetStudentsMultipleSchoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentsMultipleSchoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentsMultipleSchoolsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetStudentsMultipleSchoolsQuery(baseOptions: Apollo.QueryHookOptions<GetStudentsMultipleSchoolsQuery, GetStudentsMultipleSchoolsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentsMultipleSchoolsQuery, GetStudentsMultipleSchoolsQueryVariables>(GetStudentsMultipleSchoolsDocument, options);
      }
export function useGetStudentsMultipleSchoolsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentsMultipleSchoolsQuery, GetStudentsMultipleSchoolsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentsMultipleSchoolsQuery, GetStudentsMultipleSchoolsQueryVariables>(GetStudentsMultipleSchoolsDocument, options);
        }
export type GetStudentsMultipleSchoolsQueryHookResult = ReturnType<typeof useGetStudentsMultipleSchoolsQuery>;
export type GetStudentsMultipleSchoolsLazyQueryHookResult = ReturnType<typeof useGetStudentsMultipleSchoolsLazyQuery>;
export type GetStudentsMultipleSchoolsQueryResult = Apollo.QueryResult<GetStudentsMultipleSchoolsQuery, GetStudentsMultipleSchoolsQueryVariables>;