import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import { UsersPaginatedItemFragmentDoc } from '../users/queries.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type FormFieldFragment = { id: string, formId: string, type: string, title: string, order: number, required: boolean, hintText?: string | null | undefined, radioButtons?: Array<string> | null | undefined, checkBoxes?: Array<string> | null | undefined, dropdownItems?: Array<string> | null | undefined, createdAt: any, updatedAt: any };

export type FormResponseFragment = { id: string, formId: string, userId: string, responseData: Array<Array<string>>, deletedAt?: any | null | undefined, createdAt: any, updatedAt: any, user: { name: string, firstName: string, lastName: string, email: string, role: string, id: string, createdAt: any, updatedAt: any, isDisabled: boolean, schoolSystem?: { id: string, name: string } | null | undefined, school?: { id: string, name: string } | null | undefined } };

export type FormsPaginatedItemFragment = { id: string, name: string, targetId?: string | null | undefined, targetType?: string | null | undefined, targetInfo?: string | null | undefined, receivers: Array<string>, initialDate: any, finalDate: any, deletedAt?: any | null | undefined, createdAt: any, updatedAt: any, formFields: Array<{ id: string, formId: string, type: string, title: string, order: number, required: boolean, hintText?: string | null | undefined, radioButtons?: Array<string> | null | undefined, checkBoxes?: Array<string> | null | undefined, dropdownItems?: Array<string> | null | undefined, createdAt: any, updatedAt: any }>, formResponses?: Array<{ id: string }> | null | undefined };

export type GetFormByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetFormByIdQuery = { getForm: { id: string, name: string, targetId?: string | null | undefined, targetType?: string | null | undefined, targetInfo?: string | null | undefined, receivers: Array<string>, initialDate: any, finalDate: any, deletedAt?: any | null | undefined, createdAt: any, updatedAt: any, formFields: Array<{ id: string, formId: string, type: string, title: string, order: number, required: boolean, hintText?: string | null | undefined, radioButtons?: Array<string> | null | undefined, checkBoxes?: Array<string> | null | undefined, dropdownItems?: Array<string> | null | undefined, createdAt: any, updatedAt: any }>, formResponses?: Array<{ id: string }> | null | undefined } };

export type GetFormsPaginatedQueryVariables = Types.Exact<{
  search?: Types.Maybe<Types.Scalars['String']>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  sortBy?: Types.Maybe<Types.Scalars['String']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  sortDirection?: Types.Maybe<Types.Scalars['String']>;
}>;


export type GetFormsPaginatedQuery = { getFormsPaginated: { nodes?: Array<{ id: string, name: string, targetId?: string | null | undefined, targetType?: string | null | undefined, targetInfo?: string | null | undefined, receivers: Array<string>, initialDate: any, finalDate: any, deletedAt?: any | null | undefined, createdAt: any, updatedAt: any, formFields: Array<{ id: string, formId: string, type: string, title: string, order: number, required: boolean, hintText?: string | null | undefined, radioButtons?: Array<string> | null | undefined, checkBoxes?: Array<string> | null | undefined, dropdownItems?: Array<string> | null | undefined, createdAt: any, updatedAt: any }>, formResponses?: Array<{ id: string }> | null | undefined }> | null | undefined } };

export type GetFormFieldsQueryVariables = Types.Exact<{
  formId: Types.Scalars['String'];
}>;


export type GetFormFieldsQuery = { getFormFields: Array<{ id: string, formId: string, type: string, title: string, order: number, required: boolean, hintText?: string | null | undefined, radioButtons?: Array<string> | null | undefined, checkBoxes?: Array<string> | null | undefined, dropdownItems?: Array<string> | null | undefined, createdAt: any, updatedAt: any }> };

export type GetFormFieldByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetFormFieldByIdQuery = { getFormFieldById: { id: string, formId: string, type: string, title: string, order: number, required: boolean, hintText?: string | null | undefined, radioButtons?: Array<string> | null | undefined, checkBoxes?: Array<string> | null | undefined, dropdownItems?: Array<string> | null | undefined, createdAt: any, updatedAt: any } };

export type GetFormResponsesQueryVariables = Types.Exact<{
  formId: Types.Scalars['String'];
}>;


export type GetFormResponsesQuery = { getFormResponses: Array<{ id: string, formId: string, userId: string, responseData: Array<Array<string>>, deletedAt?: any | null | undefined, createdAt: any, updatedAt: any, user: { name: string, firstName: string, lastName: string, email: string, role: string, id: string, createdAt: any, updatedAt: any, isDisabled: boolean, schoolSystem?: { id: string, name: string } | null | undefined, school?: { id: string, name: string } | null | undefined } }> };

export type GetFormResponsesPaginatedQueryVariables = Types.Exact<{
  formId: Types.Scalars['String'];
  search?: Types.Maybe<Types.Scalars['String']>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  sortBy?: Types.Maybe<Types.Scalars['String']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  sortDirection?: Types.Maybe<Types.Scalars['String']>;
}>;


export type GetFormResponsesPaginatedQuery = { getFormResponsesPaginated: { nodes?: Array<{ id: string, formId: string, userId: string, responseData: Array<Array<string>>, deletedAt?: any | null | undefined, createdAt: any, updatedAt: any, user: { name: string, firstName: string, lastName: string, email: string, role: string, id: string, createdAt: any, updatedAt: any, isDisabled: boolean, schoolSystem?: { id: string, name: string } | null | undefined, school?: { id: string, name: string } | null | undefined } }> | null | undefined } };

export const FormResponseFragmentDoc = gql`
    fragment FormResponse on FormResponseDTO {
  id
  formId
  userId
  user {
    ...UsersPaginatedItem
  }
  responseData
  deletedAt
  createdAt
  updatedAt
}
    ${UsersPaginatedItemFragmentDoc}`;
export const FormFieldFragmentDoc = gql`
    fragment FormField on FormFieldsDTO {
  id
  formId
  type
  title
  order
  required
  hintText
  radioButtons
  checkBoxes
  dropdownItems
  createdAt
  updatedAt
}
    `;
export const FormsPaginatedItemFragmentDoc = gql`
    fragment FormsPaginatedItem on FormDTO {
  id
  name
  targetId
  targetType
  targetInfo
  receivers
  initialDate
  finalDate
  deletedAt
  createdAt
  updatedAt
  formFields {
    ...FormField
  }
  formResponses {
    id
  }
}
    ${FormFieldFragmentDoc}`;
export const GetFormByIdDocument = gql`
    query getFormById($id: String!) {
  getForm(id: $id) {
    ...FormsPaginatedItem
  }
}
    ${FormsPaginatedItemFragmentDoc}`;

/**
 * __useGetFormByIdQuery__
 *
 * To run a query within a React component, call `useGetFormByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFormByIdQuery(baseOptions: Apollo.QueryHookOptions<GetFormByIdQuery, GetFormByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFormByIdQuery, GetFormByIdQueryVariables>(GetFormByIdDocument, options);
      }
export function useGetFormByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFormByIdQuery, GetFormByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFormByIdQuery, GetFormByIdQueryVariables>(GetFormByIdDocument, options);
        }
export type GetFormByIdQueryHookResult = ReturnType<typeof useGetFormByIdQuery>;
export type GetFormByIdLazyQueryHookResult = ReturnType<typeof useGetFormByIdLazyQuery>;
export type GetFormByIdQueryResult = Apollo.QueryResult<GetFormByIdQuery, GetFormByIdQueryVariables>;
export const GetFormsPaginatedDocument = gql`
    query getFormsPaginated($search: String, $limit: Int, $sortBy: String, $offset: Int, $sortDirection: String) {
  getFormsPaginated(
    search: $search
    limit: $limit
    offset: $offset
    sortBy: $sortBy
    sortDirection: $sortDirection
  ) {
    nodes {
      ...FormsPaginatedItem
    }
  }
}
    ${FormsPaginatedItemFragmentDoc}`;

/**
 * __useGetFormsPaginatedQuery__
 *
 * To run a query within a React component, call `useGetFormsPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormsPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormsPaginatedQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      offset: // value for 'offset'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useGetFormsPaginatedQuery(baseOptions?: Apollo.QueryHookOptions<GetFormsPaginatedQuery, GetFormsPaginatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFormsPaginatedQuery, GetFormsPaginatedQueryVariables>(GetFormsPaginatedDocument, options);
      }
export function useGetFormsPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFormsPaginatedQuery, GetFormsPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFormsPaginatedQuery, GetFormsPaginatedQueryVariables>(GetFormsPaginatedDocument, options);
        }
export type GetFormsPaginatedQueryHookResult = ReturnType<typeof useGetFormsPaginatedQuery>;
export type GetFormsPaginatedLazyQueryHookResult = ReturnType<typeof useGetFormsPaginatedLazyQuery>;
export type GetFormsPaginatedQueryResult = Apollo.QueryResult<GetFormsPaginatedQuery, GetFormsPaginatedQueryVariables>;
export const GetFormFieldsDocument = gql`
    query getFormFields($formId: String!) {
  getFormFields(formId: $formId) {
    ...FormField
  }
}
    ${FormFieldFragmentDoc}`;

/**
 * __useGetFormFieldsQuery__
 *
 * To run a query within a React component, call `useGetFormFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormFieldsQuery({
 *   variables: {
 *      formId: // value for 'formId'
 *   },
 * });
 */
export function useGetFormFieldsQuery(baseOptions: Apollo.QueryHookOptions<GetFormFieldsQuery, GetFormFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFormFieldsQuery, GetFormFieldsQueryVariables>(GetFormFieldsDocument, options);
      }
export function useGetFormFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFormFieldsQuery, GetFormFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFormFieldsQuery, GetFormFieldsQueryVariables>(GetFormFieldsDocument, options);
        }
export type GetFormFieldsQueryHookResult = ReturnType<typeof useGetFormFieldsQuery>;
export type GetFormFieldsLazyQueryHookResult = ReturnType<typeof useGetFormFieldsLazyQuery>;
export type GetFormFieldsQueryResult = Apollo.QueryResult<GetFormFieldsQuery, GetFormFieldsQueryVariables>;
export const GetFormFieldByIdDocument = gql`
    query getFormFieldById($id: String!) {
  getFormFieldById(id: $id) {
    ...FormField
  }
}
    ${FormFieldFragmentDoc}`;

/**
 * __useGetFormFieldByIdQuery__
 *
 * To run a query within a React component, call `useGetFormFieldByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormFieldByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormFieldByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFormFieldByIdQuery(baseOptions: Apollo.QueryHookOptions<GetFormFieldByIdQuery, GetFormFieldByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFormFieldByIdQuery, GetFormFieldByIdQueryVariables>(GetFormFieldByIdDocument, options);
      }
export function useGetFormFieldByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFormFieldByIdQuery, GetFormFieldByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFormFieldByIdQuery, GetFormFieldByIdQueryVariables>(GetFormFieldByIdDocument, options);
        }
export type GetFormFieldByIdQueryHookResult = ReturnType<typeof useGetFormFieldByIdQuery>;
export type GetFormFieldByIdLazyQueryHookResult = ReturnType<typeof useGetFormFieldByIdLazyQuery>;
export type GetFormFieldByIdQueryResult = Apollo.QueryResult<GetFormFieldByIdQuery, GetFormFieldByIdQueryVariables>;
export const GetFormResponsesDocument = gql`
    query getFormResponses($formId: String!) {
  getFormResponses(formId: $formId) {
    ...FormResponse
  }
}
    ${FormResponseFragmentDoc}`;

/**
 * __useGetFormResponsesQuery__
 *
 * To run a query within a React component, call `useGetFormResponsesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormResponsesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormResponsesQuery({
 *   variables: {
 *      formId: // value for 'formId'
 *   },
 * });
 */
export function useGetFormResponsesQuery(baseOptions: Apollo.QueryHookOptions<GetFormResponsesQuery, GetFormResponsesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFormResponsesQuery, GetFormResponsesQueryVariables>(GetFormResponsesDocument, options);
      }
export function useGetFormResponsesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFormResponsesQuery, GetFormResponsesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFormResponsesQuery, GetFormResponsesQueryVariables>(GetFormResponsesDocument, options);
        }
export type GetFormResponsesQueryHookResult = ReturnType<typeof useGetFormResponsesQuery>;
export type GetFormResponsesLazyQueryHookResult = ReturnType<typeof useGetFormResponsesLazyQuery>;
export type GetFormResponsesQueryResult = Apollo.QueryResult<GetFormResponsesQuery, GetFormResponsesQueryVariables>;
export const GetFormResponsesPaginatedDocument = gql`
    query getFormResponsesPaginated($formId: String!, $search: String, $limit: Int, $sortBy: String, $offset: Int, $sortDirection: String) {
  getFormResponsesPaginated(
    formId: $formId
    search: $search
    limit: $limit
    offset: $offset
    sortBy: $sortBy
    sortDirection: $sortDirection
  ) {
    nodes {
      ...FormResponse
    }
  }
}
    ${FormResponseFragmentDoc}`;

/**
 * __useGetFormResponsesPaginatedQuery__
 *
 * To run a query within a React component, call `useGetFormResponsesPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormResponsesPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormResponsesPaginatedQuery({
 *   variables: {
 *      formId: // value for 'formId'
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      offset: // value for 'offset'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useGetFormResponsesPaginatedQuery(baseOptions: Apollo.QueryHookOptions<GetFormResponsesPaginatedQuery, GetFormResponsesPaginatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFormResponsesPaginatedQuery, GetFormResponsesPaginatedQueryVariables>(GetFormResponsesPaginatedDocument, options);
      }
export function useGetFormResponsesPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFormResponsesPaginatedQuery, GetFormResponsesPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFormResponsesPaginatedQuery, GetFormResponsesPaginatedQueryVariables>(GetFormResponsesPaginatedDocument, options);
        }
export type GetFormResponsesPaginatedQueryHookResult = ReturnType<typeof useGetFormResponsesPaginatedQuery>;
export type GetFormResponsesPaginatedLazyQueryHookResult = ReturnType<typeof useGetFormResponsesPaginatedLazyQuery>;
export type GetFormResponsesPaginatedQueryResult = Apollo.QueryResult<GetFormResponsesPaginatedQuery, GetFormResponsesPaginatedQueryVariables>;