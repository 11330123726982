import { differenceWith } from "lodash";
import { PathsDetailedFragment } from "../../../apollo/busRoutes/queries.generated";
import { BusStopDto } from "../../../apollo/types.generated";
import { AssignationPath, BusStop } from "./interfaces";

export const getWayPoints = (
  stops: (BusStop | BusStopDto)[]
): google.maps.DirectionsWaypoint[] => {
  const length = stops.length;
  if (length < 3) return [];

  return stops.slice(0, length).map((s) => {
    if ("latitude" in s) {
      return {
        location: new google.maps.LatLng({ lat: s.latitude, lng: s.longitude }),
        stopover: true,
      };
    }

    return {
      location: new google.maps.LatLng({ ...s }),
      stopover: true,
    };
  });
};

export const mapServerStopsToLocalStops = (stops?: BusStopDto[]): BusStop[] => {
  if (!stops) return [];
  const sortes = [...stops].sort((a, b) => a.order - b.order);
  return sortes.map((s) => ({
    address: s.address,
    id: s.id,
    lat: s.latitude,
    lng: s.longitude,
  }));
};

export const getUnassignedStudents = (
  students: AssignationPath["students"],
  stops: AssignationPath["stops"]
) => {
  return stops.reduce((newStudents, stop, index) => {
    newStudents = differenceWith(
      newStudents,
      stop.students,
      (a, b) => a.id === b.id
    );
    return newStudents;
  }, students);
};
