import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Main from "./Main";
import SignInPage from "../pages/home/SignInPage";
import MobileAppPolicyPage from "../pages/home/MobileAppPolicyPage";
import CreateAccountPage from "../pages/home/CreateAccountPage";

const AppRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact>
          <SignInPage />
        </Route>

        <PrivateRoute path="/dashboard">
          <Main />
        </PrivateRoute>

        <Route
          path={["/createAccount", "/signup", "/SIGNUP", "/SignUp", "/signUp"]}
          exact
        >
          <CreateAccountPage />
        </Route>
        <Route path="/app/privacy" exact>
          <MobileAppPolicyPage />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default AppRoutes;
