import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import PageContainer from "../../shared/components/PageContainer";
import TableCard from "../../shared/components/TableCard";
import RefundVoidTransactionsModal from "./components/RefundVoidTransactionsModal";
import TransactionsTable from "./components/TransactionsTable";

interface TransactionsPageProps {}

const TransactionsPage: React.FC<TransactionsPageProps> = ({}) => {
  const [openModal, setOpenModal] = React.useState(false);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <PageContainer>
      <RefundVoidTransactionsModal
        open={openModal}
        onClose={handleCloseModal}
      />
      <TableCard>
        <Typography
          variant="h5"
          textAlign="start"
          fontWeight="bold"
          color="secondary"
          gutterBottom
        >
          Transactions
        </Typography>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item marginTop={"10px"}>
            <Button variant="outlined" onClick={() => setOpenModal(true)}>
              Refund/Void a Transaction
            </Button>
          </Grid>
        </Grid>
        <TransactionsTable />
      </TableCard>
    </PageContainer>
  );
};

export default TransactionsPage;
