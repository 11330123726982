import { GridColDef } from "@mui/x-data-grid";
import React from "react";

import {
  GetSchoolsPaginatedDocument,
  SchoolsPaginatedItemFragment,
} from "../../../apollo/schools/queries.generated";
import { SchoolSystemPaginatedItemFragment } from "../../../apollo/schoolSystems/queries.generated";
import RemoteDataTable, {
  BasicTableActionFunction,
  getBasicActionColumns,
  RemoteDataTableProps,
} from "../../../shared/components/RemoteDataTable";

interface SchoolsTableProps
  extends Pick<RemoteDataTableProps, "onAddClick" | "refresherBoolean"> {
  onEditPress?: BasicTableActionFunction<SchoolsPaginatedItemFragment>;
  onDeletePress?: BasicTableActionFunction<SchoolsPaginatedItemFragment>;
}
const muiColumns: GridColDef[] = [
  // {
  //   flex: 1,
  //   minWidth: 200,
  //   field: "id",
  //   sortable: false,
  //   headerName: "View",
  //   renderCell: (params) => {
  //     return <Button>{params.value}</Button>;
  //   },
  // },
  { flex: 1, field: "code", headerName: "Code", minWidth: 200 },
  { flex: 1, field: "name", headerName: "Name", minWidth: 200 },
  { flex: 1, field: "state", headerName: "State", minWidth: 200 },
  { flex: 1, field: "status", headerName: "Status", minWidth: 200 },
  {
    flex: 1,
    field: "schoolSystem",
    headerName: "School System",
    valueGetter: ({ value }) => {
      return (value as SchoolSystemPaginatedItemFragment)?.name;
    },
    minWidth: 200,
  },
];

const SchoolsTable: React.FC<SchoolsTableProps> = ({
  onEditPress,
  onDeletePress,
  ...props
}) => {
  return (
    <RemoteDataTable
      query={GetSchoolsPaginatedDocument}
      searchable
      renderAddButton
      columns={muiColumns}
      actionColumns={getBasicActionColumns(onEditPress, onDeletePress)}
      {...props}
    />
  );
};

export default SchoolsTable;
