import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
  Button,
  Slide,
  DialogContentText,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  List,
  ListItem,
  ListItemText,
  Divider,
  TextField,
  Box,
  IconButton,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import {
  DateRange,
  DateRangePicker,
  LoadingButton,
  LocalizationProvider,
} from "@mui/lab";
import { FaSave } from "react-icons/fa";
import { TransitionProps } from "@mui/material/transitions";
import {
  BusRoutesMergeableDocument,
  GetBusRouteDocument,
  MergedBusRoutesDocument,
  MergedBusStopsDocument,
  useBusRoutePathQuery,
  useCreateMergeBusRoutePathMutation,
  useDeleteMergedBusRoutePathMutation,
  useGetBusRouteLazyQuery,
  useGetBusRouteQuery,
  useMergedBusRoutesQuery,
  useUpdateMergedBusRoutePathMutation,
} from "../../../apollo/busRoutes/queries.generated";
import dayjs, { Dayjs } from "dayjs";
import AdapterDayjs from "@mui/lab/AdapterDayjs";
import {
  BusRouteDto,
  MergedBusRoutePathsDto,
} from "../../../apollo/types.generated";
import { Delete, Edit } from "@mui/icons-material";
import useDidMountEffect from "../../../shared/hooks/useDidMountEffect";
import { useLazyQuery } from "@apollo/client";

interface MergesPathUpdateModalProps {
  open: boolean;
  onClose: () => void;
  routeId: string;
  schoolSystemId: string;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MergePathUpdateModal: React.FC<MergesPathUpdateModalProps> = ({
  onClose,
  open,
  schoolSystemId,
  routeId,
}) => {
  const [checkedRoutes, setCheckedRoutes] = React.useState<any>([]);
  const [editableRoutes, setEditableRoutes] = React.useState<any>([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [editOption, setEditOption] = useState<{
    option: string;
    route: MergedBusRoutePathsDto;
  }>();

  const [
    updateMergedBusRoutePath,
    { loading: updateMergedBusRoutePathLoading },
  ] = useUpdateMergedBusRoutePathMutation();

  const [
    deleteMergedBusRoutePath,
    { loading: deleteMergedBusRoutePathLoading },
  ] = useDeleteMergedBusRoutePathMutation();

  const { data: mergedBusRoutesData, loading: mergedBusRoutesLoading } =
    useMergedBusRoutesQuery({
      variables: {
        busRouteId: routeId,
      },
    });

  const mergedRoutes =
    (mergedBusRoutesData?.getMergedBusRoutePaths as MergedBusRoutePathsDto[]) ||
    [];

  useEffect(() => {}, [checkedRoutes]);
  useEffect(() => {}, [editableRoutes]);
  useEffect(() => {}, [editOption]);

  useEffect(() => {
    if (!open) {
      setCheckedRoutes([]);
      setEditableRoutes([]);
    }
  }, [open]);

  const handleDeleteMergedRoute = async (route: MergedBusRoutePathsDto) => {
    await deleteMergedBusRoutePath({
      variables: {
        mergedRouteId: route.id,
      },
      refetchQueries: [
        {
          query: GetBusRouteDocument,
          variables: {
            id: route?.mainBusRouteId,
          },
        },
        {
          query: BusRoutesMergeableDocument,
          variables: {
            schoolSystemId: route?.mainBusRoute.schoolSystem.id,
            busRouteId: route?.mainBusRouteId,
          },
        },
        {
          query: MergedBusRoutesDocument,
          variables: {
            busRouteId: routeId,
          },
        },
        {
          query: MergedBusStopsDocument,
          variables: {
            busRouteId: routeId,
          },
        },
      ],
    });

    setOpenConfirmDialog(false);
    setTimeout(() => {
      onClose();
    }, 1000);
  };

  const handleCheckedRoutes = (
    event: React.MouseEvent<HTMLElement>,
    value: string[]
  ) => {
    const route = mergedRoutes.find(
      (r) => r?.id === event.currentTarget.attributes[3].value
    );

    const dateRange: DateRange<Dayjs> = [
      route?.startMergeTime ? dayjs(route.startMergeTime) : dayjs(),
      route?.endMergeTime ? dayjs(route.endMergeTime) : dayjs(),
    ];

    if (event.currentTarget.attributes[4].value === "false") {
      event.currentTarget.attributes[6].value = "border-color: orange;";
      setCheckedRoutes([
        ...checkedRoutes,
        {
          mergedRouteId: route?.id,
          dates: dateRange,
        },
      ]);
    } else {
      setCheckedRoutes(
        checkedRoutes.filter(
          (item: any) =>
            item.mergedRouteId !== event.currentTarget.attributes[3].value
        )
      );
      event.currentTarget.attributes[6].value = "border-color: gray;";
    }
  };

  const handleClickOpenConfirmDialog = useCallback(() => {
    setOpenConfirmDialog(true);
  }, []);

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleUpdateMergedRoutes = async () => {
    checkedRoutes.forEach(async (item: any) => {
      await updateMergedBusRoutePath({
        variables: {
          data: {
            id: item.mergedRouteId,
            startTime: item.dates[0].toDate(),
            endTime: item.dates[1].toDate(),
          },
        },
        refetchQueries: [
          {
            query: GetBusRouteDocument,
            variables: {
              id: routeId,
            },
          },
          {
            query: BusRoutesMergeableDocument,
            variables: {
              schoolSystemId: schoolSystemId,
              busRouteId: routeId,
            },
          },
          {
            query: MergedBusRoutesDocument,
            variables: {
              busRouteId: routeId,
            },
          },
          {
            query: MergedBusStopsDocument,
            variables: {
              busRouteId: routeId,
            },
          },
        ],
      });
    });

    setOpenConfirmDialog(false);
    setTimeout(() => {
      onClose();
    }, 1000);
  };

  const handleEditRoute = (route: MergedBusRoutePathsDto) => {
    if (
      editableRoutes.find(
        (r: any) => r!.pathId === route?.mergeableBusRoutePath?.id
      )
    ) {
      setEditableRoutes(
        editableRoutes.filter(
          (r: any) => r!.pathId !== route?.mergeableBusRoutePath?.id
        )
      );
    } else {
      setEditableRoutes([
        ...editableRoutes,
        {
          pathId: route?.mergeableBusRoutePath?.id,
        },
      ]);
    }
  };

  const handleEditOptions = (
    option: string,
    route: MergedBusRoutePathsDto | []
  ) => {
    if (option === "delete") {
      setEditOption({
        option: option,
        route: route as MergedBusRoutePathsDto,
      });
      setOpenConfirmDialog(true);
    }

    if (option === "update") {
      setEditOption({
        option: option,
        route: mergedRoutes[0],
      });
      setOpenConfirmDialog(true);
    }
  };

  return (
    <Box>
      <Grid container sx={{ marginTop: "5px", textAlign: "left" }} spacing={2}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Divider
              sx={{
                marginTop: "8px",
                marginBottom: "8px",
              }}
              color="lightgray"
            />
            <Typography>Select routes to update</Typography>

            <List dense={true}>
              {mergedRoutes !== undefined && mergedRoutes.length > 0 ? (
                mergedRoutes.map((route) => (
                  <Grid item xs={12}>
                    <ListItem key={route?.id}>
                      <Grid item xs={6} md={11}>
                        <ToggleButtonGroup
                          value={checkedRoutes?.map((item: any) =>
                            item.mergedRouteId === route?.id ? route?.id : null
                          )}
                          onChange={handleCheckedRoutes}
                          aria-label="Select Routes"
                        >
                          <ToggleButton
                            style={{ borderColor: "gray" }}
                            value={route?.id}
                            color="primary"
                            aria-label="Select Routes"
                          >
                            <Typography textAlign={"start"}>
                              {route.pathName} - <b>{route.pathType}</b>
                            </Typography>
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Grid>
                      {checkedRoutes.map((item: any) =>
                        item.mergedRouteId === route?.id ? (
                          <Grid item xs={12} md={12} lg={12} marginLeft="10px">
                            <LocalizationProvider
                              key={item.mergedRouteId}
                              dateAdapter={AdapterDayjs}
                              localeText={{
                                start: "Start Date",
                                end: "End Date",
                              }}
                            >
                              <DateRangePicker
                                // key={item.pathId}
                                // disableOpenPicker={true}
                                disabled={
                                  editableRoutes.find(
                                    (r: any) =>
                                      r!.pathId ===
                                      route?.mergeableBusRoutePath?.id
                                  )
                                    ? false
                                    : true
                                }
                                value={item.dates}
                                disablePast
                                onChange={(newValue) => {
                                  setCheckedRoutes(
                                    checkedRoutes.map((item: any) =>
                                      item.mergedRouteId === route?.id
                                        ? {
                                            ...item,
                                            dates: newValue.map((date: any) =>
                                              dayjs(date).startOf("day")
                                            ),
                                          }
                                        : item
                                    )
                                  );
                                }}
                                renderInput={(startProps, endProps) => (
                                  <React.Fragment>
                                    <TextField {...startProps} />
                                    <Box sx={{ mx: 1 }}>
                                      <Typography
                                        color={
                                          editableRoutes.find(
                                            (r: any) =>
                                              r!.pathId ===
                                              route?.mergeableBusRoutePath?.id
                                          )
                                            ? "black"
                                            : "gray"
                                        }
                                      >
                                        to
                                      </Typography>
                                    </Box>
                                    <TextField {...endProps} />
                                  </React.Fragment>
                                )}
                              />
                              <Grid item xs={12} textAlign="right">
                                <IconButton
                                  onClick={() => handleEditRoute(route)}
                                  aria-label="edit"
                                  size="small"
                                >
                                  <Edit />
                                </IconButton>
                                <IconButton
                                  onClick={() =>
                                    handleEditOptions("delete", route)
                                  }
                                  aria-label="delete"
                                  size="small"
                                >
                                  <Delete />
                                </IconButton>
                              </Grid>
                            </LocalizationProvider>
                          </Grid>
                        ) : null
                      )}
                    </ListItem>
                    <Divider
                      sx={{
                        marginTop: "10px",
                        marginBottom: "8px",
                      }}
                      color="lightgray"
                    />
                  </Grid>
                ))
              ) : (
                <ListItem>
                  <ListItemText>
                    <Typography color={"gray"}>
                      No merged routes found
                    </Typography>
                  </ListItemText>
                </ListItem>
              )}
            </List>
            <DialogActions>
              <Button
                // type="submit"
                startIcon={<FaSave />}
                sx={{ fontSize: "18px", marginRight: "10px" }}
                onClick={() => handleEditOptions("update", [])}
                disabled={checkedRoutes.length === 0}
              >
                Update routes
              </Button>
              <Button onClick={onClose} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Grid>

          <Dialog
            open={openConfirmDialog}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{"Confirm this action"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                {editOption?.option === "delete" && (
                  <Typography>
                    Are you sure you want to delete the selected route?
                  </Typography>
                )}
                {editOption?.option === "update" && (
                  <Typography>
                    Are you sure you want to update the selected routes?
                  </Typography>
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseConfirmDialog}>Cancel</Button>
              {editOption?.option === "update" && (
                <LoadingButton
                  loading={updateMergedBusRoutePathLoading}
                  onClick={handleUpdateMergedRoutes}
                >
                  Confirm
                </LoadingButton>
              )}
              {editOption?.option === "delete" && (
                <Button
                  onClick={() => handleDeleteMergedRoute(editOption?.route)}
                  disabled={deleteMergedBusRoutePathLoading}
                >
                  Confirm
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MergePathUpdateModal;
