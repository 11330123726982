import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  DriverRoutePeriodFragment,
  useGetRouteDriversLazyQuery,
} from "../../apollo/busDrivers/queries.generated";
import { useGetBusRouteLazyQuery } from "../../apollo/busRoutes/queries.generated";
import LoadingPage from "../home/LoadingPage";
import BusRouteForm from "./components/BusRouteForm";

interface BusRouteFormPageProps {
  isEdit?: boolean;
}

const BusRouteFormPage: React.FC<BusRouteFormPageProps> = ({ isEdit }) => {
  let { id } = useParams<{ id: string }>();
  const [loadRoute, { data, loading, error }] = useGetBusRouteLazyQuery({
    variables: { id: id },
  });
  const [loadDriverPeriod, { data: driverPeriodData }] =
    useGetRouteDriversLazyQuery({
      variables: { routeId: id },
    });

  const [driver, setDriver] = useState<DriverRoutePeriodFragment>();

  useEffect(() => {
    if (isEdit) {
      loadRoute({ variables: { id } });
      loadDriverPeriod({ variables: { routeId: id } });
      setDriver(
        driverPeriodData?.getRouteDrivers.find(
          (driver) => driver.isSubstituteDriver === false
        )
      );
    }
  }, [isEdit, driverPeriodData]);

  if (isEdit && !data) {
    return <LoadingPage />;
  }
  return <BusRouteForm editingRoute={data?.busRoute} driverPeriod={driver} />;
};
export default BusRouteFormPage;
