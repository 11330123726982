import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Collapse, IconButton, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import ControlledTextField from "../../../shared/components/ControlledTextField";
import * as Yup from "yup";
import { Close } from "@mui/icons-material";
import {
  useAddFamilyMemberMutation,
  useMyFamilyQuery,
} from "../../../apollo/families/query.generated";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
type Props = {
  open: boolean;
  onSuccess: () => void;
  onCloseClick: () => void;
};

type FormInputs = {
  email: string;
};

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Type a valid email address.")
    .required("You must specify an email address."),
});

const AddFamilyMemberSection: React.FC<Props> = ({
  open,
  onSuccess,
  onCloseClick,
}) => {
  const { control, handleSubmit, reset } = useForm<FormInputs>({
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const [add, { loading, data, error }] = useAddFamilyMemberMutation();
  const { refetch } = useMyFamilyQuery();

  const onSubmit = (data: FormInputs) => {
    add({ variables: { email: data.email } });
  };

  useEffect(() => {
    if (error) {
      toast.error("There was an error adding member: " + error.message);
    }

    return () => {};
  }, [error]);

  useEffect(() => {
    if (data) {
      toast.success("Family member added successfully!");
      reset();
      refetch();

      // history.
      //The page should redirect to the dashboard once the token is loaded;
    }

    return () => {};
  }, [data]);

  return (
    <Collapse in={open}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignContent="stretch"
        marginBottom="10px"
      >
        <Typography gutterBottom textAlign="start">
          Add Member
        </Typography>
        <IconButton onClick={onCloseClick}>
          <Close />
        </IconButton>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ControlledTextField
          control={control}
          name="email"
          label="Email Address"
          fullWidth
        />
        <Box display="flex" justifyContent="flex-end" marginY="20px">
          <LoadingButton
            loading={loading}
            type="submit"
            variant="outlined"
            color="primary"
            sx={{
              width: {
                xs: "100%",
                sm: "20%",
                md: "30%",
              },
            }}
          >
            Submit
          </LoadingButton>
        </Box>
      </form>
    </Collapse>
  );
};

export default AddFamilyMemberSection;
