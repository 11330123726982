import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SchoolSystemSettingsFragment = { id: string, showBusStops: boolean, timezone?: string | null | undefined, showSubstituteRoutes: boolean, familiesPaymentMethod: Types.SchoolSystemPaymentMethod, feePerRoute: string, subscriptionFee: string, turnByTurnDirections: boolean, showBusTracking: boolean, schoolSystem: { id: string, name: string } };

export type GetSchoolSystemSettingsQueryVariables = Types.Exact<{
  schoolSystemId?: Types.Maybe<Types.Scalars['String']>;
}>;


export type GetSchoolSystemSettingsQuery = { schoolSystemSettings: { id: string, showBusStops: boolean, timezone?: string | null | undefined, showSubstituteRoutes: boolean, familiesPaymentMethod: Types.SchoolSystemPaymentMethod, feePerRoute: string, subscriptionFee: string, turnByTurnDirections: boolean, showBusTracking: boolean, schoolSystem: { id: string, name: string } } };

export type UpdateSchoolSystemSettingsMutationVariables = Types.Exact<{
  schoolSystemId?: Types.Maybe<Types.Scalars['String']>;
  data: Types.UpdateSchoolSystemSettingsDto;
}>;


export type UpdateSchoolSystemSettingsMutation = { updateSchoolSystemSettings: { id: string, timezone?: string | null | undefined } };

export type UserSchoolSystemPaymentMethodQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserSchoolSystemPaymentMethodQuery = { getUserSchoolSystemPaymentMethod?: string | null | undefined };

export type FamilySchoolSystemPaymentMethodQueryVariables = Types.Exact<{
  familyId: Types.Scalars['String'];
}>;


export type FamilySchoolSystemPaymentMethodQuery = { getFamilySchoolSystemPaymentMethod?: string | null | undefined };

export const SchoolSystemSettingsFragmentDoc = gql`
    fragment SchoolSystemSettings on SchoolSystemSettingsDTO {
  id
  showBusStops
  timezone
  showSubstituteRoutes
  familiesPaymentMethod
  feePerRoute
  subscriptionFee
  turnByTurnDirections
  showBusTracking
  schoolSystem {
    id
    name
  }
}
    `;
export const GetSchoolSystemSettingsDocument = gql`
    query getSchoolSystemSettings($schoolSystemId: String) {
  schoolSystemSettings(schoolSystemId: $schoolSystemId) {
    ...SchoolSystemSettings
  }
}
    ${SchoolSystemSettingsFragmentDoc}`;

/**
 * __useGetSchoolSystemSettingsQuery__
 *
 * To run a query within a React component, call `useGetSchoolSystemSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolSystemSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolSystemSettingsQuery({
 *   variables: {
 *      schoolSystemId: // value for 'schoolSystemId'
 *   },
 * });
 */
export function useGetSchoolSystemSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetSchoolSystemSettingsQuery, GetSchoolSystemSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSchoolSystemSettingsQuery, GetSchoolSystemSettingsQueryVariables>(GetSchoolSystemSettingsDocument, options);
      }
export function useGetSchoolSystemSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSchoolSystemSettingsQuery, GetSchoolSystemSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSchoolSystemSettingsQuery, GetSchoolSystemSettingsQueryVariables>(GetSchoolSystemSettingsDocument, options);
        }
export type GetSchoolSystemSettingsQueryHookResult = ReturnType<typeof useGetSchoolSystemSettingsQuery>;
export type GetSchoolSystemSettingsLazyQueryHookResult = ReturnType<typeof useGetSchoolSystemSettingsLazyQuery>;
export type GetSchoolSystemSettingsQueryResult = Apollo.QueryResult<GetSchoolSystemSettingsQuery, GetSchoolSystemSettingsQueryVariables>;
export const UpdateSchoolSystemSettingsDocument = gql`
    mutation updateSchoolSystemSettings($schoolSystemId: String, $data: UpdateSchoolSystemSettingsDTO!) {
  updateSchoolSystemSettings(data: $data, schoolSystemId: $schoolSystemId) {
    id
    timezone
  }
}
    `;
export type UpdateSchoolSystemSettingsMutationFn = Apollo.MutationFunction<UpdateSchoolSystemSettingsMutation, UpdateSchoolSystemSettingsMutationVariables>;

/**
 * __useUpdateSchoolSystemSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateSchoolSystemSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSchoolSystemSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSchoolSystemSettingsMutation, { data, loading, error }] = useUpdateSchoolSystemSettingsMutation({
 *   variables: {
 *      schoolSystemId: // value for 'schoolSystemId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSchoolSystemSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSchoolSystemSettingsMutation, UpdateSchoolSystemSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSchoolSystemSettingsMutation, UpdateSchoolSystemSettingsMutationVariables>(UpdateSchoolSystemSettingsDocument, options);
      }
export type UpdateSchoolSystemSettingsMutationHookResult = ReturnType<typeof useUpdateSchoolSystemSettingsMutation>;
export type UpdateSchoolSystemSettingsMutationResult = Apollo.MutationResult<UpdateSchoolSystemSettingsMutation>;
export type UpdateSchoolSystemSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateSchoolSystemSettingsMutation, UpdateSchoolSystemSettingsMutationVariables>;
export const UserSchoolSystemPaymentMethodDocument = gql`
    query userSchoolSystemPaymentMethod {
  getUserSchoolSystemPaymentMethod
}
    `;

/**
 * __useUserSchoolSystemPaymentMethodQuery__
 *
 * To run a query within a React component, call `useUserSchoolSystemPaymentMethodQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSchoolSystemPaymentMethodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSchoolSystemPaymentMethodQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserSchoolSystemPaymentMethodQuery(baseOptions?: Apollo.QueryHookOptions<UserSchoolSystemPaymentMethodQuery, UserSchoolSystemPaymentMethodQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserSchoolSystemPaymentMethodQuery, UserSchoolSystemPaymentMethodQueryVariables>(UserSchoolSystemPaymentMethodDocument, options);
      }
export function useUserSchoolSystemPaymentMethodLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserSchoolSystemPaymentMethodQuery, UserSchoolSystemPaymentMethodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserSchoolSystemPaymentMethodQuery, UserSchoolSystemPaymentMethodQueryVariables>(UserSchoolSystemPaymentMethodDocument, options);
        }
export type UserSchoolSystemPaymentMethodQueryHookResult = ReturnType<typeof useUserSchoolSystemPaymentMethodQuery>;
export type UserSchoolSystemPaymentMethodLazyQueryHookResult = ReturnType<typeof useUserSchoolSystemPaymentMethodLazyQuery>;
export type UserSchoolSystemPaymentMethodQueryResult = Apollo.QueryResult<UserSchoolSystemPaymentMethodQuery, UserSchoolSystemPaymentMethodQueryVariables>;
export const FamilySchoolSystemPaymentMethodDocument = gql`
    query familySchoolSystemPaymentMethod($familyId: String!) {
  getFamilySchoolSystemPaymentMethod(familyId: $familyId)
}
    `;

/**
 * __useFamilySchoolSystemPaymentMethodQuery__
 *
 * To run a query within a React component, call `useFamilySchoolSystemPaymentMethodQuery` and pass it any options that fit your needs.
 * When your component renders, `useFamilySchoolSystemPaymentMethodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFamilySchoolSystemPaymentMethodQuery({
 *   variables: {
 *      familyId: // value for 'familyId'
 *   },
 * });
 */
export function useFamilySchoolSystemPaymentMethodQuery(baseOptions: Apollo.QueryHookOptions<FamilySchoolSystemPaymentMethodQuery, FamilySchoolSystemPaymentMethodQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FamilySchoolSystemPaymentMethodQuery, FamilySchoolSystemPaymentMethodQueryVariables>(FamilySchoolSystemPaymentMethodDocument, options);
      }
export function useFamilySchoolSystemPaymentMethodLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FamilySchoolSystemPaymentMethodQuery, FamilySchoolSystemPaymentMethodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FamilySchoolSystemPaymentMethodQuery, FamilySchoolSystemPaymentMethodQueryVariables>(FamilySchoolSystemPaymentMethodDocument, options);
        }
export type FamilySchoolSystemPaymentMethodQueryHookResult = ReturnType<typeof useFamilySchoolSystemPaymentMethodQuery>;
export type FamilySchoolSystemPaymentMethodLazyQueryHookResult = ReturnType<typeof useFamilySchoolSystemPaymentMethodLazyQuery>;
export type FamilySchoolSystemPaymentMethodQueryResult = Apollo.QueryResult<FamilySchoolSystemPaymentMethodQuery, FamilySchoolSystemPaymentMethodQueryVariables>;