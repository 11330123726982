import { Print } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import React, { ReactInstance, useRef } from "react";
import QRCode from "react-qr-code";
import ReactToPrint from "react-to-print";
import { BusRouteDetailedFragment } from "../../../apollo/busRoutes/queries.generated";
import CopyButton from "../../../shared/components/CopyButton";

interface SignUpQrCodeProps {
  busRoute: BusRouteDetailedFragment;
}

const SignUpQrCode: React.FC<SignUpQrCodeProps> = ({ busRoute }) => {
  const routeLink =
    process.env.NODE_ENV === "development"
      ? window.location.host +
        `/createAccount?type=student&routeId=${busRoute.routeId}`
      : `https://manage.arttiee.com/signup?type=student&routeId=${busRoute.routeId}`;

  const codeRef = useRef<HTMLDivElement | null>(null);
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{ alignItems: { md: "flex-start", xs: "center" } }}
    >
      <div style={{ display: "none" }}>
        <Box
          ref={codeRef}
          display="flex"
          flexDirection="column"
          width="100%"
          marginY="50px"
          alignItems="center"
        >
          <Typography
            sx={{ marginBottom: "20px" }}
            variant="h4"
          >{`Route ${busRoute.routeId} / ${busRoute.school.name}`}</Typography>
          <QRCode value={routeLink} />
        </Box>
      </div>

      <QRCode value={routeLink} />

      <CopyButton
        textToCopy={routeLink}
        buttonText="Copy Url"
        sx={{
          marginTop: "2%",
          width: {
            xs: "100%",
            sm: "256px",
          },
        }}
      />
      <ReactToPrint
        trigger={() => {
          // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
          // to the root node of the returned component as it will be overwritten.
          return (
            <Button
              variant="contained"
              color="primary"
              startIcon={<Print />}
              sx={{
                marginTop: "2%",
                width: {
                  xs: "100%",
                  sm: "256px",
                },
              }}
            >
              Print it
            </Button>
          );
        }}
        content={() => codeRef.current}
      />
    </Box>
  );
};

export default SignUpQrCode;
