import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton, LocalizationProvider } from "@mui/lab";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as Yup from "yup";
import {
  GetVacationPeriodsDocument,
  useCreateVacationPeriodMutation,
  useUpdateVacationPeriodMutation,
  VacationPeriodFragment,
} from "../../../apollo/vacationPeriods/queries.generated";
import useDidMountEffect from "../../../shared/hooks/useDidMountEffect";

import AdapterDayJs from "@mui/lab/AdapterDayjs";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import ControlledDatePicker from "../../../shared/components/ControlledDatePicker";
import dayjs from "dayjs";

interface VacationPeriodFormModalProps {
  editingPeriod?: VacationPeriodFragment;
  onClose: () => void;
  open: boolean;
  schoolSystemId?: string;
}

interface FormInputs {
  startDate: any | null;
  endDate: any | null;
}

const getDefaultValues = (
  editingPeriod?: VacationPeriodFragment
): FormInputs => {
  return {
    startDate: editingPeriod ? dayjs(editingPeriod.startDate) : null,
    endDate: editingPeriod ? dayjs(editingPeriod.endDate) : null,
  };
};

const validationSchema = Yup.object({
  startDate: Yup.object().required("This field is required."),
  endDate: Yup.object().required("This field is required."),
});

const VacationPeriodFormModal: React.FC<VacationPeriodFormModalProps> = ({
  editingPeriod,
  onClose,
  open,
  schoolSystemId,
}) => {
  const { control, handleSubmit, reset, setValue, watch } = useForm<FormInputs>(
    {
      resolver: yupResolver(validationSchema),
      shouldFocusError: true,
      defaultValues: getDefaultValues(),
    }
  );

  const startDate = watch("startDate");
  const refetch = {
    query: GetVacationPeriodsDocument,
    variables: schoolSystemId ? { schoolSystemId } : undefined,
  };
  const [
    create,
    { data: createData, loading: createLoading, error: createError },
  ] = useCreateVacationPeriodMutation({
    refetchQueries: [refetch],
  });

  const [
    update,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useUpdateVacationPeriodMutation({
    refetchQueries: [refetch],
  });

  const onSubmit = (data: FormInputs) => {
    if (editingPeriod) {
      update({
        variables: {
          id: editingPeriod.id,
          data: {
            ...data,
            schoolSystemId,
          },
        },
      });
      return;
    }

    create({
      variables: {
        data: {
          ...data,
          schoolSystemId,
        },
      },
    });
  };

  useEffect(() => {
    if (createData) {
      toast.success("Period added successfully");
      onClose();
    }

    if (createError) {
      toast.error("Error adding period: " + createError.message);
    }
  }, [createData, createError]);

  useEffect(() => {
    if (updateData) {
      toast.success("Period updated successfully");
      onClose();
    }

    if (updateError) {
      toast.error("Error editing period: " + updateError.message);
    }
  }, [updateData, updateError]);

  useDidMountEffect(() => {
    reset(getDefaultValues(editingPeriod));
  }, [editingPeriod]);

  return (
    <Dialog
      open={open}
      onClose={!createLoading ? onClose : undefined}
      maxWidth="md"
      fullWidth
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <DialogTitle>{editingPeriod ? "Update" : "Create"} Period</DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDayJs}>
            <Grid container spacing={2} sx={{ marginTop: "10px" }}>
              <Grid item xs={12} sm={12} md={6}>
                <ControlledDatePicker
                  label="Start Date"
                  control={control}
                  // minTime={limit}
                  // ampm={false}
                  // shouldDisableTime={(timeValue, clockType) => {
                  //   if (clockType === "hours") console.log({ timeValue });
                  //   return false;
                  // }}
                  name={`startDate`}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <ControlledDatePicker
                  label="End Date"
                  control={control}
                  name={`endDate`}
                  minDate={startDate}
                />
              </Grid>
            </Grid>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel </Button>
          <LoadingButton loading={createLoading || updateLoading} type="submit">
            Submit
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default VacationPeriodFormModal;
