import { Alert, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  MyPaymentMethodFragment,
  useGetMyPaymentMethodQuery,
  useMySubscriptionQuery,
} from "../../apollo/parents/query.generated";
import { useUserSchoolSystemPaymentMethodQuery } from "../../apollo/schoolSystemSettings/queries.generated";
import PageContainer from "../../shared/components/PageContainer";
import useDidMountEffect from "../../shared/hooks/useDidMountEffect";
import PaymentFormModal from "./components/PaymentFormModal";
import PaymentMethodSection from "./components/PaymentMethodSection";
import SubscriptionStatusSection from "./components/SubscriptionStatusSection";

type MySubscriptionPageProps = {};

const MySubscriptionPage: React.FC<MySubscriptionPageProps> = (props) => {
  const { data, loading, error } = useGetMyPaymentMethodQuery();
  const {
    data: subscriptonData,
    loading: subscriptionLoading,
    error: subscriptionError,
  } = useMySubscriptionQuery();

  const { data: systemSchoolPaymentMethodData } =
    useUserSchoolSystemPaymentMethodQuery();

  useEffect(() => {
    if (error) {
      toast.error(
        "Error tring to get your payment method information: " + error?.message
      );
    }
  }, [error]);

  useEffect(() => {
    if (subscriptionError) {
      toast.error(
        "Error tring to get your subscription information: " +
          subscriptionError?.message
      );
    }
  }, [subscriptionError]);

  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const handleOnClosePaymentModal = () => {
    setShowPaymentModal(false);
  };

  const handleOnOpenPaymentModal = () => {
    setShowPaymentModal(true);
  };
  return (
    <PageContainer>
      <PaymentFormModal
        open={showPaymentModal}
        onClose={handleOnClosePaymentModal}
        editingPayment={data?.myPaymentMethod as MyPaymentMethodFragment}
      ></PaymentFormModal>
      <Typography
        variant="h4"
        color="primary"
        textAlign="start"
        sx={{ marginBottom: "20px" }}
      >
        My Subscription
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <SubscriptionStatusSection
            paymentMethod={data?.myPaymentMethod}
            loading={subscriptionLoading}
            subscriptionStatus={subscriptonData?.mySubscription}
            systemSchoolPaymentMethod={
              systemSchoolPaymentMethodData?.getUserSchoolSystemPaymentMethod
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <PaymentMethodSection
            paymentMethod={data?.myPaymentMethod}
            loading={loading}
            handleOpenPaymentModal={handleOnOpenPaymentModal}
            systemSchoolPaymentMethod={
              systemSchoolPaymentMethodData?.getUserSchoolSystemPaymentMethod
            }
          />
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default MySubscriptionPage;
