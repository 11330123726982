import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
  Button,
  Slide,
  DialogContentText,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  List,
  ListItem,
  ListItemText,
  Divider,
  TextField,
  Box,
  IconButton,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import {
  DateRange,
  DateRangePicker,
  LoadingButton,
  LocalizationProvider,
} from "@mui/lab";
import { FaSave } from "react-icons/fa";
import { TransitionProps } from "@mui/material/transitions";
import {
  BusRoutesMergeableDocument,
  GetBusRouteDocument,
  MergedBusRoutesDocument,
  MergedBusStopsDocument,
  useBusRoutePathQuery,
  useCreateMergeBusRoutePathMutation,
  useDeleteMergedBusRoutePathMutation,
  useDeleteMergedBusStopMutation,
  useGetBusRouteLazyQuery,
  useGetBusRouteQuery,
  useMergedBusRoutesQuery,
  useMergedBusStopsQuery,
  useUpdateMergedBusRoutePathMutation,
  useUpdateMergedBusStopMutation,
} from "../../../apollo/busRoutes/queries.generated";
import dayjs, { Dayjs } from "dayjs";
import AdapterDayjs from "@mui/lab/AdapterDayjs";
import {
  BusRouteDto,
  MergedBusRoutePathsDto,
  MergedStopsDto,
  StudentDto,
} from "../../../apollo/types.generated";
import { Delete, Edit } from "@mui/icons-material";
import useDidMountEffect from "../../../shared/hooks/useDidMountEffect";
import { useLazyQuery } from "@apollo/client";

interface MergeStopUpdateModalProps {
  open: boolean;
  onClose: () => void;
  routeId: string;
  schoolSystemId: string;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MergeStopUpdateModal: React.FC<MergeStopUpdateModalProps> = ({
  onClose,
  open,
  schoolSystemId,
  routeId,
}) => {
  const [checkedStops, setCheckedStops] = React.useState<any>([]);
  const [editableStops, setEditableStops] = React.useState<any>([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [editOption, setEditOption] = useState<{
    option: string;
    stop: MergedStopsDto;
  }>();

  const [updateMergedStops, { loading: updateMergedStopsLoading }] =
    useUpdateMergedBusStopMutation();

  const [deleteMergedStops, { loading: deleteMergedStopsLoading }] =
    useDeleteMergedBusStopMutation();

  const { data: mergedStopsData, loading: mergedStopsLoading } =
    useMergedBusStopsQuery({
      variables: {
        busRouteId: routeId,
      },
    });

  const mergedStops =
    (mergedStopsData?.getMergedBusStops as MergedStopsDto[]) || [];

  useEffect(() => {}, [checkedStops]);
  useEffect(() => {}, [editableStops]);
  useEffect(() => {}, [editOption]);

  useEffect(() => {
    if (!open) {
      setCheckedStops([]);
      setEditableStops([]);
    }
  }, [open]);

  const handleDeleteMergedStop = async (stop: MergedStopsDto) => {
    await deleteMergedStops({
      variables: {
        mergedStopId: stop.id,
      },
      refetchQueries: [
        {
          query: GetBusRouteDocument,
          variables: {
            id: routeId,
          },
        },
        {
          query: BusRoutesMergeableDocument,
          variables: {
            schoolSystemId: schoolSystemId,
            busRouteId: routeId,
          },
        },
        {
          query: MergedBusStopsDocument,
          variables: {
            busRouteId: routeId,
          },
        },
        {
          query: MergedBusRoutesDocument,
          variables: {
            busRouteId: routeId,
          },
        },
        {
          query: MergedBusStopsDocument,
          variables: {
            busRouteId: routeId,
          },
        },
      ],
    });

    setOpenConfirmDialog(false);
    setTimeout(() => {
      onClose();
    }, 1000);
  };

  const handleCheckedStops = (
    event: React.MouseEvent<HTMLElement>,
    value: string[]
  ) => {
    const stop = mergedStops.find(
      (r) => r?.id === event.currentTarget.attributes[3].value
    );

    const dateRange: DateRange<Dayjs> = [
      stop?.startMergeTime ? dayjs(stop.startMergeTime) : dayjs(),
      stop?.endMergeTime ? dayjs(stop.endMergeTime) : dayjs(),
    ];

    if (event.currentTarget.attributes[4].value === "false") {
      event.currentTarget.attributes[6].value = "border-color: orange;";
      setCheckedStops([
        ...checkedStops,
        {
          mergedStopId: stop?.id,
          dates: dateRange,
        },
      ]);
    } else {
      setCheckedStops(
        checkedStops.filter(
          (item: any) =>
            item.mergedStopId !== event.currentTarget.attributes[3].value
        )
      );
      event.currentTarget.attributes[6].value = "border-color: gray;";
    }
  };

  const handleClickOpenConfirmDialog = useCallback(() => {
    setOpenConfirmDialog(true);
  }, []);

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleUpdateStops = async () => {
    checkedStops.forEach(async (item: any) => {
      await updateMergedStops({
        variables: {
          data: {
            id: item.mergedStopId,
            startTime: item.dates[0].toDate(),
            endTime: item.dates[1].toDate(),
          },
        },
        refetchQueries: [
          {
            query: GetBusRouteDocument,
            variables: {
              id: routeId,
            },
          },
          {
            query: BusRoutesMergeableDocument,
            variables: {
              schoolSystemId: schoolSystemId,
              busRouteId: routeId,
            },
          },
          {
            query: MergedBusRoutesDocument,
            variables: {
              busRouteId: routeId,
            },
          },
          {
            query: MergedBusStopsDocument,
            variables: {
              busRouteId: routeId,
            },
          },
        ],
      });
    });

    setOpenConfirmDialog(false);
    setTimeout(() => {
      onClose();
    }, 1000);
  };

  const handleEditStop = (stop: MergedStopsDto) => {
    if (editableStops.find((r: any) => r!.stopId === stop?.id)) {
      setEditableStops(
        editableStops.filter((r: any) => r!.stopId !== stop?.id)
      );
    } else {
      setEditableStops([
        ...editableStops,
        {
          stopId: stop?.id,
        },
      ]);
    }
  };

  const handleEditOptions = (option: string, stop: MergedStopsDto | []) => {
    if (option === "delete") {
      setEditOption({
        option: option,
        stop: stop as MergedStopsDto,
      });
      setOpenConfirmDialog(true);
    }

    if (option === "update") {
      setEditOption({
        option: option,
        stop: mergedStops[0],
      });
      setOpenConfirmDialog(true);
    }
  };

  const handleStopStudents = (students: StudentDto[]) => {
    const studentList = students.map((student) => {
      return {
        id: student.id,
        name: student.user.name,
      };
    });

    const studentNames = studentList.map((student) => student.name);

    return studentNames.join(", ");
  };

  return (
    <Box>
      <Grid container sx={{ marginTop: "5px", textAlign: "left" }} spacing={2}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Divider
              sx={{
                marginTop: "8px",
                marginBottom: "8px",
              }}
              color="lightgray"
            />
            <Typography>Select routes to update</Typography>

            <List dense={true}>
              {mergedStops !== undefined && mergedStops.length > 0 ? (
                mergedStops.map((stop) => (
                  <Grid item xs={12}>
                    <ListItem key={stop?.id}>
                      <Grid item xs={6} md={11}>
                        <ToggleButtonGroup
                          value={checkedStops?.map((item: any) =>
                            item.mergedStopId === stop?.id ? stop?.id : null
                          )}
                          onChange={handleCheckedStops}
                          aria-label="Select Routes"
                        >
                          <ToggleButton
                            style={{ borderColor: "gray" }}
                            value={stop?.id}
                            color="primary"
                            aria-label="Select Routes"
                          >
                            <Typography textAlign={"start"}>
                              {stop.mergeableBusStop.address}
                              {stop.mergeableBusStop.students?.length > 0 && (
                                <b>
                                  {" - "} Students:{" "}
                                  {handleStopStudents(
                                    stop.mergeableBusStop.students
                                  )}
                                </b>
                              )}
                            </Typography>
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Grid>
                      {checkedStops.map((item: any) =>
                        item.mergedStopId === stop?.id ? (
                          <Grid item xs={12} md={12} lg={12} marginLeft="10px">
                            <LocalizationProvider
                              key={item.mergedStopId}
                              dateAdapter={AdapterDayjs}
                              localeText={{
                                start: "Start Date",
                                end: "End Date",
                              }}
                            >
                              <DateRangePicker
                                // key={item.pathId}
                                // disableOpenPicker={true}
                                disabled={
                                  editableStops.find(
                                    (r: any) => r!.stopId === stop?.id
                                  )
                                    ? false
                                    : true
                                }
                                value={item.dates}
                                disablePast
                                onChange={(newValue) => {
                                  setCheckedStops(
                                    checkedStops.map((item: any) =>
                                      item.mergedStopId === stop?.id
                                        ? {
                                            ...item,
                                            dates: newValue.map((date: any) =>
                                              dayjs(date).startOf("day")
                                            ),
                                          }
                                        : item
                                    )
                                  );
                                }}
                                renderInput={(startProps, endProps) => (
                                  <React.Fragment>
                                    <TextField {...startProps} />
                                    <Box sx={{ mx: 1 }}>
                                      <Typography
                                        color={
                                          editableStops.find(
                                            (r: any) => r!.stopId === stop?.id
                                          )
                                            ? "black"
                                            : "gray"
                                        }
                                      >
                                        to
                                      </Typography>
                                    </Box>
                                    <TextField {...endProps} />
                                  </React.Fragment>
                                )}
                              />
                              <Grid item xs={12} textAlign="right">
                                <IconButton
                                  onClick={() => handleEditStop(stop)}
                                  aria-label="edit"
                                  size="small"
                                >
                                  <Edit />
                                </IconButton>
                                <IconButton
                                  onClick={() =>
                                    handleEditOptions("delete", stop)
                                  }
                                  aria-label="delete"
                                  size="small"
                                >
                                  <Delete />
                                </IconButton>
                              </Grid>
                            </LocalizationProvider>
                          </Grid>
                        ) : null
                      )}
                    </ListItem>
                    <Divider
                      sx={{
                        marginTop: "10px",
                        marginBottom: "8px",
                      }}
                      color="lightgray"
                    />
                  </Grid>
                ))
              ) : (
                <ListItem>
                  <ListItemText>
                    <Typography color={"gray"}>
                      No merged stops found
                    </Typography>
                  </ListItemText>
                </ListItem>
              )}
            </List>
            <DialogActions>
              <Button
                // type="submit"
                startIcon={<FaSave />}
                sx={{ fontSize: "18px", marginRight: "10px" }}
                onClick={() => handleEditOptions("update", [])}
                disabled={checkedStops.length === 0}
              >
                Update stops
              </Button>
              <Button onClick={onClose} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Grid>

          <Dialog
            open={openConfirmDialog}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{"Confirm this action"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                {editOption?.option === "delete" && (
                  <Typography>
                    Are you sure you want to delete the selected stop?
                  </Typography>
                )}
                {editOption?.option === "update" && (
                  <Typography>
                    Are you sure you want to update the selected stops?
                  </Typography>
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseConfirmDialog}>Cancel</Button>
              {editOption?.option === "update" && (
                <LoadingButton
                  loading={updateMergedStopsLoading}
                  onClick={handleUpdateStops}
                >
                  Confirm
                </LoadingButton>
              )}
              {editOption?.option === "delete" && (
                <Button
                  onClick={() => handleDeleteMergedStop(editOption?.stop)}
                  disabled={deleteMergedStopsLoading}
                >
                  Confirm
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MergeStopUpdateModal;
