import React from "react";
import { Control, Controller, Path } from "react-hook-form";
import { MultiSelectItem } from "./interfaces";
import MultiSelectDialogField, {
  MultiSelectDialogFieldProps,
} from "./MultiSelectDialogField";

interface ControlledMultiSelectDialogProps<
  TFieldValues,
  Context extends object
> {
  name: Path<TFieldValues>;
  control: Control<TFieldValues, Context>;
}
type Props<
  TFieldValues,
  Context extends object,
  Item
> = ControlledMultiSelectDialogProps<TFieldValues, Context> &
  Omit<MultiSelectDialogFieldProps<Item>, "onChange" | "value">;
const ControlledMultiSelectDialog = <
  TFieldValues,
  Context extends object,
  Item extends MultiSelectItem
>({
  name,
  control,
  ...props
}: Props<TFieldValues, Context, Item>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <MultiSelectDialogField
          {...props}
          onChange={field.onChange}
          value={field.value as Item[]}
          textfieldProps={{
            fullWidth: true,
            error: !!fieldState.error?.message,
            helperText: fieldState.error?.message,
            onBlur: field.onBlur,
            ref: field.ref,
            ...props.textfieldProps,
          }}
        />
      )}
    />
  );
};

export default ControlledMultiSelectDialog;
