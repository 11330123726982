import React, { useEffect } from "react";
import {
  Grid,
  Modal,
  Paper,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useCreateSchoolSystemMutation,
  useUpdateSchoolSystemMutation,
} from "../../../apollo/auth/mutations.generated";
import {
  GetSchoolSystemsPaginatedDocument,
  SchoolSystemPaginatedItemFragment,
} from "../../../apollo/schoolSystems/queries.generated";
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import { useNow } from "@mui/lab/internal/pickers/hooks/useUtils";
import useDidMountEffect from "../../../shared/hooks/useDidMountEffect";
interface SchoolSystemFormModalProps {
  open: boolean;
  onClose: () => void;
  editingSystem?: SchoolSystemPaginatedItemFragment;
}

interface FormInputs {
  name: string;
}

const schema: Yup.SchemaOf<FormInputs> = Yup.object({
  name: Yup.string().required("This field is required"),
});

const SchoolSystemFormModal: React.FC<SchoolSystemFormModalProps> = ({
  open,
  onClose,
  editingSystem,
}) => {
  const { control, handleSubmit, setValue } = useForm<FormInputs>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    defaultValues: {
      name: editingSystem?.name || "",
    },
  });

  const [
    create,
    { data: createData, loading: createLoading, error: createError },
  ] = useCreateSchoolSystemMutation({
    refetchQueries: [GetSchoolSystemsPaginatedDocument],
  });

  const [
    update,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useUpdateSchoolSystemMutation({
    refetchQueries: [GetSchoolSystemsPaginatedDocument],
  });

  const onSubmit = (data: FormInputs) => {
    if (editingSystem) {
      update({
        variables: {
          id: editingSystem.id,
          data,
        },
      });
      return;
    }
    create({ variables: { data } });
  };

  useEffect(() => {
    if (createData) {
      toast.success("School System created successfully");
      onClose();
    }

    if (createError) {
      toast.error("Error creating School System: " + createError.message);
    }
  }, [createData, createError]);

  useEffect(() => {
    if (updateData) {
      toast.success("School System updated successfully");
      onClose();
    }

    if (updateError) {
      toast.error("Error updating School System: " + updateError.message);
    }
  }, [updateData, updateError]);

  useDidMountEffect(() => {
    setValue("name", editingSystem ? editingSystem.name : "");
  }, [editingSystem]);
  return (
    <Dialog
      open={open}
      onClose={!createLoading ? onClose : undefined}
      maxWidth="md"
      fullWidth
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          {editingSystem ? "Update" : "Create"} School System
        </DialogTitle>
        <DialogContent>
          <Grid container sx={{ marginTop: "10px" }}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="name"
                render={({ field, formState }) => (
                  <TextField
                    {...field}
                    label="Name"
                    fullWidth
                    error={!!formState.errors.name}
                    helperText={formState.errors.name?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel </Button>
          <LoadingButton loading={createLoading || updateLoading} type="submit">
            Submit
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default SchoolSystemFormModal;
