import createStore from "zustand";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";
dayjs.extend(utc);
dayjs.extend(timezone);

export type SettingsStore = {
  timezone: string;
  loadTimezone: (timezone: string) => void;
};

const useSettingsStore = createStore<SettingsStore>((set, get) => ({
  timezone: dayjs.tz.guess(),

  loadTimezone: (timezone: string) => {
    set({ timezone });
  },
}));

export default useSettingsStore;
