import { Box, Card, Grid, Typography } from "@mui/material";
import React, { useState } from "react";

import DeleteModal from "../../shared/components/DeleteModal";

import ParentsTable from "./components/ParentsTable";

import {
  DeleteParentDocument,
  GetParentsPaginatedDocument,
  ParentsPaginatedItemFragment,
} from "../../apollo/parents/query.generated";
import ParentsFormModal from "./components/ParentsFormModal";
import PageContainer from "../../shared/components/PageContainer";
import TableCard from "../../shared/components/TableCard";

interface ParentsPageProps {}

const ParentsPage: React.FC<ParentsPageProps> = ({}) => {
  const [showFormModal, setShowFormModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedParent, setSelectedParent] =
    useState<ParentsPaginatedItemFragment>();

  const handleFormClose = () => {
    setSelectedParent(undefined);
    setShowFormModal(false);
  };

  const handleDeleteModalClose = () => {
    setSelectedParent(undefined);
    setShowDeleteModal(false);
  };
  return (
    <PageContainer>
      <ParentsFormModal
        editingParent={selectedParent}
        open={showFormModal}
        onClose={handleFormClose}
      />
      <DeleteModal
        open={showDeleteModal}
        mutation={DeleteParentDocument}
        updateQueries={[GetParentsPaginatedDocument]}
        variables={{ id: selectedParent?.id }}
        onClose={handleDeleteModalClose}
        title="Delete Parent"
      />
      <TableCard>
        <Typography
          variant="h5"
          textAlign="start"
          fontWeight="bold"
          color="secondary"
          gutterBottom
        >
          Parents
        </Typography>
        <ParentsTable
          onAddClick={() => setShowFormModal(true)}
          onEditPress={(item) => {
            setSelectedParent(item);
            setShowFormModal(true);
          }}
          onDeletePress={(item) => {
            setSelectedParent(item);
            setShowDeleteModal(true);
          }}
        />
        {/* <DataGrid
        style={{
          border: "none",
        }}
        columns={columns}
        rows={data?.StudentsystemsPaginated.nodes || []}
      /> */}
      </TableCard>
    </PageContainer>
  );
};

export default ParentsPage;
