import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UserFragment = { name: string, firstName: string, lastName: string, email: string, role: string, id: string, createdAt: any, updatedAt: any, isDisabled: boolean };

export type UsersPaginatedItemFragment = { name: string, firstName: string, lastName: string, email: string, role: string, id: string, createdAt: any, updatedAt: any, isDisabled: boolean, schoolSystem?: { id: string, name: string } | null | undefined, school?: { id: string, name: string } | null | undefined };

export type CheckEmailAvailabilityQueryVariables = Types.Exact<{
  email: Types.Scalars['String'];
  userId?: Types.Maybe<Types.Scalars['String']>;
}>;


export type CheckEmailAvailabilityQuery = { checkEmailAvailability: boolean };

export type GetUsersPaginatedQueryVariables = Types.Exact<{
  search?: Types.Maybe<Types.Scalars['String']>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  sortBy?: Types.Maybe<Types.Scalars['String']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  sortDirection?: Types.Maybe<Types.Scalars['String']>;
  schoolSystemId?: Types.Maybe<Types.Scalars['String']>;
  schoolId?: Types.Maybe<Types.Scalars['String']>;
}>;


export type GetUsersPaginatedQuery = { usersPaginated: { totalsCount: number, nodes?: Array<{ name: string, firstName: string, lastName: string, email: string, role: string, id: string, createdAt: any, updatedAt: any, isDisabled: boolean, schoolSystem?: { id: string, name: string } | null | undefined, school?: { id: string, name: string } | null | undefined }> | null | undefined } };

export type GetUserQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetUserQuery = { User: { name: string, firstName: string, lastName: string, email: string, role: string, id: string, createdAt: any, updatedAt: any, isDisabled: boolean, schoolSystem?: { id: string, name: string } | null | undefined, school?: { id: string, name: string } | null | undefined } };

export type CreateUserMutationVariables = Types.Exact<{
  data: Types.CreateUserDto;
}>;


export type CreateUserMutation = { createUser: { id: string, createdAt: any, updatedAt: any } };

export type UpdateUserMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  data: Types.UpdateUserDto;
}>;


export type UpdateUserMutation = { updateUser: { id: string } };

export type DeleteUserMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteUserMutation = { deleteUser: boolean };

export type UpdateUserStatusMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  isDisabled: Types.Scalars['Boolean'];
}>;


export type UpdateUserStatusMutation = { updateUserStatus: { id: string, isDisabled: boolean } };

export type GetUsersFamilyPaginatedQueryVariables = Types.Exact<{
  search?: Types.Maybe<Types.Scalars['String']>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  sortBy?: Types.Maybe<Types.Scalars['String']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  sortDirection?: Types.Maybe<Types.Scalars['String']>;
  schoolSystemId?: Types.Maybe<Types.Scalars['String']>;
  schoolId?: Types.Maybe<Types.Scalars['String']>;
}>;


export type GetUsersFamilyPaginatedQuery = { usersFamilyPaginated: { totalsCount: number, nodes?: Array<{ name: string, firstName: string, lastName: string, email: string, role: string, id: string, createdAt: any, updatedAt: any, isDisabled: boolean, schoolSystem?: { id: string, name: string } | null | undefined, school?: { id: string, name: string } | null | undefined }> | null | undefined } };

export type GetSchoolSystemDisabledUsersPaginatedQueryVariables = Types.Exact<{
  search?: Types.Maybe<Types.Scalars['String']>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  sortBy?: Types.Maybe<Types.Scalars['String']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  sortDirection?: Types.Maybe<Types.Scalars['String']>;
  schoolSystemId?: Types.Maybe<Types.Scalars['String']>;
}>;


export type GetSchoolSystemDisabledUsersPaginatedQuery = { getSchoolSystemDisabledUsersPaginated: { totalsCount: number, nodes?: Array<{ name: string, firstName: string, lastName: string, email: string, role: string, id: string, createdAt: any, updatedAt: any, isDisabled: boolean, schoolSystem?: { id: string, name: string } | null | undefined, school?: { id: string, name: string } | null | undefined }> | null | undefined } };

export const UserFragmentDoc = gql`
    fragment User on UserDTO {
  name
  firstName
  lastName
  email
  role
  id
  createdAt
  updatedAt
  isDisabled
}
    `;
export const UsersPaginatedItemFragmentDoc = gql`
    fragment UsersPaginatedItem on UserDTO {
  ...User
  schoolSystem {
    id
    name
  }
  school {
    id
    name
  }
}
    ${UserFragmentDoc}`;
export const CheckEmailAvailabilityDocument = gql`
    query checkEmailAvailability($email: String!, $userId: String) {
  checkEmailAvailability(email: $email, userId: $userId)
}
    `;

/**
 * __useCheckEmailAvailabilityQuery__
 *
 * To run a query within a React component, call `useCheckEmailAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckEmailAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckEmailAvailabilityQuery({
 *   variables: {
 *      email: // value for 'email'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCheckEmailAvailabilityQuery(baseOptions: Apollo.QueryHookOptions<CheckEmailAvailabilityQuery, CheckEmailAvailabilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckEmailAvailabilityQuery, CheckEmailAvailabilityQueryVariables>(CheckEmailAvailabilityDocument, options);
      }
export function useCheckEmailAvailabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckEmailAvailabilityQuery, CheckEmailAvailabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckEmailAvailabilityQuery, CheckEmailAvailabilityQueryVariables>(CheckEmailAvailabilityDocument, options);
        }
export type CheckEmailAvailabilityQueryHookResult = ReturnType<typeof useCheckEmailAvailabilityQuery>;
export type CheckEmailAvailabilityLazyQueryHookResult = ReturnType<typeof useCheckEmailAvailabilityLazyQuery>;
export type CheckEmailAvailabilityQueryResult = Apollo.QueryResult<CheckEmailAvailabilityQuery, CheckEmailAvailabilityQueryVariables>;
export const GetUsersPaginatedDocument = gql`
    query getUsersPaginated($search: String, $limit: Int, $sortBy: String, $offset: Int, $sortDirection: String, $schoolSystemId: String, $schoolId: String) {
  usersPaginated(
    search: $search
    limit: $limit
    offset: $offset
    sortBy: $sortBy
    sortDirection: $sortDirection
    schoolSystemId: $schoolSystemId
    schoolId: $schoolId
  ) {
    nodes {
      ...UsersPaginatedItem
    }
    totalsCount
  }
}
    ${UsersPaginatedItemFragmentDoc}`;

/**
 * __useGetUsersPaginatedQuery__
 *
 * To run a query within a React component, call `useGetUsersPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersPaginatedQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      offset: // value for 'offset'
 *      sortDirection: // value for 'sortDirection'
 *      schoolSystemId: // value for 'schoolSystemId'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetUsersPaginatedQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersPaginatedQuery, GetUsersPaginatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersPaginatedQuery, GetUsersPaginatedQueryVariables>(GetUsersPaginatedDocument, options);
      }
export function useGetUsersPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersPaginatedQuery, GetUsersPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersPaginatedQuery, GetUsersPaginatedQueryVariables>(GetUsersPaginatedDocument, options);
        }
export type GetUsersPaginatedQueryHookResult = ReturnType<typeof useGetUsersPaginatedQuery>;
export type GetUsersPaginatedLazyQueryHookResult = ReturnType<typeof useGetUsersPaginatedLazyQuery>;
export type GetUsersPaginatedQueryResult = Apollo.QueryResult<GetUsersPaginatedQuery, GetUsersPaginatedQueryVariables>;
export const GetUserDocument = gql`
    query getUser($id: String!) {
  User(id: $id) {
    ...UsersPaginatedItem
  }
}
    ${UsersPaginatedItemFragmentDoc}`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const CreateUserDocument = gql`
    mutation createUser($data: CreateUserDTO!) {
  createUser(data: $data) {
    id
    createdAt
    updatedAt
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($id: String!, $data: UpdateUserDTO!) {
  updateUser(id: $id, data: $data) {
    id
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($id: String!) {
  deleteUser(id: $id)
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const UpdateUserStatusDocument = gql`
    mutation updateUserStatus($id: String!, $isDisabled: Boolean!) {
  updateUserStatus(id: $id, isDisabled: $isDisabled) {
    id
    isDisabled
  }
}
    `;
export type UpdateUserStatusMutationFn = Apollo.MutationFunction<UpdateUserStatusMutation, UpdateUserStatusMutationVariables>;

/**
 * __useUpdateUserStatusMutation__
 *
 * To run a mutation, you first call `useUpdateUserStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserStatusMutation, { data, loading, error }] = useUpdateUserStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isDisabled: // value for 'isDisabled'
 *   },
 * });
 */
export function useUpdateUserStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserStatusMutation, UpdateUserStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserStatusMutation, UpdateUserStatusMutationVariables>(UpdateUserStatusDocument, options);
      }
export type UpdateUserStatusMutationHookResult = ReturnType<typeof useUpdateUserStatusMutation>;
export type UpdateUserStatusMutationResult = Apollo.MutationResult<UpdateUserStatusMutation>;
export type UpdateUserStatusMutationOptions = Apollo.BaseMutationOptions<UpdateUserStatusMutation, UpdateUserStatusMutationVariables>;
export const GetUsersFamilyPaginatedDocument = gql`
    query getUsersFamilyPaginated($search: String, $limit: Int, $sortBy: String, $offset: Int, $sortDirection: String, $schoolSystemId: String, $schoolId: String) {
  usersFamilyPaginated(
    search: $search
    limit: $limit
    offset: $offset
    sortBy: $sortBy
    sortDirection: $sortDirection
    schoolSystemId: $schoolSystemId
    schoolId: $schoolId
  ) {
    nodes {
      ...UsersPaginatedItem
    }
    totalsCount
  }
}
    ${UsersPaginatedItemFragmentDoc}`;

/**
 * __useGetUsersFamilyPaginatedQuery__
 *
 * To run a query within a React component, call `useGetUsersFamilyPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersFamilyPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersFamilyPaginatedQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      offset: // value for 'offset'
 *      sortDirection: // value for 'sortDirection'
 *      schoolSystemId: // value for 'schoolSystemId'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetUsersFamilyPaginatedQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersFamilyPaginatedQuery, GetUsersFamilyPaginatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersFamilyPaginatedQuery, GetUsersFamilyPaginatedQueryVariables>(GetUsersFamilyPaginatedDocument, options);
      }
export function useGetUsersFamilyPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersFamilyPaginatedQuery, GetUsersFamilyPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersFamilyPaginatedQuery, GetUsersFamilyPaginatedQueryVariables>(GetUsersFamilyPaginatedDocument, options);
        }
export type GetUsersFamilyPaginatedQueryHookResult = ReturnType<typeof useGetUsersFamilyPaginatedQuery>;
export type GetUsersFamilyPaginatedLazyQueryHookResult = ReturnType<typeof useGetUsersFamilyPaginatedLazyQuery>;
export type GetUsersFamilyPaginatedQueryResult = Apollo.QueryResult<GetUsersFamilyPaginatedQuery, GetUsersFamilyPaginatedQueryVariables>;
export const GetSchoolSystemDisabledUsersPaginatedDocument = gql`
    query getSchoolSystemDisabledUsersPaginated($search: String, $limit: Int, $sortBy: String, $offset: Int, $sortDirection: String, $schoolSystemId: String) {
  getSchoolSystemDisabledUsersPaginated(
    search: $search
    limit: $limit
    offset: $offset
    sortBy: $sortBy
    sortDirection: $sortDirection
    schoolSystemId: $schoolSystemId
  ) {
    nodes {
      ...UsersPaginatedItem
    }
    totalsCount
  }
}
    ${UsersPaginatedItemFragmentDoc}`;

/**
 * __useGetSchoolSystemDisabledUsersPaginatedQuery__
 *
 * To run a query within a React component, call `useGetSchoolSystemDisabledUsersPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolSystemDisabledUsersPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolSystemDisabledUsersPaginatedQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      offset: // value for 'offset'
 *      sortDirection: // value for 'sortDirection'
 *      schoolSystemId: // value for 'schoolSystemId'
 *   },
 * });
 */
export function useGetSchoolSystemDisabledUsersPaginatedQuery(baseOptions?: Apollo.QueryHookOptions<GetSchoolSystemDisabledUsersPaginatedQuery, GetSchoolSystemDisabledUsersPaginatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSchoolSystemDisabledUsersPaginatedQuery, GetSchoolSystemDisabledUsersPaginatedQueryVariables>(GetSchoolSystemDisabledUsersPaginatedDocument, options);
      }
export function useGetSchoolSystemDisabledUsersPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSchoolSystemDisabledUsersPaginatedQuery, GetSchoolSystemDisabledUsersPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSchoolSystemDisabledUsersPaginatedQuery, GetSchoolSystemDisabledUsersPaginatedQueryVariables>(GetSchoolSystemDisabledUsersPaginatedDocument, options);
        }
export type GetSchoolSystemDisabledUsersPaginatedQueryHookResult = ReturnType<typeof useGetSchoolSystemDisabledUsersPaginatedQuery>;
export type GetSchoolSystemDisabledUsersPaginatedLazyQueryHookResult = ReturnType<typeof useGetSchoolSystemDisabledUsersPaginatedLazyQuery>;
export type GetSchoolSystemDisabledUsersPaginatedQueryResult = Apollo.QueryResult<GetSchoolSystemDisabledUsersPaginatedQuery, GetSchoolSystemDisabledUsersPaginatedQueryVariables>;