import React, { useMemo } from "react";
import {
  Avatar,
  Button,
  colors,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { PathsDetailedFragment } from "../../../apollo/busRoutes/queries.generated";
import { getUnassignedStudents, mapServerStopsToLocalStops } from "./utils";
import { BusStopDto } from "../../../apollo/types.generated";
import RoutePreviewer from "./RoutePreviewer";
import TimezoneAwareDatetime, {
  timezoneAwareFormat,
} from "../../../shared/components/TimezoneAwareDatetime";
import RouteLogsTable from "./RouteLogsTable";

interface SchedulesAreaProps {
  routeId: string;
  path: PathsDetailedFragment;
}

const SchedulesArea: React.FC<SchedulesAreaProps> = ({ path, routeId }) => {
  const unassignedStudents = useMemo(
    () => getUnassignedStudents(path.students, path.stops),
    [path.students, path.stops]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4">{path.type}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          <TimezoneAwareDatetime
            sx={{ display: "inline" }}
            datetime={path.startTime}
            format="hh:mm A"
          />
          {" - "}
          <TimezoneAwareDatetime
            sx={{ display: "inline" }}
            datetime={path.endTime}
            format="hh:mm A"
          />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <RoutePreviewer busStops={path.stops} />
      </Grid>
      <Grid item xs={12} sx={{ marginTop: "20px" }}>
        <Typography variant="h5" textAlign="start">
          Unassigned Students
        </Typography>
        <List>
          {unassignedStudents.length > 0 ? (
            unassignedStudents.map((s) => (
              <React.Fragment key={s.id}>
                <ListItem disablePadding sx={{ marginBottom: "10px" }}>
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: "primary" }} variant="rounded">
                      {s.user.name.charAt(0)}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={`${s.user.name}`} />
                </ListItem>
              </React.Fragment>
            ))
          ) : (
            <Typography textAlign="start" color={colors.grey[500]}>
              All the students have been assigned to a stop.
            </Typography>
          )}
        </List>
        <Grid item xs={12} sx={{ marginTop: "20px" }}>
          <Typography variant="h5" textAlign="start">
            Route Logs
          </Typography>
          <RouteLogsTable routeId={routeId} pathType={path.type} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SchedulesArea;
