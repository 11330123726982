import {
  Avatar,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React from "react";
import { VacationPeriodFragment } from "../../../apollo/vacationPeriods/queries.generated";
import dayjs from "dayjs";
import { Delete } from "@mui/icons-material";
interface VacationPeriodsListProps {
  items: VacationPeriodFragment[];
  onEditClick: (item: VacationPeriodFragment) => void;
  onDeleteClick: (item: VacationPeriodFragment) => void;
}

const VacationPeriodsList: React.FC<VacationPeriodsListProps> = ({
  items,
  onEditClick,
  onDeleteClick,
}) => {
  return (
    <List>
      {items.map((item, i) => (
        <React.Fragment key={item.id}>
          <ListItem
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => onDeleteClick(item)}
              >
                <Delete />
              </IconButton>
            }
          >
            <ListItemButton onClick={() => onEditClick(item)}>
              <ListItemAvatar>
                <Avatar>{i + 1 + ""}</Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`From ${dayjs(item.startDate).format(
                  "MM/DD/YYYY"
                )}, To ${dayjs(item.endDate).format("MM/DD/YYYY")} `}
              />
            </ListItemButton>
          </ListItem>

          <Divider variant="inset" component="li" />
        </React.Fragment>
      ))}
    </List>
  );
};

export default VacationPeriodsList;
