import { Box, Chip, TextField, TextFieldProps } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import MultiSelectDialog, { MultiSelectDialogProps } from ".";
import { MultiSelectItem } from "./interfaces";

export interface MultiSelectDialogFieldProps<Item>
  extends Omit<
    MultiSelectDialogProps<Item>,
    "open" | "onItemsChange" | "onClose" | "selectedItems" | "title"
  > {
  onChange: (items: Item[]) => void;
  value?: Item[];
  dialogTitle?: string;
  textfieldProps?: TextFieldProps;
}

const MultiSelectDialogField = <Item extends MultiSelectItem>({
  labelsExtractor,
  onChange,
  value,
  variables,
  query,
  limit,
  dialogTitle,
  textfieldProps,
}: MultiSelectDialogFieldProps<Item>) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const onSelectFocus = useCallback(() => {
    setDialogOpen((o) => (o = true));
  }, [setDialogOpen]);
  const onDialogClose = useCallback(() => {
    console.log("close");
    setDialogOpen((o) => (o = false));
  }, [setDialogOpen]);

  const renderValues = useCallback(
    (selected?: Item[]) => {
      if (!selected || !selected?.length) return undefined;
      const handleDelete = (itemIndex: number) => () => {
        const temp = [...selected];
        temp.splice(itemIndex, 1);
        onChange(temp);
      };
      return (
        <>
          {selected.map((item, index) => (
            <Chip
              key={item.id}
              label={labelsExtractor(item).primary}
              onDelete={handleDelete(index)}
              sx={{ marginRight: "5px" }}
            />
          ))}
        </>
      );
    },
    [labelsExtractor, onChange]
  );

  const selected = useMemo(() => value || [], [value]);

  return (
    <>
      <MultiSelectDialog
        open={dialogOpen}
        onClose={onDialogClose}
        labelsExtractor={labelsExtractor}
        title={dialogTitle}
        onItemsChange={onChange}
        selectedItems={selected}
        query={query}
        limit={limit}
        variables={variables}
      />
      {/* <FormControl
        fullWidth={selectProps?.fullWidth}
        error={selectProps?.error}
      >
        <InputLabel>{selectProps?.label}</InputLabel> */}
      <TextField
        contentEditable={false}
        onClick={onSelectFocus}
        {...textfieldProps}
        //   multiple
        // label="Text"
        // value={undefined}
        InputProps={{
          startAdornment: renderValues(value),
        }}
      />
      {/* {selectProps?.helperText && (
          <FormHelperText>{selectProps?.helperText}</FormHelperText>
        )}
      </FormControl> */}
    </>
  );
};

export default MultiSelectDialogField;
