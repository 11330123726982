import { Person, SchoolOutlined, PersonAdd } from "@mui/icons-material";
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Fab,
  Portal,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import toast from "react-hot-toast";
import {
  MyFamilyItemFragment,
  useMyFamilyQuery,
  useRemoveFamilyMemberMutation,
} from "../../../apollo/families/query.generated";
import useAuthUser from "../../../shared/hooks/useAuthUser";
import AddFamilyMemberSection from "./AddFamilyMemberSection";

type ParentsContentProps = {};

const ParentsContent: React.FC<ParentsContentProps> = (props) => {
  const [addMemberOpen, setAddMemberOpen] = useState(false);
  const { loading, data, error, refetch } = useMyFamilyQuery();
  const [remove, {}] = useRemoveFamilyMemberMutation({
    onCompleted: () => {
      toast.success("Member removed successfully!");
      refetch();
    },
    onError: (error) => {
      toast.success("Error trying to remove member: " + error.message);
    },
  });
  const user = useAuthUser();

  const onAddMemberSuccess = () => {
    refetch();
  };

  const onRemoveClick = (userId: string) => {
    remove({
      variables: {
        id: userId,
      },
    });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          fontSize="2rem"
          fontWeight="200"
          gutterBottom
          textAlign="start"
        >
          Family Management
        </Typography>
        <Portal>
          <Fab
            onClick={() => {
              setAddMemberOpen(true);
            }}
            variant="extended"
            color="primary"
            sx={{
              color: "white",
              position: "absolute",
              right: "5%",
              bottom: "5%",
            }}
          >
            <PersonAdd sx={{ mr: 1 }} />
            Add Member
          </Fab>
        </Portal>
      </Grid>
      <Grid item xs={12}>
        <AddFamilyMemberSection
          open={addMemberOpen}
          onSuccess={onAddMemberSuccess}
          onCloseClick={() => setAddMemberOpen(false)}
        />
      </Grid>
      <Grid item xs={12}>
        <FamilyMembersList
          family={data?.myFamily}
          userId={user?.id}
          onRemoveClick={onRemoveClick}
        />
      </Grid>
    </Grid>
  );
};

type FamilyMembersListProps = {
  userId?: string;
  family?: MyFamilyItemFragment | null;
  onRemoveClick: (id: string) => void;
};

const FamilyMembersList: React.FC<FamilyMembersListProps> = ({
  family,
  userId,
  onRemoveClick,
}) => {
  if (!family) {
    return (
      <Typography textAlign="start">
        No members have been added to your family.
      </Typography>
    );
  }

  const members = [...family.members].sort((a, b) => {
    if (a.role === "Parent") return -1;
    if (b.role === "Parent") return 1;
    return 0;
  });

  return (
    <>
      <Typography gutterBottom textAlign="start">
        Members
      </Typography>
      <List>
        {members.map((m) => {
          const isUser = m.id === userId;
          return (
            <ListItem
              key={m.id}
              secondaryAction={
                !isUser ? (
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => onRemoveClick(m.id)}
                  >
                    Remove
                  </Button>
                ) : undefined
              }
            >
              <ListItemIcon>
                {m.role === "Parent" ? <Person /> : <SchoolOutlined />}
              </ListItemIcon>
              <ListItemText
                primary={`${m.name} ${isUser ? "(You)" : ""}`}
                secondary={m.email}
              />
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

export default ParentsContent;
