import { TextField, TextFieldProps } from "@mui/material";
import React from "react";
import { Controller, Control, Path } from "react-hook-form";

interface ControlledTextFieldProps<TFieldValues, Context extends object> {
  name: Path<TFieldValues>;
  control: Control<TFieldValues, Context>;
}

const ControlledTextField = <TFieldValues, Context extends object>({
  control,
  name,
  ...props
}: ControlledTextFieldProps<TFieldValues, Context> &
  Omit<TextFieldProps, "name">) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...props}
          {...field}
          ref={field.ref}
          fullWidth
          error={!!error}
          helperText={error?.message}
        />
      )}
    />
  );
};

export default ControlledTextField;
