import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  DeleteFamilyDocument,
  FamilyPaginatedItemFragment,
  GetFamiliesPaginatedDocument,
  useDeleteFamilyWithNoMembersMutation,
} from "../../apollo/families/query.generated";
import DeleteModal from "../../shared/components/DeleteModal";
import PageContainer from "../../shared/components/PageContainer";
import TableCard from "../../shared/components/TableCard";
import FamiliesTable from "./components/FamiliesTable";
import FamilyFormModal from "./components/FamilyFormModal";
import FamilyMembersModal from "./components/FamilyMembersModal";

interface FamiliesPageProps {}

const FamiliesPage: React.FC<FamiliesPageProps> = ({}) => {
  const [showFormModal, setShowFormModal] = useState(false);
  const [showMembersModal, setShowMembersModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedFamily, setSelectedFamily] =
    useState<FamilyPaginatedItemFragment>();
  const [deleteFamilyNoMembers] = useDeleteFamilyWithNoMembersMutation({
    refetchQueries: [GetFamiliesPaginatedDocument],
  });

  deleteFamilyNoMembers();

  const handleFormClose = () => {
    setSelectedFamily(undefined);
    setShowFormModal(false);
  };

  const handleDeleteModalClose = () => {
    setSelectedFamily(undefined);
    setShowDeleteModal(false);
  };

  const handleMembersModalClose = () => {
    setSelectedFamily(undefined);
    setShowMembersModal(false);
  };

  return (
    <PageContainer>
      <FamilyMembersModal
        open={showMembersModal}
        family={selectedFamily}
        onClose={handleMembersModalClose}
      />
      <FamilyFormModal
        editingFamily={selectedFamily}
        open={showFormModal}
        onClose={handleFormClose}
      />
      <DeleteModal
        open={showDeleteModal}
        mutation={DeleteFamilyDocument}
        updateQueries={[GetFamiliesPaginatedDocument]}
        variables={{ id: selectedFamily?.id }}
        onClose={handleDeleteModalClose}
        title="Delete Family"
      />
      <TableCard>
        <Typography
          variant="h5"
          textAlign="start"
          fontWeight="bold"
          color="secondary"
          gutterBottom
        >
          Families
        </Typography>
        <FamiliesTable
          onAddClick={() => setShowFormModal(true)}
          onViewClick={(item) => {
            setSelectedFamily(item);
            setShowMembersModal(true);
          }}
          onEditPress={(item) => {
            setSelectedFamily(item);
            setShowFormModal(true);
          }}
          onDeletePress={(item) => {
            setSelectedFamily(item);
            setShowDeleteModal(true);
          }}
        />
        {/* <DataGrid
          style={{
            border: "none",
          }}
          columns={columns}
          rows={data?.StudentsystemsPaginated.nodes || []}
        /> */}
      </TableCard>
    </PageContainer>
  );
};

export default FamiliesPage;
