import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
  Button,
} from "@mui/material";
import React from "react";
import { FamilyPaginatedItemFragment } from "../../../apollo/families/query.generated";
import FamilyPreviewSection from "./FamilyPreviewSection";

interface FamilyMembersModalProps {
  open: boolean;
  onClose: () => void;
  family?: FamilyPaginatedItemFragment;
}

const FamilyMembersModal: React.FC<FamilyMembersModalProps> = ({
  onClose,
  open,
  family,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{family?.name}'s Family Members</DialogTitle>
      <DialogContent>
        <Grid container sx={{ marginTop: "10px" }} spacing={2}>
          <Grid item xs={12}>
            {/* <FamilyPreviewSection parents={family?.parents || []} /> */}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Dismiss</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FamilyMembersModal;
