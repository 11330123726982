import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SchoolsPaginatedItemFragment = { id: string, name: string, code: string, state: string, status: Types.SchoolStatus, addressLat?: number | null | undefined, addressLng?: number | null | undefined, address?: string | null | undefined, createdAt: any, updatedAt: any, schoolSystem?: { id: string, name: string } | null | undefined };

export type GetSchoolsPaginatedQueryVariables = Types.Exact<{
  search?: Types.Maybe<Types.Scalars['String']>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  sortBy?: Types.Maybe<Types.Scalars['String']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  sortDirection?: Types.Maybe<Types.Scalars['String']>;
  schoolSystemId?: Types.Maybe<Types.Scalars['String']>;
  schoolId?: Types.Maybe<Types.Scalars['String']>;
}>;


export type GetSchoolsPaginatedQuery = { schoolsPaginated: { totalsCount: number, nodes?: Array<{ id: string, name: string, code: string, state: string, status: Types.SchoolStatus, addressLat?: number | null | undefined, addressLng?: number | null | undefined, address?: string | null | undefined, createdAt: any, updatedAt: any, schoolSystem?: { id: string, name: string } | null | undefined }> | null | undefined } };

export type GetSchoolQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetSchoolQuery = { school: { id: string, schoolSystemId?: string | null | undefined } };

export type CreateSchoolMutationVariables = Types.Exact<{
  data: Types.CreateSchoolDto;
}>;


export type CreateSchoolMutation = { createSchool: { id: string, name: string, createdAt: any, updatedAt: any } };

export type UpdateSchoolMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  data: Types.UpdateSchoolDto;
}>;


export type UpdateSchoolMutation = { updateSchool: { id: string } };

export type DeleteSchoolMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteSchoolMutation = { deleteSchool: boolean };

export type GetDisabledSchoolsPaginatedQueryVariables = Types.Exact<{
  search?: Types.Maybe<Types.Scalars['String']>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  sortBy?: Types.Maybe<Types.Scalars['String']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  sortDirection?: Types.Maybe<Types.Scalars['String']>;
  schoolSystemId?: Types.Maybe<Types.Scalars['String']>;
  schoolId?: Types.Maybe<Types.Scalars['String']>;
}>;


export type GetDisabledSchoolsPaginatedQuery = { getDisabledSchoolsPaginated: { totalsCount: number, nodes?: Array<{ id: string, name: string, code: string, state: string, status: Types.SchoolStatus, addressLat?: number | null | undefined, addressLng?: number | null | undefined, address?: string | null | undefined, createdAt: any, updatedAt: any, schoolSystem?: { id: string, name: string } | null | undefined }> | null | undefined } };

export const SchoolsPaginatedItemFragmentDoc = gql`
    fragment SchoolsPaginatedItem on SchoolDTO {
  id
  name
  schoolSystem {
    id
    name
  }
  code
  state
  status
  addressLat
  addressLng
  address
  createdAt
  updatedAt
}
    `;
export const GetSchoolsPaginatedDocument = gql`
    query getSchoolsPaginated($search: String, $limit: Int, $sortBy: String, $offset: Int, $sortDirection: String, $schoolSystemId: String, $schoolId: String) {
  schoolsPaginated(
    search: $search
    limit: $limit
    offset: $offset
    sortBy: $sortBy
    sortDirection: $sortDirection
    schoolSystemId: $schoolSystemId
    schoolId: $schoolId
  ) {
    nodes {
      ...SchoolsPaginatedItem
    }
    totalsCount
  }
}
    ${SchoolsPaginatedItemFragmentDoc}`;

/**
 * __useGetSchoolsPaginatedQuery__
 *
 * To run a query within a React component, call `useGetSchoolsPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolsPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolsPaginatedQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      offset: // value for 'offset'
 *      sortDirection: // value for 'sortDirection'
 *      schoolSystemId: // value for 'schoolSystemId'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetSchoolsPaginatedQuery(baseOptions?: Apollo.QueryHookOptions<GetSchoolsPaginatedQuery, GetSchoolsPaginatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSchoolsPaginatedQuery, GetSchoolsPaginatedQueryVariables>(GetSchoolsPaginatedDocument, options);
      }
export function useGetSchoolsPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSchoolsPaginatedQuery, GetSchoolsPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSchoolsPaginatedQuery, GetSchoolsPaginatedQueryVariables>(GetSchoolsPaginatedDocument, options);
        }
export type GetSchoolsPaginatedQueryHookResult = ReturnType<typeof useGetSchoolsPaginatedQuery>;
export type GetSchoolsPaginatedLazyQueryHookResult = ReturnType<typeof useGetSchoolsPaginatedLazyQuery>;
export type GetSchoolsPaginatedQueryResult = Apollo.QueryResult<GetSchoolsPaginatedQuery, GetSchoolsPaginatedQueryVariables>;
export const GetSchoolDocument = gql`
    query getSchool($id: String!) {
  school(id: $id) {
    id
    schoolSystemId
  }
}
    `;

/**
 * __useGetSchoolQuery__
 *
 * To run a query within a React component, call `useGetSchoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSchoolQuery(baseOptions: Apollo.QueryHookOptions<GetSchoolQuery, GetSchoolQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSchoolQuery, GetSchoolQueryVariables>(GetSchoolDocument, options);
      }
export function useGetSchoolLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSchoolQuery, GetSchoolQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSchoolQuery, GetSchoolQueryVariables>(GetSchoolDocument, options);
        }
export type GetSchoolQueryHookResult = ReturnType<typeof useGetSchoolQuery>;
export type GetSchoolLazyQueryHookResult = ReturnType<typeof useGetSchoolLazyQuery>;
export type GetSchoolQueryResult = Apollo.QueryResult<GetSchoolQuery, GetSchoolQueryVariables>;
export const CreateSchoolDocument = gql`
    mutation createSchool($data: CreateSchoolDTO!) {
  createSchool(data: $data) {
    id
    name
    createdAt
    updatedAt
  }
}
    `;
export type CreateSchoolMutationFn = Apollo.MutationFunction<CreateSchoolMutation, CreateSchoolMutationVariables>;

/**
 * __useCreateSchoolMutation__
 *
 * To run a mutation, you first call `useCreateSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSchoolMutation, { data, loading, error }] = useCreateSchoolMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSchoolMutation(baseOptions?: Apollo.MutationHookOptions<CreateSchoolMutation, CreateSchoolMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSchoolMutation, CreateSchoolMutationVariables>(CreateSchoolDocument, options);
      }
export type CreateSchoolMutationHookResult = ReturnType<typeof useCreateSchoolMutation>;
export type CreateSchoolMutationResult = Apollo.MutationResult<CreateSchoolMutation>;
export type CreateSchoolMutationOptions = Apollo.BaseMutationOptions<CreateSchoolMutation, CreateSchoolMutationVariables>;
export const UpdateSchoolDocument = gql`
    mutation updateSchool($id: String!, $data: UpdateSchoolDTO!) {
  updateSchool(id: $id, data: $data) {
    id
  }
}
    `;
export type UpdateSchoolMutationFn = Apollo.MutationFunction<UpdateSchoolMutation, UpdateSchoolMutationVariables>;

/**
 * __useUpdateSchoolMutation__
 *
 * To run a mutation, you first call `useUpdateSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSchoolMutation, { data, loading, error }] = useUpdateSchoolMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSchoolMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSchoolMutation, UpdateSchoolMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSchoolMutation, UpdateSchoolMutationVariables>(UpdateSchoolDocument, options);
      }
export type UpdateSchoolMutationHookResult = ReturnType<typeof useUpdateSchoolMutation>;
export type UpdateSchoolMutationResult = Apollo.MutationResult<UpdateSchoolMutation>;
export type UpdateSchoolMutationOptions = Apollo.BaseMutationOptions<UpdateSchoolMutation, UpdateSchoolMutationVariables>;
export const DeleteSchoolDocument = gql`
    mutation deleteSchool($id: String!) {
  deleteSchool(id: $id)
}
    `;
export type DeleteSchoolMutationFn = Apollo.MutationFunction<DeleteSchoolMutation, DeleteSchoolMutationVariables>;

/**
 * __useDeleteSchoolMutation__
 *
 * To run a mutation, you first call `useDeleteSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSchoolMutation, { data, loading, error }] = useDeleteSchoolMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSchoolMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSchoolMutation, DeleteSchoolMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSchoolMutation, DeleteSchoolMutationVariables>(DeleteSchoolDocument, options);
      }
export type DeleteSchoolMutationHookResult = ReturnType<typeof useDeleteSchoolMutation>;
export type DeleteSchoolMutationResult = Apollo.MutationResult<DeleteSchoolMutation>;
export type DeleteSchoolMutationOptions = Apollo.BaseMutationOptions<DeleteSchoolMutation, DeleteSchoolMutationVariables>;
export const GetDisabledSchoolsPaginatedDocument = gql`
    query getDisabledSchoolsPaginated($search: String, $limit: Int, $sortBy: String, $offset: Int, $sortDirection: String, $schoolSystemId: String, $schoolId: String) {
  getDisabledSchoolsPaginated(
    search: $search
    limit: $limit
    offset: $offset
    sortBy: $sortBy
    sortDirection: $sortDirection
    schoolSystemId: $schoolSystemId
    schoolId: $schoolId
  ) {
    nodes {
      ...SchoolsPaginatedItem
    }
    totalsCount
  }
}
    ${SchoolsPaginatedItemFragmentDoc}`;

/**
 * __useGetDisabledSchoolsPaginatedQuery__
 *
 * To run a query within a React component, call `useGetDisabledSchoolsPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDisabledSchoolsPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDisabledSchoolsPaginatedQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      offset: // value for 'offset'
 *      sortDirection: // value for 'sortDirection'
 *      schoolSystemId: // value for 'schoolSystemId'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetDisabledSchoolsPaginatedQuery(baseOptions?: Apollo.QueryHookOptions<GetDisabledSchoolsPaginatedQuery, GetDisabledSchoolsPaginatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDisabledSchoolsPaginatedQuery, GetDisabledSchoolsPaginatedQueryVariables>(GetDisabledSchoolsPaginatedDocument, options);
      }
export function useGetDisabledSchoolsPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDisabledSchoolsPaginatedQuery, GetDisabledSchoolsPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDisabledSchoolsPaginatedQuery, GetDisabledSchoolsPaginatedQueryVariables>(GetDisabledSchoolsPaginatedDocument, options);
        }
export type GetDisabledSchoolsPaginatedQueryHookResult = ReturnType<typeof useGetDisabledSchoolsPaginatedQuery>;
export type GetDisabledSchoolsPaginatedLazyQueryHookResult = ReturnType<typeof useGetDisabledSchoolsPaginatedLazyQuery>;
export type GetDisabledSchoolsPaginatedQueryResult = Apollo.QueryResult<GetDisabledSchoolsPaginatedQuery, GetDisabledSchoolsPaginatedQueryVariables>;