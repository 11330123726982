import {
  Button,
  colors,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";
import { BusRouteForAssignationsFragment } from "../../../apollo/busRoutes/queries.generated";
import { ExtractArrayType } from "../../../shared/utils";
import { cloneDeep, differenceWith } from "lodash";
import { Controller, useForm } from "react-hook-form";
import { getUnassignedStudents } from "./utils";
import { AssignationPath } from "./interfaces";

interface StudentsStopsAssignerProps {
  paths: BusRouteForAssignationsFragment["paths"];
  onSubmit: (data: AssignerFormInput) => void;
}

export interface AssignerFormInput {
  paths: {
    unassignedStudents: AssignationPath["students"];
    stops: AssignationPath["stops"];
  }[];
}

const StudentsStopsAssigner: React.FC<StudentsStopsAssignerProps> = ({
  paths,
  onSubmit,
}) => {
  const { control, handleSubmit } = useForm<AssignerFormInput>({
    defaultValues: getDefaultValues(paths),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3} sx={{ marginBottom: "30px" }}>
        {paths.map((path, i) => (
          <React.Fragment key={path.id}>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                {path.type + " Schedule"}
              </Typography>
            </Grid>
            <Grid item xs={12} key={path.id}>
              <Controller
                name={`paths.${i}`}
                control={control}
                render={({ field, fieldState, formState }) => {
                  const onChange = (path: PathData) => {
                    field.onChange({ target: { value: path } });
                  };

                  return (
                    <ScheduleAssigner
                      path={field.value}
                      onPathDataChange={onChange}
                    />
                  );
                }}
              />
            </Grid>
          </React.Fragment>
        ))}
        <Grid item xs={12} alignItems="flex-end">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const getDefaultValues = (paths: AssignationPath[]): AssignerFormInput => {
  return {
    paths: paths.map((p) => ({
      stops: p.stops,
      unassignedStudents: getUnassignedStudents(p.students, p.stops),
    })),
  };
};

const moveUnassigned = (
  type: "toUnassigned" | "fromUnassigned",
  unassignedStudents: AssignationPath["students"],
  currentStops: AssignationPath["stops"],
  stopId: string,
  studentId: string
) => {
  const newStudents = [...unassignedStudents];
  const newStops = cloneDeep(currentStops);
  const stopIndex = newStops.findIndex((s) => s.id === stopId);
  // console.log({ stopIndex });
  if (type === "toUnassigned") {
    // console.log({ newStops });
    const studentIndex = newStops[stopIndex].students.findIndex(
      (s) => s.id === studentId
    );
    if (studentIndex > -1) {
      const [removed] = newStops[stopIndex].students.splice(studentIndex, 1);
      newStudents.push(removed);
    }

    return { newStudents, newStops };
  }

  const studentIndex = newStudents.findIndex((s) => s.id === studentId);
  if (studentIndex > -1) {
    const [removed] = newStudents.splice(studentIndex, 1);
    // console.log({ removed, students: newStops[stopIndex].students });
    newStops[stopIndex].students.push(removed);
  }

  return { newStudents, newStops };
};
const moveFromStops = (
  currentStops: AssignationPath["stops"],
  sourceStopId: string,
  destinationStopId: string,
  studentId: string
) => {
  const newStops = cloneDeep(currentStops);
  const sourceIndex = newStops.findIndex((s) => s.id === sourceStopId);
  const destinationIndex = newStops.findIndex(
    (s) => s.id === destinationStopId
  );
  const studentIndex = newStops[sourceIndex].students.findIndex(
    (s) => s.id === studentId
  );
  const [removed] = newStops[sourceIndex].students.splice(studentIndex, 1);
  newStops[destinationIndex].students.push(removed);
  return newStops;
};

type PathData = {
  unassignedStudents: AssignationPath["students"];
  stops: AssignationPath["stops"];
};

interface ScheduleAssignerProps {
  path: PathData;

  onPathDataChange: (data: PathData) => void;
}

const ScheduleAssigner: React.FC<ScheduleAssignerProps> = ({
  path: { unassignedStudents, stops },
  onPathDataChange,
}) => {
  const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    const sInd = source.droppableId;
    const dInd = destination.droppableId;

    if (sInd !== dInd) {
      if (dInd === "students-list") {
        const { newStops, newStudents } = moveUnassigned(
          "toUnassigned",
          unassignedStudents,
          stops,
          sInd,
          result.draggableId
        );

        onPathDataChange({ stops: newStops, unassignedStudents: newStudents });
        //Move to students list;
      } else if (sInd === "students-list") {
        //move to another stop list;
        const { newStops, newStudents } = moveUnassigned(
          "fromUnassigned",
          unassignedStudents,
          stops,
          dInd,
          result.draggableId
        );

        onPathDataChange({ stops: newStops, unassignedStudents: newStudents });
      } else {
        const newStops = moveFromStops(stops, sInd, dInd, result.draggableId);
        onPathDataChange({ stops: newStops, unassignedStudents });
      }
    }
  };

  return (
    <Grid container spacing={3}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Grid item xs={6}>
          <Typography variant="h6" textAlign="start">
            Unassigned Students
          </Typography>
          <Droppable droppableId={"students-list"}>
            {(provided, snapshot) => (
              <List
                ref={provided.innerRef}
                {...provided.droppableProps}
                title="Unassigned Students"
                sx={{
                  backgroundColor: snapshot.isDraggingOver
                    ? colors.lightBlue["100"]
                    : colors.grey["300"],
                  minHeight: "100px",
                  borderRadius: "20px",
                  padding: "10px",

                  transition: "all 0.5s ease-out;",
                }}
              >
                {unassignedStudents.map((student, index) => (
                  <Draggable
                    key={student.id}
                    draggableId={student.id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <ListItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        sx={{
                          backgroundColor: colors.common.white,
                          borderRadius: "10px",
                          marginY: "5px",
                        }}
                      >
                        <ListItemText
                          key={student.user.id}
                          primary={student.user.name}
                        />
                      </ListItem>
                    )}
                  </Draggable>
                ))}
              </List>
            )}
          </Droppable>
        </Grid>
        <Grid item xs={6} container spacing={3}>
          {stops.map((stop, i) => (
            <Grid item xs={12} key={stop.id}>
              <Typography textAlign="start" variant="h6">{`${i + 1} - ${
                stop.address
              }`}</Typography>
              <Droppable key={stop.id} droppableId={stop.id}>
                {(provided, snapshot) => (
                  <List
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    title={stop.address}
                    sx={{
                      backgroundColor: snapshot.isDraggingOver
                        ? colors.lightBlue["100"]
                        : colors.grey["300"],
                      minHeight: "100px",
                      borderRadius: "20px",
                      padding: "10px",

                      transition: "all 0.5s ease-out;",
                    }}
                  >
                    {stop.students.map((student, index) => (
                      <Draggable
                        key={student.id}
                        draggableId={student.id}
                        index={index}
                      >
                        {(provided) => (
                          <ListItem
                            ref={provided.innerRef}
                            title={student.user.name}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            sx={{
                              backgroundColor: colors.common.white,
                              borderRadius: "10px",
                              marginY: "5px",
                            }}
                          >
                            <ListItemText primary={student.user.name} />
                          </ListItem>
                        )}
                      </Draggable>
                    ))}
                  </List>
                )}
              </Droppable>
            </Grid>
          ))}
        </Grid>
      </DragDropContext>
    </Grid>
  );
};

export default StudentsStopsAssigner;
