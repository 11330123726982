import { Card } from "@mui/material";
import React from "react";

interface TableCardProps {}

const TableCard: React.FC<TableCardProps> = ({ children }) => {
  return (
    <Card
      variant="modern"
      sx={{
        padding: "30px",
        minHeight: "500px",
        width: "100%",
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
      }}
    >
      {" "}
      {children}
    </Card>
  );
};

export default TableCard;
