import dayjs from "dayjs";
import { useEffect } from "react";
import { useGetSchoolSystemSettingsLazyQuery } from "../../apollo/schoolSystemSettings/queries.generated";
import useAuthStore from "../stores/authStore";
import useSettingsStore  from "../stores/settingsStore";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc)
dayjs.extend(timezone)

const SettingsLoader: React.FC = () => {
    const [getSettings, { data, error }] = useGetSchoolSystemSettingsLazyQuery();
    const { authToken } = useAuthStore();
    const { loadTimezone } = useSettingsStore();

    useEffect(() => {
        if (authToken) {
            getSettings();
        }
    }, [authToken, getSettings]);

    useEffect(() => {
        if(data?.schoolSystemSettings.timezone) {
            loadTimezone(data.schoolSystemSettings.timezone);
            dayjs.tz.setDefault(data.schoolSystemSettings.timezone);
        }

        if (error) {
           console.log(error);
        }
    }, [data, error, loadTimezone]);

    return null;

}

export default SettingsLoader;