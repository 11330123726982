import { GridColDef } from "@mui/x-data-grid";
import React from "react";
import {
  BusDriversPaginatedItemFragment,
  GetBusDriversPaginatedDocument,
} from "../../../apollo/busDrivers/queries.generated";
import { SchoolSystemPaginatedItemFragment } from "../../../apollo/schoolSystems/queries.generated";

import {
  GetStudentsPaginatedDocument,
  StudentsPaginatedItemFragment,
} from "../../../apollo/students/queries.generated";
import { SchoolDto } from "../../../apollo/types.generated";
import Link from "../../../navigation/Link";

import RemoteDataTable, {
  BasicTableActionFunction,
  getBasicActionColumns,
  RemoteDataTableProps,
} from "../../../shared/components/RemoteDataTable";

interface BusDriversTableProps
  extends Pick<RemoteDataTableProps, "onAddClick" | "refresherBoolean"> {
  onEditPress?: BasicTableActionFunction<BusDriversPaginatedItemFragment>;
  onDeletePress?: BasicTableActionFunction<BusDriversPaginatedItemFragment>;
}
const muiColumns: GridColDef[] = [
  // {
  //   flex: 1,
  //   minWidth: 200,
  //   field: "id",
  //   sortable: false,
  //   headerName: "View",
  //   renderCell: (params) => {
  //     return <Button>{params.value}</Button>;
  //   },
  // },

  {
    flex: 1,
    field: "name",
    headerName: "Name",
    minWidth: 200,
    renderCell: ({ row }) => {
      const student = row as BusDriversPaginatedItemFragment;
      return (
        <Link to={"/dashboard/busdrivers/" + student.id}>
          {`${student.user.firstName} ${student.user.lastName}`}
        </Link>
      );
    },
  },
  {
    flex: 1,
    field: "email",
    headerName: "Email",
    minWidth: 200,
    valueGetter: ({ row }) => {
      const student = row as BusDriversPaginatedItemFragment;
      return student.user.email;
    },
  },
  {
    flex: 1,
    field: "busNumber",
    headerName: "Bus Number",
    minWidth: 200,
  },

  { flex: 1, field: "accountStatus", headerName: "Status", minWidth: 200 },
  {
    flex: 1,
    sortable: false,

    field: "assignedSchools",
    headerName: "Schools",
    valueGetter: ({ value }) => {
      return (value as SchoolDto[]).map((s) => s.name).join(", ");
    },
    minWidth: 200,
  },
  {
    flex: 1,
    field: "schoolSystem",
    headerName: "School System",
    valueGetter: ({ value }) => {
      return (value as SchoolSystemPaginatedItemFragment)?.name;
    },
    minWidth: 200,
  },
];

const BusDriversTable: React.FC<BusDriversTableProps> = ({
  onEditPress,
  onDeletePress,
  ...props
}) => {
  return (
    <RemoteDataTable
      query={GetBusDriversPaginatedDocument}
      searchable
      renderAddButton
      columns={muiColumns}
      actionColumns={getBasicActionColumns(onEditPress, onDeletePress)}
      {...props}
    />
  );
};

export default BusDriversTable;
