import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  GoogleMap,
  Marker,
  DirectionsRenderer,
  DirectionsService,
} from "@react-google-maps/api";
import { Container, Grid } from "@mui/material";

import * as uuid from "uuid";

import PlaceSearch, {
  PlaceResult,
} from "../../../shared/components/PlacesSearch";
import MapsApiLoader from "../../../shared/components/MapsApiLoader";
import OrderList from "./OrderList";
import { BusStop } from "./interfaces";
import { getWayPoints } from "./utils";

interface RouteCreatorProps {
  onBusStopsChange: (stops: BusStop[]) => void;
  stops?: BusStop[];
  initialStops?: BusStop[];
}

const RouteCreator: React.FC<RouteCreatorProps> = ({
  onBusStopsChange,
  initialStops = [],
  stops,
}) => {
  const [busStops, setBusStops] = useState<BusStop[]>(initialStops);
  const onDeleteClick = (item: BusStop) => {
    setBusStops((list) => list.filter((i) => i.id !== item.id));
  };
  const onPlaceAdded = (result: PlaceResult) => {
    setBusStops((stops) => {
      return [
        ...stops,
        {
          ...result,
          id: "FAKE-" + uuid.v1(),
        },
      ];
    });
  };

  useEffect(() => {
    // console.
    onBusStopsChange(busStops);
  }, [onBusStopsChange, busStops]);

  return (
    <MapsApiLoader>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PlaceSearch onPlaceChange={onPlaceAdded} />
        </Grid>

        <Grid item xs={12}>
          <Map points={stops ? stops : busStops} />
        </Grid>

        <Grid item xs={12}>
          <OrderList
            busStops={stops ? stops : busStops}
            onReorder={setBusStops}
            onDeleteClick={onDeleteClick}
          />
        </Grid>
      </Grid>
    </MapsApiLoader>
  );
};
// RouteCreator.whyDidYouRender = true;
export default RouteCreator;

interface MapProps {
  points: BusStop[];
}

const Map: React.FC<MapProps> = ({ points }) => {
  const map = useRef<google.maps.Map>();
  const [directionResults, setDirectionResults] =
    useState<google.maps.DirectionsResult | null>(null);
  const onMapLoad = (loadedMap: google.maps.Map) => {
    map.current = loadedMap;
  };

  const onDirectionsLoad = (
    result: google.maps.DirectionsResult | null,
    status: google.maps.DirectionsStatus
  ) => {
    if (result !== null) {
      if (status === "OK") {
        setDirectionResults(result);
      }
    }
  };

  useEffect(() => {
    if (points && points[0]) {
      map.current?.setCenter(points[0]);
    }
    return () => {};
  }, [points, map]);

  const directions = useMemo(() => {
    if (points.length < 2) {
      return null;
    }

    return (
      <DirectionsService
        callback={onDirectionsLoad}
        options={{
          destination: points[points.length - 1],
          origin: points[0],
          travelMode: google.maps.TravelMode.DRIVING,
          waypoints: getWayPoints(points),
        }}
      />
    );
  }, [points.length]);

  return (
    <>
      <GoogleMap
        mapContainerStyle={{
          height: "500px",
          borderRadius: "15px",
        }}
        center={{
          lat: -3.745,
          lng: -38.523,
        }}
        zoom={10}
        onLoad={onMapLoad}
      >
        {/* Child components, such as markers, info windows, etc. */}
        {directions}

        {directionResults !== null && (
          <DirectionsRenderer
            options={{
              directions: directionResults,
            }}
          />
        )}
        {points?.map((p, i) => (
          <Marker
            position={p}
            key={p.id}
            label={{
              text: `${i + 1} - ${p.address}`,
              className: "marker-label",
            }}
          />
        ))}
      </GoogleMap>
    </>
  );
};
