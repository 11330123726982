import {
  Box,
  Button,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Slide,
  Typography,
} from "@mui/material";
import InfoSection, { InfoItem } from "../../../shared/components/InfoSection";
import InfoIcon from "@mui/icons-material/Info";
import EventNoteIcon from "@mui/icons-material/EventNote";
import BadgeIcon from "@mui/icons-material/Badge";
import PaidIcon from "@mui/icons-material/Paid";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AssistantIcon from "@mui/icons-material/Assistant";
import { format } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import {
  MySubscriptionFragment,
  usePauseSubscriptionMutation,
  useResumeSubscriptionMutation,
} from "../../../apollo/parents/query.generated";
import { TransitionProps } from "@mui/material/transitions";
import {
  usePauseFamilySubscriptionMutation,
  useResumeFamilySubscriptionMutation,
} from "../../../apollo/families/query.generated";
import { InternalRefetchQueriesInclude } from "@apollo/client";
import moment from "moment";

type Props = {
  familySubscription?: MySubscriptionFragment | null;
  handleOpenUpdateSubscriptionModal: () => void;
  inAdminPanel: boolean;
  propFamilyId: string;
  refetchQ?: InternalRefetchQueriesInclude;
  systemSchoolPaymentMethod?: string | null;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FamilyDetailsSection = ({
  familySubscription,
  handleOpenUpdateSubscriptionModal,
  inAdminPanel,
  propFamilyId,
  refetchQ,
  systemSchoolPaymentMethod,
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [pauseMySubscription] = usePauseSubscriptionMutation({
    refetchQueries: refetchQ,
  });
  const [pauseFamilySubscription] = usePauseFamilySubscriptionMutation({
    variables: {
      id: propFamilyId,
    },
    refetchQueries: refetchQ,
  });
  const [resumeMySubscription] = useResumeSubscriptionMutation({
    refetchQueries: refetchQ,
  });
  const [resumeFamilySubscription] = useResumeFamilySubscriptionMutation({
    variables: {
      id: propFamilyId,
    },
    refetchQueries: refetchQ,
  });
  const [openDialog, setOpenDialog] = useState(false);

  const finalFee = () => {
    let amount = Number(familySubscription?.amount);
    let discount = Number(familySubscription?.discount);
    let finalAmount = amount - amount * discount;
    return finalAmount;
  };

  useEffect(() => {
    if (familySubscription) {
      finalFee();
    }
  }, [familySubscription]);

  // var processingDate = familySubscription?.nextProcessingDate;
  // if(moment().isAfter(familySubscription?.nextProcessingDate))
  // processingDate = "Expired";

  const items: InfoItem[] = [
    {
      icon: <InfoIcon />,
      label: "Status",
      value: `${
        familySubscription ? " " + familySubscription.status : "Not Subscribed"
      }`,
      color: colors.orange[500],
    },
    {
      icon: <BadgeIcon />,
      label: "Member Since",
      value: `${format(
        new Date(familySubscription?.createdAt),
        "MMMM D YYYY"
      )}`,
      color: colors.green[500],
    },
  ];

  if (systemSchoolPaymentMethod === "SchoolSystem") {
    items.push({
      icon: <AssistantIcon />,
      label: "Extra info",
      value: "Subscription paid by school",
      color: colors.cyan[700],
    });
  }

  if (systemSchoolPaymentMethod === "Parent") {
    items.push(
      {
        icon: <EventNoteIcon />,
        label: `${
          familySubscription?.status === "Paused"
            ? "Cancellation Date"
            : "Next Payment"
        }`,
        value: `${
          familySubscription?.nextProcessingDate
            ? " " +
              (moment().isAfter(familySubscription?.nextProcessingDate)
                ? "Expired"
                : format(
                    new Date(familySubscription.nextProcessingDate),
                    "MMMM D YYYY"
                  ))
            : "Not available"
        }`,
        color: colors.yellow[700],
      },
      {
        icon: <AttachMoneyIcon />,
        label: "Fee",
        value: `$${familySubscription?.amount} USD`,
        color: colors.red[300],
      },
      {
        icon: <MoneyOffIcon />,
        label: "Discount",
        value: `${Number(familySubscription?.discount) * 100}%`,
        color: colors.purple[300],
      },
      {
        icon: <PaidIcon />,
        label: "Final Fee",
        value: `$${finalFee().toPrecision(2)} USD`,
        color: colors.indigo[200],
      }
    );
  }

  if (inAdminPanel && familySubscription?.paymentMethod)
    items.push({
      icon: <CreditCardIcon />,
      label: "Payment Method",
      value: `${
        familySubscription?.paymentMethod?.id ? "Registered" : "Not registered"
      }`,
      color: colors.lightBlue[200],
    });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const options: string[] = [];
  if (inAdminPanel) options.push("Edit");
  if (familySubscription?.status === "Active") options.push("Pause");
  if (familySubscription?.status === "Paused") options.push("Resume");

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { innerText },
    } = event;
    if (innerText === "Edit") {
      handleOpenUpdateSubscriptionModal();
      handleCloseMenu();
    }
    if (innerText === "Pause") {
      handleCloseMenu();
      handleClickOpenDialog();
    }
    if (innerText === "Resume") {
      handleCloseMenu();
      handleClickOpenDialog();
    }
  };

  const handleMySubscription = () => {
    inAdminPanel
      ? familySubscription?.status === "Active"
        ? pauseFamilySubscription()
        : moment().isBefore(familySubscription?.nextProcessingDate)
        ? resumeFamilySubscription()
        : setOpenDialog(false)
      : familySubscription?.status === "Active"
      ? pauseMySubscription()
      : resumeMySubscription();
    setOpenDialog(false);
  };

  const handleClickOpenDialog = useCallback(() => {
    setOpenDialog(true);
  }, []);

  const handleCloseDialog = () => {
    setOpenDialog(false);
    handleCloseMenu();
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        {inAdminPanel ? (
          <Typography
            variant="h5"
            textAlign="start"
            fontWeight="bold"
            sx={{ marginRight: "10px" }}
          >
            Subscription Information
            {familySubscription && (
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
            )}
          </Typography>
        ) : (
          <Typography variant="h5" textAlign="start" gutterBottom>
            Subscription Information
            {familySubscription &&
              familySubscription?.status !== "Trial" &&
              familySubscription?.paymentMethod && (
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? "long-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreVertIcon />
                </IconButton>
              )}
          </Typography>
        )}
        {familySubscription ? (
          <Box textAlign={"start"}>
            <Grid marginTop={"10px"}>
              <InfoSection items={items} />
            </Grid>
          </Box>
        ) : (
          <Typography variant="h6" marginTop={"10px"} textAlign={"start"}>
            Not Subscribed
          </Typography>
        )}
      </Grid>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        PaperProps={{
          style: {
            width: "20ch",
          },
        }}
      >
        {options.map((option) => {
          return (
            <MenuItem key={option} onClick={handleMenuItemClick}>
              {option}
            </MenuItem>
          );
        })}
      </Menu>

      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Confirm this action"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {inAdminPanel
              ? familySubscription?.status === "Active"
                ? "Are you sure you want to pause this subscription?"
                : moment().isAfter(familySubscription?.nextProcessingDate)
                ? "You can't resume this subscription because it's expired."
                : "Are you sure you want to resume this subscription?"
              : familySubscription?.status === "Active"
              ? "Are you sure you want to pause your subscription?"
              : moment().isAfter(familySubscription?.nextProcessingDate)
              ? `Your subscription is overdue, you will be charged $${finalFee()} USD. Are you sure you want to resume your subscription?`
              : "Are you sure you want to resume your subscription?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleMySubscription}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default FamilyDetailsSection;
