import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type TransactionPaginatedItemFragment = { id: string, transactionId: string, amount: number, status: string, schools: string, createdAt: any, user: { name: string }, family: { name: string }, schoolSystem: { name: string } };

export type GetTransactionPaginatedQueryVariables = Types.Exact<{
  search?: Types.Maybe<Types.Scalars['String']>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  sortBy?: Types.Maybe<Types.Scalars['String']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  sortDirection?: Types.Maybe<Types.Scalars['String']>;
}>;


export type GetTransactionPaginatedQuery = { getTransactionsPaginated?: { nodes?: Array<{ id: string, transactionId: string, amount: number, status: string, schools: string, createdAt: any, user: { name: string }, family: { name: string }, schoolSystem: { name: string } }> | null | undefined } | null | undefined };

export type RefundTransactionMutationVariables = Types.Exact<{
  transactionId: Types.Scalars['String'];
  amount: Types.Scalars['Float'];
}>;


export type RefundTransactionMutation = { refundTransaction: boolean };

export type VoidTransactionMutationVariables = Types.Exact<{
  transactionId: Types.Scalars['String'];
}>;


export type VoidTransactionMutation = { voidTransaction: boolean };

export type GetTransactionIdQueryVariables = Types.Exact<{
  transactionId: Types.Scalars['String'];
}>;


export type GetTransactionIdQuery = { getTransactionId?: { id: string, transactionId: string, amount: number, status: string, schools: string, createdAt: any, user: { name: string }, family: { name: string }, schoolSystem: { name: string } } | null | undefined };

export const TransactionPaginatedItemFragmentDoc = gql`
    fragment TransactionPaginatedItem on StoredTransactionsDTO {
  id
  transactionId
  amount
  status
  user {
    name
  }
  family {
    name
  }
  schoolSystem {
    name
  }
  schools
  createdAt
}
    `;
export const GetTransactionPaginatedDocument = gql`
    query getTransactionPaginated($search: String, $limit: Int, $sortBy: String, $offset: Int, $sortDirection: String) {
  getTransactionsPaginated(
    search: $search
    limit: $limit
    offset: $offset
    sortBy: $sortBy
    sortDirection: $sortDirection
  ) {
    nodes {
      ...TransactionPaginatedItem
    }
  }
}
    ${TransactionPaginatedItemFragmentDoc}`;

/**
 * __useGetTransactionPaginatedQuery__
 *
 * To run a query within a React component, call `useGetTransactionPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionPaginatedQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      offset: // value for 'offset'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useGetTransactionPaginatedQuery(baseOptions?: Apollo.QueryHookOptions<GetTransactionPaginatedQuery, GetTransactionPaginatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTransactionPaginatedQuery, GetTransactionPaginatedQueryVariables>(GetTransactionPaginatedDocument, options);
      }
export function useGetTransactionPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransactionPaginatedQuery, GetTransactionPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTransactionPaginatedQuery, GetTransactionPaginatedQueryVariables>(GetTransactionPaginatedDocument, options);
        }
export type GetTransactionPaginatedQueryHookResult = ReturnType<typeof useGetTransactionPaginatedQuery>;
export type GetTransactionPaginatedLazyQueryHookResult = ReturnType<typeof useGetTransactionPaginatedLazyQuery>;
export type GetTransactionPaginatedQueryResult = Apollo.QueryResult<GetTransactionPaginatedQuery, GetTransactionPaginatedQueryVariables>;
export const RefundTransactionDocument = gql`
    mutation refundTransaction($transactionId: String!, $amount: Float!) {
  refundTransaction(transactionId: $transactionId, amount: $amount)
}
    `;
export type RefundTransactionMutationFn = Apollo.MutationFunction<RefundTransactionMutation, RefundTransactionMutationVariables>;

/**
 * __useRefundTransactionMutation__
 *
 * To run a mutation, you first call `useRefundTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefundTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refundTransactionMutation, { data, loading, error }] = useRefundTransactionMutation({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useRefundTransactionMutation(baseOptions?: Apollo.MutationHookOptions<RefundTransactionMutation, RefundTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefundTransactionMutation, RefundTransactionMutationVariables>(RefundTransactionDocument, options);
      }
export type RefundTransactionMutationHookResult = ReturnType<typeof useRefundTransactionMutation>;
export type RefundTransactionMutationResult = Apollo.MutationResult<RefundTransactionMutation>;
export type RefundTransactionMutationOptions = Apollo.BaseMutationOptions<RefundTransactionMutation, RefundTransactionMutationVariables>;
export const VoidTransactionDocument = gql`
    mutation voidTransaction($transactionId: String!) {
  voidTransaction(transactionId: $transactionId)
}
    `;
export type VoidTransactionMutationFn = Apollo.MutationFunction<VoidTransactionMutation, VoidTransactionMutationVariables>;

/**
 * __useVoidTransactionMutation__
 *
 * To run a mutation, you first call `useVoidTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoidTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voidTransactionMutation, { data, loading, error }] = useVoidTransactionMutation({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useVoidTransactionMutation(baseOptions?: Apollo.MutationHookOptions<VoidTransactionMutation, VoidTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VoidTransactionMutation, VoidTransactionMutationVariables>(VoidTransactionDocument, options);
      }
export type VoidTransactionMutationHookResult = ReturnType<typeof useVoidTransactionMutation>;
export type VoidTransactionMutationResult = Apollo.MutationResult<VoidTransactionMutation>;
export type VoidTransactionMutationOptions = Apollo.BaseMutationOptions<VoidTransactionMutation, VoidTransactionMutationVariables>;
export const GetTransactionIdDocument = gql`
    query getTransactionId($transactionId: String!) {
  getTransactionId(transactionId: $transactionId) {
    ...TransactionPaginatedItem
  }
}
    ${TransactionPaginatedItemFragmentDoc}`;

/**
 * __useGetTransactionIdQuery__
 *
 * To run a query within a React component, call `useGetTransactionIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionIdQuery({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useGetTransactionIdQuery(baseOptions: Apollo.QueryHookOptions<GetTransactionIdQuery, GetTransactionIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTransactionIdQuery, GetTransactionIdQueryVariables>(GetTransactionIdDocument, options);
      }
export function useGetTransactionIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransactionIdQuery, GetTransactionIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTransactionIdQuery, GetTransactionIdQueryVariables>(GetTransactionIdDocument, options);
        }
export type GetTransactionIdQueryHookResult = ReturnType<typeof useGetTransactionIdQuery>;
export type GetTransactionIdLazyQueryHookResult = ReturnType<typeof useGetTransactionIdLazyQuery>;
export type GetTransactionIdQueryResult = Apollo.QueryResult<GetTransactionIdQuery, GetTransactionIdQueryVariables>;