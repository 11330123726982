import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import { ParentsPaginatedItemFragmentDoc, MySubscriptionFragmentDoc } from '../parents/query.generated';
import { UsersPaginatedItemFragmentDoc } from '../users/queries.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type FamilyPaginatedItemFragment = { id: string, createdAt: any, updatedAt: any, name: string, membersCount: number, schoolSystem?: { id: string, name: string } | null | undefined, parents: Array<{ id: string, studentsCount: number, createdAt: any, updatedAt: any, schoolSystem: { id: string, name: string }, students?: Array<{ id: string, studentId?: string | null | undefined, user: { id: string, name: string, lastName: string, firstName: string, email: string }, school?: { id: string, name: string } | null | undefined }> | null | undefined, user: { name: string, firstName: string, lastName: string, email: string, role: string, id: string, createdAt: any, updatedAt: any, isDisabled: boolean, family?: { id: string, name: string } | null | undefined } }>, members: Array<{ name: string, firstName: string, lastName: string, email: string, role: string, id: string, createdAt: any, updatedAt: any, isDisabled: boolean, schoolSystem?: { id: string, name: string } | null | undefined, school?: { id: string, name: string } | null | undefined }> };

export type GetFamiliesPaginatedQueryVariables = Types.Exact<{
  search?: Types.Maybe<Types.Scalars['String']>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  sortBy?: Types.Maybe<Types.Scalars['String']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  sortDirection?: Types.Maybe<Types.Scalars['String']>;
  schoolSystemId?: Types.Maybe<Types.Scalars['String']>;
  schoolId?: Types.Maybe<Types.Scalars['String']>;
}>;


export type GetFamiliesPaginatedQuery = { familiesPaginated: { totalsCount: number, nodes?: Array<{ id: string, createdAt: any, updatedAt: any, name: string, membersCount: number, schoolSystem?: { id: string, name: string } | null | undefined, parents: Array<{ id: string, studentsCount: number, createdAt: any, updatedAt: any, schoolSystem: { id: string, name: string }, students?: Array<{ id: string, studentId?: string | null | undefined, user: { id: string, name: string, lastName: string, firstName: string, email: string }, school?: { id: string, name: string } | null | undefined }> | null | undefined, user: { name: string, firstName: string, lastName: string, email: string, role: string, id: string, createdAt: any, updatedAt: any, isDisabled: boolean, family?: { id: string, name: string } | null | undefined } }>, members: Array<{ name: string, firstName: string, lastName: string, email: string, role: string, id: string, createdAt: any, updatedAt: any, isDisabled: boolean, schoolSystem?: { id: string, name: string } | null | undefined, school?: { id: string, name: string } | null | undefined }> }> | null | undefined } };

export type CreateFamilyMutationVariables = Types.Exact<{
  data: Types.CreateFamilyDto;
}>;


export type CreateFamilyMutation = { createFamily: { id: string, createdAt: any, updatedAt: any } };

export type UpdateFamilyMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  data: Types.UpdateFamilyDto;
}>;


export type UpdateFamilyMutation = { updateFamily: { id: string } };

export type DeleteFamilyMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteFamilyMutation = { deleteFamily: boolean };

export type MyFamilyItemFragment = { id: string, members: Array<{ id: string, name: string, role: string, email: string }> };

export type MyFamilyQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MyFamilyQuery = { myFamily?: { id: string, members: Array<{ id: string, name: string, role: string, email: string }> } | null | undefined };

export type AddFamilyMemberMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
}>;


export type AddFamilyMemberMutation = { addFamilyMember: { id: string } };

export type RemoveFamilyMemberMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type RemoveFamilyMemberMutation = { removeFamilyMember: { id: string } };

export type GetFamilyQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetFamilyQuery = { getFamily: { id: string, createdAt: any, updatedAt: any, name: string, membersCount: number, familySubscriptions?: { id: string, createdAt: any, updatedAt: any, nextProcessingDate?: any | null | undefined, cancellationDate?: string | null | undefined, status: Types.SubscriptionStatus, amount?: string | null | undefined, discount?: string | null | undefined, paymentMethod?: { id: string, nameOnCard: string, number: string, expMonth: string, expYear: string, address: { streetAddress1: string, city: string, province: string, postalCode: string, country: string } } | null | undefined } | null | undefined, schoolSystem?: { id: string, name: string } | null | undefined, parents: Array<{ id: string, studentsCount: number, createdAt: any, updatedAt: any, schoolSystem: { id: string, name: string }, students?: Array<{ id: string, studentId?: string | null | undefined, user: { id: string, name: string, lastName: string, firstName: string, email: string }, school?: { id: string, name: string } | null | undefined }> | null | undefined, user: { name: string, firstName: string, lastName: string, email: string, role: string, id: string, createdAt: any, updatedAt: any, isDisabled: boolean, family?: { id: string, name: string } | null | undefined } }>, members: Array<{ name: string, firstName: string, lastName: string, email: string, role: string, id: string, createdAt: any, updatedAt: any, isDisabled: boolean, schoolSystem?: { id: string, name: string } | null | undefined, school?: { id: string, name: string } | null | undefined }> } };

export type PauseFamilySubscriptionMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type PauseFamilySubscriptionMutation = { pauseFamilySubscription: { id: string } };

export type ResumeFamilySubscriptionMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type ResumeFamilySubscriptionMutation = { resumeFamilySubscription: { id: string } };

export type DeleteFamilyWithNoMembersMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type DeleteFamilyWithNoMembersMutation = { checkAndDeleteFamiliesWithNoMembers?: boolean | null | undefined };

export const FamilyPaginatedItemFragmentDoc = gql`
    fragment FamilyPaginatedItem on FamilyDTO {
  id
  createdAt
  updatedAt
  schoolSystem {
    id
    name
  }
  name
  parents {
    ...ParentsPaginatedItem
  }
  membersCount
  members {
    ...UsersPaginatedItem
  }
}
    ${ParentsPaginatedItemFragmentDoc}
${UsersPaginatedItemFragmentDoc}`;
export const MyFamilyItemFragmentDoc = gql`
    fragment MyFamilyItem on FamilyDTO {
  id
  members {
    id
    name
    role
    email
  }
}
    `;
export const GetFamiliesPaginatedDocument = gql`
    query GetFamiliesPaginated($search: String, $limit: Int, $sortBy: String, $offset: Int, $sortDirection: String, $schoolSystemId: String, $schoolId: String) {
  familiesPaginated(
    search: $search
    limit: $limit
    offset: $offset
    sortBy: $sortBy
    sortDirection: $sortDirection
    schoolSystemId: $schoolSystemId
    schoolId: $schoolId
  ) {
    nodes {
      ...FamilyPaginatedItem
    }
    totalsCount
  }
}
    ${FamilyPaginatedItemFragmentDoc}`;

/**
 * __useGetFamiliesPaginatedQuery__
 *
 * To run a query within a React component, call `useGetFamiliesPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFamiliesPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFamiliesPaginatedQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      offset: // value for 'offset'
 *      sortDirection: // value for 'sortDirection'
 *      schoolSystemId: // value for 'schoolSystemId'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetFamiliesPaginatedQuery(baseOptions?: Apollo.QueryHookOptions<GetFamiliesPaginatedQuery, GetFamiliesPaginatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFamiliesPaginatedQuery, GetFamiliesPaginatedQueryVariables>(GetFamiliesPaginatedDocument, options);
      }
export function useGetFamiliesPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFamiliesPaginatedQuery, GetFamiliesPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFamiliesPaginatedQuery, GetFamiliesPaginatedQueryVariables>(GetFamiliesPaginatedDocument, options);
        }
export type GetFamiliesPaginatedQueryHookResult = ReturnType<typeof useGetFamiliesPaginatedQuery>;
export type GetFamiliesPaginatedLazyQueryHookResult = ReturnType<typeof useGetFamiliesPaginatedLazyQuery>;
export type GetFamiliesPaginatedQueryResult = Apollo.QueryResult<GetFamiliesPaginatedQuery, GetFamiliesPaginatedQueryVariables>;
export const CreateFamilyDocument = gql`
    mutation createFamily($data: CreateFamilyDTO!) {
  createFamily(data: $data) {
    id
    createdAt
    updatedAt
  }
}
    `;
export type CreateFamilyMutationFn = Apollo.MutationFunction<CreateFamilyMutation, CreateFamilyMutationVariables>;

/**
 * __useCreateFamilyMutation__
 *
 * To run a mutation, you first call `useCreateFamilyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFamilyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFamilyMutation, { data, loading, error }] = useCreateFamilyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateFamilyMutation(baseOptions?: Apollo.MutationHookOptions<CreateFamilyMutation, CreateFamilyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFamilyMutation, CreateFamilyMutationVariables>(CreateFamilyDocument, options);
      }
export type CreateFamilyMutationHookResult = ReturnType<typeof useCreateFamilyMutation>;
export type CreateFamilyMutationResult = Apollo.MutationResult<CreateFamilyMutation>;
export type CreateFamilyMutationOptions = Apollo.BaseMutationOptions<CreateFamilyMutation, CreateFamilyMutationVariables>;
export const UpdateFamilyDocument = gql`
    mutation updateFamily($id: String!, $data: UpdateFamilyDTO!) {
  updateFamily(id: $id, data: $data) {
    id
  }
}
    `;
export type UpdateFamilyMutationFn = Apollo.MutationFunction<UpdateFamilyMutation, UpdateFamilyMutationVariables>;

/**
 * __useUpdateFamilyMutation__
 *
 * To run a mutation, you first call `useUpdateFamilyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFamilyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFamilyMutation, { data, loading, error }] = useUpdateFamilyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateFamilyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFamilyMutation, UpdateFamilyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFamilyMutation, UpdateFamilyMutationVariables>(UpdateFamilyDocument, options);
      }
export type UpdateFamilyMutationHookResult = ReturnType<typeof useUpdateFamilyMutation>;
export type UpdateFamilyMutationResult = Apollo.MutationResult<UpdateFamilyMutation>;
export type UpdateFamilyMutationOptions = Apollo.BaseMutationOptions<UpdateFamilyMutation, UpdateFamilyMutationVariables>;
export const DeleteFamilyDocument = gql`
    mutation deleteFamily($id: String!) {
  deleteFamily(id: $id)
}
    `;
export type DeleteFamilyMutationFn = Apollo.MutationFunction<DeleteFamilyMutation, DeleteFamilyMutationVariables>;

/**
 * __useDeleteFamilyMutation__
 *
 * To run a mutation, you first call `useDeleteFamilyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFamilyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFamilyMutation, { data, loading, error }] = useDeleteFamilyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFamilyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFamilyMutation, DeleteFamilyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFamilyMutation, DeleteFamilyMutationVariables>(DeleteFamilyDocument, options);
      }
export type DeleteFamilyMutationHookResult = ReturnType<typeof useDeleteFamilyMutation>;
export type DeleteFamilyMutationResult = Apollo.MutationResult<DeleteFamilyMutation>;
export type DeleteFamilyMutationOptions = Apollo.BaseMutationOptions<DeleteFamilyMutation, DeleteFamilyMutationVariables>;
export const MyFamilyDocument = gql`
    query MyFamily {
  myFamily {
    ...MyFamilyItem
  }
}
    ${MyFamilyItemFragmentDoc}`;

/**
 * __useMyFamilyQuery__
 *
 * To run a query within a React component, call `useMyFamilyQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyFamilyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyFamilyQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyFamilyQuery(baseOptions?: Apollo.QueryHookOptions<MyFamilyQuery, MyFamilyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyFamilyQuery, MyFamilyQueryVariables>(MyFamilyDocument, options);
      }
export function useMyFamilyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyFamilyQuery, MyFamilyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyFamilyQuery, MyFamilyQueryVariables>(MyFamilyDocument, options);
        }
export type MyFamilyQueryHookResult = ReturnType<typeof useMyFamilyQuery>;
export type MyFamilyLazyQueryHookResult = ReturnType<typeof useMyFamilyLazyQuery>;
export type MyFamilyQueryResult = Apollo.QueryResult<MyFamilyQuery, MyFamilyQueryVariables>;
export const AddFamilyMemberDocument = gql`
    mutation AddFamilyMember($email: String!) {
  addFamilyMember(email: $email) {
    id
  }
}
    `;
export type AddFamilyMemberMutationFn = Apollo.MutationFunction<AddFamilyMemberMutation, AddFamilyMemberMutationVariables>;

/**
 * __useAddFamilyMemberMutation__
 *
 * To run a mutation, you first call `useAddFamilyMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFamilyMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFamilyMemberMutation, { data, loading, error }] = useAddFamilyMemberMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAddFamilyMemberMutation(baseOptions?: Apollo.MutationHookOptions<AddFamilyMemberMutation, AddFamilyMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddFamilyMemberMutation, AddFamilyMemberMutationVariables>(AddFamilyMemberDocument, options);
      }
export type AddFamilyMemberMutationHookResult = ReturnType<typeof useAddFamilyMemberMutation>;
export type AddFamilyMemberMutationResult = Apollo.MutationResult<AddFamilyMemberMutation>;
export type AddFamilyMemberMutationOptions = Apollo.BaseMutationOptions<AddFamilyMemberMutation, AddFamilyMemberMutationVariables>;
export const RemoveFamilyMemberDocument = gql`
    mutation RemoveFamilyMember($id: String!) {
  removeFamilyMember(id: $id) {
    id
  }
}
    `;
export type RemoveFamilyMemberMutationFn = Apollo.MutationFunction<RemoveFamilyMemberMutation, RemoveFamilyMemberMutationVariables>;

/**
 * __useRemoveFamilyMemberMutation__
 *
 * To run a mutation, you first call `useRemoveFamilyMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFamilyMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFamilyMemberMutation, { data, loading, error }] = useRemoveFamilyMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveFamilyMemberMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFamilyMemberMutation, RemoveFamilyMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveFamilyMemberMutation, RemoveFamilyMemberMutationVariables>(RemoveFamilyMemberDocument, options);
      }
export type RemoveFamilyMemberMutationHookResult = ReturnType<typeof useRemoveFamilyMemberMutation>;
export type RemoveFamilyMemberMutationResult = Apollo.MutationResult<RemoveFamilyMemberMutation>;
export type RemoveFamilyMemberMutationOptions = Apollo.BaseMutationOptions<RemoveFamilyMemberMutation, RemoveFamilyMemberMutationVariables>;
export const GetFamilyDocument = gql`
    query GetFamily($id: String!) {
  getFamily(id: $id) {
    ...FamilyPaginatedItem
    familySubscriptions {
      ...MySubscription
    }
  }
}
    ${FamilyPaginatedItemFragmentDoc}
${MySubscriptionFragmentDoc}`;

/**
 * __useGetFamilyQuery__
 *
 * To run a query within a React component, call `useGetFamilyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFamilyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFamilyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFamilyQuery(baseOptions: Apollo.QueryHookOptions<GetFamilyQuery, GetFamilyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFamilyQuery, GetFamilyQueryVariables>(GetFamilyDocument, options);
      }
export function useGetFamilyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFamilyQuery, GetFamilyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFamilyQuery, GetFamilyQueryVariables>(GetFamilyDocument, options);
        }
export type GetFamilyQueryHookResult = ReturnType<typeof useGetFamilyQuery>;
export type GetFamilyLazyQueryHookResult = ReturnType<typeof useGetFamilyLazyQuery>;
export type GetFamilyQueryResult = Apollo.QueryResult<GetFamilyQuery, GetFamilyQueryVariables>;
export const PauseFamilySubscriptionDocument = gql`
    mutation pauseFamilySubscription($id: String!) {
  pauseFamilySubscription(familyId: $id) {
    id
  }
}
    `;
export type PauseFamilySubscriptionMutationFn = Apollo.MutationFunction<PauseFamilySubscriptionMutation, PauseFamilySubscriptionMutationVariables>;

/**
 * __usePauseFamilySubscriptionMutation__
 *
 * To run a mutation, you first call `usePauseFamilySubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePauseFamilySubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pauseFamilySubscriptionMutation, { data, loading, error }] = usePauseFamilySubscriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePauseFamilySubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<PauseFamilySubscriptionMutation, PauseFamilySubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PauseFamilySubscriptionMutation, PauseFamilySubscriptionMutationVariables>(PauseFamilySubscriptionDocument, options);
      }
export type PauseFamilySubscriptionMutationHookResult = ReturnType<typeof usePauseFamilySubscriptionMutation>;
export type PauseFamilySubscriptionMutationResult = Apollo.MutationResult<PauseFamilySubscriptionMutation>;
export type PauseFamilySubscriptionMutationOptions = Apollo.BaseMutationOptions<PauseFamilySubscriptionMutation, PauseFamilySubscriptionMutationVariables>;
export const ResumeFamilySubscriptionDocument = gql`
    mutation resumeFamilySubscription($id: String!) {
  resumeFamilySubscription(familyId: $id) {
    id
  }
}
    `;
export type ResumeFamilySubscriptionMutationFn = Apollo.MutationFunction<ResumeFamilySubscriptionMutation, ResumeFamilySubscriptionMutationVariables>;

/**
 * __useResumeFamilySubscriptionMutation__
 *
 * To run a mutation, you first call `useResumeFamilySubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResumeFamilySubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resumeFamilySubscriptionMutation, { data, loading, error }] = useResumeFamilySubscriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResumeFamilySubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<ResumeFamilySubscriptionMutation, ResumeFamilySubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResumeFamilySubscriptionMutation, ResumeFamilySubscriptionMutationVariables>(ResumeFamilySubscriptionDocument, options);
      }
export type ResumeFamilySubscriptionMutationHookResult = ReturnType<typeof useResumeFamilySubscriptionMutation>;
export type ResumeFamilySubscriptionMutationResult = Apollo.MutationResult<ResumeFamilySubscriptionMutation>;
export type ResumeFamilySubscriptionMutationOptions = Apollo.BaseMutationOptions<ResumeFamilySubscriptionMutation, ResumeFamilySubscriptionMutationVariables>;
export const DeleteFamilyWithNoMembersDocument = gql`
    mutation deleteFamilyWithNoMembers {
  checkAndDeleteFamiliesWithNoMembers
}
    `;
export type DeleteFamilyWithNoMembersMutationFn = Apollo.MutationFunction<DeleteFamilyWithNoMembersMutation, DeleteFamilyWithNoMembersMutationVariables>;

/**
 * __useDeleteFamilyWithNoMembersMutation__
 *
 * To run a mutation, you first call `useDeleteFamilyWithNoMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFamilyWithNoMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFamilyWithNoMembersMutation, { data, loading, error }] = useDeleteFamilyWithNoMembersMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteFamilyWithNoMembersMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFamilyWithNoMembersMutation, DeleteFamilyWithNoMembersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFamilyWithNoMembersMutation, DeleteFamilyWithNoMembersMutationVariables>(DeleteFamilyWithNoMembersDocument, options);
      }
export type DeleteFamilyWithNoMembersMutationHookResult = ReturnType<typeof useDeleteFamilyWithNoMembersMutation>;
export type DeleteFamilyWithNoMembersMutationResult = Apollo.MutationResult<DeleteFamilyWithNoMembersMutation>;
export type DeleteFamilyWithNoMembersMutationOptions = Apollo.BaseMutationOptions<DeleteFamilyWithNoMembersMutation, DeleteFamilyWithNoMembersMutationVariables>;