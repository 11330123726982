import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Slide,
  Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import {
  GetMyPaymentMethodDocument,
  MyPaymentMethodFragment,
  useDeleteMyPaymentMethodMutation,
} from "../../../apollo/parents/query.generated";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import "./styles.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TransitionProps } from "@mui/material/transitions";

type Props = {
  paymentMethod?: MyPaymentMethodFragment | null;
  loading?: boolean;
  systemSchoolPaymentMethod?: string | null;
  handleOpenPaymentModal: () => void;
};

const PaymentMethodSection = ({
  paymentMethod,
  loading,
  handleOpenPaymentModal,
  systemSchoolPaymentMethod,
}: Props) => {
  const [deletePaymentMethod] = useDeleteMyPaymentMethodMutation({
    refetchQueries: [{ query: GetMyPaymentMethodDocument }],
  });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const handleClickOpenDialog = useCallback(() => {
    setOpenDialog(true);
  }, []);
  if (loading) {
    return <LinearProgress />;
  }

  const HandleDeletePaymentMethod = () => {
    setOpenDialog(false);
    handleCloseMenu();
    deletePaymentMethod();
  };

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const options = ["Edit", "Delete"];

  const handleCloseDialog = () => {
    setOpenDialog(false);
    handleCloseMenu();
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { innerText },
    } = event;
    if (innerText === "Edit") {
      handleOpenPaymentModal();
      handleCloseMenu();
    } else if (innerText === "Delete") {
      handleClickOpenDialog();
    }
  };

  const handleSchoolSystemPaymentMethod = () => {
    if (systemSchoolPaymentMethod === "SchoolSystem" && paymentMethod) {
      return (
        <Box>
          <Alert severity="info">
            Your school includes this service, so you don't need a payment
            method. You can delete it without worries.
          </Alert>
        </Box>
      );
    }

    if (systemSchoolPaymentMethod === "SchoolSystem" && !paymentMethod) {
      return (
        <Box>
          <Alert severity="info">
            Your school includes this service, so you don't need a payment
            method.
          </Alert>
        </Box>
      );
    }

    if (systemSchoolPaymentMethod !== "SchoolSystem" && !paymentMethod) {
      return (
        <Box>
          <Alert severity="info">You haven't added a payment method yet</Alert>
          <Button onClick={handleOpenPaymentModal}>Add Payment Method</Button>
        </Box>
      );
    }
  };

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "start",
        marginTop: "10px",
      }}
    >
      <Typography variant="h5" gutterBottom>
        Payment Method
        {paymentMethod && (
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
        )}
      </Typography>
      {paymentMethod && handleSchoolSystemPaymentMethod()}
      {paymentMethod ? (
        <Cards
          preview
          // focused="number"
          expiry={paymentMethod.expMonth + "/" + paymentMethod.expYear}
          name={paymentMethod.nameOnCard}
          cvc={""}
          number={paymentMethod.number}
        />
      ) : (
        handleSchoolSystemPaymentMethod()
      )}

      {paymentMethod && (
        <Box marginTop={2}>
          <Typography variant="h6" gutterBottom>
            Billing Address
          </Typography>
          <Typography variant="body1" gutterBottom align="left">
            {paymentMethod?.address?.streetAddress1}
          </Typography>
          <Typography variant="body1" gutterBottom align="left">
            {paymentMethod?.address?.city}, {paymentMethod?.address?.province}{" "}
            {paymentMethod?.address?.postalCode}
          </Typography>

          <Typography variant="body1" gutterBottom align="left">
            {paymentMethod?.address?.country}
          </Typography>
        </Box>
      )}

      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        PaperProps={{
          style: {
            width: "20ch",
          },
        }}
      >
        {options.map((option) => {
          return (
            <MenuItem key={option} onClick={handleMenuItemClick}>
              {option}
            </MenuItem>
          );
        })}

        <Dialog
          open={openDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseDialog}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Confirm this action"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Your payment method will be deleted. Do you want to continue?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button onClick={HandleDeletePaymentMethod}>Confirm</Button>
          </DialogActions>
        </Dialog>
      </Menu>
    </Box>
  );
};

export default PaymentMethodSection;
