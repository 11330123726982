import React, { cloneElement } from "react";
import {
  Close,
  LocationCityRounded,
  SchoolOutlined,
  FamilyRestroom,
  CalendarToday,
} from "@mui/icons-material";
import {
  Box,
  ButtonBase,
  Divider,
  Drawer as MuiDrawer,
  IconButton,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FiCamera, FiHexagon, FiHome, FiUsers } from "react-icons/fi";
import { FaRegCalendarCheck } from "react-icons/fa";
import { shouldForwardProp, styled } from "@mui/system";
import { useRouteMatch } from "react-router";
import { NavLink } from "react-router-dom";
import { FaBusAlt, FaRoad, FaUserAlt, FaUsers } from "react-icons/fa";
import RateReviewIcon from "@mui/icons-material/RateReview";
import ReceiptIcon from "@mui/icons-material/Receipt";
import useAbility from "../shared/hooks/useAbility";
interface DrawerProps {
  isOpen: boolean;
  onCloseClick?: () => void;
}

export const drawerWidth = 260;

const Drawer: React.FC<DrawerProps> = ({ isOpen, onCloseClick }) => {
  const ability = useAbility();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));

  const drawerContent = (
    <>
      <Toolbar
        sx={{
          height: "100px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            noWrap
            component="div"
            fontWeight="bold"
            color="primary"
            // sx={{ display: { xs: "block", sm: "block" } }}
          >
            Arttiee
          </Typography>
          <IconButton
            size="large"
            edge="start"
            color="primary"
            aria-label="open drawer"
            sx={{
              mr: 2,
              display: {
                lg: "none",
              },
            }}
            onClick={onCloseClick}
          >
            <Close />
          </IconButton>
        </Box>
      </Toolbar>
      <Box
        sx={{
          marginBottom: "24px",
          paddingX: {
            xs: "24px",
          },
        }}
      >
        <DrawerItem label="Home" icon={<FiHome />} path="/dashboard" exact />
        {ability.can("manage", "My Subscription") && (
          <DrawerItem
            label="My Subscription"
            icon={<FaRegCalendarCheck />}
            path="/dashboard/mySubscription"
          />
        )}

        {ability.can("manage", "School Systems") && (
          <DrawerItem
            label="Schools System"
            icon={<FiHexagon />}
            path="/dashboard/schoolSystem"
          />
        )}
        {ability.can("read", "Vacation Periods") && (
          <DrawerItem
            label="Vacation Periods"
            icon={<CalendarToday />}
            path="/dashboard/vacationPeriods"
          />
        )}

        {ability.can("read", "Schools") && (
          <DrawerItem
            label="Schools"
            icon={<LocationCityRounded />}
            path="/dashboard/schools"
          />
        )}

        {ability.can("read", "Students") && (
          <DrawerItem
            label="Students"
            icon={<SchoolOutlined />}
            path="/dashboard/students"
          />
        )}

        {ability.can("read", "Parents") && (
          <DrawerItem
            label="Parents"
            icon={<FaUsers />}
            path="/dashboard/parents"
          />
        )}
        {ability.can("read", "Families") && (
          <DrawerItem
            label="Families"
            icon={<FamilyRestroom />}
            path="/dashboard/families"
          />
        )}

        {ability.can("read", "Bus Drivers") && (
          <DrawerItem
            label="Bus Drivers"
            icon={<FaBusAlt />}
            path="/dashboard/busDrivers"
          />
        )}

        {ability.can("read", "Bus Routes") && (
          <DrawerItem
            label="Bus Routes"
            icon={<FaRoad />}
            path="/dashboard/busRoutes"
          />
        )}

        {ability.can("read", "Users") && (
          <DrawerItem
            label="Users"
            icon={<FaUserAlt />}
            path="/dashboard/users"
          />
        )}

        {ability.can("manage", "all") && (
          <DrawerItem
            label="Transactions"
            icon={<ReceiptIcon />}
            path="/dashboard/transactions"
          />
        )}

        {ability.can("manage", "all") && (
          <DrawerItem
            label="Forms"
            icon={<RateReviewIcon />}
            path="/dashboard/forms"
          />
        )}
        {/* <DrawerItem label="Home" icon={<FiCamera />} /> */}
      </Box>
      {/* <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader> */}
      <Divider />
    </>
  );

  return (
    <>
      <MuiDrawer
        variant={isLg ? "permanent" : isMd ? "persistent" : "temporary"}
        open={isOpen}
        anchor="left"
        onClose={onCloseClick}
        sx={{
          // display: {
          //   xs: "block",
          //   md: "block",
          // },
          width: drawerWidth,
          flexShrink: 0,
          overflowX: "hidden",
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            overflowX: "hidden",
            boxSizing: "border-box",
          },
        }}
      >
        {drawerContent}
      </MuiDrawer>
    </>
  );
};

interface DrawerItemBoxProps {
  selected?: boolean;
}

const DrawerItemBox = styled(ButtonBase)<DrawerItemBoxProps>(
  ({ theme, selected }) => {
    const typedTheme = theme as Theme;
    return {
      padding: theme.spacing("10px", "15px"),
      width: "100%",
      borderRadius: theme.shape.borderRadius,
      display: "flex",
      backgroundColor: selected
        ? typedTheme.palette.primary.main
        : "transparent",
      color: selected ? "white" : typedTheme.palette.grey[600],
      alignItems: "center",
      justifyContent: "flex-start",
    };
  }
);

interface DrawerItemProps {
  path: string;
  icon: React.ReactNode;
  label: string;
  exact?: boolean;
}
const DrawerItem: React.FC<DrawerItemProps> = ({
  icon,
  label,
  path,
  exact,
}) => {
  const isActive = useRouteMatch({
    path,
    exact,
  });
  const moddedIcon = cloneElement(icon as unknown as any, { size: "20px" });

  return (
    <NavLink to={path} style={{ textDecoration: "none" }}>
      <DrawerItemBox selected={!!isActive}>
        <Box
          marginRight="15px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          {moddedIcon}
        </Box>
        <Typography>{label}</Typography>
      </DrawerItemBox>
    </NavLink>
  );
};
export default Drawer;
