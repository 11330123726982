// import React from 'react'

import { ApolloError, DocumentNode, useMutation } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import toaster from "react-hot-toast";

interface DisableUserModalProps {
  mutation: DocumentNode;
  updateQueries: DocumentNode;
  title?: string;
  text?: string;
  btnText?: string;
  successMessage: string;
  errorMessage: string;
  open: boolean;
  variables: any;
  onSuccess?: () => void;
  onClose: () => void;
}

const DisableUserModal: React.FC<DisableUserModalProps> = ({
  open,
  title,
  text,
  btnText,
  mutation,
  updateQueries,
  variables,
  onClose,
  onSuccess,
  successMessage,
  errorMessage,
}) => {
  const [process, {}] = useMutation(mutation, {
    refetchQueries: [updateQueries],
  });

  const onUpdateClick = () => {
    toaster.promise(process({ variables }), {
      loading: "Disabling...",
      success: () => {
        if (onSuccess) onSuccess();
        return successMessage;
      },
      error: (error: ApolloError) => {
        return errorMessage + error.message;
      },
    });
    onClose();
  };

  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onUpdateClick}>{btnText}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DisableUserModal;
