import React from "react";
import { Container, CircularProgress, Grid } from "@mui/material";

const LoadingPage: React.FC = () => {
  return (
    <Container
      sx={{
        height: "100%",
        width: "100%",
        flex: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ flex: 1 }}
      >
        <CircularProgress size={100} />
      </Grid>
    </Container>
  );
};

export default LoadingPage;
