import { LocationCityRounded } from "@mui/icons-material";
import {
  colors,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Avatar,
  Divider,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import dayjs from "dayjs";
import React, { useMemo, useState } from "react";
import { FaBusAlt, FaEdit, FaEllipsisV, FaTrashAlt } from "react-icons/fa";
import QRCode from "react-qr-code";
import { useHistory, useParams } from "react-router-dom";
import {
  DeleteBusRouteDocument,
  GetBusRoutesPaginatedDocument,
  useGetBusRouteQuery,
  useMergedBusRoutesQuery,
  useMergedBusStopsQuery,
} from "../../apollo/busRoutes/queries.generated";
import { BusStopDto } from "../../apollo/types.generated";
import CopyButton from "../../shared/components/CopyButton";
import DeleteModal from "../../shared/components/DeleteModal";
import DescriptionItem from "../../shared/components/DescriptionItem";
import MapsApiLoader from "../../shared/components/MapsApiLoader";
import Menu from "../../shared/components/Menu";
import LoadingPage from "../home/LoadingPage";
import RoutePreviewer from "./components/RoutePreviewer";
import SchedulesArea from "./components/SchedulesArea";
import SignUpQrCode from "./components/SignUpQrCode";
import { mapServerStopsToLocalStops } from "./components/utils";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { useGetRouteDriversQuery } from "../../apollo/busDrivers/queries.generated";
import { format } from "date-fns";
import InfoSection, { InfoItem } from "../../shared/components/InfoSection";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import SubstituteDriverModal from "./components/SubstituteDriverModal";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import DomainIcon from "@mui/icons-material/Domain";
import MergeRoutesModal from "./components/MergeRoutesModal";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import toast from "react-hot-toast";

interface BusRoutePageProps {}

const BusRoutePage: React.FC<BusRoutePageProps> = ({}) => {
  let { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [showSubstituteDriverModal, setShowSubstituteDriverModal] =
    useState(false);
  const [showMergeRoutesModal, setShowMergeRoutesModal] = useState(false);
  const { data, loading, error } = useGetBusRouteQuery({
    variables: { id: id },
  });
  const { data: mergedBusRoutesData } = useMergedBusRoutesQuery({
    variables: {
      busRouteId: id,
    },
  });

  const { data: mergedStopsData } = useMergedBusStopsQuery({
    variables: {
      busRouteId: id,
    },
  });

  const { data: driverPeriodData, error: driverPeriodError } =
    useGetRouteDriversQuery({
      variables: { routeId: id },
    });

  const handleDeleteModalClose = () => {
    setshowDeleteModal(false);
  };

  if (error || driverPeriodError) return <div>Error</div>;
  if (!data || !driverPeriodData) return <LoadingPage />;
  const { busRoute } = data;
  const driver = busRoute.busDriver;

  const substituteDriver = driverPeriodData?.getRouteDrivers.find(
    (driver) => driver.isSubstituteDriver === true
  );

  const items: InfoItem[] = [
    {
      icon: <LocationCityRounded />,
      label: "Main School",
      value: busRoute.school.name,
      color: colors.green[300],
    },
    // {
    //   icon: <HolidayVillageIcon />,
    //   label: "School System",
    //   value: busRoute.schoolSystem?.name,
    //   color: colors.red[300],
    // },
    {
      icon: <FaBusAlt />,
      label: "Bus Driver",
      value: `${busRoute.busDriver.user.firstName} ${busRoute.busDriver.user.lastName}`,
      color: colors.yellow[700],
    },
    // {
    //   icon: <DateRangeIcon />,
    //   label: "Bus Driver Period of Service",
    //   value: `${format(
    //     new Date(driver?.routePeriodStart),
    //     "MMMM D YYYY"
    //   )} - ${format(new Date(driver?.routePeriodEnd), "MMMM D YYYY")}`,
    //   color: colors.yellow[900],
    // },
  ];

  if (substituteDriver) {
    items.push({
      icon: <DirectionsBusIcon />,
      label: "Substitute Bus Driver",
      value: `${substituteDriver.busDriver?.user?.name}`,
      color: colors.lightBlue[300],
    });

    items.push({
      icon: <DateRangeIcon />,
      label: "Substitute Bus Driver Period of Service",
      value: `${format(
        new Date(substituteDriver?.routePeriodStart),
        "MMMM D YYYY"
      )} - ${format(
        new Date(substituteDriver?.routePeriodEnd),
        "MMMM D YYYY"
      )}`,
      color: colors.lightBlue[600],
    });
  }

  if (busRoute.aditionalSchools) {
    if (busRoute.aditionalSchools.length > 0)
      items.push({
        icon: <DomainIcon />,
        label: "Aditional Schools",
        value: `${busRoute.aditionalSchools
          .map((school) => school.name)
          .join(", ")}`,
        color: colors.blue[300],
      });
  }

  if (mergedBusRoutesData?.getMergedBusRoutePaths) {
    if (mergedBusRoutesData.getMergedBusRoutePaths.length > 0) {
      items.push({
        icon: <AddLocationIcon />,
        label: "Merged Routes Count",
        value: `${mergedBusRoutesData.getMergedBusRoutePaths.length}`,
        color: colors.deepPurple[300],
      });
    }
  }

  if (mergedStopsData?.getMergedBusStops) {
    if (mergedStopsData.getMergedBusStops.length > 0) {
      items.push({
        icon: <AddLocationIcon />,
        label: "Merged Stops Count",
        value: `${mergedStopsData.getMergedBusStops.length}`,
        color: colors.deepOrange[300],
      });
    }
  }

  const handleCloseSubstituteDriverModal = () => {
    setShowSubstituteDriverModal(false);
  };

  const handleCloseMergeRoutesModal = () => {
    setShowMergeRoutesModal(false);
  };

  return (
    <Box
      sx={{
        paddingBottom: 10,
        paddingX: {
          xs: 2,
          sm: 3,
          md: 8,
          lg: 10,
        },
      }}
    >
      <DeleteModal
        open={showDeleteModal}
        mutation={DeleteBusRouteDocument}
        updateQueries={[GetBusRoutesPaginatedDocument]}
        variables={{ id: busRoute.id }}
        onClose={handleDeleteModalClose}
        onSuccess={() => {
          history.push("/dashboard/busRoutes");
        }}
        title="Delete Bus Route"
        successMessage="Bus Route deleted successfully"
      />
      <Grid container justifyContent="flex-start">
        <Grid container sx={{ marginY: "15px" }} spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h5"
              textAlign="start"
              color={colors.grey["400"]}
            >
              Bus Route
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Typography
                variant="h3"
                textAlign="start"
                color="secondary"
                sx={{ marginRight: "10px" }}
              >
                {busRoute.routeId || "ID"}
              </Typography>
              <Menu
                anchor={<FaEllipsisV />}
                items={[
                  {
                    label: "Edit",
                    icon: <FaEdit />,
                    onClick: () => {
                      if (
                        (mergedBusRoutesData?.getMergedBusRoutePaths &&
                          mergedBusRoutesData.getMergedBusRoutePaths.length >
                            0) ||
                        (mergedStopsData?.getMergedBusStops &&
                          mergedStopsData.getMergedBusStops.length > 0)
                      ) {
                        toast.error(
                          "You cannot edit a route that has merged routes or stops, please unmerge first"
                        );
                      } else {
                        history.push(
                          `/dashboard/busRoutes/${busRoute.id}/edit`
                        );
                      }
                    },
                  },
                  {
                    label: "Delete",
                    icon: <FaTrashAlt />,
                    onClick: () => {
                      setshowDeleteModal(true);
                    },
                  },
                  {
                    label: "Manage Substitute Bus Driver",
                    icon: <ManageAccountsIcon />,
                    onClick: () => {
                      setShowSubstituteDriverModal(true);
                    },
                  },
                  {
                    label: "Manage Merge of Routes",
                    icon: <AddLocationAltIcon />,
                    onClick: () => {
                      setShowMergeRoutesModal(true);
                    },
                  },
                ]}
              />
            </Box>
            <Typography
              variant="h5"
              textAlign="start"
              color="secondary"
              sx={{ marginRight: "10px" }}
            >
              {busRoute.name}
            </Typography>
            <Grid item xs={12} md={11}>
              <InfoSection items={items} />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                marginTop: "25px",
                fontSize: "1.5rem",
                textAlign: "start",
              }}
              gutterBottom
            >
              Students sign up QR Code
            </Typography>
            <SignUpQrCode busRoute={busRoute} />
          </Grid>

          <Grid item xs={12} sx={{ marginTop: "20px" }}>
            <Typography variant="h5" textAlign="start">
              Schedules
            </Typography>
          </Grid>
          {busRoute.paths.length > 0 ? (
            <MapsApiLoader>
              {busRoute.paths.map((path) => (
                <Grid item xs={12} md={6} key={path.id}>
                  <SchedulesArea path={path} routeId={id} />
                </Grid>
              ))}
            </MapsApiLoader>
          ) : (
            <Grid item xs={12}>
              <Typography
                fontSize="20px"
                color={colors.grey["400"]}
                textAlign="start"
              >
                You must specify this route's schedules.
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <SubstituteDriverModal
        open={showSubstituteDriverModal}
        onClose={handleCloseSubstituteDriverModal}
        substituteDriver={substituteDriver}
        routeId={busRoute.id}
        actualDriver={driver}
      />
      <MergeRoutesModal
        open={showMergeRoutesModal}
        onClose={handleCloseMergeRoutesModal}
        routeId={busRoute.id}
        schoolSystemId={busRoute.schoolSystem.id}
      />
    </Box>
  );
};

export default BusRoutePage;
