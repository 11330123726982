import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import produce, { Draft } from "immer";
import {
  BusStatusUpdate,
  CurrentRoute,
  CurrentRoutesContainer,
} from "./components/interfaces";
import {
  AdminCurrentPathFragment,
  useGetCurrentRoutesQuery,
} from "../../apollo/busRoutes/queries.generated";
import useValue from "../../shared/hooks/useValue";
import { io } from "socket.io-client";
import _ from "lodash";
import useAuthUser from "../../shared/hooks/useAuthUser";
import toast from "react-hot-toast";

import CurrentRoutesMap from "./components/CurrentRoutesMap";
import { BusAlert } from "@mui/icons-material";

interface CurrentRoutesPageProps {}

// const routesListToMap = (
//   routes: AdminCurrentPathFragment[]
// ): CurrentRoutesMap => {
//   return routes.reduce((map, current) => {
//     map[current.attendance.id] = routeItemToMapItem(current);
//     return map;
//   }, {} as CurrentRoutesMap);
// };

const routeItemToMapItem = (
  current: AdminCurrentPathFragment
): CurrentRoute => ({
  attendanceId: current.attendance.id,
  busDriverName: current.attendance.busDriver.user.name,
  startTime: current.path.startTime,
  endTime: current.path.endTime,
  schoolName: current.school.name,
  aditionalSchools: current.route.aditionalSchools,
  stops: current.path.stops,
  currentHeading: 0,
  routeId: current.route.routeId,
  routeName: current.route.name,
  attendanceStartTime: current.attendance.createdAt,
});
const CurrentRoutesPage: React.FC<CurrentRoutesPageProps> = ({}) => {
  const user = useAuthUser();
  const [currentRoutes, setCurrentRoutes] = useState<CurrentRoutesContainer>(
    {}
  );

  const { loading, data, error, refetch } = useGetCurrentRoutesQuery({
    fetchPolicy: "network-only",
  });
  // console.log(process.env.NODE_ENV);
  const socket = useValue(
    io(process.env.NODE_ENV === "development" ? "localhost:4000" : "", {
      transports: ["websocket"],
      autoConnect: false,
    })
  );

  useEffect(() => {
    if (data) {
      setCurrentRoutes(
        produce((draft: Draft<CurrentRoutesContainer>) => {
          const draftArray = _.toArray(draft);
          const newItems = data.adminCurrentPaths.map(routeItemToMapItem);

          //Here we compare and add the items that are not present
          _.differenceBy(newItems, draftArray, "attendanceId").forEach((i) => {
            // console.log("adding listener for: " + i.attendanceId);
            draft[i.attendanceId] = i;
            socket.on(i.attendanceId, (data: BusStatusUpdate) => {
              if (data.type === "LOCATION_UPDATE") {
                // console.log("Location update for: " + i.busDriverName);
                setCurrentRoutes(
                  produce((draft: Draft<CurrentRoutesContainer>) => {
                    if (!draft[i.attendanceId]) return;
                    draft[i.attendanceId].currentHeading = data.heading;
                    draft[i.attendanceId].currentLocation = {
                      lat: data.lat,
                      lng: data.lng,
                    };
                  })
                );
              }
            });
          });

          //Here We Remove the items that are no longer on the server
          _.differenceBy(draftArray, newItems, "attendanceId").forEach((i) => {
            socket.off(i.attendanceId);
            delete draft[i.attendanceId];
          });
        })
      );
    }

    return () => {};
  }, [data]);

  useEffect(() => {
    const callBack = () => {
      // console.log("Should refetch");
      refetch();
    };
    socket.on(
      user?.schoolSystemId
        ? "routesUpdates/" + user.schoolSystemId
        : "routesUpdates",
      callBack
    );

    socket.on("connect", () => {
      // console.log("Socket connected: " + socket.id);
      toast.success("Connected to the server for live updates");
    });
    socket.on("connect_error", (data) => {
      toast.error("Error connecting to websocket: " + data);
      console.log("Connection error " + data);
    });
    socket.connect();
    // console.log("Adding socket listeners");

    return () => {
      // console.log("Removing listeners");

      // socket.removeAllListeners();
      socket.disconnect();
    };
  }, []);

  return (
    <Box paddingX={3} marginBottom="100px">
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h4" textAlign="start" gutterBottom>
            Ongoing Routes
          </Typography>
        </Grid>

        <Grid item xs={12}>
          {_.isEmpty(currentRoutes) ? (
            <Box>
              <Typography
                fontSize="18px"
                color="text.secondary"
                textAlign="start"
              >
                There are no ongoing routes currently.
              </Typography>
            </Box>
          ) : (
            <CurrentRoutesMap currentRoutes={_.toArray(currentRoutes)} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default CurrentRoutesPage;
