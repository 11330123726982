import { Grid, colors } from "@mui/material";
import { GridSize } from "@mui/material/Grid";

import DescriptionItem from "./DescriptionItem";
import React from "react";

export interface InfoItem {
  label: string;
  value: string;
  icon: React.ReactNode;
  color: string;
}

export interface InfoSectionProps {
  items: InfoItem[];
}
const InfoSection: React.FC<InfoSectionProps> = ({ items }) => {
  return (
    <Grid container spacing={1}>
      {items.map((item) => {
        return (
          <Grid key={item.label} item xs={12} md={6}>
            <DescriptionItem
              icon={item.icon}
              label={item.label}
              value={`${item.value}`}
              marginY={"2.5%"}
              color={item.color}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default InfoSection;
