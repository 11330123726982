import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Link,
  Image,
  Font,
} from "@react-pdf/renderer";
import { BusRouteDto } from "../../../apollo/types.generated";
import QRCode from "qrcode";

interface PDFDocumentProps {
  selectedItems: BusRouteDto[];
  handleLoadingPage: () => void;
}

const PDFDocument: React.FC<PDFDocumentProps> = ({
  selectedItems,
  handleLoadingPage,
}) => {
  const styles = StyleSheet.create({
    containerView: {
      width: "90%",
      marginLeft: "5%",
      marginRight: "5%",
    },
    titleView: {
      width: "100%",
      height: "15vh",
      marginTop: "10px",
      marginBottom: "10px",
      display: "flex",
      justifyContent: "flex-start",
      flexDirection: "row",
      alignItems: "center",
    },
    titleNamesView: {
      border: "4px solid #E9983F",
      borderRadius: "4px",
      display: "flex",
      flexDirection: "column",
      padding: 10,
      margin: "auto",
    },
    title: {
      fontFamily: "Poppins",
      fontSize: "3vw",
      fontWeight: 600,
      lineHeight: 1,
      color: "#000000",
      margin: 0,
      marginBottom: 7,
      textAlign: "center",
    },
    titleSubInfo: {
      fontFamily: "Poppins",
      fontSize: "2vw",
      color: "#808080",
      lineHeight: 1,
    },
    routeTitle: {
      width: "100%",
      fontSize: "3vw",
      paddingTop: "3px",
      paddingBottom: "3px",
      fontWeight: "bold",
      backgroundColor: "#E9983F",
      color: "#FFFFFF",
      textAlign: "center",
    },
    bodyqrView: {
      marginTop: 10,
      display: "flex",
      flexDirection: "column",
      alignContent: "center",
      justifyContent: "center",
      width: "60%",
    },
  });

  Font.register({
    family: "Poppins",
    fonts: [
      {
        src: "/fonts/Poppins-Regular.ttf",
      },

      {
        src: "/fonts/Poppins-SemiBold.ttf",
        fontWeight: 600,
        lineHeight: 1,
      },
    ],
  });

  const route = (element: BusRouteDto) => {
    return process.env.NODE_ENV === "development"
      ? window.location.host +
          `/createAccount?type=student&routeId=${element.routeId}`
      : `https://manage.arttiee.com/signup?type=student&routeId=${element.routeId}`;
  };

  return (
    <Document title="Arttie" onRender={handleLoadingPage}>
      {selectedItems &&
        selectedItems.map((element, index) => {
          return (
            <Page size="A4" key={element.id}>
              <View style={styles.containerView}>
                <View //Title View
                  style={styles.titleView}
                >
                  <Image src="\images\logo.png" style={{ height: "70%" }} />
                  <View style={styles.titleNamesView}>
                    <Text
                      style={styles.title}
                    >{`${element.school.name.trim()}`}</Text>
                    <Text style={styles.titleSubInfo}>
                      {`Route name: ${element.name}`}
                    </Text>
                    <Text style={styles.titleSubInfo}>
                      {`Route ID: ${element.routeId}`}
                    </Text>
                    <Text style={styles.titleSubInfo}>
                      {`Bus Driver: ${element.busDriver.user.name}`}
                    </Text>
                    <Text style={styles.titleSubInfo}>
                      {`Bus number: ${element.busDriver.busNumber}`}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 10,
                  }}
                >
                  <View style={{ width: "40%" }}>
                    <Image
                      src="/images/Phone-preview.png"
                      style={{ height: "38vh", width: "90%", paddingLeft: 30 }}
                    />
                  </View>
                  <View style={styles.bodyqrView}>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginRight: "5%",
                      }}
                    >
                      <Text
                        style={{
                          marginBottom: 5,
                          fontFamily: "Poppins",
                          lineHeight: 1,
                        }}
                      >
                        <Text
                          style={{
                            fontFamily: "Poppins",
                            lineHeight: 1,
                            fontWeight: 600,
                          }}
                        >
                          Step 1.
                        </Text>
                        Access the URL or scan the QR Code with your camera to
                        get started!
                      </Text>
                    </View>
                    <Link
                      src={route(element)}
                      style={{ width: "100%", marginBottom: 5 }}
                    >
                      https://arttiee.com/signup
                    </Link>
                    <Image
                      style={{
                        height: "170px",
                        width: "170px",
                      }}
                      src={QRCode.toDataURL(route(element))}
                    />
                    <Text
                      style={{
                        marginBottom: 5,
                        fontFamily: "Poppins",
                        lineHeight: 1,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "Poppins",
                          lineHeight: 1,
                          fontWeight: 600,
                        }}
                      >
                        Step 2.
                      </Text>
                      Get connected on our app available on:
                    </Text>
                    <View
                      style={{
                        marginBottom: 5,
                        width: "50%",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Image
                        src="/images/google-play.png"
                        style={{ marginRight: 10, width: 34, height: 34 }}
                      />
                      <Image
                        src="/images/app-store.png"
                        style={{ width: 34, height: 34 }}
                      />
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                  }}
                >
                  <View
                    style={{
                      height: 1,
                      width: "15%",
                      border: "2px solid #E9983F",
                      margin: "auto",
                    }}
                  />
                  <Text
                    style={{
                      fontFamily: "Poppins",
                      lineHeight: 1,
                    }}
                  >
                    IT'S TIME TO START TRACKING
                  </Text>
                  <View
                    style={{
                      height: 1,
                      width: "15%",
                      border: "2px solid #E9983F",
                      margin: "auto",
                      alignSelf: "center",
                    }}
                  />
                </View>
                <View>
                  <Text
                    style={{
                      fontSize: "4vw",
                      color: "#E9983F",
                      fontFamily: "Poppins",
                      lineHeight: 1,
                      margin: 0,
                      marginBottom: 10,
                      textAlign: "center",
                    }}
                  >
                    DOWNLOAD TODAY!
                  </Text>
                </View>
                <View
                  style={{
                    height: "120px",
                    backgroundColor: "#F39C12",
                    color: "#000000",
                    padding: 8,
                    borderRadius: 10,
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "33%",
                    }}
                  >
                    <Image
                      style={{
                        height: "35px",
                        width: "35px",
                        alignSelf: "center",
                      }}
                      src="/images/checkmark.png"
                    />
                    <Text
                      style={{
                        color: "white",
                        fontSize: "2.5vw",
                        alignSelf: "center",
                        fontFamily: "Poppins",
                        lineHeight: 1,
                        fontWeight: 600,
                      }}
                    >
                      Real-Time
                    </Text>

                    <View
                      style={{
                        height: 1,
                        width: "15%",
                        border: "1px solid #FFFFFF",
                        marginTop: 5,
                        marginBottom: 5,
                        marginHorizontal: "auto",
                      }}
                    />
                    <Text
                      style={{
                        color: "white",
                        fontSize: "2vw",
                        alignSelf: "center",
                        textAlign: "center",
                        fontFamily: "Poppins",
                        lineHeight: 1,
                      }}
                    >
                      Parents and Students can now track the bus in real-time
                    </Text>
                  </View>

                  <View
                    style={{
                      height: "100%",
                      width: 0.1,
                      border: "2px solid #FFFFFF",
                      marginHorizontal: 10,
                    }}
                  />

                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "33%",
                    }}
                  >
                    <Image
                      style={{
                        height: "35px",
                        width: "35px",
                        alignSelf: "center",
                      }}
                      src="/images/checkmark.png"
                    />
                    <Text
                      style={{
                        color: "white",
                        fontSize: "2.5vw",
                        alignSelf: "center",
                        fontFamily: "Poppins",
                        lineHeight: 1,
                        fontWeight: 600,
                      }}
                    >
                      Notifications
                    </Text>

                    <View
                      style={{
                        height: 1,
                        width: "15%",
                        border: "1px solid #FFFFFF",
                        marginTop: 5,
                        marginBottom: 5,
                        marginHorizontal: "auto",
                      }}
                    />
                    <Text
                      style={{
                        color: "white",
                        fontSize: "2vw",
                        alignSelf: "center",
                        textAlign: "center",
                        fontFamily: "Poppins",
                        lineHeight: 1,
                      }}
                    >
                      Get notified when your child arrives to and from school
                    </Text>
                  </View>
                  <View
                    style={{
                      height: "100%",
                      width: 0.1,
                      border: "2px solid #FFFFFF",
                      marginHorizontal: 10,
                    }}
                  />
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "33%",
                    }}
                  >
                    <Image
                      style={{
                        height: "35px",
                        width: "35px",
                        alignSelf: "center",
                      }}
                      src="/images/checkmark.png"
                    />
                    <Text
                      style={{
                        color: "white",
                        fontSize: "2.5vw",
                        alignSelf: "center",
                        fontFamily: "Poppins",
                        lineHeight: 1,
                        fontWeight: 600,
                      }}
                    >
                      Management
                    </Text>

                    <View
                      style={{
                        height: 1,
                        width: "15%",
                        border: "1px solid #FFFFFF",
                        marginTop: 5,
                        marginBottom: 5,
                        marginHorizontal: "auto",
                      }}
                    />
                    <Text
                      style={{
                        color: "white",
                        fontSize: "2vw",
                        alignSelf: "center",
                        textAlign: "center",
                        fontFamily: "Poppins",
                        lineHeight: 1,
                      }}
                    >
                      Manage and View all your students routes in one app
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    marginTop: 15,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      src="/images/mail.png"
                      style={{
                        height: "50px",
                        width: "50px",
                        marginRight: 10,
                        objectFit: "cover",
                      }}
                    />
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: "2.5vw",
                          fontFamily: "Poppins",
                          lineHeight: 1,
                        }}
                      >
                        More Info Email Us:
                      </Text>
                      <Text
                        style={{
                          fontSize: "3vw",
                          fontFamily: "Poppins",
                          lineHeight: 1,
                          fontWeight: 600,
                        }}
                      >
                        support@arttiee.com
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      src="/images/globe.png"
                      style={{
                        height: "50px",
                        width: "50px",
                        marginRight: 10,
                        objectFit: "cover",
                      }}
                    />
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: "2.5vw",
                          fontFamily: "Poppins",
                          lineHeight: 1,
                        }}
                      >
                        Visit Us at:
                      </Text>
                      <Text
                        style={{
                          fontSize: "3vw",
                          fontFamily: "Poppins",
                          lineHeight: 1,
                          fontWeight: 600,
                        }}
                      >
                        www.Arttiee.com
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </Page>
          );
        })}
    </Document>
  );
};

export default PDFDocument;
