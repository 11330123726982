import { Grid } from "@mui/material";
import React from "react";
import {
  GetBusDriversCountDocument,
  GetSchoolsCountDocument,
  GetStudentsCountDocument,
  GetParentsCountDocument,
} from "../../../apollo/shared/query.generated";
import StatsCard from "./StatsCard";
interface SystemManagersContentProps {
  showSchoolCard?: boolean;
}

const SystemManagersContent: React.FC<SystemManagersContentProps> = ({showSchoolCard}) => {
  return (
    <Grid container spacing={2}>
      {showSchoolCard && (
      <Grid item xs={6} md={3}>
        <StatsCard
          query={GetSchoolsCountDocument}
          color="#00C0EF"
          text="Schools"
        />
      
      </Grid>)}
      <Grid item xs={6} md={3}>
        <StatsCard
          query={GetParentsCountDocument}
          color="#F39C12"
          text="Parents"
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <StatsCard
          query={GetStudentsCountDocument}
          color="#DD4B39"
          text="Students"
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <StatsCard
          query={GetBusDriversCountDocument}
          color="#00A65A"
          text="Bus drivers"
        />
      </Grid>
    </Grid>
  );
};

export default SystemManagersContent;
