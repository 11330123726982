import { GridColDef } from "@mui/x-data-grid";
import React from "react";

import { SchoolSystemPaginatedItemFragment } from "../../../apollo/schoolSystems/queries.generated";

import { UsersPaginatedItemFragment } from "../../../apollo/users/queries.generated";

import { FamilyPaginatedItemFragment } from "../../../apollo/families/query.generated";
import { GetTransactionPaginatedDocument } from "../../../apollo/transactions/queries.generated";
import { timezoneAwareFormat } from "../../../shared/components/TimezoneAwareDatetime";
import TransactionsDataTable from "./TransactionsDataTable";

interface TransactionsTableProps {}
const muiColumns: GridColDef[] = [
  {
    flex: 1,
    field: "transactionId",
    headerName: "Transaction Number",
    minWidth: 200,
  },
  {
    flex: 1,
    field: "amount",
    headerName: "Amount",
    minWidth: 50,
  },
  {
    flex: 1,
    field: "status",
    headerName: "Status",
    minWidth: 150,
  },
  {
    flex: 1,
    field: "user",
    headerName: "Parent",
    minWidth: 100,
    valueGetter: ({ value }) => {
      return (value as UsersPaginatedItemFragment)?.name;
    },
  },
  {
    flex: 1,
    field: "family",
    headerName: "Family",
    minWidth: 100,
    valueGetter: ({ value }) => {
      return (value as FamilyPaginatedItemFragment)?.name;
    },
  },
  {
    flex: 1,
    field: "schools",
    headerName: "Schools",
    minWidth: 300,
  },
  {
    flex: 1,
    field: "schoolSystem",
    headerName: "School System",
    valueGetter: ({ value }) => {
      return (value as SchoolSystemPaginatedItemFragment)?.name;
    },
    minWidth: 200,
  },
  {
    flex: 1,
    field: "createdAt",
    headerName: "Created At",
    minWidth: 200,
    valueFormatter: ({ value }) =>
      timezoneAwareFormat(value as Date, "MMM D, YYYY h:mm A"),
  },
];

const TransactionsTable: React.FC<TransactionsTableProps> = ({ ...props }) => {
  return (
    <TransactionsDataTable
      query={GetTransactionPaginatedDocument}
      searchable
      columns={muiColumns}
      //   actionColumns={getBasicActionColumns()}
      {...props}
    />
  );
};

export default TransactionsTable;
