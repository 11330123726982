import { FamilyRestroom, SchoolOutlined } from "@mui/icons-material";
import {
  ButtonBase,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import useQuery from "../../shared/hooks/useQuery";
import useAuthStore from "../../shared/stores/authStore";
import ParentsAccountForm from "./components/ParentsAccountForm";
import StudentsAccountForm from "./components/StudentsAccountForm";

interface CreateAccountPageProps {}

type AccountType = "student" | "parent" | null;

const CreateAccountPage: React.FC<CreateAccountPageProps> = ({}) => {
  const query = useQuery();
  const history = useHistory();
  const authToken = useAuthStore((s) => s.authToken);

  const [accountType, setAccountType] = useState<AccountType>(
    query.get("type") as AccountType
  );

  const onClick = (accountType: AccountType) => () => {
    if (!accountType) return;

    const params = new URLSearchParams();
    setAccountType(accountType);
    params.append("type", accountType);
    history.push({ search: params.toString() });
  };

  const getForm = () => {
    if (!accountType) return null;

    if (accountType === "parent") return <ParentsAccountForm />;

    return <StudentsAccountForm />;
  };

  if (authToken) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Container sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <Dialog open={!accountType} fullWidth>
        <DialogTitle>Welcome to Arttiee</DialogTitle>

        <DialogContent>
          <DialogContentText sx={{ marginBottom: "20px" }}>
            Select the account type you want to create to get started:
          </DialogContentText>
          <Grid container spacing={4}>
            <Grid item xs>
              <ButtonBase
                onClick={onClick("parent")}
                sx={{
                  borderRadius: "20px",
                  borderColor: (theme) => theme.palette.primary.main,
                  borderWidth: "1px",
                  borderStyle: "solid",
                  width: "100%",
                  paddingY: "50px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    // width: "200px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FamilyRestroom
                    sx={{
                      fontSize: 40,
                      color: (theme) => theme.palette.primary.main,
                    }}
                  />
                  <Typography variant="h4" color="primary">
                    Parent
                  </Typography>
                </Box>
              </ButtonBase>
            </Grid>
            <Grid item xs>
              <ButtonBase
                onClick={onClick("student")}
                sx={{
                  borderRadius: "20px",
                  borderColor: (theme) => theme.palette.secondary.main,
                  borderWidth: "1px",
                  borderStyle: "solid",
                  width: "100%",
                  paddingY: "50px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    // width: "200px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <SchoolOutlined
                    sx={{
                      fontSize: 40,
                      color: (theme) => theme.palette.secondary.main,
                    }}
                  />
                  <Typography variant="h4" color="secondary">
                    Student
                  </Typography>
                </Box>
              </ButtonBase>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      {getForm()}
    </Container>
  );
};

export default CreateAccountPage;
