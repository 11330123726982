import { useQuery } from "@apollo/client";
import { Paper, Typography } from "@mui/material";
import Color from "color";
import { DocumentNode } from "graphql";
import { useMemo } from "react";
import { getObjectFirstKey } from "../../../shared/utils";

interface StatsCardProps {
  // number: number | string;
  color: string;
  text: string;
  query: DocumentNode;
}
const StatsCard: React.FC<StatsCardProps> = ({
  color,
  text,
  // number,
  query,
}) => {
  const colorObject = Color(color);
  const { data, loading, error } = useQuery(query);

  const value = useMemo(() => {
    if (!data) return 0;
    const resultKey = getObjectFirstKey(data);
    if (!resultKey) return 0;
    return data[resultKey] as number;
  }, [data]);

  return (
    <Paper
      sx={{
        height: "200px",
        padding: "50px",
        backgroundColor: color,
        color: colorObject.isDark() ? "white" : "black",
        boxShadow: `0px 48px 100px 0px ${colorObject.alpha(0.15).rgb()}`,
      }}
    >
      <Typography variant="h2" textAlign="start" fontWeight="bold">
        {value}
      </Typography>
      <Typography variant="h5" textAlign="start">
        {text}
      </Typography>
    </Paper>
  );
};

export default StatsCard;
