import { Box, Grid } from "@mui/material";
import React from "react";
import { GetSchoolSystemsPaginatedDocument } from "../../apollo/schoolSystems/queries.generated";
import SelectModalField from "../../shared/components/SelectModalField";
import useAbility from "../../shared/hooks/useAbility";
import useQuery from "../../shared/hooks/useQuery";

interface SchoolSystemSettingsPageProps {}

const SchoolSystemSettingsPage: React.FC<
  SchoolSystemSettingsPageProps
> = ({}) => {
  const ability = useAbility();
  const query = useQuery();

  return (
    <Box paddingX={3}>
      <Grid container>
        {ability.can("manage", "School Systems") && <Grid item xs={12}></Grid>}
      </Grid>
    </Box>
  );
};

export default SchoolSystemSettingsPage;
