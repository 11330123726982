import { Typography, colors, Box } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { useGetBusDriverQuery } from "../../apollo/busDrivers/queries.generated";
import InfoSection, { InfoItem } from "../../shared/components/InfoSection";
import PageContainer from "../../shared/components/PageContainer";
import LoadingPage from "../home/LoadingPage";

import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import SchoolIcon from "@mui/icons-material/School";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { LocationCityRounded } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import ChromeReaderModeIcon from "@mui/icons-material/ChromeReaderMode";
import GroupIcon from "@mui/icons-material/Group";

interface BusDriverPageProps {}

const BusDriverPage: React.FC<BusDriverPageProps> = ({}) => {
  let { id } = useParams<{ id: string }>();

  const { data, loading, error } = useGetBusDriverQuery({
    variables: { id: id },
  });

  if (error) return <div>Error</div>;
  if (!data) return <LoadingPage />;

  const isVerified =
    data.busDriver.accountStatus === "Unverified" ? false : true;

  const schools = data.busDriver.assignedSchools.length > 0 ? true : false;
  const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const schoolItems: InfoItem[] = [];
  const items: InfoItem[] = [
    {
      icon: `${data.busDriver.user.firstName[0]}${data.busDriver.user.lastName[0]}`,
      label: "Name",
      value: `${data.busDriver.user.name}`,
      color: colors.orange[500],
    },
    {
      icon: <EmailRoundedIcon />,
      label: "Email",
      value: `${data.busDriver.user.email}`,
      color: colors.yellow[700],
    },
    {
      icon: <AccountCircleOutlinedIcon />,
      label: "Role",
      value: `${data.busDriver.user.role}`,
      color: colors.lightBlue[200],
    },
    {
      icon: <ChromeReaderModeIcon />,
      label: "Bus Number",
      value: `${data.busDriver.busNumber || "None"}`,
      color: colors.indigo[200],
    },
  ];

  if (data.busDriver.schoolSystem)
    items.push({
      icon: <LocationCityRounded />,
      label: "SchoolSystem",
      value: `${data.busDriver.schoolSystem.name}`,
      color: colors.lightGreen[500],
    });
  items.push({
    icon: isVerified ? <CheckCircleIcon /> : <CancelRoundedIcon />,
    label: "Bus Driver Verification",
    value: `${data.busDriver.accountStatus}`,
    color: isVerified ? colors.green[500] : colors.red[500],
  });

  if (data.busDriver.assignedSchools.length >= 1) {
    for (
      let index = 0;
      index < data.busDriver.assignedSchools.length;
      index++
    ) {
      const element = data.busDriver.assignedSchools[index];
      schoolItems.push({
        icon: `${element.name.split(" ")[0][0]}${
          element.name.split(" ")[1][0]
        }`,
        label: `${element.code}`,
        value: `${element.name}`,
        color: stringToColor(element.name),
      });
    }
  }

  //   if (data.student.school)
  // items.push({
  //   icon: <SchoolIcon />,
  //   label: "School ",
  //   value: `${data.student.school.name}`,
  //   color: colors.brown[300],
  // });

  //   if (data.student.user.family)
  //     items.push({
  //       icon: <GroupIcon />,
  //       label: "Family ",
  //       value: `${data.student.user.family}`,
  //       color: colors.teal[300],
  //     });

  return (
    <PageContainer>
      <Typography
        variant="h5"
        textAlign="start"
        fontWeight="bold"
        color="secondary"
        sx={{ marginRight: "10px" }}
      >
        Bus Driver Information
      </Typography>

      <InfoSection items={items} />
      {schools && (
        <Box>
          <Typography
            variant="h6"
            textAlign="start"
            fontWeight="bold"
            color="secondary"
            sx={{ marginRight: "10px", marginTop: "10px" }}
          >
            Assigned Schools
          </Typography>
          <InfoSection items={schoolItems} />
        </Box>
      )}
    </PageContainer>
  );
};
export default BusDriverPage;
