import { Add } from "@mui/icons-material";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  GetSchoolSystemsPaginatedDocument,
  SchoolSystemPaginatedItemFragment,
} from "../../apollo/schoolSystems/queries.generated";
import {
  DeleteVacationPeriodDocument,
  GetVacationPeriodsDocument,
  useGetVacationPeriodsLazyQuery,
  VacationPeriodFragment,
} from "../../apollo/vacationPeriods/queries.generated";
import DeleteModal from "../../shared/components/DeleteModal";
import PageContainer from "../../shared/components/PageContainer";
import SelectModalField from "../../shared/components/SelectModalField";
import useAbility from "../../shared/hooks/useAbility";
import LoadingPage from "../home/LoadingPage";
import VacationPeriodFormModal from "./components/VacationPeriodFormModal";
import VacationPeriodsList from "./components/VacationPeriodsList";

interface VacationPeriodsPageProps {}

const VacationPeriodsPage: React.FC<VacationPeriodsPageProps> = ({}) => {
  const [fetch, { loading, data, error }] = useGetVacationPeriodsLazyQuery();
  const [selectedPeriod, setSelectedPeriod] =
    useState<VacationPeriodFragment>();
  const [showFormModal, setShowFormModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [schoolSystemId, setSchoolSystemId] = useState<string>();
  const ability = useAbility();

  const onEditClick = useCallback((period: VacationPeriodFragment) => {
    setSelectedPeriod(period);
    setShowFormModal(true);
  }, []);

  const onFormModalClose = useCallback(() => {
    setShowFormModal(false);
    setSelectedPeriod(undefined);
  }, []);

  const onAddClick = useCallback(() => {
    setShowFormModal(true);
  }, []);

  const onDeleteClick = useCallback((period: VacationPeriodFragment) => {
    setSelectedPeriod(period);
    setShowDeleteModal(true);
  }, []);

  const onDeleteModalClose = useCallback(() => {
    setShowDeleteModal(false);
    setSelectedPeriod(undefined);
  }, []);

  useEffect(() => {
    if (ability.can("manage", "all")) {
      if (schoolSystemId) {
        fetch({ variables: { schoolSystemId } });
      }
    } else {
      fetch();
    }

    return () => {};
  }, [ability, schoolSystemId]);

  useEffect(() => {
    if (error) {
      toast.error("Error trying to fetch the periods: " + error.message);
      console.error(error);
    }
    return () => {};
  }, [error]);

  const getContent = () => {
    if (loading) return <LoadingPage />;

    if (!data || !data?.vacationPeriods.length) {
      return (
        <Typography textAlign="start">
          No vacation periods have been found.
        </Typography>
      );
    }

    return (
      <VacationPeriodsList
        items={data.vacationPeriods}
        onDeleteClick={onDeleteClick}
        onEditClick={onEditClick}
      />
    );
  };

  return (
    <PageContainer>
      <DeleteModal
        open={showDeleteModal}
        onClose={onDeleteModalClose}
        mutation={DeleteVacationPeriodDocument}
        updateQueries={[GetVacationPeriodsDocument]}
        variables={{ id: selectedPeriod?.id }}
        successMessage="Deleted period successfully."
        title="Delete Vacation Period"
        text="This action cannot be undone."
      />
      <VacationPeriodFormModal
        onClose={onFormModalClose}
        open={showFormModal}
        editingPeriod={selectedPeriod}
        schoolSystemId={schoolSystemId}
      />
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: "30px" }}>
        <Typography variant="h4" textAlign="start" sx={{ marginRight: "10px" }}>
          Vacation Periods
        </Typography>
        <IconButton onClick={onAddClick}>
          <Add />
        </IconButton>
      </Box>

      <Grid container spacing={2} justifyContent="start">
        <Grid item xs={12} md={3} sm={6}>
          {ability.can("manage", "all") && (
            <SelectModalField
              textFieldProps={{
                fullWidth: true,
                label: "Pick a School System",
                onChange: (e) => setSchoolSystemId(e.target.value),
              }}
              query={GetSchoolSystemsPaginatedDocument}
              labelsExractor={(item: SchoolSystemPaginatedItemFragment) => ({
                primary: item.name,
              })}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {getContent()}
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default VacationPeriodsPage;
