import {
  Box,
  Button,
  Icon,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { red } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageContainer from "../../../shared/components/PageContainer";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { FormsPaginatedItemFragment } from "../../../apollo/forms/queries.generated";
import {
  CalendarPicker,
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/lab";
import AdapterDayJs from "@mui/lab/AdapterDayjs";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import FormFieldsToolManage from "./FormFieldsToolManage";

interface FormFieldsToolProps {
  formInfo?: FormsPaginatedItemFragment;
}

const FormFieldsTool: React.FC<FormFieldsToolProps> = ({ formInfo }) => {
  const formFields = formInfo?.formFields;

  // Form title field
  const FormTitle = () => {
    return (
      <Typography
        sx={{
          backgroundColor: "#E9983F",
          padding: "5px",
          borderRadius: "5px",
          color: "black",
          fontWeight: "bold",
          paddingBottom: "10px",
          paddingTop: "10px",
        }}
        textAlign="start"
        width="100%"
      >
        <Icon
          component={ArrowBackOutlinedIcon}
          sx={{
            marginRight: "40px",
            marginLeft: "10px",
            verticalAlign: "middle",
          }}
          fontSize="small"
        />
        {formInfo?.name}
      </Typography>
    );
  };

  // Submit button
  const SubmitButton = () => {
    return (
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#E9983F",
          color: "white",
          fontWeight: "bold",
          borderRadius: "5px",
          marginTop: "10px",
          marginBottom: "10px",
          width: "30%",
          alignSelf: "center",
        }}
      >
        Submit
      </Button>
    );
  };

  // Text field
  const TextFieldWithTitle = (title: string, hintText: string, key: string) => {
    return (
      <Box
        key={key}
        display="flex"
        flexDirection="column"
        alignItems="start"
        width="100%"
        marginTop={"10px"}
      >
        <Typography fontWeight="bold" textAlign="start" color={"black"}>
          {title}
        </Typography>
        <TextField
          id="outlined-basic"
          label={hintText}
          variant="outlined"
          sx={{ width: "100%" }}
        />
      </Box>
    );
  };

  // Text info field
  const TextInfo = (text: string, key: string) => {
    return (
      <Box
        key={key}
        display="flex"
        flexDirection="column"
        alignItems="start"
        width="100%"
        marginTop={"10px"}
        marginBottom={"10px"}
      >
        <Typography textAlign="start" color={"black"}>
          {text}
        </Typography>
      </Box>
    );
  };

  // Text area field field
  const TextAreaWithTitle = (title: string, hintText: string, key: string) => {
    return (
      <Box
        key={key}
        display="flex"
        flexDirection="column"
        alignItems="start"
        width="100%"
        marginTop={"10px"}
      >
        <Typography fontWeight="bold" textAlign="start" color={"black"}>
          {title}
        </Typography>
        <TextField
          id="outlined-basic"
          label={hintText}
          variant="outlined"
          multiline
          rows={4}
          sx={{ width: "100%" }}
        />
      </Box>
    );
  };

  // Radio group field
  const RadioGroupWithTitle = (title: string, options: [], key: string) => {
    return (
      <Box
        key={key}
        display="flex"
        flexDirection="column"
        alignItems="start"
        width="100%"
        marginTop={"10px"}
      >
        <Typography fontWeight="bold" textAlign="start" color={"black"}>
          {title}
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="start"
          sx={{ width: "100%", marginLeft: "10px" }}
          gap="10px"
        >
          {options.map((option) => {
            return (
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap={"10px"}
                sx={{ marginRight: "20px", marginTop: "10px" }}
              >
                <input type="radio" key={option} />
                <label>{option}</label>
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  };

  // Checkbox group field
  const CheckboxGroupWithTitle = (title: string, options: [], key: string) => {
    return (
      <Box
        key={key}
        display="flex"
        flexDirection="column"
        alignItems="start"
        width="100%"
        marginTop={"10px"}
      >
        <Typography fontWeight="bold" textAlign="start" color={"black"}>
          {title}
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="start"
          sx={{ width: "100%", marginLeft: "10px" }}
          gap="10px"
        >
          {options.map((option) => {
            return (
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap={"10px"}
                sx={{ marginRight: "20px", marginTop: "10px" }}
              >
                <input type="checkbox" key={option} />
                <label>{option}</label>
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  };

  // Dropdown field
  const DropdownWithTitle = (
    title: string,
    options: [],
    hintText: string,
    key: string
  ) => {
    return (
      <Box
        key={key}
        display="flex"
        flexDirection="column"
        alignItems="start"
        width="100%"
        marginTop={"10px"}
      >
        <Typography fontWeight="bold" textAlign="start" color={"black"}>
          {title}
        </Typography>
        <TextField
          id="outlined-select"
          select
          label={hintText}
          sx={{ width: "100%", textAlign: "left" }}
        >
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </Box>
    );
  };

  // Calendar picker field
  const CalendarPickerWithTitle = (title: string, key: string) => {
    return (
      <Box
        key={key}
        display="flex"
        flexDirection="column"
        alignItems="start"
        width="100%"
        marginTop={"10px"}
      >
        <Typography fontWeight="bold" textAlign="start" color={"black"}>
          {title}
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayJs}>
          <DatePicker
            renderInput={(params) => <TextField {...params} fullWidth />}
            onChange={() => {}}
            value={new Date()}
            // width="100%"
          />
        </LocalizationProvider>
      </Box>
    );
  };

  // Time picker field
  const TimePickerWithTitle = (title: string, key: string) => {
    return (
      <Box
        key={key}
        display="flex"
        flexDirection="column"
        alignItems="start"
        width="100%"
        marginTop={"10px"}
      >
        <Typography fontWeight="bold" textAlign="start" color={"black"}>
          {title}
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayJs}>
          <TimePicker
            value={new Date()}
            onChange={() => {}}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </LocalizationProvider>
      </Box>
    );
  };

  // Image picker field
  const ImagePickerWithTitle = (title: string, key: string) => {
    return (
      <Box
        key={key}
        display="flex"
        flexDirection="column"
        alignItems="start"
        width="100%"
        marginTop={"10px"}
      >
        <Typography fontWeight="bold" textAlign="start" color={"black"}>
          {title}
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          sx={{ width: "100%", marginTop: "5px" }}
          gap="10px"
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#e6e6e6",
              color: "black",
              fontSize: "12px",
              fontWeight: "bold",
              borderRadius: "5px",
              alignSelf: "center",
            }}
          >
            Select Images
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#e6e6e6",
              borderRadius: "15px",
              alignSelf: "center",
            }}
          >
            <Icon
              component={CameraAltIcon}
              sx={{
                color: "black",
                verticalAlign: "middle",
              }}
              fontSize="small"
            />
          </Button>
        </Box>
      </Box>
    );
  };

  const FormFields = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="start"
        width="100%"
        marginTop={"10px"}
        sx={{ marginLeft: "10px" }}
      >
        {formFields?.map((formField) => {
          const key = formField.id;
          switch (formField.type) {
            case "infoText":
              return TextInfo(formField.title as string, key);
            case "text":
              return TextFieldWithTitle(
                formField.title,
                formField.hintText as string,
                key
              );

            case "phone":
              return TextFieldWithTitle(
                formField.title,
                formField.hintText as string,
                key
              );
            case "email":
              return TextFieldWithTitle(
                formField.title,
                formField.hintText as string,
                key
              );
            case "number":
              return TextFieldWithTitle(
                formField.title,
                formField.hintText as string,
                key
              );
            case "multiline":
              return TextAreaWithTitle(
                formField.title,
                formField.hintText as string,
                key
              );
            case "radio":
              return RadioGroupWithTitle(
                formField.title,
                formField.radioButtons as [],
                key
              );
            case "checkbox":
              return CheckboxGroupWithTitle(
                formField.title,
                formField.checkBoxes as [],
                key
              );
            case "dropdown":
              return DropdownWithTitle(
                formField.title,
                formField.dropdownItems as [],
                formField.hintText as string,
                key
              );
            case "date":
              return CalendarPickerWithTitle(formField.title, key);
            case "time":
              return TimePickerWithTitle(formField.title, key);
            case "images":
              return ImagePickerWithTitle(formField.title, key);
            default:
              return null;
          }
        })}
      </Box>
    );
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          marginBottom: "10px",
          marginTop: "20px",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Typography fontWeight="bold" textAlign="start" color={"gray"}>
          Form Fields
        </Typography>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="repeat(2, 1fr)"
        gap={2}
        sx={{
          marginTop: "10px",
          border: "1px solid",
          borderRadius: "10px",
          padding: "10px",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap="10px"
          alignItems="center"
        >
          {<FormFieldsToolManage formInfo={formInfo} />}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap="10px"
          alignItems="center"
        >
          <Typography
            fontWeight="bold"
            textAlign="center"
            fontSize={12}
            sx={{ marginLeft: "10px" }}
          >
            Rough sample view of the mobile form (Not functional)
          </Typography>

          <Box
            display="flex"
            flexDirection="column"
            gap="10px"
            alignItems="center"
            width="65%"
            sx={{
              border: "10px solid",
              borderRadius: "15px",
              borderColor: "#4f4f4f",
            }}
          >
            {FormTitle()}

            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap="10px"
              sx={{
                overflowY: "scroll",
                width: "100%",
                marginTop: "10px",
                marginBottom: "10px",
                paddingLeft: "20px",
                paddingRight: "20px",
                height: "50vh",
              }}
            >
              {FormFields()}
              {SubmitButton()}
            </Box>
            <Box
              sx={{
                backgroundColor: "#4f4f4f",
                width: "100%",
                height: "25px",
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FormFieldsTool;
