import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  RadioGroup,
  FormControlLabelProps as RadioButtonsControlLabelProps,
  styled,
  FormControlLabel,
  useRadioGroup,
  Radio,
  Typography,
  Breakpoint,
  ToggleButtonGroup,
  ToggleButton,
  Button,
  Divider,
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import {
  useBusRoutesMergeableQuery,
  useMergedBusRoutesQuery,
  useMergedBusStopsQuery,
} from "../../../apollo/busRoutes/queries.generated";
import {
  BusRouteDto,
  MergedBusRoutePathsDto,
} from "../../../apollo/types.generated";
import MergePathCreatorModal from "./MergePathCreator";
import MergePathUpdateModal from "./MergePathUpdate";
import MergeStopCreatorModal from "./MergeStopCreator";
import MergeStopUpdateModal from "./MergeStopUpdate";

interface MergesRoutesModalProps {
  open: boolean;
  onClose: () => void;
  routeId: string;
  schoolSystemId: string;
}

interface StyledFormControlLabelProps extends RadioButtonsControlLabelProps {
  checked: boolean;
}

const StyledRadioButtonsControlLabel = styled(
  (props: StyledFormControlLabelProps) => <FormControlLabel {...props} />
)(({ theme, checked }) => ({
  ".MuiFormControlLabel-label": checked && {
    color: theme.palette.primary.main,
  },
}));

const MergeRoutesModal: React.FC<MergesRoutesModalProps> = ({
  onClose,
  open,
  routeId,
  schoolSystemId,
}) => {
  const [editData, setEditData] = React.useState<string | null>("");
  const [editOption, setEditOption] = React.useState<string>("routes");
  const [mergeOption, setMergeOption] = React.useState("routes");

  const [checkedRoutes, setCheckedRoutes] = React.useState<any>([]);
  const { data: mergeableRoutesData } = useBusRoutesMergeableQuery({
    variables: {
      schoolSystemId,
      busRouteId: routeId,
    },
  });

  const { data: mergedBusStopsData, loading: mergedBusStopsLoading } =
    useMergedBusStopsQuery({
      variables: {
        busRouteId: routeId,
      },
    });

  const { data: mergedBusRoutesData, loading: mergedBusRoutesLoading } =
    useMergedBusRoutesQuery({
      variables: {
        busRouteId: routeId,
      },
    });

  const [editableData, setEditableData] = React.useState(false);

  const [dialogWidth, setDialogWidth] = React.useState<Breakpoint>("sm");

  const handleEditableData = (
    event: React.MouseEvent<HTMLElement>,
    value: string | null
  ) => {
    setEditData(event.currentTarget.attributes[3].value);
    if (event.currentTarget.attributes[4].value === "false") {
      event.currentTarget.attributes[5].value = "border-color: orange;";
    } else {
      event.currentTarget.attributes[5].value = "border-color: gray;";
      setEditData("");
    }
  };

  function RadioButtonsControlLabel(props: RadioButtonsControlLabelProps) {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
      checked = radioGroup.value === props.value;
    }

    useEffect(() => {
      if (radioGroup) {
        setMergeOption(radioGroup.value);
      }
    }, [radioGroup?.value]);

    return <StyledRadioButtonsControlLabel checked={checked} {...props} />;
  }

  function EditOptionsControlLabel(props: RadioButtonsControlLabelProps) {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
      checked = radioGroup.value === props.value;
    }

    useEffect(() => {
      if (radioGroup) {
        setEditOption(radioGroup.value);
      }
    }, [radioGroup?.value]);

    return <StyledRadioButtonsControlLabel checked={checked} {...props} />;
  }

  useEffect(() => {}, [checkedRoutes]);

  useEffect(() => {
    if (!open) {
      setCheckedRoutes([]);
      setEditData("");
    }
  }, [open]);

  useEffect(() => {
    if (mergeOption === "routes") {
      setDialogWidth("sm");
    } else {
      setDialogWidth("md");
    }
  }, [mergeOption]);

  useEffect(() => {
    if (!mergedBusStopsLoading && !mergedBusRoutesLoading) {
      if (
        mergedBusStopsData!.getMergedBusStops?.length > 0 ||
        mergedBusRoutesData!.getMergedBusRoutePaths?.length > 0
      )
        setEditableData(true);
    }
  }, [mergedBusStopsData, mergedBusRoutesData]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { width: "800px" } }}
      fullWidth
      maxWidth={dialogWidth}
    >
      <DialogTitle>Manage Merge of Routes</DialogTitle>
      <form
        //   onSubmit={handleSubmit(OnSubmit)}
        autoComplete="off"
      >
        <DialogContent>
          {editableData && (
            <ToggleButtonGroup
              value={editData}
              exclusive
              onChange={handleEditableData}
              aria-label="Check Merges"
            >
              <ToggleButton
                value="editData"
                color="primary"
                style={{ borderColor: "gray" }}
              >
                <Typography variant="body1">Update merged data</Typography>
              </ToggleButton>
            </ToggleButtonGroup>
          )}

          {editData === "editData" && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Divider
                  sx={{
                    marginTop: "8px",
                    marginBottom: "8px",
                  }}
                  color="lightgray"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>What do you want to update?</Typography>
                <RadioGroup row name="editOptions" defaultValue="routes">
                  <EditOptionsControlLabel
                    value="routes"
                    label="Routes"
                    control={<Radio />}
                  />
                  <EditOptionsControlLabel
                    value="stops"
                    label="Stops"
                    control={<Radio />}
                  />
                </RadioGroup>
              </Grid>
            </Grid>
          )}

          {editData === "editData" ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {editOption === "routes" && (
                  <MergePathUpdateModal
                    schoolSystemId={schoolSystemId}
                    routeId={routeId}
                    onClose={onClose}
                    open={open}
                  />
                )}
                {editOption === "stops" && (
                  <MergeStopUpdateModal
                    schoolSystemId={schoolSystemId}
                    onClose={onClose}
                    open={open}
                    routeId={routeId}
                  />
                )}
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              sx={{ marginTop: "5px", textAlign: "left" }}
              spacing={2}
            >
              <Grid item xs={12}>
                <Typography>What do you want to merge?</Typography>
                <RadioGroup row name="mergeOptions" defaultValue="routes">
                  <RadioButtonsControlLabel
                    value="routes"
                    label="Routes"
                    control={<Radio />}
                  />
                  <RadioButtonsControlLabel
                    value="stops"
                    label="Stops"
                    control={<Radio />}
                  />
                </RadioGroup>
                {mergeOption === "routes" && (
                  <MergePathCreatorModal
                    onClose={onClose}
                    open={open}
                    routeId={routeId}
                    schoolSystemId={schoolSystemId}
                    mergeableRoutes={
                      (mergeableRoutesData?.getBusRoutesToMerge as BusRouteDto[]) ??
                      []
                    }
                  />
                )}
                {mergeOption === "stops" && (
                  <MergeStopCreatorModal
                    onClose={onClose}
                    open={open}
                    routeId={routeId}
                    schoolSystemId={schoolSystemId}
                    mergeableRoutes={
                      (mergeableRoutesData?.getBusRoutesToMerge as BusRouteDto[]) ??
                      []
                    }
                    mergedBusStops={mergedBusStopsData?.getMergedBusStops ?? []}
                  />
                )}
              </Grid>
            </Grid>
          )}
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default MergeRoutesModal;
