import { OutlinedInput } from "@mui/material";
import { Autocomplete } from "@react-google-maps/api";
import React, { useRef, useState } from "react";

export interface PlaceResult {
  address: string;
  lat: number;
  lng: number;
}
interface PlaceSearchProps {
  onPlaceChange: (place: PlaceResult) => void;
}

const PlaceSearch: React.FC<PlaceSearchProps> = ({ onPlaceChange }) => {
  // const { isLoaded, loadError } = useJsApiLoader({
  //   libraries: libraries,
  //   googleMapsApiKey: apiKey, // ,
  //   // ...otherOptions
  // });

  const [query, setQuery] = useState("");

  const searchBox = useRef<google.maps.places.Autocomplete>();
  const onPlacesChanged = () => {
    const place = searchBox.current?.getPlace();
    if (place) {
      console.log(place);
      const lat = place.geometry?.location?.lat();
      const lng = place.geometry?.location?.lng();
      if (lat && lng && place.formatted_address) {
        setQuery("");
        onPlaceChange({ lat, lng, address: place.formatted_address });
      }
    }
  };

  return (
    <Autocomplete
      onLoad={(s) => (searchBox.current = s)}
      onPlaceChanged={onPlacesChanged}
      restrictions={{
        country: ["us", "do"],
      }}
      // types={["geocode"]}
    >
      {/* <input type="text" /> */}
      <OutlinedInput
        id="search-place"
        fullWidth
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        // placeholder="Search place"

        // sx={{ marginBottom: 20 }}
      />
    </Autocomplete>
  );
};

export default PlaceSearch;
