import { GridColDef } from "@mui/x-data-grid";
import React from "react";

import { SchoolsPaginatedItemFragment } from "../../../apollo/schools/queries.generated";
import { SchoolSystemPaginatedItemFragment } from "../../../apollo/schoolSystems/queries.generated";

import {
  GetUsersPaginatedDocument,
  UsersPaginatedItemFragment,
} from "../../../apollo/users/queries.generated";

import RemoteDataTable, {
  BasicTableActionFunction,
  getBasicActionColumns,
  RemoteDataTableProps,
} from "../../../shared/components/RemoteDataTable";
import Link from "../../../navigation/Link";

interface UsersTableProps
  extends Pick<RemoteDataTableProps, "onAddClick" | "refresherBoolean"> {
  onEditPress?: BasicTableActionFunction<UsersPaginatedItemFragment>;
  onDeletePress?: BasicTableActionFunction<UsersPaginatedItemFragment>;
}
const muiColumns: GridColDef[] = [
  {
    flex: 1,
    field: "name",
    headerName: "Name",
    minWidth: 200,
    renderCell: ({ row, value }) => {
      const user = row as UsersPaginatedItemFragment;
      return <Link to={"/dashboard/users/" + user.id}>{value}</Link>;
    },
  },
  {
    flex: 1,
    field: "email",
    headerName: "Email",
    minWidth: 200,
  },
  {
    flex: 1,
    field: "role",
    headerName: "Role",
    minWidth: 200,
  },
  {
    flex: 1,
    field: "school",
    headerName: "School",
    minWidth: 200,
    valueGetter: ({ value }) => {
      if (!value) return "Not Required";
      return (value as SchoolsPaginatedItemFragment)?.name;
    },
  },
  {
    flex: 1,
    field: "schoolSystem",
    headerName: "School System",
    valueGetter: ({ value }) => {
      return (value as SchoolSystemPaginatedItemFragment)?.name;
    },
    minWidth: 200,
  },
];

const UsersTable: React.FC<UsersTableProps> = ({
  onEditPress,
  onDeletePress,
  ...props
}) => {
  return (
    <RemoteDataTable
      query={GetUsersPaginatedDocument}
      searchable
      renderAddButton
      columns={muiColumns}
      actionColumns={getBasicActionColumns(onEditPress, onDeletePress)}
      {...props}
    />
  );
};

export default UsersTable;
