import React, { useEffect } from "react";
import {
  FamilyPaginatedItemFragment,
  GetFamiliesPaginatedDocument,
  useCreateFamilyMutation,
  useUpdateFamilyMutation,
} from "../../../apollo/families/query.generated";
import {
  GetParentsPaginatedDocument,
  ParentsPaginatedItemFragment,
} from "../../../apollo/parents/query.generated";
import { CreateFamilyDto } from "../../../apollo/types.generated";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import ControlledTextField from "../../../shared/components/ControlledTextField";
import ControlledMultiSelectDialog from "../../../shared/components/MultiSelectDialog.tsx/ControlledMultiSelectDialog";
import useDidMountEffect from "../../../shared/hooks/useDidMountEffect";
import FamilyPreviewSection from "./FamilyPreviewSection";
import {
  GetUsersFamilyPaginatedDocument,
  GetUsersPaginatedDocument,
  UsersPaginatedItemFragment,
} from "../../../apollo/users/queries.generated";

interface FamilyFormModalProps {
  open: boolean;
  onClose: () => void;
  editingFamily?: FamilyPaginatedItemFragment;
}

interface FormInputs extends Omit<CreateFamilyDto, "usersIds"> {
  users: UsersPaginatedItemFragment[];
}
const schema = Yup.object({
  name: Yup.string().required("You must specify a family name."),
  users: Yup.array().min(1, "You must add at least one member").defined(),
});

const getDefaultValues = (
  family?: FamilyPaginatedItemFragment
): FormInputs | undefined => {
  return {
    name: family?.name || "",
    users: family?.members || [],
  };
};

const FamilyFormModal: React.FC<FamilyFormModalProps> = ({
  open,
  onClose,
  editingFamily,
}) => {
  const { control, handleSubmit, reset, setValue, watch } = useForm<FormInputs>(
    {
      resolver: yupResolver(schema),
      shouldFocusError: true,
      defaultValues: getDefaultValues(editingFamily),
    }
  );

  const users = watch("users");

  const [
    create,
    { data: createData, loading: createLoading, error: createError },
  ] = useCreateFamilyMutation({
    refetchQueries: [GetFamiliesPaginatedDocument],
  });

  const [
    update,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useUpdateFamilyMutation({
    refetchQueries: [GetFamiliesPaginatedDocument],
  });

  const onSubmit = (data: FormInputs) => {
    const { users, ...rest } = data;
    const usersIds = users.map((user) => user.id);
    if (editingFamily) {
      update({
        variables: {
          id: editingFamily.id,
          data: {
            ...rest,
            usersIds,
          },
        },
        refetchQueries: [
          GetFamiliesPaginatedDocument,
          GetParentsPaginatedDocument,
        ],
      });
      return;
    }

    create({
      variables: {
        data: {
          ...rest,
          usersIds,
        },
      },
      refetchQueries: [
        GetFamiliesPaginatedDocument,
        GetParentsPaginatedDocument,
      ],
    });
  };

  useEffect(() => {
    if (createData) {
      toast.success("Family created successfully");
      onClose();
    }

    if (createError) {
      toast.error("Error creating Family: " + createError.message);
    }
  }, [createData, createError]);

  useEffect(() => {
    if (updateData) {
      toast.success("Family updated successfully");
      onClose();
    }

    if (updateError) {
      toast.error("Error updating Family: " + updateError.message);
    }
  }, [updateData, updateError]);

  useDidMountEffect(() => {
    reset(getDefaultValues(editingFamily));
  }, [editingFamily]);

  return (
    <Dialog
      open={open}
      onClose={!createLoading ? onClose : undefined}
      maxWidth="md"
      fullWidth
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <DialogTitle>{editingFamily ? "Update" : "Create"} Family</DialogTitle>
        <DialogContent>
          <Grid container sx={{ marginTop: "10px" }} spacing={2}>
            <Grid item xs={12}>
              <ControlledTextField
                name="name"
                control={control}
                label="First Name"
              />
            </Grid>

            <Grid item xs={12}>
              <ControlledMultiSelectDialog
                name="users"
                control={control}
                dialogTitle="Select Family's Members"
                textfieldProps={{
                  label: "Members",
                  fullWidth: true,
                }}
                query={GetUsersFamilyPaginatedDocument}
                labelsExtractor={(item: UsersPaginatedItemFragment) => ({
                  primary: item.name,
                  secondary: item.schoolSystem?.name,
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <FamilyPreviewSection users={users} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel </Button>
          <LoadingButton loading={createLoading || updateLoading} type="submit">
            Submit
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default FamilyFormModal;
