import React from "react";
import {
  Backdrop,
  CircularProgress,
  BackdropProps,
  useTheme,
} from "@mui/material";
interface Props extends BackdropProps {}

const LoadingBackdrop: React.FC<Props> = ({ open }) => {
  const theme = useTheme();
  return (
    <Backdrop
      open={open}
      sx={{
        zIndex: theme.zIndex.drawer + 1,
      }}
    >
      <CircularProgress color="secondary" />
    </Backdrop>
  );
};

export default LoadingBackdrop;
