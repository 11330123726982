import { Box, Grid, Typography, Button, Container } from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { GetSchoolSystemsPaginatedDocument } from "../../../apollo/schoolSystems/queries.generated";
import { CreateStudentAccountDto } from "../../../apollo/types.generated";
import ControlledSelectModalField from "../../../shared/components/ControlledSelectModalField";
import ControlledTextField from "../../../shared/components/ControlledTextField";
import LoadingBackdrop from "../../../shared/components/LoadingBackdrop";
import * as Yup from "yup";
import apolloClient from "../../../apollo/client";
import {
  CheckEmailAvailabilityDocument,
  CheckEmailAvailabilityQuery,
  CheckEmailAvailabilityQueryVariables,
} from "../../../apollo/users/queries.generated";
import { useCreateStudentAccountMutation } from "../../../apollo/students/queries.generated";
import toast from "react-hot-toast";
import { yupResolver } from "@hookform/resolvers/yup";
import useQuery from "../../../shared/hooks/useQuery";
import SuccessInfoPage from "../../../shared/components/SuccessInfoPage";
import InfoIcon from "@mui/icons-material/Info";

interface StudentsAccountFormProps {}

interface FormInputs extends CreateStudentAccountDto {
  passwordConfirmation: string;
}

const validationSchema = Yup.object({
  firstName: Yup.string().required("This field is required"),
  lastName: Yup.string().required("This field is required"),
  routeId: Yup.string().required("Missing bus route number/name"),

  email: Yup.string()
    .required("This field is required")
    .email("Invalid email")
    .test(
      "test-mail",
      "This email address is not available",
      async (value, context) => {
        if (!value) return false;
        const result = await apolloClient.query<
          CheckEmailAvailabilityQuery,
          CheckEmailAvailabilityQueryVariables
        >({
          query: CheckEmailAvailabilityDocument,
          variables: { email: value },
        });

        return result.data.checkEmailAvailability;
      }
    ),
  parentEmail: Yup.string()
    .notOneOf(
      Yup.ref("email") ? [Yup.ref("email")] : [],
      "Parent email must be different than student email"
    )
    .email("Invalid email"),

  password: Yup.string().required("This field is required"),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required(""),
});
const StudentsAccountForm: React.FC<StudentsAccountFormProps> = ({}) => {
  const query = useQuery();
  const { control, handleSubmit } = useForm<FormInputs>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      passwordConfirmation: "",
      routeId: query.get("routeId") || "",
      parentEmail: "",
    },
  });

  const [submit, { loading, data, error }] = useCreateStudentAccountMutation();

  const onSubmit = ({ passwordConfirmation, ...data }: FormInputs) => {
    submit({
      variables: {
        data,
      },
    });
  };

  useEffect(() => {
    if (error) {
      toast.error(
        "There was an error processing your request: " + error.message
      );
    }

    return () => {};
  }, [error]);

  useEffect(() => {
    if (data) {
      // loadUser(data.createParentAccount.parent.user);
      // toast.success("Account created successfully");
      // updateAuthToken(data.createParentAccount.token);
      // history.
      //The page should redirect to the dashboard once the token is loaded;
    }

    return () => {};
  }, [data]);
  if (data?.createStudentAccount) {
    return <SuccessInfoPage text="Account created successfully." />;
  }

  return (
    <Box
      flex={1}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      marginBottom="20px"
    >
      <LoadingBackdrop open={loading} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container>
          <Grid container sx={{ marginTop: "10px" }} spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom textAlign="start">
                Create Student Account
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <ControlledTextField
                name="routeId"
                control={control}
                label="Bus Route Number/Name*"
              />

              <Typography variant="body2" align="left" color={"GrayText"}>
                <InfoIcon fontSize="inherit" />
                Ask your bus driver for the route number/name if needed.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <ControlledTextField
                name="firstName"
                control={control}
                label="First Name*"
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                name="lastName"
                control={control}
                label="Last Name*"
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                name="email"
                control={control}
                label="Email*"
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                name="parentEmail"
                control={control}
                label="Parent's Email"
              />
            </Grid>

            <Grid item xs={12}>
              <ControlledTextField
                name="password"
                control={control}
                type="password"
                label="Password*"
              />
            </Grid>

            <Grid item xs={12}>
              <ControlledTextField
                type="password"
                name="passwordConfirmation"
                control={control}
                label="Password Confirmation*"
              />
            </Grid>

            <Grid item xs={12} container justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{ paddingX: "50px" }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Container>
      </form>
    </Box>
  );
};

export default StudentsAccountForm;
