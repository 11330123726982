import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateBusRouteMutationVariables = Types.Exact<{
  data: Types.CreateBusRouteDto;
}>;


export type CreateBusRouteMutation = { createBusRoute: { id: string, school: { id: string } } };

export type PathsDetailedFragment = { id: string, startTime: any, endTime: any, type: Types.BusRoutePathType, students: Array<{ id: string, user: { id: string, name: string, firstName: string, lastName: string, email: string } }>, stops: Array<{ id: string, address: string, latitude: number, longitude: number, order: number, students: Array<{ id: string, user: { id: string, name: string, firstName: string, lastName: string, email: string } }> }> };

export type BusRouteDetailedFragment = { id: string, routeId?: string | null | undefined, createdAt: any, name: string, paths: Array<{ id: string, startTime: any, endTime: any, type: Types.BusRoutePathType, students: Array<{ id: string, user: { id: string, name: string, firstName: string, lastName: string, email: string } }>, stops: Array<{ id: string, address: string, latitude: number, longitude: number, order: number, students: Array<{ id: string, user: { id: string, name: string, firstName: string, lastName: string, email: string } }> }> }>, busDriver: { id: string, busNumber?: string | null | undefined, user: { lastName: string, id: string, firstName: string } }, school: { id: string, name: string, schoolSystem?: { id: string, name: string } | null | undefined }, schoolSystem: { id: string, name: string }, aditionalSchools?: Array<{ id: string, name: string }> | null | undefined };

export type GetBusRouteQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetBusRouteQuery = { busRoute: { id: string, routeId?: string | null | undefined, createdAt: any, name: string, paths: Array<{ id: string, startTime: any, endTime: any, type: Types.BusRoutePathType, students: Array<{ id: string, user: { id: string, name: string, firstName: string, lastName: string, email: string } }>, stops: Array<{ id: string, address: string, latitude: number, longitude: number, order: number, students: Array<{ id: string, user: { id: string, name: string, firstName: string, lastName: string, email: string } }> }> }>, busDriver: { id: string, busNumber?: string | null | undefined, user: { lastName: string, id: string, firstName: string } }, school: { id: string, name: string, schoolSystem?: { id: string, name: string } | null | undefined }, schoolSystem: { id: string, name: string }, aditionalSchools?: Array<{ id: string, name: string }> | null | undefined } };

export type BusRoutePaginatedItemFragment = { id: string, routeId?: string | null | undefined, name: string, createdAt: any, updatedAt: any, schoolSystem: { id: string, name: string }, school: { id: string, name: string }, busDriver: { id: string, busNumber?: string | null | undefined, user: { id: string, firstName: string, lastName: string, name: string } }, aditionalSchools?: Array<{ id: string, name: string }> | null | undefined };

export type GetBusRoutesPaginatedQueryVariables = Types.Exact<{
  search?: Types.Maybe<Types.Scalars['String']>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  sortBy?: Types.Maybe<Types.Scalars['String']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  sortDirection?: Types.Maybe<Types.Scalars['String']>;
  schoolSystemId?: Types.Maybe<Types.Scalars['String']>;
  schoolId?: Types.Maybe<Types.Scalars['String']>;
}>;


export type GetBusRoutesPaginatedQuery = { busRoutesPaginated: { nodes?: Array<{ id: string, routeId?: string | null | undefined, name: string, createdAt: any, updatedAt: any, schoolSystem: { id: string, name: string }, school: { id: string, name: string }, busDriver: { id: string, busNumber?: string | null | undefined, user: { id: string, firstName: string, lastName: string, name: string } }, aditionalSchools?: Array<{ id: string, name: string }> | null | undefined }> | null | undefined } };

export type DeleteBusRouteMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteBusRouteMutation = { deleteBusRoute: { id: string } };

export type UpdateBusRouteMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  data: Types.UpdateBusRouteDto;
}>;


export type UpdateBusRouteMutation = { updateBusRoute: { id: string, school: { id: string }, paths: Array<{ id: string, startTime: any, endTime: any, type: Types.BusRoutePathType, students: Array<{ id: string, user: { id: string, name: string, firstName: string, lastName: string, email: string } }>, stops: Array<{ id: string, address: string, latitude: number, longitude: number, order: number, students: Array<{ id: string, user: { id: string, name: string, firstName: string, lastName: string, email: string } }> }> }> } };

export type BusRouteForAssignationsFragment = { id: string, routeId?: string | null | undefined, createdAt: any, name: string, paths: Array<{ id: string, type: Types.BusRoutePathType, students: Array<{ id: string, user: { id: string, name: string } }>, stops: Array<{ id: string, address: string, longitude: number, latitude: number, students: Array<{ id: string, user: { id: string, name: string } }> }> }> };

export type GetBusRouteForAssignationQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetBusRouteForAssignationQuery = { busRoute: { id: string, routeId?: string | null | undefined, createdAt: any, name: string, paths: Array<{ id: string, type: Types.BusRoutePathType, students: Array<{ id: string, user: { id: string, name: string } }>, stops: Array<{ id: string, address: string, longitude: number, latitude: number, students: Array<{ id: string, user: { id: string, name: string } }> }> }> } };

export type AssignStudentsToStopsMutationVariables = Types.Exact<{
  data: Array<Types.CreateBusStopAssigmentDto> | Types.CreateBusStopAssigmentDto;
}>;


export type AssignStudentsToStopsMutation = { assignStudentsToStops: Array<{ id: string }> };

export type AdminCurrentPathFragment = { id: string, attendance: { id: string, createdAt: any, busDriver: { id: string, user: { id: string, name: string } } }, path: { id: string, startTime: any, endTime: any, stops: Array<{ id: string, latitude: number, longitude: number, address: string }> }, school: { id: string, name: string }, route: { id: string, name: string, routeId?: string | null | undefined, busDriver: { id: string, user: { id: string, name: string } }, aditionalSchools?: Array<{ id: string, name: string }> | null | undefined } };

export type GetCurrentRoutesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCurrentRoutesQuery = { adminCurrentPaths: Array<{ id: string, attendance: { id: string, createdAt: any, busDriver: { id: string, user: { id: string, name: string } } }, path: { id: string, startTime: any, endTime: any, stops: Array<{ id: string, latitude: number, longitude: number, address: string }> }, school: { id: string, name: string }, route: { id: string, name: string, routeId?: string | null | undefined, busDriver: { id: string, user: { id: string, name: string } }, aditionalSchools?: Array<{ id: string, name: string }> | null | undefined } }> };

export type CreateAditionalSchoolsMutationVariables = Types.Exact<{
  data: Types.CreateAditionalSchoolsDto;
}>;


export type CreateAditionalSchoolsMutation = { createAditionalSchools: boolean };

export type UpdateAditionalSchoolsMutationVariables = Types.Exact<{
  data: Types.CreateAditionalSchoolsDto;
}>;


export type UpdateAditionalSchoolsMutation = { updateAdditionalSchools: boolean };

export type BusRoutesMergeableQueryVariables = Types.Exact<{
  schoolSystemId: Types.Scalars['String'];
  busRouteId: Types.Scalars['String'];
}>;


export type BusRoutesMergeableQuery = { getBusRoutesToMerge?: Array<{ id: string, name: string, busRoutePathsForMerging?: Array<Array<string>> | null | undefined, school: { id: string, name: string }, paths: Array<{ id: string, type: Types.BusRoutePathType, stops: Array<{ id: string, address: string, students: Array<{ id: string, user: { id: string, name: string } }> }> }> }> | null | undefined };

export type CreateMergeBusRoutePathMutationVariables = Types.Exact<{
  data: Types.MergeBusRoutePathDto;
}>;


export type CreateMergeBusRoutePathMutation = { createMergeBusRoutePath: boolean };

export type CreateMergePathStopMutationVariables = Types.Exact<{
  data: Types.MergePathStopsDto;
}>;


export type CreateMergePathStopMutation = { createMergeBusStop: boolean };

export type MergedBusStopsQueryVariables = Types.Exact<{
  busRouteId: Types.Scalars['String'];
}>;


export type MergedBusStopsQuery = { getMergedBusStops: Array<{ id: string, pathType: Types.BusRoutePathType, startMergeTime: any, endMergeTime: any, mainBusRoute: { id: string, name: string }, mergeableBusStop: { id: string, address: string, students: Array<{ id: string, user: { id: string, name: string } }> } }> };

export type MergedBusRoutesQueryVariables = Types.Exact<{
  busRouteId: Types.Scalars['String'];
}>;


export type MergedBusRoutesQuery = { getMergedBusRoutePaths: Array<{ id: string, mainBusRouteId: string, originBusRouteId: string, pathType: string, pathName: string, startMergeTime: any, endMergeTime: any, mainBusRoute: { id: string, name: string, schoolSystem: { id: string, name: string } }, mergeableBusRoutePath: { id: string, busRouteId: string, startTime: any, endTime: any, students: Array<{ id: string, user: { id: string, name: string } }>, stops: Array<{ id: string }> } }> };

export type DeleteMergedBusStopMutationVariables = Types.Exact<{
  mergedStopId: Types.Scalars['String'];
}>;


export type DeleteMergedBusStopMutation = { deleteMergedBusStop: boolean };

export type DeleteMergedBusRoutePathMutationVariables = Types.Exact<{
  mergedRouteId: Types.Scalars['String'];
}>;


export type DeleteMergedBusRoutePathMutation = { deleteMergedBusRoutePath: boolean };

export type UpdateMergedBusStopMutationVariables = Types.Exact<{
  data: Types.UpdateMergedStopDto;
}>;


export type UpdateMergedBusStopMutation = { updateMergedBusStop: boolean };

export type UpdateMergedBusRoutePathMutationVariables = Types.Exact<{
  data: Types.UpdateMergedRouteDto;
}>;


export type UpdateMergedBusRoutePathMutation = { updateMergedBusRoutePath: boolean };

export type BusRoutePathQueryVariables = Types.Exact<{
  routePathId: Types.Scalars['String'];
}>;


export type BusRoutePathQuery = { getBusRoutePath: { id: string, startTime: any, endTime: any, type: Types.BusRoutePathType, students: Array<{ id: string, user: { id: string, name: string, firstName: string, lastName: string, email: string } }>, stops: Array<{ id: string, address: string, latitude: number, longitude: number, order: number, students: Array<{ id: string, user: { id: string, name: string, firstName: string, lastName: string, email: string } }> }> } };

export type AttendanceListFragment = { id: string, createdAt: any, startTime?: any | null | undefined, completedAt?: any | null | undefined, totalTimeInAttendance?: string | null | undefined, busPath: { id: string, type: Types.BusRoutePathType }, busDriver: { id: string, user: { id: string, name: string } } };

export type AttendanceListPaginatedQueryVariables = Types.Exact<{
  busRouteId: Types.Scalars['String'];
  pathType?: Types.Maybe<Types.Scalars['String']>;
  search?: Types.Maybe<Types.Scalars['String']>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  sortBy?: Types.Maybe<Types.Scalars['String']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  sortDirection?: Types.Maybe<Types.Scalars['String']>;
}>;


export type AttendanceListPaginatedQuery = { attendanceListPaginated?: { nodes?: Array<{ id: string, createdAt: any, startTime?: any | null | undefined, completedAt?: any | null | undefined, totalTimeInAttendance?: string | null | undefined, busPath: { id: string, type: Types.BusRoutePathType }, busDriver: { id: string, user: { id: string, name: string } } }> | null | undefined } | null | undefined };

export const PathsDetailedFragmentDoc = gql`
    fragment PathsDetailed on BusRoutePathDTO {
  id
  startTime
  endTime
  type
  students {
    id
    user {
      id
      name
      firstName
      lastName
      email
    }
  }
  stops {
    id
    address
    latitude
    longitude
    order
    students {
      id
      user {
        id
        name
        firstName
        lastName
        email
      }
    }
  }
}
    `;
export const BusRouteDetailedFragmentDoc = gql`
    fragment BusRouteDetailed on BusRouteDTO {
  id
  routeId
  createdAt
  name
  paths {
    ...PathsDetailed
  }
  busDriver {
    id
    user {
      lastName
      id
      firstName
    }
    busNumber
  }
  school {
    id
    name
    schoolSystem {
      id
      name
    }
  }
  schoolSystem {
    id
    name
  }
  aditionalSchools {
    id
    name
  }
}
    ${PathsDetailedFragmentDoc}`;
export const BusRoutePaginatedItemFragmentDoc = gql`
    fragment BusRoutePaginatedItem on BusRouteDTO {
  id
  routeId
  name
  schoolSystem {
    id
    name
  }
  school {
    id
    name
  }
  busDriver {
    id
    busNumber
    user {
      id
      firstName
      lastName
      name
    }
  }
  aditionalSchools {
    id
    name
  }
  createdAt
  updatedAt
}
    `;
export const BusRouteForAssignationsFragmentDoc = gql`
    fragment BusRouteForAssignations on BusRouteDTO {
  id
  routeId
  createdAt
  name
  paths {
    id
    type
    students {
      id
      user {
        id
        name
      }
    }
    stops {
      id
      address
      longitude
      latitude
      students {
        id
        user {
          id
          name
        }
      }
    }
  }
}
    `;
export const AdminCurrentPathFragmentDoc = gql`
    fragment AdminCurrentPath on AdminPathInformationDTO {
  id
  attendance {
    id
    createdAt
    busDriver {
      id
      user {
        id
        name
      }
    }
  }
  path {
    id
    startTime
    endTime
    stops {
      id
      latitude
      longitude
      address
    }
  }
  school {
    id
    name
  }
  route {
    id
    name
    routeId
    busDriver {
      id
      user {
        id
        name
      }
    }
    aditionalSchools {
      id
      name
    }
  }
}
    `;
export const AttendanceListFragmentDoc = gql`
    fragment attendanceList on AttendanceDTO {
  id
  createdAt
  startTime
  completedAt
  totalTimeInAttendance
  busPath {
    id
    type
  }
  busDriver {
    id
    user {
      id
      name
    }
  }
}
    `;
export const CreateBusRouteDocument = gql`
    mutation createBusRoute($data: CreateBusRouteDTO!) {
  createBusRoute(data: $data) {
    id
    school {
      id
    }
  }
}
    `;
export type CreateBusRouteMutationFn = Apollo.MutationFunction<CreateBusRouteMutation, CreateBusRouteMutationVariables>;

/**
 * __useCreateBusRouteMutation__
 *
 * To run a mutation, you first call `useCreateBusRouteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBusRouteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBusRouteMutation, { data, loading, error }] = useCreateBusRouteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateBusRouteMutation(baseOptions?: Apollo.MutationHookOptions<CreateBusRouteMutation, CreateBusRouteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBusRouteMutation, CreateBusRouteMutationVariables>(CreateBusRouteDocument, options);
      }
export type CreateBusRouteMutationHookResult = ReturnType<typeof useCreateBusRouteMutation>;
export type CreateBusRouteMutationResult = Apollo.MutationResult<CreateBusRouteMutation>;
export type CreateBusRouteMutationOptions = Apollo.BaseMutationOptions<CreateBusRouteMutation, CreateBusRouteMutationVariables>;
export const GetBusRouteDocument = gql`
    query getBusRoute($id: String!) {
  busRoute(id: $id) {
    ...BusRouteDetailed
  }
}
    ${BusRouteDetailedFragmentDoc}`;

/**
 * __useGetBusRouteQuery__
 *
 * To run a query within a React component, call `useGetBusRouteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusRouteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusRouteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBusRouteQuery(baseOptions: Apollo.QueryHookOptions<GetBusRouteQuery, GetBusRouteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBusRouteQuery, GetBusRouteQueryVariables>(GetBusRouteDocument, options);
      }
export function useGetBusRouteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBusRouteQuery, GetBusRouteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBusRouteQuery, GetBusRouteQueryVariables>(GetBusRouteDocument, options);
        }
export type GetBusRouteQueryHookResult = ReturnType<typeof useGetBusRouteQuery>;
export type GetBusRouteLazyQueryHookResult = ReturnType<typeof useGetBusRouteLazyQuery>;
export type GetBusRouteQueryResult = Apollo.QueryResult<GetBusRouteQuery, GetBusRouteQueryVariables>;
export const GetBusRoutesPaginatedDocument = gql`
    query getBusRoutesPaginated($search: String, $limit: Int, $sortBy: String, $offset: Int, $sortDirection: String, $schoolSystemId: String, $schoolId: String) {
  busRoutesPaginated(
    schoolSystemId: $schoolSystemId
    schoolId: $schoolId
    search: $search
    limit: $limit
    offset: $offset
    sortBy: $sortBy
    sortDirection: $sortDirection
  ) {
    nodes {
      ...BusRoutePaginatedItem
    }
  }
}
    ${BusRoutePaginatedItemFragmentDoc}`;

/**
 * __useGetBusRoutesPaginatedQuery__
 *
 * To run a query within a React component, call `useGetBusRoutesPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusRoutesPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusRoutesPaginatedQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      offset: // value for 'offset'
 *      sortDirection: // value for 'sortDirection'
 *      schoolSystemId: // value for 'schoolSystemId'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetBusRoutesPaginatedQuery(baseOptions?: Apollo.QueryHookOptions<GetBusRoutesPaginatedQuery, GetBusRoutesPaginatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBusRoutesPaginatedQuery, GetBusRoutesPaginatedQueryVariables>(GetBusRoutesPaginatedDocument, options);
      }
export function useGetBusRoutesPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBusRoutesPaginatedQuery, GetBusRoutesPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBusRoutesPaginatedQuery, GetBusRoutesPaginatedQueryVariables>(GetBusRoutesPaginatedDocument, options);
        }
export type GetBusRoutesPaginatedQueryHookResult = ReturnType<typeof useGetBusRoutesPaginatedQuery>;
export type GetBusRoutesPaginatedLazyQueryHookResult = ReturnType<typeof useGetBusRoutesPaginatedLazyQuery>;
export type GetBusRoutesPaginatedQueryResult = Apollo.QueryResult<GetBusRoutesPaginatedQuery, GetBusRoutesPaginatedQueryVariables>;
export const DeleteBusRouteDocument = gql`
    mutation deleteBusRoute($id: String!) {
  deleteBusRoute(id: $id) {
    id
  }
}
    `;
export type DeleteBusRouteMutationFn = Apollo.MutationFunction<DeleteBusRouteMutation, DeleteBusRouteMutationVariables>;

/**
 * __useDeleteBusRouteMutation__
 *
 * To run a mutation, you first call `useDeleteBusRouteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBusRouteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBusRouteMutation, { data, loading, error }] = useDeleteBusRouteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBusRouteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBusRouteMutation, DeleteBusRouteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBusRouteMutation, DeleteBusRouteMutationVariables>(DeleteBusRouteDocument, options);
      }
export type DeleteBusRouteMutationHookResult = ReturnType<typeof useDeleteBusRouteMutation>;
export type DeleteBusRouteMutationResult = Apollo.MutationResult<DeleteBusRouteMutation>;
export type DeleteBusRouteMutationOptions = Apollo.BaseMutationOptions<DeleteBusRouteMutation, DeleteBusRouteMutationVariables>;
export const UpdateBusRouteDocument = gql`
    mutation updateBusRoute($id: String!, $data: UpdateBusRouteDTO!) {
  updateBusRoute(data: $data, id: $id) {
    id
    school {
      id
    }
    paths {
      ...PathsDetailed
    }
  }
}
    ${PathsDetailedFragmentDoc}`;
export type UpdateBusRouteMutationFn = Apollo.MutationFunction<UpdateBusRouteMutation, UpdateBusRouteMutationVariables>;

/**
 * __useUpdateBusRouteMutation__
 *
 * To run a mutation, you first call `useUpdateBusRouteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusRouteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusRouteMutation, { data, loading, error }] = useUpdateBusRouteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateBusRouteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBusRouteMutation, UpdateBusRouteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBusRouteMutation, UpdateBusRouteMutationVariables>(UpdateBusRouteDocument, options);
      }
export type UpdateBusRouteMutationHookResult = ReturnType<typeof useUpdateBusRouteMutation>;
export type UpdateBusRouteMutationResult = Apollo.MutationResult<UpdateBusRouteMutation>;
export type UpdateBusRouteMutationOptions = Apollo.BaseMutationOptions<UpdateBusRouteMutation, UpdateBusRouteMutationVariables>;
export const GetBusRouteForAssignationDocument = gql`
    query getBusRouteForAssignation($id: String!) {
  busRoute(id: $id) {
    ...BusRouteForAssignations
  }
}
    ${BusRouteForAssignationsFragmentDoc}`;

/**
 * __useGetBusRouteForAssignationQuery__
 *
 * To run a query within a React component, call `useGetBusRouteForAssignationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusRouteForAssignationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusRouteForAssignationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBusRouteForAssignationQuery(baseOptions: Apollo.QueryHookOptions<GetBusRouteForAssignationQuery, GetBusRouteForAssignationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBusRouteForAssignationQuery, GetBusRouteForAssignationQueryVariables>(GetBusRouteForAssignationDocument, options);
      }
export function useGetBusRouteForAssignationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBusRouteForAssignationQuery, GetBusRouteForAssignationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBusRouteForAssignationQuery, GetBusRouteForAssignationQueryVariables>(GetBusRouteForAssignationDocument, options);
        }
export type GetBusRouteForAssignationQueryHookResult = ReturnType<typeof useGetBusRouteForAssignationQuery>;
export type GetBusRouteForAssignationLazyQueryHookResult = ReturnType<typeof useGetBusRouteForAssignationLazyQuery>;
export type GetBusRouteForAssignationQueryResult = Apollo.QueryResult<GetBusRouteForAssignationQuery, GetBusRouteForAssignationQueryVariables>;
export const AssignStudentsToStopsDocument = gql`
    mutation assignStudentsToStops($data: [CreateBusStopAssigmentDTO!]!) {
  assignStudentsToStops(data: $data) {
    id
  }
}
    `;
export type AssignStudentsToStopsMutationFn = Apollo.MutationFunction<AssignStudentsToStopsMutation, AssignStudentsToStopsMutationVariables>;

/**
 * __useAssignStudentsToStopsMutation__
 *
 * To run a mutation, you first call `useAssignStudentsToStopsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignStudentsToStopsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignStudentsToStopsMutation, { data, loading, error }] = useAssignStudentsToStopsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAssignStudentsToStopsMutation(baseOptions?: Apollo.MutationHookOptions<AssignStudentsToStopsMutation, AssignStudentsToStopsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignStudentsToStopsMutation, AssignStudentsToStopsMutationVariables>(AssignStudentsToStopsDocument, options);
      }
export type AssignStudentsToStopsMutationHookResult = ReturnType<typeof useAssignStudentsToStopsMutation>;
export type AssignStudentsToStopsMutationResult = Apollo.MutationResult<AssignStudentsToStopsMutation>;
export type AssignStudentsToStopsMutationOptions = Apollo.BaseMutationOptions<AssignStudentsToStopsMutation, AssignStudentsToStopsMutationVariables>;
export const GetCurrentRoutesDocument = gql`
    query getCurrentRoutes {
  adminCurrentPaths {
    ...AdminCurrentPath
  }
}
    ${AdminCurrentPathFragmentDoc}`;

/**
 * __useGetCurrentRoutesQuery__
 *
 * To run a query within a React component, call `useGetCurrentRoutesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentRoutesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentRoutesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentRoutesQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentRoutesQuery, GetCurrentRoutesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentRoutesQuery, GetCurrentRoutesQueryVariables>(GetCurrentRoutesDocument, options);
      }
export function useGetCurrentRoutesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentRoutesQuery, GetCurrentRoutesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentRoutesQuery, GetCurrentRoutesQueryVariables>(GetCurrentRoutesDocument, options);
        }
export type GetCurrentRoutesQueryHookResult = ReturnType<typeof useGetCurrentRoutesQuery>;
export type GetCurrentRoutesLazyQueryHookResult = ReturnType<typeof useGetCurrentRoutesLazyQuery>;
export type GetCurrentRoutesQueryResult = Apollo.QueryResult<GetCurrentRoutesQuery, GetCurrentRoutesQueryVariables>;
export const CreateAditionalSchoolsDocument = gql`
    mutation createAditionalSchools($data: CreateAditionalSchoolsDTO!) {
  createAditionalSchools(data: $data)
}
    `;
export type CreateAditionalSchoolsMutationFn = Apollo.MutationFunction<CreateAditionalSchoolsMutation, CreateAditionalSchoolsMutationVariables>;

/**
 * __useCreateAditionalSchoolsMutation__
 *
 * To run a mutation, you first call `useCreateAditionalSchoolsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAditionalSchoolsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAditionalSchoolsMutation, { data, loading, error }] = useCreateAditionalSchoolsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateAditionalSchoolsMutation(baseOptions?: Apollo.MutationHookOptions<CreateAditionalSchoolsMutation, CreateAditionalSchoolsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAditionalSchoolsMutation, CreateAditionalSchoolsMutationVariables>(CreateAditionalSchoolsDocument, options);
      }
export type CreateAditionalSchoolsMutationHookResult = ReturnType<typeof useCreateAditionalSchoolsMutation>;
export type CreateAditionalSchoolsMutationResult = Apollo.MutationResult<CreateAditionalSchoolsMutation>;
export type CreateAditionalSchoolsMutationOptions = Apollo.BaseMutationOptions<CreateAditionalSchoolsMutation, CreateAditionalSchoolsMutationVariables>;
export const UpdateAditionalSchoolsDocument = gql`
    mutation updateAditionalSchools($data: CreateAditionalSchoolsDTO!) {
  updateAdditionalSchools(data: $data)
}
    `;
export type UpdateAditionalSchoolsMutationFn = Apollo.MutationFunction<UpdateAditionalSchoolsMutation, UpdateAditionalSchoolsMutationVariables>;

/**
 * __useUpdateAditionalSchoolsMutation__
 *
 * To run a mutation, you first call `useUpdateAditionalSchoolsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAditionalSchoolsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAditionalSchoolsMutation, { data, loading, error }] = useUpdateAditionalSchoolsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAditionalSchoolsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAditionalSchoolsMutation, UpdateAditionalSchoolsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAditionalSchoolsMutation, UpdateAditionalSchoolsMutationVariables>(UpdateAditionalSchoolsDocument, options);
      }
export type UpdateAditionalSchoolsMutationHookResult = ReturnType<typeof useUpdateAditionalSchoolsMutation>;
export type UpdateAditionalSchoolsMutationResult = Apollo.MutationResult<UpdateAditionalSchoolsMutation>;
export type UpdateAditionalSchoolsMutationOptions = Apollo.BaseMutationOptions<UpdateAditionalSchoolsMutation, UpdateAditionalSchoolsMutationVariables>;
export const BusRoutesMergeableDocument = gql`
    query busRoutesMergeable($schoolSystemId: String!, $busRouteId: String!) {
  getBusRoutesToMerge(schoolSystemId: $schoolSystemId, busRouteId: $busRouteId) {
    id
    name
    school {
      id
      name
    }
    busRoutePathsForMerging
    paths {
      id
      type
      stops {
        id
        address
        students {
          id
          user {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useBusRoutesMergeableQuery__
 *
 * To run a query within a React component, call `useBusRoutesMergeableQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusRoutesMergeableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusRoutesMergeableQuery({
 *   variables: {
 *      schoolSystemId: // value for 'schoolSystemId'
 *      busRouteId: // value for 'busRouteId'
 *   },
 * });
 */
export function useBusRoutesMergeableQuery(baseOptions: Apollo.QueryHookOptions<BusRoutesMergeableQuery, BusRoutesMergeableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusRoutesMergeableQuery, BusRoutesMergeableQueryVariables>(BusRoutesMergeableDocument, options);
      }
export function useBusRoutesMergeableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusRoutesMergeableQuery, BusRoutesMergeableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusRoutesMergeableQuery, BusRoutesMergeableQueryVariables>(BusRoutesMergeableDocument, options);
        }
export type BusRoutesMergeableQueryHookResult = ReturnType<typeof useBusRoutesMergeableQuery>;
export type BusRoutesMergeableLazyQueryHookResult = ReturnType<typeof useBusRoutesMergeableLazyQuery>;
export type BusRoutesMergeableQueryResult = Apollo.QueryResult<BusRoutesMergeableQuery, BusRoutesMergeableQueryVariables>;
export const CreateMergeBusRoutePathDocument = gql`
    mutation createMergeBusRoutePath($data: mergeBusRoutePathDTO!) {
  createMergeBusRoutePath(data: $data)
}
    `;
export type CreateMergeBusRoutePathMutationFn = Apollo.MutationFunction<CreateMergeBusRoutePathMutation, CreateMergeBusRoutePathMutationVariables>;

/**
 * __useCreateMergeBusRoutePathMutation__
 *
 * To run a mutation, you first call `useCreateMergeBusRoutePathMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMergeBusRoutePathMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMergeBusRoutePathMutation, { data, loading, error }] = useCreateMergeBusRoutePathMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateMergeBusRoutePathMutation(baseOptions?: Apollo.MutationHookOptions<CreateMergeBusRoutePathMutation, CreateMergeBusRoutePathMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMergeBusRoutePathMutation, CreateMergeBusRoutePathMutationVariables>(CreateMergeBusRoutePathDocument, options);
      }
export type CreateMergeBusRoutePathMutationHookResult = ReturnType<typeof useCreateMergeBusRoutePathMutation>;
export type CreateMergeBusRoutePathMutationResult = Apollo.MutationResult<CreateMergeBusRoutePathMutation>;
export type CreateMergeBusRoutePathMutationOptions = Apollo.BaseMutationOptions<CreateMergeBusRoutePathMutation, CreateMergeBusRoutePathMutationVariables>;
export const CreateMergePathStopDocument = gql`
    mutation createMergePathStop($data: mergePathStopsDTO!) {
  createMergeBusStop(data: $data)
}
    `;
export type CreateMergePathStopMutationFn = Apollo.MutationFunction<CreateMergePathStopMutation, CreateMergePathStopMutationVariables>;

/**
 * __useCreateMergePathStopMutation__
 *
 * To run a mutation, you first call `useCreateMergePathStopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMergePathStopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMergePathStopMutation, { data, loading, error }] = useCreateMergePathStopMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateMergePathStopMutation(baseOptions?: Apollo.MutationHookOptions<CreateMergePathStopMutation, CreateMergePathStopMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMergePathStopMutation, CreateMergePathStopMutationVariables>(CreateMergePathStopDocument, options);
      }
export type CreateMergePathStopMutationHookResult = ReturnType<typeof useCreateMergePathStopMutation>;
export type CreateMergePathStopMutationResult = Apollo.MutationResult<CreateMergePathStopMutation>;
export type CreateMergePathStopMutationOptions = Apollo.BaseMutationOptions<CreateMergePathStopMutation, CreateMergePathStopMutationVariables>;
export const MergedBusStopsDocument = gql`
    query mergedBusStops($busRouteId: String!) {
  getMergedBusStops(routeId: $busRouteId) {
    id
    mainBusRoute {
      id
      name
    }
    mergeableBusStop {
      id
      address
      students {
        id
        user {
          id
          name
        }
      }
    }
    pathType
    startMergeTime
    endMergeTime
  }
}
    `;

/**
 * __useMergedBusStopsQuery__
 *
 * To run a query within a React component, call `useMergedBusStopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMergedBusStopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMergedBusStopsQuery({
 *   variables: {
 *      busRouteId: // value for 'busRouteId'
 *   },
 * });
 */
export function useMergedBusStopsQuery(baseOptions: Apollo.QueryHookOptions<MergedBusStopsQuery, MergedBusStopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MergedBusStopsQuery, MergedBusStopsQueryVariables>(MergedBusStopsDocument, options);
      }
export function useMergedBusStopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MergedBusStopsQuery, MergedBusStopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MergedBusStopsQuery, MergedBusStopsQueryVariables>(MergedBusStopsDocument, options);
        }
export type MergedBusStopsQueryHookResult = ReturnType<typeof useMergedBusStopsQuery>;
export type MergedBusStopsLazyQueryHookResult = ReturnType<typeof useMergedBusStopsLazyQuery>;
export type MergedBusStopsQueryResult = Apollo.QueryResult<MergedBusStopsQuery, MergedBusStopsQueryVariables>;
export const MergedBusRoutesDocument = gql`
    query mergedBusRoutes($busRouteId: String!) {
  getMergedBusRoutePaths(routeId: $busRouteId) {
    id
    mainBusRoute {
      id
      name
      schoolSystem {
        id
        name
      }
    }
    mainBusRouteId
    originBusRouteId
    pathType
    pathName
    mergeableBusRoutePath {
      id
      busRouteId
      startTime
      endTime
      students {
        id
        user {
          id
          name
        }
      }
      stops {
        id
      }
    }
    startMergeTime
    endMergeTime
  }
}
    `;

/**
 * __useMergedBusRoutesQuery__
 *
 * To run a query within a React component, call `useMergedBusRoutesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMergedBusRoutesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMergedBusRoutesQuery({
 *   variables: {
 *      busRouteId: // value for 'busRouteId'
 *   },
 * });
 */
export function useMergedBusRoutesQuery(baseOptions: Apollo.QueryHookOptions<MergedBusRoutesQuery, MergedBusRoutesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MergedBusRoutesQuery, MergedBusRoutesQueryVariables>(MergedBusRoutesDocument, options);
      }
export function useMergedBusRoutesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MergedBusRoutesQuery, MergedBusRoutesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MergedBusRoutesQuery, MergedBusRoutesQueryVariables>(MergedBusRoutesDocument, options);
        }
export type MergedBusRoutesQueryHookResult = ReturnType<typeof useMergedBusRoutesQuery>;
export type MergedBusRoutesLazyQueryHookResult = ReturnType<typeof useMergedBusRoutesLazyQuery>;
export type MergedBusRoutesQueryResult = Apollo.QueryResult<MergedBusRoutesQuery, MergedBusRoutesQueryVariables>;
export const DeleteMergedBusStopDocument = gql`
    mutation deleteMergedBusStop($mergedStopId: String!) {
  deleteMergedBusStop(mergedStopId: $mergedStopId)
}
    `;
export type DeleteMergedBusStopMutationFn = Apollo.MutationFunction<DeleteMergedBusStopMutation, DeleteMergedBusStopMutationVariables>;

/**
 * __useDeleteMergedBusStopMutation__
 *
 * To run a mutation, you first call `useDeleteMergedBusStopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMergedBusStopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMergedBusStopMutation, { data, loading, error }] = useDeleteMergedBusStopMutation({
 *   variables: {
 *      mergedStopId: // value for 'mergedStopId'
 *   },
 * });
 */
export function useDeleteMergedBusStopMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMergedBusStopMutation, DeleteMergedBusStopMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMergedBusStopMutation, DeleteMergedBusStopMutationVariables>(DeleteMergedBusStopDocument, options);
      }
export type DeleteMergedBusStopMutationHookResult = ReturnType<typeof useDeleteMergedBusStopMutation>;
export type DeleteMergedBusStopMutationResult = Apollo.MutationResult<DeleteMergedBusStopMutation>;
export type DeleteMergedBusStopMutationOptions = Apollo.BaseMutationOptions<DeleteMergedBusStopMutation, DeleteMergedBusStopMutationVariables>;
export const DeleteMergedBusRoutePathDocument = gql`
    mutation deleteMergedBusRoutePath($mergedRouteId: String!) {
  deleteMergedBusRoutePath(mergedRouteId: $mergedRouteId)
}
    `;
export type DeleteMergedBusRoutePathMutationFn = Apollo.MutationFunction<DeleteMergedBusRoutePathMutation, DeleteMergedBusRoutePathMutationVariables>;

/**
 * __useDeleteMergedBusRoutePathMutation__
 *
 * To run a mutation, you first call `useDeleteMergedBusRoutePathMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMergedBusRoutePathMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMergedBusRoutePathMutation, { data, loading, error }] = useDeleteMergedBusRoutePathMutation({
 *   variables: {
 *      mergedRouteId: // value for 'mergedRouteId'
 *   },
 * });
 */
export function useDeleteMergedBusRoutePathMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMergedBusRoutePathMutation, DeleteMergedBusRoutePathMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMergedBusRoutePathMutation, DeleteMergedBusRoutePathMutationVariables>(DeleteMergedBusRoutePathDocument, options);
      }
export type DeleteMergedBusRoutePathMutationHookResult = ReturnType<typeof useDeleteMergedBusRoutePathMutation>;
export type DeleteMergedBusRoutePathMutationResult = Apollo.MutationResult<DeleteMergedBusRoutePathMutation>;
export type DeleteMergedBusRoutePathMutationOptions = Apollo.BaseMutationOptions<DeleteMergedBusRoutePathMutation, DeleteMergedBusRoutePathMutationVariables>;
export const UpdateMergedBusStopDocument = gql`
    mutation updateMergedBusStop($data: UpdateMergedStopDTO!) {
  updateMergedBusStop(data: $data)
}
    `;
export type UpdateMergedBusStopMutationFn = Apollo.MutationFunction<UpdateMergedBusStopMutation, UpdateMergedBusStopMutationVariables>;

/**
 * __useUpdateMergedBusStopMutation__
 *
 * To run a mutation, you first call `useUpdateMergedBusStopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMergedBusStopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMergedBusStopMutation, { data, loading, error }] = useUpdateMergedBusStopMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMergedBusStopMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMergedBusStopMutation, UpdateMergedBusStopMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMergedBusStopMutation, UpdateMergedBusStopMutationVariables>(UpdateMergedBusStopDocument, options);
      }
export type UpdateMergedBusStopMutationHookResult = ReturnType<typeof useUpdateMergedBusStopMutation>;
export type UpdateMergedBusStopMutationResult = Apollo.MutationResult<UpdateMergedBusStopMutation>;
export type UpdateMergedBusStopMutationOptions = Apollo.BaseMutationOptions<UpdateMergedBusStopMutation, UpdateMergedBusStopMutationVariables>;
export const UpdateMergedBusRoutePathDocument = gql`
    mutation updateMergedBusRoutePath($data: UpdateMergedRouteDTO!) {
  updateMergedBusRoutePath(data: $data)
}
    `;
export type UpdateMergedBusRoutePathMutationFn = Apollo.MutationFunction<UpdateMergedBusRoutePathMutation, UpdateMergedBusRoutePathMutationVariables>;

/**
 * __useUpdateMergedBusRoutePathMutation__
 *
 * To run a mutation, you first call `useUpdateMergedBusRoutePathMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMergedBusRoutePathMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMergedBusRoutePathMutation, { data, loading, error }] = useUpdateMergedBusRoutePathMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMergedBusRoutePathMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMergedBusRoutePathMutation, UpdateMergedBusRoutePathMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMergedBusRoutePathMutation, UpdateMergedBusRoutePathMutationVariables>(UpdateMergedBusRoutePathDocument, options);
      }
export type UpdateMergedBusRoutePathMutationHookResult = ReturnType<typeof useUpdateMergedBusRoutePathMutation>;
export type UpdateMergedBusRoutePathMutationResult = Apollo.MutationResult<UpdateMergedBusRoutePathMutation>;
export type UpdateMergedBusRoutePathMutationOptions = Apollo.BaseMutationOptions<UpdateMergedBusRoutePathMutation, UpdateMergedBusRoutePathMutationVariables>;
export const BusRoutePathDocument = gql`
    query busRoutePath($routePathId: String!) {
  getBusRoutePath(routePathId: $routePathId) {
    ...PathsDetailed
  }
}
    ${PathsDetailedFragmentDoc}`;

/**
 * __useBusRoutePathQuery__
 *
 * To run a query within a React component, call `useBusRoutePathQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusRoutePathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusRoutePathQuery({
 *   variables: {
 *      routePathId: // value for 'routePathId'
 *   },
 * });
 */
export function useBusRoutePathQuery(baseOptions: Apollo.QueryHookOptions<BusRoutePathQuery, BusRoutePathQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusRoutePathQuery, BusRoutePathQueryVariables>(BusRoutePathDocument, options);
      }
export function useBusRoutePathLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusRoutePathQuery, BusRoutePathQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusRoutePathQuery, BusRoutePathQueryVariables>(BusRoutePathDocument, options);
        }
export type BusRoutePathQueryHookResult = ReturnType<typeof useBusRoutePathQuery>;
export type BusRoutePathLazyQueryHookResult = ReturnType<typeof useBusRoutePathLazyQuery>;
export type BusRoutePathQueryResult = Apollo.QueryResult<BusRoutePathQuery, BusRoutePathQueryVariables>;
export const AttendanceListPaginatedDocument = gql`
    query attendanceListPaginated($busRouteId: String!, $pathType: String, $search: String, $limit: Int, $sortBy: String, $offset: Int, $sortDirection: String) {
  attendanceListPaginated(
    busRouteId: $busRouteId
    pathType: $pathType
    search: $search
    limit: $limit
    offset: $offset
    sortBy: $sortBy
    sortDirection: $sortDirection
  ) {
    nodes {
      ...attendanceList
    }
  }
}
    ${AttendanceListFragmentDoc}`;

/**
 * __useAttendanceListPaginatedQuery__
 *
 * To run a query within a React component, call `useAttendanceListPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendanceListPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendanceListPaginatedQuery({
 *   variables: {
 *      busRouteId: // value for 'busRouteId'
 *      pathType: // value for 'pathType'
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      offset: // value for 'offset'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useAttendanceListPaginatedQuery(baseOptions: Apollo.QueryHookOptions<AttendanceListPaginatedQuery, AttendanceListPaginatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AttendanceListPaginatedQuery, AttendanceListPaginatedQueryVariables>(AttendanceListPaginatedDocument, options);
      }
export function useAttendanceListPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AttendanceListPaginatedQuery, AttendanceListPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AttendanceListPaginatedQuery, AttendanceListPaginatedQueryVariables>(AttendanceListPaginatedDocument, options);
        }
export type AttendanceListPaginatedQueryHookResult = ReturnType<typeof useAttendanceListPaginatedQuery>;
export type AttendanceListPaginatedLazyQueryHookResult = ReturnType<typeof useAttendanceListPaginatedLazyQuery>;
export type AttendanceListPaginatedQueryResult = Apollo.QueryResult<AttendanceListPaginatedQuery, AttendanceListPaginatedQueryVariables>;