import React, { useEffect } from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import useDidMountEffect from "../../../shared/hooks/useDidMountEffect";
import {
  GetBusDriversPaginatedDocument,
  BusDriversPaginatedItemFragment,
  useCreateBusDriverMutation,
  useUpdateBusDriverMutation,
} from "../../../apollo/busDrivers/queries.generated";
import { CreateBusDriverDto } from "../../../apollo/types.generated";
import ControlledTextField from "../../../shared/components/ControlledTextField";
import ControlledSelectModalField from "../../../shared/components/ControlledSelectModalField";

import {
  GetSchoolsPaginatedDocument,
  SchoolsPaginatedItemFragment,
} from "../../../apollo/schools/queries.generated";
import apolloClient from "../../../apollo/client";
import {
  CheckEmailAvailabilityQuery,
  CheckEmailAvailabilityDocument,
  CheckEmailAvailabilityQueryVariables,
} from "../../../apollo/users/queries.generated";
import useAuthUser from "../../../shared/hooks/useAuthUser";
import ControlledMultiSelectDialog from "../../../shared/components/MultiSelectDialog.tsx/ControlledMultiSelectDialog";
import useAbility from "../../../shared/hooks/useAbility";

interface BusDriversFormModalProps {
  open: boolean;
  onClose: () => void;
  editingBusDriver?: BusDriversPaginatedItemFragment;
}

type SchoolFormItemType = Pick<SchoolsPaginatedItemFragment, "id" | "name">;
interface FormInputs
  extends Omit<CreateBusDriverDto, "password" | "schoolId" | "schoolIds"> {
  passwordConfirmation?: string;
  password?: string;
  schools: SchoolFormItemType[];
}

const getSchema = (
  editingStudent?: BusDriversPaginatedItemFragment
): Yup.SchemaOf<FormInputs> =>
  Yup.object({
    firstName: Yup.string().required("This field is required"),
    lastName: Yup.string().required("This field is required"),
    // schoolId: Yup.string().required("This field is required"),
    email: Yup.string()
      .required("This field is required")
      .email("Invalid email")
      .test(
        "test-mail",
        "This email address is not available",
        async (value, context) => {
          if (!value) return false;
          const result = await apolloClient.query<
            CheckEmailAvailabilityQuery,
            CheckEmailAvailabilityQueryVariables
          >({
            query: CheckEmailAvailabilityDocument,
            variables: { email: value, userId: editingStudent?.user.id },
          });

          return result.data.checkEmailAvailability;
        }
      ),
    password: editingStudent
      ? Yup.string().optional()
      : Yup.string().required("This field is required"),
    passwordConfirmation: editingStudent
      ? Yup.string()
          .optional()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
      : Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required(""),
    busNumber: Yup.string().required("This field is required"),
    schoolSystemId: Yup.string().optional(),
    schools: Yup.array(
      Yup.object().shape({
        id: Yup.string().required(),
        name: Yup.string().required(),
      })
    )
      .required()
      .min(1, "You must assign at least one school."),
  });

const getDefaultValues = (
  busDriver?: BusDriversPaginatedItemFragment
): FormInputs | undefined => {
  return {
    firstName: busDriver?.user.firstName || "",
    lastName: busDriver?.user.lastName || "",
    email: busDriver?.user.email || "",
    password: "",
    passwordConfirmation: "",
    //TODO: Fix this implementation hack
    schools:
      busDriver?.assignedSchools.map(
        (s) =>
          ({
            ...s,
            schoolSystem: busDriver?.schoolSystem,
          } as SchoolFormItemType)
      ) || [],
    busNumber: busDriver?.busNumber || "",
  };
};
const BusDriversFormModal: React.FC<BusDriversFormModalProps> = ({
  open,
  onClose,
  editingBusDriver,
}) => {
  const authUser = useAuthUser();
  const ability = useAbility();
  const { control, handleSubmit, reset, setValue } = useForm<FormInputs>({
    resolver: yupResolver(getSchema(editingBusDriver)),
    shouldFocusError: true,
    defaultValues: getDefaultValues(editingBusDriver),
  });

  const [
    create,
    { data: createData, loading: createLoading, error: createError },
  ] = useCreateBusDriverMutation({
    refetchQueries: [GetBusDriversPaginatedDocument],
  });

  const [
    update,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useUpdateBusDriverMutation({
    refetchQueries: [GetBusDriversPaginatedDocument],
  });

  const onSubmit = (data: FormInputs) => {
    const { passwordConfirmation, schools, ...rest } = data;
    if (editingBusDriver) {
      update({
        variables: {
          id: editingBusDriver.id,
          data: {
            ...rest,
            schoolIds: schools.map((s) => s.id),
          },
        },
        refetchQueries: [GetBusDriversPaginatedDocument],
      });
      return;
    }

    if (rest.password) {
      create({
        variables: {
          data: {
            ...rest,
            schoolIds: schools.map((s) => s.id),
            password: rest.password,
          },
        },
        refetchQueries: [GetBusDriversPaginatedDocument],
      });
    }
  };

  useEffect(() => {
    if (createData) {
      toast.success("Bus Driver created successfully");
      onClose();
    }

    if (createError) {
      toast.error("Error creating Bus Driver: " + createError.message);
    }
  }, [createData, createError]);

  useEffect(() => {
    if (updateData) {
      toast.success("Bus Driver updated successfully");
      onClose();
    }

    if (updateError) {
      toast.error("Error updating Bus Driver: " + updateError.message);
    }
  }, [updateData, updateError]);

  useDidMountEffect(() => {
    reset(getDefaultValues(editingBusDriver));
  }, [editingBusDriver]);

  useEffect(() => {
    if (authUser && authUser.schoolId && ability.cannot("manage", "Schools")) {
      setValue("schools", [
        { id: authUser.schoolId, name: "BASE_SCHOOL_NAME" },
      ]);
    }
  }, [ability, authUser]);

  return (
    <Dialog
      open={open}
      onClose={!createLoading ? onClose : undefined}
      maxWidth="md"
      fullWidth
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <DialogTitle>
          {editingBusDriver ? "Update" : "Create"} Bus Driver
        </DialogTitle>
        <DialogContent>
          <Grid container sx={{ marginTop: "10px" }} spacing={2}>
            <Grid item xs={12}>
              <ControlledTextField
                name="firstName"
                control={control}
                label="First Name"
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                name="lastName"
                control={control}
                label="Last Name"
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                name="email"
                control={control}
                label="Email"
              />
            </Grid>

            <Grid item xs={12}>
              {!!editingBusDriver && (
                <Typography variant="caption" color="GrayText">
                  You can skip this field to omit changing the driver's password
                </Typography>
              )}
              <ControlledTextField
                name="password"
                control={control}
                type="password"
                label="Password"
              />
            </Grid>

            <Grid item xs={12}>
              <ControlledTextField
                type="password"
                name="passwordConfirmation"
                control={control}
                label="Password Confirmation"
              />
            </Grid>
            {ability.can("manage", "Schools") && (
              <Grid item xs={12}>
                <ControlledMultiSelectDialog
                  name="schools"
                  control={control}
                  dialogTitle="Assigned Schools"
                  textfieldProps={{
                    label: "Assigned Schools",
                    fullWidth: true,
                  }}
                  query={GetSchoolsPaginatedDocument}
                  labelsExtractor={(item: SchoolsPaginatedItemFragment) => ({
                    primary: item.name,
                    secondary: item.schoolSystem?.name,
                  })}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <ControlledTextField
                name="busNumber"
                control={control}
                label="Bus Number"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel </Button>
          <LoadingButton loading={createLoading || updateLoading} type="submit">
            Submit
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default BusDriversFormModal;
