import { Grid, Card, Typography, Button } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useHistory } from "react-router";
import {
  GetBusRoutesPaginatedDocument,
  useGetBusRoutesPaginatedQuery,
} from "../../apollo/busRoutes/queries.generated";
import { BusRouteDto } from "../../apollo/types.generated";
import Link from "../../navigation/Link";
import PageContainer from "../../shared/components/PageContainer";
import TableCard from "../../shared/components/TableCard";
import useAbility from "../../shared/hooks/useAbility";
import BusRoutesTable from "./components/BusRoutesTable";
import PDFModal from "./components/PDFModal";

interface BusRoutesPageProps {}

const BusRoutesPage: React.FC<BusRoutesPageProps> = ({}) => {
  const history = useHistory();
  const ability = useAbility();
  const [selectedItems, setSelectedItems] = useState<BusRouteDto[]>([]);
  const [showPdfModal, setShowPdfModal] = useState(false);

  // const updateBusRoutes = useGetBusRoutesPaginatedQuery();

  const handlePDF = (itemsArr: BusRouteDto[]) => {
    setSelectedItems(itemsArr);
    setShowPdfModal(true);
  };

  const handleOnDialogClose = () => {
    setShowPdfModal(false);
  };

  return (
    <PageContainer>
      {showPdfModal && (
        <PDFModal
          handleClose={handleOnDialogClose}
          selectedItems={selectedItems}
          sourceRequest={"busRoute"}
        ></PDFModal>
      )}
      {/* <BusDriversFormModal
    editingBusDriver={selectedBusDriver}
    open={showFormModal}
    onClose={handleFormClose}
  /> */}
      {/* <DeleteModal
    open={showDeleteModal}
    mutation={DeleteBusDriverDocument}
    updateQueries={[GetBusDriversPaginatedDocument]}
    variables={{ id: selectedBusDriver?.id }}
    onClose={handleDeleteModalClose}
    title="Delete Bus Driver"
  /> */}
      <TableCard>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-start"
          sx={{ marginBottom: "20px" }}
        >
          <Grid item xs={12} sm={12} md={3} lg={2}>
            <Typography
              variant="h5"
              textAlign="start"
              fontWeight="bold"
              color="secondary"
            >
              Bus Routes
            </Typography>
          </Grid>
          {ability.can("manage", "Schools") && (
            <Grid item xs={12} sm={12} md={3} container>
              <Link to="/dashboard/busRoutes/live">
                <Button variant="outlined" fullWidth>
                  Live Routes
                </Button>
              </Link>
            </Grid>
          )}
        </Grid>

        <BusRoutesTable
          onAddClick={() => history.push("/dashboard/busRoutes/create")}
          handlePDF={handlePDF}
          pdf={true}
          // onEditPress={(item) => {
          //   setSelectedBusDriver(item);
          //   setShowFormModal(true);
          // }}
          // onDeletePress={(item) => {
          //   setSelectedBusDriver(item);
          //   setShowDeleteModal(true);
          // }}
        />
        {/* <DataGrid
    style={{
      border: "none",
    }}
    columns={columns}
    rows={data?.StudentsystemsPaginated.nodes || []}
  /> */}
      </TableCard>
    </PageContainer>
  );
};

export default BusRoutesPage;
