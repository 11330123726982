import React, { useState } from "react";
import { Autocomplete, Grid, MenuItem, TextField } from "@mui/material";

import ControlledTextField from "./ControlledTextField";
import { CreatePaymentMethodAddressDto } from "../../apollo/types.generated";
import { Control } from "react-hook-form";
import { TypeOf } from "yup";

interface FormInputs extends CreatePaymentMethodAddressDto {}

interface AddressFormSectionProps<T> {
  control: Control<T>;
}

export const getAddressDefaultValues = (
  address?: CreatePaymentMethodAddressDto
): FormInputs => {
  return {
    city: "",
    country: "",
    postalCode: "",
    state: "",
    streetAddress1: "",
  };
};

interface TFormWithAddress {
  // [key: string]: any;
  address: {
    city: string;
    country: string;
    postalCode: string;
    state: string;
    streetAddress1: string;
  };
}

const AddressFormSection = <FormInputType extends TFormWithAddress>({
  control,
}: AddressFormSectionProps<FormInputType>) => {
  const addressControl = control as unknown as Control<TFormWithAddress>;

  const country = [
    {
      code: "USA",
      name: "United States",
    },
  ];

  const states = [
    { value: "AK", name: "Alaska" },
    { value: "AL", name: "Alabama" },
    { value: "AR", name: "Arkansas" },
    { value: "AZ", name: "Arizona" },
    { value: "CA", name: "California" },
    { value: "CO", name: "Colorado" },
    { value: "CT", name: "Connecticut" },
    { value: "DC", name: "District of Columbia" },
    { value: "DE", name: "Delaware" },
    { value: "FL", name: "Florida" },
    { value: "GA", name: "Georgia" },
    { value: "HI", name: "Hawaii" },
    { value: "IA", name: "Iowa" },
    { value: "ID", name: "Idaho" },
    { value: "IL", name: "Illinois" },
    { value: "IN", name: "Indiana" },
    { value: "KS", name: "Kansas" },
    { value: "KY", name: "Kentucky" },
    { value: "LA", name: "Louisiana" },
    { value: "MA", name: "Massachusetts" },
    { value: "MD", name: "Maryland" },
    { value: "ME", name: "Maine" },
    { value: "MI", name: "Michigan" },
    { value: "MN", name: "Minnesota" },
    { value: "MO", name: "Missouri" },
    { value: "MS", name: "Mississippi" },
    { value: "MT", name: "Montana" },
    { value: "NC", name: "North Carolina" },
    { value: "ND", name: "North Dakota" },
    { value: "NE", name: "Nebraska" },
    { value: "NH", name: "New Hampshire" },
    { value: "NJ", name: "New Jersey" },
    { value: "NM", name: "New Mexico" },
    { value: "NV", name: "Nevada" },
    { value: "NY", name: "New York" },
    { value: "OH", name: "Ohio" },
    { value: "OK", name: "Oklahoma" },
    { value: "OR", name: "Oregon" },
    { value: "PA", name: "Pennsylvania" },
    { value: "RI", name: "Rhode Island" },
    { value: "SC", name: "South Carolina" },
    { value: "SD", name: "South Dakota" },
    { value: "TN", name: "Tennessee" },
    { value: "TX", name: "Texas" },
    { value: "UT", name: "Utah" },
    { value: "VA", name: "Virginia" },
    { value: "VT", name: "Vermont" },
    { value: "WA", name: "Washington" },
    { value: "WI", name: "Wisconsin" },
    { value: "WV", name: "West Virginia" },
    { value: "WY", name: "Wyoming" },
  ];

  const getCountryDefaultOption = () =>
    country.find((c) => c.code === "")?.code ?? null;
  const getStateDefaultOption = () =>
    states.find((s) => s.value === "") ?? null;

  const [stateValue, setStateValue] = React.useState(getStateDefaultOption);
  const [countryValue, setCountryValue] = React.useState(
    getCountryDefaultOption
  );

  return (
    <Grid container item spacing={2}>
      <Grid item xs={12}>
        <ControlledTextField
          name="address.city"
          control={addressControl}
          label="City"
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          select
          name="address.country"
          control={addressControl}
          label="Country"
          // value={""}
        >
          {country.map((option) => (
            <MenuItem key={option.code} value={option.code}>
              {option.name}
            </MenuItem>
          ))}
        </ControlledTextField>
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          type="number"
          name="address.postalCode"
          control={addressControl}
          label="Postal Code"
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          select
          name="address.state"
          control={addressControl}
          label="State"
          // value={"TX"}
        >
          {states.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </ControlledTextField>
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          name="address.streetAddress1"
          control={addressControl}
          label="Street Address"
        />
      </Grid>
    </Grid>
  );
};

export default AddressFormSection;
