import React from "react";
import { Link as MLink } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
interface Props {
  to: string;
  target?: string;
}
const Link: React.FC<Props> = ({ children, to, target }) => {
  return (
    <MLink
      component={RouterLink}
      to={to}
      target={target}
      sx={{ textDecoration: "none" }}
    >
      {children}
    </MLink>
  );
};

export default Link;
