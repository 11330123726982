import {
  Autocomplete,
  Button,
  Container,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import AdapterDayJs from "@mui/lab/AdapterDayjs";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { BusRouteFormInputs, BusStop } from "../components/interfaces";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import toast from "react-hot-toast";

import { useForm } from "react-hook-form";
import { Box } from "@mui/system";

import ControlledSelectModalField from "../../../shared/components/ControlledSelectModalField";
import {
  GetSchoolsPaginatedDocument,
  SchoolsPaginatedItemFragment,
  useGetSchoolQuery,
  useGetSchoolsPaginatedQuery,
} from "../../../apollo/schools/queries.generated";
import {
  BusDriversPaginatedItemFragment,
  DriverRoutePeriodFragment,
  GetBusDriversPaginatedDocument,
  GetRouteDriversDocument,
} from "../../../apollo/busDrivers/queries.generated";

import RouteCreator from "./RouteCreator";
import ControlledTextField from "../../../shared/components/ControlledTextField";
import {
  BusRouteDetailedFragment,
  GetBusRouteDocument,
  GetBusRoutesPaginatedDocument,
  useCreateAditionalSchoolsMutation,
  useCreateBusRouteMutation,
  useGetBusRoutesPaginatedQuery,
  useUpdateAditionalSchoolsMutation,
  useUpdateBusRouteMutation,
} from "../../../apollo/busRoutes/queries.generated";
import { useHistory } from "react-router";

import useDidMountEffect from "../../../shared/hooks/useDidMountEffect";
import {
  CreateBusPathDto,
  CreateBusStopDto,
} from "../../../apollo/types.generated";
import LoadingBackdrop from "../../../shared/components/LoadingBackdrop";
import useAuthUser from "../../../shared/hooks/useAuthUser";
import { AutoFixNormal, AutoFixOff } from "@mui/icons-material";
import BusRoutePathForm, {
  getBusRoutePathInitialValues,
} from "./BusRoutePathForm";
import useAbility from "../../../shared/hooks/useAbility";
import { AuthUserFragment } from "../../../apollo/auth/queries.generated";
import FormErrorDialog from "../../../shared/components/FormErrorDialog";
import ControlledDatePicker from "../../../shared/components/ControlledDatePicker";
import ControlledSearchSelect from "../../../shared/components/ControlledSearchSelect";
import { useLazyQuery } from "@apollo/client";

const getInitialValues = (
  busRoute?: BusRouteDetailedFragment,
  authUser?: AuthUserFragment | null,
  driverPeriod?: DriverRoutePeriodFragment
): BusRouteFormInputs => {
  return {
    routeId: busRoute?.routeId || "",
    busDriverId: busRoute?.busDriver.id || "",
    schoolId: busRoute?.school.id || authUser?.schoolId || "",
    name: busRoute?.name || "",
    paths: getBusRoutePathInitialValues(busRoute?.paths),
    additionalSchools: busRoute?.aditionalSchools || [],
    // startTime: driverPeriod?.routePeriodStart || "",
    // endTime: driverPeriod?.routePeriodEnd || "",
  };
};
const schema = z
  .object({
    routeId: z
      .string({ required_error: "This field is required" })
      .nonempty({ message: "This field is required" }),
    busDriverId: z
      .string({ required_error: "This field is required" })
      .nonempty({ message: "This field is required" }),
    // startTime: z.any({ required_error: "This field is required" }),
    // endTime: z.any({ required_error: "This field is required" }),
    paths: z.array(
      z.object({
        id: z.string().optional(),
        type: z
          .string({ required_error: "This field is required" })
          .nonempty({ message: "This field is required" }),
        startTime: z
          .any({
            required_error: "This field is required",
            invalid_type_error: "This field is required",
          })
          .refine((value) => !!value, { message: "This field is required" }),
        endTime: z
          .any({
            required_error: "This field is required",
            invalid_type_error: "This field is required",
          })
          .refine((value) => !!value, { message: "This field is required" }),

        students: z
          .array(z.any(), {
            required_error: "You must assign students to this route",
          })
          .min(1, "You must assign students to this route")
          .optional(),
        busStops: z
          .array(
            z.object({
              address: z.string(),
              id: z.string(),
              lat: z.number(),
              lng: z.number(),
            }),
            {
              required_error: "You must add at least 2 stops",
            }
          )
          .min(2, "You must add at least 2 stops"),
      })
    ),
    name: z
      .string({ required_error: "This field is required" })
      .nonempty({ message: "This field is required" }),
    schoolId: z
      .string({ required_error: "This field is required" })
      .nonempty({ message: "This field is required" }),
  })
  .required();

interface BusRouteFormProps {
  editingRoute?: BusRouteDetailedFragment;
  driverPeriod?: DriverRoutePeriodFragment;
}
const BusRouteForm: React.FC<BusRouteFormProps> = ({
  editingRoute,
  driverPeriod,
}) => {
  const isEdit = !!editingRoute;
  const authUser = useAuthUser();
  const [autoGenerateId, setautoGenerateId] = useState(!editingRoute);
  const form = useForm<BusRouteFormInputs>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: getInitialValues(editingRoute, authUser, driverPeriod),
    resolver: zodResolver(schema),
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,

    formState: { errors },
    watch,
  } = form;
  const schoolId: string = watch("schoolId");
  const additionalSchools = watch("additionalSchools");
  const paths = watch("paths");
  const routeName = watch("name");
  const ability = useAbility();
  const history = useHistory();

  const [
    create,
    { data: createData, loading: createLoading, error: createError },
  ] = useCreateBusRouteMutation({
    refetchQueries: [GetBusRoutesPaginatedDocument],
  });

  const [createAditionalSchools, { loading: createAditionalSchoolsLoading }] =
    useCreateAditionalSchoolsMutation({
      refetchQueries: [GetBusRoutesPaginatedDocument],
    });

  const [updateAdditionalSchools, { loading: updateAdditionalSchoolsLoading }] =
    useUpdateAditionalSchoolsMutation();

  const updateBusRoutes = useGetBusRoutesPaginatedQuery();

  const mainSchoolData = useGetSchoolQuery({
    variables: {
      id: schoolId,
    },
  });

  const getAdditionalSchools = useGetSchoolsPaginatedQuery({
    variables: { schoolSystemId: mainSchoolData.data?.school?.schoolSystemId },
  });

  const [additionalSchoolsList, setAdditionalSchoolsList] = useState<
    SchoolsPaginatedItemFragment[]
  >([]);

  const [refetchBusRoute] = useLazyQuery(GetBusRouteDocument);

  useEffect(() => {
    mainSchoolData.refetch({
      id: schoolId,
    });
    getAdditionalSchools.refetch({
      schoolSystemId: mainSchoolData.data?.school?.schoolSystemId,
    });
  }, [schoolId]);

  const [
    update,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useUpdateBusRouteMutation({
    refetchQueries: [
      { query: GetBusRouteDocument, variables: { id: editingRoute?.id || "" } },
      {
        query: GetRouteDriversDocument,
        variables: { routeId: editingRoute?.id || "" },
      },
    ],
  });

  const onSubmit = (data: BusRouteFormInputs) => {
    // return;

    const paths: CreateBusPathDto[] = data.paths.map(
      ({ students, ...path }) => ({
        ...path,
        studentIds: students.map((s) => s.id),
        busStops: path.busStops.map<CreateBusStopDto>((stop, i) => ({
          id: stop.id.includes("FAKE") ? undefined : stop.id,
          address: stop.address,
          latitude: stop.lat,
          longitude: stop.lng,
          order: i + 1,
        })),
      })
    );

    if (isEdit) {
      return update({
        variables: {
          id: editingRoute.id,
          data: {
            ...data,
            paths,
          },
        },
      });
    }
    create({
      variables: {
        data: {
          ...data,
          paths,
        },
      },
    });
  };

  useEffect(() => {
    if (createData) {
      toast.success("Bus Route created successfully");

      const additionalSchoolsIds = additionalSchools.map((school) => school.id);

      createAditionalSchools({
        variables: {
          data: {
            busRouteId: createData.createBusRoute.id,
            schoolIds: additionalSchoolsIds.filter(
              (id) => id !== createData.createBusRoute.school.id
            ),
          },
        },
      });

      history.push(
        "/dashboard/busRoutes/" + createData.createBusRoute.id + "/assign"
      );
    }

    if (createError) {
      toast.error("Error creating Bus Route: " + createError.message);
    }
  }, [createData, createError]);

  useEffect(() => {
    if (updateData) {
      toast.success("Bus Route updated successfully");

      const additionalSchoolsIds = additionalSchools.map((school) => school.id);

      updateAdditionalSchools({
        variables: {
          data: {
            busRouteId: updateData.updateBusRoute.id,
            schoolIds: additionalSchoolsIds.filter(
              (id) => id !== updateData.updateBusRoute.school.id
            ),
          },
        },
      });

      updateBusRoutes.refetch();
      refetchBusRoute({ variables: { id: updateData.updateBusRoute.id } });

      history.push(
        "/dashboard/busRoutes/" + updateData.updateBusRoute.id + "/assign"
      );
    }

    if (updateError) {
      toast.error("Error updating Bus Route: " + updateError.message);
    }
  }, [updateData, updateError]);

  useEffect(() => {
    if (authUser && authUser.schoolId) {
      setValue("schoolId", authUser.schoolId);
    }
  }, [authUser]);

  const generateIdFromName = (name: string) => {
    if (!name) return "";
    const id = name
      .split(" ")
      .filter((word) => /\S/.test(word))
      .map((word) => {
        if (word.length > 1) {
          return word.slice(0, 2);
        }
        return word;
      })
      .join("-");

    return id;
  };
  useDidMountEffect(() => {
    if (autoGenerateId && routeName) {
      setValue("routeId", generateIdFromName(routeName));
    }
  }, [routeName, autoGenerateId]);

  const [multiSchools, setMultiSchools] = useState("No");

  useDidMountEffect(() => {
    if (additionalSchools.length > 0) {
      setMultiSchools("Yes");
    }
  }, [additionalSchools]);

  const handleMoreSchoolsOption = (event: SelectChangeEvent) => {
    setMultiSchools(event.target.value);
    if (event.target.value === "No") {
      setValue("additionalSchools", []);
    }
  };

  useEffect(() => {
    setAdditionalSchoolsList(
      getAdditionalSchools.data?.schoolsPaginated.nodes?.filter(
        (school) => school.id !== mainSchoolData.data?.school?.id
      ) || []
    );
  }, [getAdditionalSchools]);

  const handleSelectSchools = useCallback(
    (schools: any[]) => {
      if (additionalSchools !== schools) {
        // setStudentFieldValue(students);
        setValue("additionalSchools", schools);
      }
    },
    [additionalSchools]
  );

  useDidMountEffect(() => {
    const auxList = additionalSchools;
    const schools = additionalSchoolsList.filter((s) =>
      auxList.some((a: SchoolsPaginatedItemFragment) => a.id === s.id)
    );
    setValue("additionalSchools", schools);
    // setStudentFieldValue(students);
  }, [schoolId]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayJs}>
      <Container>
        <FormErrorDialog errors={errors} />
        <LoadingBackdrop open={createLoading || updateLoading} />
        <Box marginBottom="20px">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4" color="primary" textAlign="start">
                  {isEdit ? "Update" : "Create"} a bus route
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <ControlledTextField
                  name="name"
                  control={control}
                  fullWidth
                  label="Route Name"
                />
              </Grid>
              <Grid item xs={12}>
                <ControlledTextField
                  name="routeId"
                  control={control}
                  fullWidth
                  label="Route ID"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Auto-Generate">
                          <IconButton
                            onClick={() => setautoGenerateId((c) => !c)}
                          >
                            {autoGenerateId ? (
                              <AutoFixNormal />
                            ) : (
                              <AutoFixOff />
                            )}
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {ability.can("manage", "Schools") && (
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <ControlledSelectModalField
                      name="schoolId"
                      control={control}
                      variables={
                        authUser?.schoolSystemId
                          ? { schoolSystemId: authUser.schoolSystemId }
                          : undefined
                      }
                      initialValue={
                        editingRoute?.school as SchoolsPaginatedItemFragment
                      }
                      textFieldProps={{
                        label: "Main School",
                        fullWidth: true,
                      }}
                      query={GetSchoolsPaginatedDocument}
                      labelsExractor={(item: SchoolsPaginatedItemFragment) => ({
                        primary: item.name,
                        secondary: item.schoolSystem?.name,
                      })}
                    />
                  </Grid>
                  {schoolId && (
                    <Grid item xs={12} textAlign="start" marginTop={"15px"}>
                      <FormHelperText>
                        This route has more than one school?
                      </FormHelperText>
                      <Select
                        value={multiSchools}
                        onChange={handleMoreSchoolsOption}
                      >
                        <MenuItem value="No"> No </MenuItem>
                        <MenuItem value="Yes"> Yes </MenuItem>
                      </Select>
                    </Grid>
                  )}

                  {multiSchools === "Yes" && (
                    <Grid item xs={12} marginTop={"15px"}>
                      {/* <ControlledSearchSelect
                        name="additionalSchools"
                        control={control}
                        initialValue={editingRoute?.aditionalSchools}
                        disabled={!schoolId}
                        variables={schoolId ? { schoolId } : undefined}
                        multiple
                        valueMapper={(items) => {
                          return items || [];
                        }}
                        optionLabelExtractor={(
                          item: SchoolsPaginatedItemFragment
                        ) => item.name}
                        TextFieldProps={{
                          label: "Additional Schools",
                          disabled: !schoolId,
                        }}
                        query={GetSchoolsPaginatedDocument}
                      /> */}
                      <Autocomplete
                        disabled={!schoolId}
                        multiple
                        id="additionalSchools"
                        options={additionalSchoolsList}
                        getOptionLabel={(option) => option.name}
                        value={additionalSchools}
                        onChange={(event, value) => {
                          handleSelectSchools(value);
                        }}
                        filterSelectedOptions
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Additional Schools"
                            disabled={!schoolId}
                          />
                        )}
                      />
                    </Grid>
                  )}
                </Grid>
              )}

              {schoolId && (
                <Grid item xs={12}>
                  <ControlledSelectModalField
                    name="busDriverId"
                    control={control}
                    initialValue={
                      editingRoute?.busDriver as BusDriversPaginatedItemFragment
                    }
                    textFieldProps={{
                      label: "Bus Driver",
                      fullWidth: true,
                    }}
                    variables={schoolId ? { schoolId } : undefined}
                    query={GetBusDriversPaginatedDocument}
                    labelsExractor={(
                      item: BusDriversPaginatedItemFragment
                    ) => ({
                      primary: `${item.user.firstName} ${item.user.lastName}`,
                      //   secondary: item.school?.name,
                    })}
                  />
                </Grid>
              )}
              {/* <Grid item xs={12} sm={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayJs}>
                  <ControlledDatePicker
                    name={`startTime`}
                    control={control}
                    label="Driver Initial Date"
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayJs}>
                  <ControlledDatePicker
                    name={`endTime`}
                    control={control}
                    label="Driver Final Date"
                  />
                </LocalizationProvider>
              </Grid> */}

              <Grid item xs={12} sx={{ marginY: "10px" }}>
                <Typography variant="h4" textAlign="start" gutterBottom>
                  Schedules
                </Typography>
                <Divider />
              </Grid>

              {paths.map((path, i) => (
                <Grid item xs={12} key={"path" + i}>
                  <BusRoutePathForm
                    index={i}
                    form={form}
                    editingPath={editingRoute?.paths[i]}
                  />
                </Grid>
              ))}

              <Grid item xs={12} container justifyContent="flex-end">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </LocalizationProvider>
  );
};
// BusRouteForm.whyDidYouRender = true;
export default BusRouteForm;
