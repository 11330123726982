import { CheckRounded } from "@mui/icons-material";
import {
  List,
  Pagination,
  Typography,
  ListItem as MuiListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import React, { useCallback } from "react";
import { LabelsExtractorFunction, MultiSelectItem } from "./interfaces";

interface ItemListProps<Item> {
  itemsTotal: number;
  items?: Item[];
  currentPage: number;
  limit: number;
  labelsExtractor: LabelsExtractorFunction<Item>;
  selectedItems: Item[];
  onPageChange: (page: number) => void;
  onItemClick: (item: Item) => void;
}

const ItemsList = <Item extends MultiSelectItem>({
  items,
  currentPage,
  limit,
  onPageChange,
  selectedItems,
  onItemClick,
  itemsTotal,
  labelsExtractor,
}: ItemListProps<Item>) => {
  const renderItem = (item: Item) => {
    return (
      <ListItem
        key={item.id}
        item={item}
        labelsExtractor={labelsExtractor}
        onItemClick={onItemClick}
        selected={!!selectedItems.find((i) => i.id === item.id)}
      />
    );
  };

  if (items && items.length) {
    return (
      <>
        <List>{items.map(renderItem)}</List>
        <Pagination
          count={Math.round(itemsTotal / limit)}
          page={currentPage}
          onChange={(e, v) => onPageChange(v)}
          hidePrevButton
          hideNextButton
          variant="outlined"
        />
      </>
    );
  }

  return (
    <>
      <Typography>No items have been found</Typography>
      <Pagination
        count={Math.ceil(itemsTotal / limit)}
        page={currentPage}
        onChange={(e, v) => onPageChange(v)}
        hidePrevButton
        hideNextButton
        variant="outlined"
        color="secondary"
      />
    </>
  );
};

interface ListItemProps<Item> {
  item: Item;
  labelsExtractor: LabelsExtractorFunction<Item>;
  onItemClick: (item: Item) => void;
  selected: boolean;
}

const ListItem = <Item,>({
  item,
  labelsExtractor,
  onItemClick: onItemClickProp,
  selected,
}: ListItemProps<Item>) => {
  const { primary, secondary } = labelsExtractor(item);
  const onItemClick = useCallback(
    () => onItemClickProp(item),
    [onItemClickProp, item]
  );

  return (
    <MuiListItem button onClick={onItemClick} style={{ borderRadius: 20 }}>
      <ListItemAvatar>
        <Avatar>{primary.charAt(0)}</Avatar>
      </ListItemAvatar>
      <ListItemText primary={primary} secondary={secondary} />
      {selected && (
        <ListItemIcon>
          <CheckRounded color="secondary" />
        </ListItemIcon>
      )}
    </MuiListItem>
  );
};

export default ItemsList;
