import { MobileTimePicker, MobileTimePickerProps } from "@mui/lab";
import PickersToolbar from "@mui/lab/internal/pickers/PickersToolbar";
import { TextField } from "@mui/material";
import React from "react";
import { Controller, Control, Path } from "react-hook-form";

interface ControlledTimePickerProps<TFieldValues, Context extends object> {
  name: Path<TFieldValues>;
  control: Control<TFieldValues, Context>;
}

const ControlledTimePicker = <TFieldValues, Context extends object>({
  control,
  name,
  ...props
}: ControlledTimePickerProps<TFieldValues, Context> &
  Omit<
    MobileTimePickerProps,
    "name" | "omit" | "onChange" | "value" | "renderInput"
  >) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <MobileTimePicker
          {...props}
          {...field}
          // ToolbarComponent={(props) => (
          //   <PickersToolbar
          //     {...props}
          //     isLandscape={false}
          //     isMobileKeyboardViewOpen={false}
          //     toggleMobileKeyboardView={() => {}}
          //     toolbarTitle="Title"
          //   />
          // )}
          InputProps={{ ...props.InputProps, fullWidth: true }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!error}
              helperText={error?.message}
              fullWidth
            />
          )}
        />
      )}
    />
  );
};

export default ControlledTimePicker;
