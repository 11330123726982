import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
  Button,
  Slide,
  DialogContentText,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  List,
  ListItem,
  ListItemText,
  Divider,
  TextField,
  Box,
  Autocomplete,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import {
  DateRange,
  DateRangePicker,
  LoadingButton,
  LocalizationProvider,
} from "@mui/lab";
import { FaSave } from "react-icons/fa";
import { TransitionProps } from "@mui/material/transitions";
import {
  BusRoutesMergeableDocument,
  GetBusRouteDocument,
  MergedBusStopsDocument,
  MergedBusStopsQuery,
  useCreateMergePathStopMutation,
} from "../../../apollo/busRoutes/queries.generated";
import dayjs, { Dayjs } from "dayjs";
import AdapterDayjs from "@mui/lab/AdapterDayjs";
import {
  BusRouteDto,
  BusRoutePathType,
  BusStopDto,
  StudentDto,
} from "../../../apollo/types.generated";

interface MergeStopCreatorModalProps {
  open: boolean;
  onClose: () => void;
  routeId: string;
  schoolSystemId: string;
  mergeableRoutes: BusRouteDto[];
  mergedBusStops: MergedBusStopsQuery["getMergedBusStops"];
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MergeStopCreatorModal: React.FC<MergeStopCreatorModalProps> = ({
  onClose,
  open,
  routeId,
  schoolSystemId,
  mergeableRoutes,
  mergedBusStops,
}) => {
  const [createPathStopMerge, { loading: createPathStopMergeLoading }] =
    useCreateMergePathStopMutation();
  const [checkedStops, setCheckedStops] = React.useState<any>([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const pathList = mergeableRoutes.map((route) => {
    return route.busRoutePathsForMerging?.map((path) => {
      return {
        routeId: route.id,
        pathId: path[0],
        pathType: path[1] as BusRoutePathType,
        routeName: route.name + " - " + path[1],
      };
    });
  });
  const pathNameList = pathList.flat().map((path) => path!.routeName);
  const [pathValue, setPathValue] = React.useState<string | null>(null);
  const [pathInputValue, setPathInputValue] = React.useState("");
  const [pathStops, setPathStops] = React.useState<BusStopDto[]>();

  const getStopPathType = (stopId: string) => {
    const pathType = mergeableRoutes.map((route) => {
      return route.paths?.map((path) => {
        // eslint-disable-next-line array-callback-return
        return path.stops?.map((stop) => {
          if (stop.id === stopId) {
            return path.type;
          }
        });
      });
    });
    return pathType
      .flat()
      .flat()
      .filter((x) => x !== undefined);
  };

  const handleCheckedStops = (
    event: React.MouseEvent<HTMLElement>,
    value: string[]
  ) => {
    const dateRange: DateRange<Dayjs> = [
      dayjs(new Date()).startOf("day"),
      dayjs(new Date()).add(1, "day").startOf("day"),
    ];

    if (event.currentTarget.attributes[4].value === "false") {
      const pathType = getStopPathType(event.currentTarget.attributes[3].value);
      event.currentTarget.attributes[6].value = "border-color: orange;";
      setCheckedStops([
        ...checkedStops,
        {
          stopId: event.currentTarget.attributes[3].value,
          dates: dateRange,
          pathType: pathType[0],
        },
      ]);
    } else {
      setCheckedStops(
        checkedStops.filter(
          (item: any) => item.stopId !== event.currentTarget.attributes[3].value
        )
      );
      event.currentTarget.attributes[6].value = "border-color: gray;";
    }
  };

  useEffect(() => {}, [checkedStops]);

  const handleClickOpenConfirmDialog = useCallback(() => {
    setOpenConfirmDialog(true);
  }, []);

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleMergeStops = async () => {
    checkedStops.forEach(async (item: any) => {
      await createPathStopMerge({
        variables: {
          data: {
            mainBusRouteId: routeId,
            mergeableBusStopId: item.stopId,
            startTime: item.dates[0].toDate(),
            endTime: item.dates[1].toDate(),
            pathType: item.pathType as BusRoutePathType,
          },
        },
        refetchQueries: [
          {
            query: GetBusRouteDocument,
            variables: {
              id: routeId,
            },
          },
          {
            query: BusRoutesMergeableDocument,
            variables: {
              schoolSystemId: schoolSystemId,
              busRouteId: routeId,
            },
          },
          {
            query: MergedBusStopsDocument,
            variables: {
              busRouteId: routeId,
            },
          },
        ],
      });
    });

    setOpenConfirmDialog(false);
    setTimeout(() => {
      onClose();
    }, 1000);
  };

  useEffect(() => {
    if (!open) {
      setCheckedStops([]);
    }
  }, [open]);

  const handleRouteInputChange = (option: string) => {
    setPathInputValue(option);
    setCheckedStops([]);
    const pathSelected = pathList.flat().find((path) => {
      return path!.routeName === option;
    });

    if (pathSelected) {
      const selectedRoute = mergeableRoutes.find((route) => {
        return route.id === pathSelected!.routeId;
      });

      selectedRoute?.paths.forEach((path) => {
        if (path.id === pathSelected!.pathId) {
          const listOfMergedStopsIds = mergedBusStops.map(
            (stop) => stop.mergeableBusStop.id
          );

          const stops = path.stops.filter((stop) => {
            return !listOfMergedStopsIds.includes(stop.id);
          });

          setPathStops(stops);
        }
      });
    }
  };

  const handleStopStudents = (students: StudentDto[]) => {
    const studentList = students.map((student) => {
      return {
        id: student.id,
        name: student.user.name,
      };
    });

    const studentNames = studentList.map((student) => student.name);

    return studentNames.join(", ");
  };

  return (
    <Box>
      <Grid container sx={{ marginTop: "5px", textAlign: "left" }} spacing={2}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <Autocomplete
                value={pathValue}
                onChange={(event: any, newValue: string | null) => {
                  setPathValue(newValue);
                }}
                inputValue={pathInputValue}
                onInputChange={(event, newInputValue) => {
                  handleRouteInputChange(newInputValue);
                }}
                options={pathNameList}
                renderInput={(params) => (
                  <TextField {...params} label="Select a Route" />
                )}
                sx={{ marginBottom: "10px" }}
              />
            </Grid>
            {pathInputValue && (
              <Grid item xs={12}>
                <Divider
                  sx={{
                    marginTop: "8px",
                    marginBottom: "8px",
                  }}
                  color="lightgray"
                />
                <Typography>Select stops to merge</Typography>

                <List dense={true}>
                  {pathStops ? (
                    pathStops.map((stop) => (
                      <ListItem key={stop.id}>
                        <ToggleButtonGroup
                          value={checkedStops?.map((item: any) =>
                            item.stopId === stop.id ? stop.id : null
                          )}
                          onChange={handleCheckedStops}
                          aria-label="Select Stops"
                        >
                          <ToggleButton
                            style={{ borderColor: "gray" }}
                            value={stop.id}
                            color="primary"
                            aria-label="Select Stops"
                          >
                            <Typography textAlign={"start"}>
                              {stop.address}
                              {stop.students?.length > 0 && (
                                <b>
                                  {" - "} Students:{" "}
                                  {handleStopStudents(stop.students)}
                                </b>
                              )}
                            </Typography>
                          </ToggleButton>
                        </ToggleButtonGroup>
                        {checkedStops.map((item: any) =>
                          item.stopId === stop.id ? (
                            <Grid
                              item
                              xs={12}
                              md={12}
                              lg={12}
                              marginLeft="10px"
                            >
                              <LocalizationProvider
                                key={item.stopId}
                                dateAdapter={AdapterDayjs}
                                localeText={{
                                  start: "Start Date",
                                  end: "End Date",
                                }}
                              >
                                <DateRangePicker
                                  // key={item.pathId}
                                  value={item.dates}
                                  disablePast
                                  onChange={(newValue) => {
                                    setCheckedStops(
                                      checkedStops.map((item: any) =>
                                        item.stopId === stop.id
                                          ? {
                                              ...item,
                                              dates: newValue.map((date: any) =>
                                                dayjs(date).startOf("day")
                                              ),
                                            }
                                          : item
                                      )
                                    );
                                  }}
                                  renderInput={(startProps, endProps) => (
                                    <React.Fragment>
                                      <TextField {...startProps} />
                                      <Box sx={{ mx: 1 }}>to </Box>
                                      <TextField {...endProps} />
                                    </React.Fragment>
                                  )}
                                />
                              </LocalizationProvider>
                            </Grid>
                          ) : null
                        )}
                      </ListItem>
                    ))
                  ) : (
                    <ListItem>
                      <ListItemText>
                        <Typography color={"gray"}>
                          There are no stops that can be merged
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  )}
                </List>
                <DialogActions>
                  <Button
                    // type="submit"
                    startIcon={<FaSave />}
                    sx={{ fontSize: "18px", marginRight: "10px" }}
                    onClick={handleClickOpenConfirmDialog}
                    disabled={checkedStops.length === 0}
                  >
                    Save stops
                  </Button>
                  <Button onClick={onClose} color="primary">
                    Cancel
                  </Button>
                </DialogActions>
              </Grid>
            )}
          </Grid>

          <Dialog
            open={openConfirmDialog}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{"Confirm this action"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure you want to merge these stops?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseConfirmDialog}>Cancel</Button>
              <LoadingButton
                loading={createPathStopMergeLoading}
                onClick={handleMergeStops}
              >
                Confirm
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MergeStopCreatorModal;
