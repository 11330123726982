import { GridColDef } from "@mui/x-data-grid";
import React from "react";

import { BusDriversPaginatedItemFragment } from "../../../apollo/busDrivers/queries.generated";
import { GetBusRoutesPaginatedDocument } from "../../../apollo/busRoutes/queries.generated";
import { SchoolSystemPaginatedItemFragment } from "../../../apollo/schoolSystems/queries.generated";

import Link from "../../../navigation/Link";

import RemoteDataTable, {
  BasicTableActionFunction,
  getBasicActionColumns,
  RemoteDataTableProps,
} from "../../../shared/components/RemoteDataTable";

interface BusRoutesTableProps
  extends Pick<
    RemoteDataTableProps,
    "onAddClick" | "refresherBoolean" | "handlePDF" | "pdf"
  > {}
const muiColumns: GridColDef[] = [
  // {
  //   flex: 1,
  //   minWidth: 200,
  //   field: "id",
  //   sortable: false,
  //   headerName: "View",
  //   renderCell: (params) => {
  //     return <Button>{params.value}</Button>;
  //   },
  // },

  {
    flex: 1,
    field: "routeId",
    headerName: "ID",
    minWidth: 200,
    renderCell: ({ row, value }) => (
      <Link to={"/dashboard/busRoutes/" + row.id}>{value || "Not Set"}</Link>
    ),
  },

  {
    flex: 1,
    field: "name",
    headerName: "Name",
    minWidth: 200,
    // renderCell: ({ row, value }) => (
    //   <Link to={"/dashboard/busRoutes/" + row.id}>{value}</Link>
    // ),
  },
  {
    flex: 1,
    field: "busDriver",
    headerName: "Bus Driver",
    minWidth: 200,
    valueGetter: ({ value }) => {
      const driver = value as BusDriversPaginatedItemFragment;
      return `${driver.user.firstName} ${driver.user.lastName}`;
    },
  },
  // {
  //   flex: 1,
  //   field: "studentsCount",
  //   headerName: "Total Students",
  //   minWidth: 200,
  // },
  {
    flex: 1,
    field: "school",
    headerName: "School",
    valueGetter: ({ value }) => {
      return (value as SchoolSystemPaginatedItemFragment)?.name;
    },
    minWidth: 200,
  },
  {
    flex: 1,
    field: "schoolSystem",
    headerName: "School System",
    valueGetter: ({ value }) => {
      return (value as SchoolSystemPaginatedItemFragment)?.name;
    },
    minWidth: 200,
  },
];

const BusRoutesTable: React.FC<BusRoutesTableProps> = ({
  //   onEditPress,
  //   onDeletePress,

  ...props
}) => {
  return (
    <RemoteDataTable
      query={GetBusRoutesPaginatedDocument}
      searchable
      renderAddButton
      columns={muiColumns}
      //   actionColumns={getBasicActionColumns(onEditPress, onDeletePress)}
      {...props}
    />
  );
};

export default BusRoutesTable;
