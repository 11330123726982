export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
};

export enum AccountStatus {
  Unverified = 'Unverified',
  Verified = 'Verified'
}

export type AdminPathInformationDto = {
  __typename?: 'AdminPathInformationDTO';
  attendance: AttendanceDto;
  attendanceId: Scalars['String'];
  id: Scalars['String'];
  path: BusRoutePathDto;
  pathId: Scalars['String'];
  route: BusRouteDto;
  routeId: Scalars['String'];
  school: SchoolDto;
  schoolId: Scalars['String'];
  schoolSystemId: Scalars['String'];
  type: Scalars['String'];
};

export type AttendanceActionDto = {
  attendanceId: Scalars['String'];
  studentId: Scalars['String'];
  type: AttendanceActionType;
};

export enum AttendanceActionType {
  DropOff = 'DropOff',
  PickUp = 'PickUp'
}

export type AttendanceDto = {
  __typename?: 'AttendanceDTO';
  busDriver: BusDriverDto;
  busPath: BusRoutePathDto;
  busPathId: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  points: Array<AttendancePointDto>;
  school: SchoolDto;
  schoolSystem?: Maybe<SchoolSystemDto>;
  schoolSystemId?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['DateTime']>;
  studentStatuses: Array<StudentAttendanceStatusDto>;
  studentsPresent: Array<StudentDto>;
  totalTimeInAttendance?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type AttendancePaginatedDto = {
  __typename?: 'AttendancePaginatedDTO';
  nodes?: Maybe<Array<AttendanceDto>>;
  totalsCount: Scalars['Int'];
};

export type AttendancePointDto = {
  __typename?: 'AttendancePointDTO';
  attendanceId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  timestamp: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type AuthSuccessPayloadDto = {
  __typename?: 'AuthSuccessPayloadDTO';
  token: Scalars['String'];
  user: UserDto;
};

export type BatchAttendanceActionDto = {
  attendanceId: Scalars['String'];
  studentIds: Array<Scalars['String']>;
  type: AttendanceActionType;
};

export type BusDriverDto = {
  __typename?: 'BusDriverDTO';
  accountStatus: AccountStatus;
  assignedRoutes: Array<BusRouteDto>;
  assignedSchools: Array<SchoolDto>;
  busNumber?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  school?: Maybe<SchoolDto>;
  schoolSystem?: Maybe<SchoolSystemDto>;
  schoolSystemId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user: UserDto;
  userId: Scalars['String'];
};

export type BusDriversPaginatedDto = {
  __typename?: 'BusDriversPaginatedDTO';
  nodes?: Maybe<Array<BusDriverDto>>;
  totalsCount: Scalars['Int'];
};

export type BusRouteDto = {
  __typename?: 'BusRouteDTO';
  aditionalSchools?: Maybe<Array<SchoolDto>>;
  busDriver: BusDriverDto;
  busDriverId: Scalars['String'];
  busRoutePathsForMerging?: Maybe<Array<Array<Scalars['String']>>>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  paths: Array<BusRoutePathDto>;
  routeId?: Maybe<Scalars['String']>;
  school: SchoolDto;
  schoolId: Scalars['String'];
  schoolSystem: SchoolSystemDto;
  updatedAt: Scalars['DateTime'];
};

export type BusRoutePathDto = {
  __typename?: 'BusRoutePathDTO';
  attendanceList: Array<AttendanceDto>;
  busRouteId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  endTime: Scalars['DateTime'];
  id: Scalars['String'];
  startTime: Scalars['DateTime'];
  stops: Array<BusStopDto>;
  students: Array<StudentDto>;
  studentsCount: Scalars['Int'];
  todaysAttendance?: Maybe<AttendanceDto>;
  type: BusRoutePathType;
  updatedAt: Scalars['DateTime'];
};

export enum BusRoutePathType {
  Am = 'AM',
  Pm = 'PM'
}

export type BusRoutesPaginatedDto = {
  __typename?: 'BusRoutesPaginatedDTO';
  nodes?: Maybe<Array<BusRouteDto>>;
  totalsCount: Scalars['Int'];
};

export type BusStopDto = {
  __typename?: 'BusStopDTO';
  address: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  order: Scalars['Float'];
  students: Array<StudentDto>;
  updatedAt: Scalars['DateTime'];
};

export type CreateAditionalSchoolsDto = {
  busRouteId: Scalars['String'];
  schoolIds: Array<Scalars['String']>;
};

export type CreateBusDriverDto = {
  busNumber?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  schoolId?: Maybe<Scalars['String']>;
  schoolIds?: Maybe<Array<Scalars['String']>>;
  schoolSystemId?: Maybe<Scalars['String']>;
};

export type CreateBusPathDto = {
  busRouteId?: Maybe<Scalars['String']>;
  busStops: Array<CreateBusStopDto>;
  endTime: Scalars['DateTime'];
  startTime: Scalars['DateTime'];
  studentIds: Array<Scalars['String']>;
  type: BusRoutePathType;
};

export type CreateBusRouteDto = {
  busDriverId: Scalars['String'];
  name: Scalars['String'];
  paths: Array<CreateBusPathDto>;
  routeId: Scalars['String'];
  schoolId: Scalars['String'];
};

export type CreateBusStopAssigmentDto = {
  stopId: Scalars['String'];
  studentIds: Array<Scalars['String']>;
};

export type CreateBusStopDto = {
  address: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  order: Scalars['Float'];
};

export type CreateDriverRoutePeriodDto = {
  busDriverId: Scalars['String'];
  endTime: Scalars['DateTime'];
  routeId: Scalars['String'];
  startTime: Scalars['DateTime'];
};

export type CreateFamilyDto = {
  name: Scalars['String'];
  usersIds: Array<Scalars['String']>;
};

export type CreateFormDto = {
  finalDate: Scalars['DateTime'];
  initialDate: Scalars['DateTime'];
  name: Scalars['String'];
  receivers: Array<Scalars['String']>;
  targetId?: Maybe<Scalars['String']>;
  targetType?: Maybe<Scalars['String']>;
};

export type CreateFormFieldsDto = {
  checkBoxes?: Maybe<Array<Scalars['String']>>;
  dropdownItems?: Maybe<Array<Scalars['String']>>;
  formId: Scalars['String'];
  hintText?: Maybe<Scalars['String']>;
  order: Scalars['Float'];
  radioButtons?: Maybe<Array<Scalars['String']>>;
  required: Scalars['Boolean'];
  title: Scalars['String'];
  type: Scalars['String'];
};

export type CreateFormResponseDto = {
  formId: Scalars['String'];
  responseData: Array<Array<Scalars['String']>>;
  userId: Scalars['String'];
};

export type CreateNotificationRegistrationDto = {
  platform: Scalars['String'];
  token: Scalars['String'];
};

export type CreateParentAccountDto = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  schoolId?: Maybe<Scalars['String']>;
  schoolSystemId?: Maybe<Scalars['String']>;
};

export type CreateParentAccountSuccessDto = {
  __typename?: 'CreateParentAccountSuccessDTO';
  parent: ParentDto;
  token: Scalars['String'];
};

export type CreateParentDto = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  schoolId?: Maybe<Scalars['String']>;
  schoolSystemId?: Maybe<Scalars['String']>;
  students?: Maybe<Array<Scalars['String']>>;
};

export type CreatePaymentMethodAddressDto = {
  city: Scalars['String'];
  country: Scalars['String'];
  postalCode: Scalars['String'];
  state: Scalars['String'];
  streetAddress1: Scalars['String'];
  streetAddress2?: Maybe<Scalars['String']>;
};

export type CreatePaymentMethodDto = {
  address: CreatePaymentMethodAddressDto;
  expMonth: Scalars['String'];
  expYear: Scalars['String'];
  nameOnCard: Scalars['String'];
  number: Scalars['String'];
};

export type CreateScheduleDto = {
  deviceId?: Maybe<Scalars['Float']>;
  duration: ScheduleDuration;
  endDate?: Maybe<Scalars['DateTime']>;
  frequency: ScheduleFrequency;
  name: Scalars['String'];
  numberOfPayments?: Maybe<Scalars['Float']>;
  processingDateInfo?: Maybe<Scalars['String']>;
  scheduleIdentifier?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status: ScheduleStatus;
  subtotalAmount: Scalars['Float'];
  taxAmount?: Maybe<Scalars['Float']>;
};

export type CreateSchoolDto = {
  address: Scalars['String'];
  addressLat: Scalars['Float'];
  addressLng: Scalars['Float'];
  code: Scalars['String'];
  name: Scalars['String'];
  schoolSystemId: Scalars['String'];
  state: Scalars['String'];
};

export type CreateSchoolSystemDto = {
  name: Scalars['String'];
};

export type CreateStudentAccountDto = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  parentEmail?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  routeId: Scalars['String'];
  studentId?: Maybe<Scalars['String']>;
};

export type CreateStudentDto = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  schoolId?: Maybe<Scalars['String']>;
  schoolSystemId?: Maybe<Scalars['String']>;
  studentId: Scalars['String'];
};

export type CreateUserDto = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  role: Scalars['String'];
  schoolId?: Maybe<Scalars['String']>;
  schoolSystemId?: Maybe<Scalars['String']>;
};

export type CreateVacationPeriodDto = {
  endDate: Scalars['DateTime'];
  schoolSystemId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
};

export type DeleteNotificationRegistrationDto = {
  platform: Scalars['String'];
};

export type DriverRoutePeriodDto = {
  __typename?: 'DriverRoutePeriodDTO';
  busDriver: BusDriverDto;
  busRoute: BusRouteDto;
  id: Scalars['String'];
  isSubstituteDriver: Scalars['Boolean'];
  routePeriodEnd: Scalars['DateTime'];
  routePeriodStart: Scalars['DateTime'];
};

export type FamiliesPaginatedDto = {
  __typename?: 'FamiliesPaginatedDTO';
  nodes?: Maybe<Array<FamilyDto>>;
  totalsCount: Scalars['Int'];
};

export type FamilyDto = {
  __typename?: 'FamilyDTO';
  createdAt: Scalars['DateTime'];
  familySubscriptions?: Maybe<SubscriptionDto>;
  id: Scalars['String'];
  members: Array<UserDto>;
  membersCount: Scalars['Float'];
  name: Scalars['String'];
  parents: Array<ParentDto>;
  schoolSystem?: Maybe<SchoolSystemDto>;
  schoolSystemId?: Maybe<Scalars['String']>;
  subscription?: Maybe<SubscriptionDto>;
  updatedAt: Scalars['DateTime'];
};

export type FormDto = {
  __typename?: 'FormDTO';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  finalDate: Scalars['DateTime'];
  formFields: Array<FormFieldsDto>;
  formResponses?: Maybe<Array<FormResponseDto>>;
  id: Scalars['String'];
  initialDate: Scalars['DateTime'];
  name: Scalars['String'];
  receivers: Array<Scalars['String']>;
  targetId?: Maybe<Scalars['String']>;
  targetInfo?: Maybe<Scalars['String']>;
  targetType?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type FormFieldsDto = {
  __typename?: 'FormFieldsDTO';
  checkBoxes?: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['DateTime'];
  dropdownItems?: Maybe<Array<Scalars['String']>>;
  formId: Scalars['String'];
  hintText?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  order: Scalars['Float'];
  radioButtons?: Maybe<Array<Scalars['String']>>;
  required: Scalars['Boolean'];
  title: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type FormPaginatedDto = {
  __typename?: 'FormPaginatedDTO';
  nodes?: Maybe<Array<FormDto>>;
  totalsCount: Scalars['Int'];
};

export type FormResponseDto = {
  __typename?: 'FormResponseDTO';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  formId: Scalars['String'];
  id: Scalars['String'];
  responseData: Array<Array<Scalars['String']>>;
  updatedAt: Scalars['DateTime'];
  user: UserDto;
  userId: Scalars['String'];
};

export type FormResponsePaginatedDto = {
  __typename?: 'FormResponsePaginatedDTO';
  nodes?: Maybe<Array<FormResponseDto>>;
  totalsCount: Scalars['Int'];
};

export type GeoPointsInfo = {
  __typename?: 'GeoPointsInfo';
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type MergedBusRoutePathsDto = {
  __typename?: 'MergedBusRoutePathsDTO';
  endMergeTime: Scalars['DateTime'];
  id: Scalars['String'];
  mainBusRoute: BusRouteDto;
  mainBusRouteId: Scalars['String'];
  mergeableBusRoutePath: BusRoutePathDto;
  originBusRouteId: Scalars['String'];
  pathName: Scalars['String'];
  pathType: Scalars['String'];
  startMergeTime: Scalars['DateTime'];
};

export type MergedStopsDto = {
  __typename?: 'MergedStopsDTO';
  endMergeTime: Scalars['DateTime'];
  id: Scalars['String'];
  mainBusRoute: BusRouteDto;
  mergeableBusStop: BusStopDto;
  pathType: BusRoutePathType;
  startMergeTime: Scalars['DateTime'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addFamilyMember: FamilyDto;
  addPaymentMethod: PaymentMethodDto;
  addRegistration: Scalars['Boolean'];
  addStudentToParent: StudentDto;
  assignStudentsToStops: Array<BusStopDto>;
  authorize: Scalars['JSONObject'];
  checkAndDeleteFamiliesWithNoMembers?: Maybe<Scalars['Boolean']>;
  createAditionalSchools: Scalars['Boolean'];
  createBusDriver: BusDriverDto;
  createBusRoute: BusRouteDto;
  createDriverRoutePeriod: DriverRoutePeriodDto;
  createDriverRoutingInfo: Scalars['Boolean'];
  createFamily: FamilyDto;
  createForm: FormDto;
  createFormField: Scalars['Boolean'];
  createFormResponses: Scalars['Boolean'];
  createMergeBusRoutePath: Scalars['Boolean'];
  createMergeBusStop: Scalars['Boolean'];
  createParent: ParentDto;
  createParentAccount: CreateParentAccountSuccessDto;
  createSchool: SchoolDto;
  createSchoolSystem: SchoolSystemDto;
  createStudent: StudentDto;
  createStudentAccount: StudentDto;
  createTodaysAttendance: AttendanceDto;
  createUser: UserDto;
  createVacationPeriod: VacationPeriodDto;
  deleteBusDriver: Scalars['Boolean'];
  deleteBusRoute: BusRouteDto;
  deleteDriverRoutePeriod: Scalars['Boolean'];
  deleteFamily: Scalars['Boolean'];
  deleteForm: Scalars['Boolean'];
  deleteFormField: Scalars['Boolean'];
  deleteMergedBusRoutePath: Scalars['Boolean'];
  deleteMergedBusStop: Scalars['Boolean'];
  deleteMyPaymentMethod: Scalars['Boolean'];
  deleteParent: Scalars['Boolean'];
  deleteSchool: Scalars['Boolean'];
  deleteSchoolSystem: Scalars['Boolean'];
  deleteStudent: Scalars['Boolean'];
  deleteUser: Scalars['Boolean'];
  deleteUserFormResponses: Scalars['Boolean'];
  deleteVacationPeriod: Scalars['Boolean'];
  enableSchoolSystem: Scalars['Boolean'];
  markAttendanceAsCompleted: AttendanceDto;
  pauseFamilySubscription: SubscriptionDto;
  pauseSubscription: SubscriptionDto;
  pickUpDropOffManyStudents: Array<StudentAttendanceStatusDto>;
  pickUpDropOffStudent: StudentAttendanceStatusDto;
  refundTransaction: Scalars['Boolean'];
  removeFamilyMember: FamilyDto;
  removeRegistration: Scalars['Boolean'];
  resumeFamilySubscription: SubscriptionDto;
  resumeSubscription: SubscriptionDto;
  setAttendanceStudents: AttendanceDto;
  setWorkingAttendance: Scalars['Boolean'];
  startSchedule: Scalars['JSONObject'];
  startSubscription: PaidSubscriptionResultDto;
  startTrialSubscription: SubscriptionDto;
  updateAdditionalSchools: Scalars['Boolean'];
  updateBusDriver: BusDriverDto;
  updateBusRoute: BusRouteDto;
  updateDriverRoutePeriod: DriverRoutePeriodDto;
  updateFamily: FamilyDto;
  updateForm: Scalars['Boolean'];
  updateFormField: Scalars['Boolean'];
  updateManyFormField: Scalars['Boolean'];
  updateMergedBusRoutePath: Scalars['Boolean'];
  updateMergedBusStop: Scalars['Boolean'];
  updateParent: ParentDto;
  updatePaymentMethod: PaymentMethodDto;
  updateSchedule: Scalars['JSONObject'];
  updateSchool: SchoolDto;
  updateSchoolSystem: SchoolSystemDto;
  updateSchoolSystemSettings: SchoolSystemSettingsDto;
  updateStudent: StudentDto;
  updateSubscription: Scalars['Boolean'];
  updateUser: UserDto;
  updateUserStatus: UserDto;
  updateVacationPeriod: VacationPeriodDto;
  verify: Scalars['JSONObject'];
  voidTransaction: Scalars['Boolean'];
};


export type MutationAddFamilyMemberArgs = {
  email: Scalars['String'];
};


export type MutationAddPaymentMethodArgs = {
  data: CreatePaymentMethodDto;
};


export type MutationAddRegistrationArgs = {
  data: CreateNotificationRegistrationDto;
};


export type MutationAddStudentToParentArgs = {
  parentId?: Maybe<Scalars['String']>;
  studentEmail: Scalars['String'];
};


export type MutationAssignStudentsToStopsArgs = {
  data: Array<CreateBusStopAssigmentDto>;
};


export type MutationAuthorizeArgs = {
  data: SaleDataDto;
};


export type MutationCreateAditionalSchoolsArgs = {
  data: CreateAditionalSchoolsDto;
};


export type MutationCreateBusDriverArgs = {
  data: CreateBusDriverDto;
};


export type MutationCreateBusRouteArgs = {
  data: CreateBusRouteDto;
};


export type MutationCreateDriverRoutePeriodArgs = {
  data: CreateDriverRoutePeriodDto;
};


export type MutationCreateDriverRoutingInfoArgs = {
  attendanceId: Scalars['String'];
  driverLat: Scalars['Float'];
  driverLng: Scalars['Float'];
};


export type MutationCreateFamilyArgs = {
  data: CreateFamilyDto;
};


export type MutationCreateFormArgs = {
  data: CreateFormDto;
};


export type MutationCreateFormFieldArgs = {
  data: CreateFormFieldsDto;
};


export type MutationCreateFormResponsesArgs = {
  data: CreateFormResponseDto;
};


export type MutationCreateMergeBusRoutePathArgs = {
  data: MergeBusRoutePathDto;
};


export type MutationCreateMergeBusStopArgs = {
  data: MergePathStopsDto;
};


export type MutationCreateParentArgs = {
  data: CreateParentDto;
};


export type MutationCreateParentAccountArgs = {
  data: CreateParentAccountDto;
};


export type MutationCreateSchoolArgs = {
  data: CreateSchoolDto;
};


export type MutationCreateSchoolSystemArgs = {
  data: CreateSchoolSystemDto;
};


export type MutationCreateStudentArgs = {
  data: CreateStudentDto;
};


export type MutationCreateStudentAccountArgs = {
  data: CreateStudentAccountDto;
};


export type MutationCreateTodaysAttendanceArgs = {
  pathId: Scalars['String'];
};


export type MutationCreateUserArgs = {
  data: CreateUserDto;
};


export type MutationCreateVacationPeriodArgs = {
  data: CreateVacationPeriodDto;
};


export type MutationDeleteBusDriverArgs = {
  id: Scalars['String'];
};


export type MutationDeleteBusRouteArgs = {
  id: Scalars['String'];
};


export type MutationDeleteDriverRoutePeriodArgs = {
  id: Scalars['String'];
};


export type MutationDeleteFamilyArgs = {
  id: Scalars['String'];
};


export type MutationDeleteFormArgs = {
  id: Scalars['String'];
};


export type MutationDeleteFormFieldArgs = {
  id: Scalars['String'];
};


export type MutationDeleteMergedBusRoutePathArgs = {
  mergedRouteId: Scalars['String'];
};


export type MutationDeleteMergedBusStopArgs = {
  mergedStopId: Scalars['String'];
};


export type MutationDeleteParentArgs = {
  id: Scalars['String'];
};


export type MutationDeleteSchoolArgs = {
  id: Scalars['String'];
};


export type MutationDeleteSchoolSystemArgs = {
  id: Scalars['String'];
};


export type MutationDeleteStudentArgs = {
  id: Scalars['String'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['String'];
};


export type MutationDeleteUserFormResponsesArgs = {
  formId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationDeleteVacationPeriodArgs = {
  id: Scalars['String'];
};


export type MutationEnableSchoolSystemArgs = {
  id: Scalars['String'];
};


export type MutationMarkAttendanceAsCompletedArgs = {
  id: Scalars['String'];
};


export type MutationPauseFamilySubscriptionArgs = {
  familyId: Scalars['String'];
};


export type MutationPickUpDropOffManyStudentsArgs = {
  data: BatchAttendanceActionDto;
};


export type MutationPickUpDropOffStudentArgs = {
  data: AttendanceActionDto;
};


export type MutationRefundTransactionArgs = {
  amount: Scalars['Float'];
  transactionId: Scalars['String'];
};


export type MutationRemoveFamilyMemberArgs = {
  id: Scalars['String'];
};


export type MutationRemoveRegistrationArgs = {
  data: DeleteNotificationRegistrationDto;
};


export type MutationResumeFamilySubscriptionArgs = {
  familyId: Scalars['String'];
};


export type MutationSetAttendanceStudentsArgs = {
  data: SetAttendanceStudentsDto;
};


export type MutationSetWorkingAttendanceArgs = {
  id: Scalars['String'];
};


export type MutationStartScheduleArgs = {
  data: CreateScheduleDto;
};


export type MutationUpdateAdditionalSchoolsArgs = {
  data: CreateAditionalSchoolsDto;
};


export type MutationUpdateBusDriverArgs = {
  data: UpdateBusDriverDto;
  id: Scalars['String'];
};


export type MutationUpdateBusRouteArgs = {
  data: UpdateBusRouteDto;
  id: Scalars['String'];
};


export type MutationUpdateDriverRoutePeriodArgs = {
  data: UpdateDriverRoutePeriodDto;
  id: Scalars['String'];
};


export type MutationUpdateFamilyArgs = {
  data: UpdateFamilyDto;
  id: Scalars['String'];
};


export type MutationUpdateFormArgs = {
  data: UpdateFormDto;
  id: Scalars['String'];
};


export type MutationUpdateFormFieldArgs = {
  data: UpdateFormFieldsDto;
};


export type MutationUpdateManyFormFieldArgs = {
  data: UpdateManyFormFieldsDto;
};


export type MutationUpdateMergedBusRoutePathArgs = {
  data: UpdateMergedRouteDto;
};


export type MutationUpdateMergedBusStopArgs = {
  data: UpdateMergedStopDto;
};


export type MutationUpdateParentArgs = {
  data: UpdateParentDto;
  id: Scalars['String'];
};


export type MutationUpdatePaymentMethodArgs = {
  data: UpdatePaymentMethodDto;
};


export type MutationUpdateScheduleArgs = {
  data: UpdateScheduleDto;
  id: Scalars['String'];
};


export type MutationUpdateSchoolArgs = {
  data: UpdateSchoolDto;
  id: Scalars['String'];
};


export type MutationUpdateSchoolSystemArgs = {
  data: UpdateSchoolSystemDto;
  id: Scalars['String'];
};


export type MutationUpdateSchoolSystemSettingsArgs = {
  data: UpdateSchoolSystemSettingsDto;
  schoolSystemId?: Maybe<Scalars['String']>;
};


export type MutationUpdateStudentArgs = {
  data: UpdateStudentDto;
  id: Scalars['String'];
};


export type MutationUpdateSubscriptionArgs = {
  data: UpdateSubscriptionDto;
  id: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  data: UpdateUserDto;
  id: Scalars['String'];
};


export type MutationUpdateUserStatusArgs = {
  id: Scalars['String'];
  isDisabled: Scalars['Boolean'];
};


export type MutationUpdateVacationPeriodArgs = {
  data: UpdateVacationPeriodDto;
  id: Scalars['String'];
};


export type MutationVerifyArgs = {
  data: VerifyDataDto;
};


export type MutationVoidTransactionArgs = {
  transactionId: Scalars['String'];
};

export type PaidSubscriptionResultDto = {
  __typename?: 'PaidSubscriptionResultDTO';
  subscription?: Maybe<SubscriptionDto>;
  transaction: TransactionDto;
};

export type ParentDto = {
  __typename?: 'ParentDTO';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  schoolSystem: SchoolSystemDto;
  schoolSystemId?: Maybe<Scalars['String']>;
  students?: Maybe<Array<StudentDto>>;
  studentsCount: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  user: UserDto;
  userId: Scalars['String'];
};

export type ParentPathInformationDto = {
  __typename?: 'ParentPathInformationDTO';
  attendance: AttendanceDto;
  busRoute: BusRouteDto;
  path: BusRoutePathDto;
  student: StudentDto;
};

export type ParentsPaginatedDto = {
  __typename?: 'ParentsPaginatedDTO';
  nodes?: Maybe<Array<ParentDto>>;
  totalsCount: Scalars['Int'];
};

export type PaymentMethodAddressDto = {
  __typename?: 'PaymentMethodAddressDTO';
  city: Scalars['String'];
  country: Scalars['String'];
  postalCode: Scalars['String'];
  province: Scalars['String'];
  streetAddress1: Scalars['String'];
  streetAddress2?: Maybe<Scalars['String']>;
  streetAddress3?: Maybe<Scalars['String']>;
};

export type PaymentMethodDto = {
  __typename?: 'PaymentMethodDTO';
  address: PaymentMethodAddressDto;
  createdAt: Scalars['DateTime'];
  expMonth: Scalars['String'];
  expYear: Scalars['String'];
  id: Scalars['String'];
  nameOnCard: Scalars['String'];
  number: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type Query = {
  __typename?: 'Query';
  User: UserDto;
  adminCurrentPaths: Array<AdminPathInformationDto>;
  attendanceList?: Maybe<Array<AttendanceDto>>;
  attendanceListPaginated?: Maybe<AttendancePaginatedDto>;
  authenticate: AuthSuccessPayloadDto;
  busDriver: BusDriverDto;
  busDriverAssignedRoutes: Array<BusRouteDto>;
  busDriverByUserId: BusDriverDto;
  busDriverNonAssignedRoutes: Array<BusRouteDto>;
  busDriversPaginated: BusDriversPaginatedDto;
  busRoute: BusRouteDto;
  busRoutesPaginated: BusRoutesPaginatedDto;
  checkEmailAvailability: Scalars['Boolean'];
  checkIfAttendanceIsInCourse: Scalars['Boolean'];
  completeAttendances: Scalars['Boolean'];
  createInscriptionsStudents: Scalars['Boolean'];
  familiesPaginated: FamiliesPaginatedDto;
  getAllBusRoutePaths: Array<BusRoutePathDto>;
  getBusRoutePath: BusRoutePathDto;
  getBusRoutesByScheduleSchool?: Maybe<Array<BusRouteDto>>;
  getBusRoutesToMerge?: Maybe<Array<BusRouteDto>>;
  getDisabledSchoolSystemsPaginated: SchoolSystemsPaginatedDto;
  getDisabledSchoolsPaginated: SchoolsPaginatedDto;
  getDriverRoutingInfo?: Maybe<RoutingInfoDto>;
  getFamily: FamilyDto;
  getFamilySchoolSystemPaymentMethod?: Maybe<Scalars['String']>;
  getForm: FormDto;
  getFormFieldById: FormFieldsDto;
  getFormFields: Array<FormFieldsDto>;
  getFormResponses: Array<FormResponseDto>;
  getFormResponsesPaginated: FormResponsePaginatedDto;
  getFormsPaginated: FormPaginatedDto;
  getMergedBusRoutePaths: Array<MergedBusRoutePathsDto>;
  getMergedBusStops: Array<MergedStopsDto>;
  getRemainingTimeToDestination?: Maybe<Scalars['String']>;
  getRouteDrivers: Array<DriverRoutePeriodDto>;
  getSchoolSystemDisabledUsersPaginated: UsersPaginatedDto;
  getSubstituteDriverRoutes: Array<DriverRoutePeriodDto>;
  getTransactionId?: Maybe<StoredTransactionsDto>;
  getTransactionsPaginated?: Maybe<TransactionsPaginatedDto>;
  getUserSchoolSystemPaymentMethod?: Maybe<Scalars['String']>;
  me?: Maybe<UserDto>;
  mergePathsAndStudentsToBusRoutes: BusRouteDto;
  myFamily?: Maybe<FamilyDto>;
  myForms?: Maybe<Array<FormDto>>;
  myPaymentMethod?: Maybe<PaymentMethodDto>;
  myStudents: Array<StudentDto>;
  mySubscription?: Maybe<SubscriptionDto>;
  mySubscriptionStatus?: Maybe<SubscriptionStatus>;
  parent: ParentDto;
  parentCurrentPaths?: Maybe<Array<ParentPathInformationDto>>;
  parentsPaginated: ParentsPaginatedDto;
  schedule: Scalars['JSONObject'];
  school: SchoolDto;
  schoolSystem: SchoolSystemDto;
  schoolSystemSettings: SchoolSystemSettingsDto;
  schoolSystems: Array<SchoolSystemDto>;
  schoolSystemsPaginated: SchoolSystemsPaginatedDto;
  schools: SchoolDto;
  schoolsPaginated: SchoolsPaginatedDto;
  student: StudentDto;
  studentCurrentPath?: Maybe<StudentPathInformationDto>;
  studentsMultipleSchools: StudentsPaginatedDto;
  studentsPaginated: StudentsPaginatedDto;
  systemBusDriversCount: Scalars['Int'];
  systemParentsCount: Scalars['Int'];
  systemSchoolsCount: Scalars['Int'];
  systemStudentsCount: Scalars['Int'];
  todaysAttendance?: Maybe<AttendanceDto>;
  upgradeTrial: Scalars['Boolean'];
  usersFamilyPaginated: UsersPaginatedDto;
  usersPaginated: UsersPaginatedDto;
  vacationPeriods: Array<VacationPeriodDto>;
};


export type QueryUserArgs = {
  id: Scalars['String'];
};


export type QueryAttendanceListArgs = {
  busRouteId: Scalars['String'];
  fromDate?: Maybe<Scalars['DateTime']>;
  pathType?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['DateTime']>;
};


export type QueryAttendanceListPaginatedArgs = {
  busRouteId: Scalars['String'];
  fromDate?: Maybe<Scalars['DateTime']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  pathType?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  schoolIds?: Maybe<Array<Scalars['String']>>;
  schoolSystemId?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['DateTime']>;
};


export type QueryAuthenticateArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type QueryBusDriverArgs = {
  id: Scalars['String'];
};


export type QueryBusDriverAssignedRoutesArgs = {
  userId: Scalars['String'];
};


export type QueryBusDriverByUserIdArgs = {
  userId: Scalars['String'];
};


export type QueryBusDriversPaginatedArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  schoolId?: Maybe<Scalars['String']>;
  schoolIds?: Maybe<Array<Scalars['String']>>;
  schoolSystemId?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
};


export type QueryBusRouteArgs = {
  id: Scalars['String'];
};


export type QueryBusRoutesPaginatedArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  schoolId?: Maybe<Scalars['String']>;
  schoolIds?: Maybe<Array<Scalars['String']>>;
  schoolSystemId?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
};


export type QueryCheckEmailAvailabilityArgs = {
  email: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
};


export type QueryCheckIfAttendanceIsInCourseArgs = {
  attendanceId: Scalars['String'];
};


export type QueryCreateInscriptionsStudentsArgs = {
  schoolId: Scalars['String'];
};


export type QueryFamiliesPaginatedArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  schoolId?: Maybe<Scalars['String']>;
  schoolIds?: Maybe<Array<Scalars['String']>>;
  schoolSystemId?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
};


export type QueryGetAllBusRoutePathsArgs = {
  routeId: Scalars['String'];
  types: Scalars['String'];
};


export type QueryGetBusRoutePathArgs = {
  routePathId: Scalars['String'];
};


export type QueryGetBusRoutesByScheduleSchoolArgs = {
  busRouteId: Scalars['String'];
};


export type QueryGetBusRoutesToMergeArgs = {
  busRouteId: Scalars['String'];
  schoolSystemId: Scalars['String'];
};


export type QueryGetDisabledSchoolSystemsPaginatedArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  schoolId?: Maybe<Scalars['String']>;
  schoolIds?: Maybe<Array<Scalars['String']>>;
  schoolSystemId?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
};


export type QueryGetDisabledSchoolsPaginatedArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  schoolId?: Maybe<Scalars['String']>;
  schoolIds?: Maybe<Array<Scalars['String']>>;
  schoolSystemId?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
};


export type QueryGetDriverRoutingInfoArgs = {
  pathId: Scalars['String'];
};


export type QueryGetFamilyArgs = {
  id: Scalars['String'];
};


export type QueryGetFamilySchoolSystemPaymentMethodArgs = {
  familyId: Scalars['String'];
};


export type QueryGetFormArgs = {
  id: Scalars['String'];
};


export type QueryGetFormFieldByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetFormFieldsArgs = {
  formId: Scalars['String'];
};


export type QueryGetFormResponsesArgs = {
  formId: Scalars['String'];
};


export type QueryGetFormResponsesPaginatedArgs = {
  formId: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  schoolId?: Maybe<Scalars['String']>;
  schoolIds?: Maybe<Array<Scalars['String']>>;
  schoolSystemId?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
};


export type QueryGetFormsPaginatedArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  schoolId?: Maybe<Scalars['String']>;
  schoolIds?: Maybe<Array<Scalars['String']>>;
  schoolSystemId?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
};


export type QueryGetMergedBusRoutePathsArgs = {
  routeId: Scalars['String'];
};


export type QueryGetMergedBusStopsArgs = {
  routeId: Scalars['String'];
};


export type QueryGetRemainingTimeToDestinationArgs = {
  attendanceId: Scalars['String'];
};


export type QueryGetRouteDriversArgs = {
  routeId: Scalars['String'];
};


export type QueryGetSchoolSystemDisabledUsersPaginatedArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  schoolId?: Maybe<Scalars['String']>;
  schoolIds?: Maybe<Array<Scalars['String']>>;
  schoolSystemId?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
};


export type QueryGetSubstituteDriverRoutesArgs = {
  driverId: Scalars['String'];
};


export type QueryGetTransactionIdArgs = {
  transactionId: Scalars['String'];
};


export type QueryGetTransactionsPaginatedArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  schoolId?: Maybe<Scalars['String']>;
  schoolIds?: Maybe<Array<Scalars['String']>>;
  schoolSystemId?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
};


export type QueryMergePathsAndStudentsToBusRoutesArgs = {
  busRouteId: Scalars['String'];
};


export type QueryParentArgs = {
  id: Scalars['String'];
};


export type QueryParentsPaginatedArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  schoolId?: Maybe<Scalars['String']>;
  schoolIds?: Maybe<Array<Scalars['String']>>;
  schoolSystemId?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
  withoutFamily?: Maybe<Scalars['Boolean']>;
};


export type QueryScheduleArgs = {
  id: Scalars['String'];
};


export type QuerySchoolArgs = {
  id: Scalars['String'];
};


export type QuerySchoolSystemArgs = {
  id: Scalars['String'];
};


export type QuerySchoolSystemSettingsArgs = {
  schoolSystemId?: Maybe<Scalars['String']>;
};


export type QuerySchoolSystemsArgs = {
  id: Scalars['String'];
};


export type QuerySchoolSystemsPaginatedArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  schoolId?: Maybe<Scalars['String']>;
  schoolIds?: Maybe<Array<Scalars['String']>>;
  schoolSystemId?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
};


export type QuerySchoolsPaginatedArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  schoolId?: Maybe<Scalars['String']>;
  schoolIds?: Maybe<Array<Scalars['String']>>;
  schoolSystemId?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
};


export type QueryStudentArgs = {
  id: Scalars['String'];
};


export type QueryStudentsMultipleSchoolsArgs = {
  data: StudentsSelectedSchoolsDto;
};


export type QueryStudentsPaginatedArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  schoolId?: Maybe<Scalars['String']>;
  schoolIds?: Maybe<Array<Scalars['String']>>;
  schoolSystemId?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
};


export type QueryTodaysAttendanceArgs = {
  busPathId: Scalars['String'];
};


export type QueryUsersFamilyPaginatedArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  schoolId?: Maybe<Scalars['String']>;
  schoolIds?: Maybe<Array<Scalars['String']>>;
  schoolSystemId?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
};


export type QueryUsersPaginatedArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  schoolId?: Maybe<Scalars['String']>;
  schoolIds?: Maybe<Array<Scalars['String']>>;
  schoolSystemId?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
};


export type QueryVacationPeriodsArgs = {
  schoolSystemId?: Maybe<Scalars['String']>;
};

export type RoutingInfoDto = {
  __typename?: 'RoutingInfoDTO';
  destinationPoint: Array<GeoPointsInfo>;
  id: Scalars['String'];
  initialPoint: Array<GeoPointsInfo>;
  pathId: Scalars['String'];
  schoolAddress: Scalars['String'];
  schoolId: Scalars['String'];
  type: Scalars['String'];
};

export type SaleAddressDto = {
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  streetAddress1: Scalars['String'];
  streetAddress2?: Maybe<Scalars['String']>;
};

export type SaleDataDto = {
  address: SaleAddressDto;
  amount: Scalars['Float'];
  cvv: Scalars['String'];
  expMonth: Scalars['String'];
  expYear: Scalars['String'];
  nameOnCard: Scalars['String'];
  number: Scalars['String'];
};

export enum ScheduleDuration {
  EndDate = 'EndDate',
  Limited = 'Limited',
  Number = 'Number',
  Ongoing = 'Ongoing'
}

export enum ScheduleFrequency {
  Annually = 'Annually',
  BiMonthly = 'BiMonthly',
  BiWeekly = 'BiWeekly',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  SemiAnnually = 'SemiAnnually',
  SemiMonthly = 'SemiMonthly',
  Weekly = 'Weekly'
}

export enum ScheduleStatus {
  Active = 'Active',
  Inactive = 'Inactive'
}

export type SchoolDto = {
  __typename?: 'SchoolDTO';
  address?: Maybe<Scalars['String']>;
  addressLat?: Maybe<Scalars['Float']>;
  addressLng?: Maybe<Scalars['Float']>;
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  schoolSystem?: Maybe<SchoolSystemDto>;
  schoolSystemId?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  status: SchoolStatus;
  updatedAt: Scalars['DateTime'];
};

export enum SchoolStatus {
  Active = 'Active',
  Inactive = 'Inactive'
}

export type SchoolSystemDto = {
  __typename?: 'SchoolSystemDTO';
  createdAt: Scalars['DateTime'];
  familiesSubscriptionFee: Scalars['Float'];
  feePerRoute: Scalars['Float'];
  id: Scalars['String'];
  monthlyFee: Scalars['Float'];
  name: Scalars['String'];
  routeCount: Scalars['Float'];
  schoolSystemSettings: SchoolSystemSettingsDto;
  updatedAt: Scalars['DateTime'];
};

export enum SchoolSystemPaymentMethod {
  Parent = 'Parent',
  SchoolSystem = 'SchoolSystem'
}

export type SchoolSystemSettingsDto = {
  __typename?: 'SchoolSystemSettingsDTO';
  createdAt: Scalars['DateTime'];
  familiesPaymentMethod: SchoolSystemPaymentMethod;
  feePerRoute: Scalars['String'];
  id: Scalars['String'];
  schoolSystem: SchoolSystemDto;
  schoolSystemId: Scalars['String'];
  showBusStops: Scalars['Boolean'];
  showBusTracking: Scalars['Boolean'];
  showSubstituteRoutes: Scalars['Boolean'];
  subscriptionFee: Scalars['String'];
  timezone?: Maybe<Scalars['String']>;
  turnByTurnDirections: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type SchoolSystemsPaginatedDto = {
  __typename?: 'SchoolSystemsPaginatedDTO';
  nodes?: Maybe<Array<SchoolSystemDto>>;
  totalsCount: Scalars['Int'];
};

export type SchoolsPaginatedDto = {
  __typename?: 'SchoolsPaginatedDTO';
  nodes?: Maybe<Array<SchoolDto>>;
  totalsCount: Scalars['Int'];
};

export type SetAttendanceStudentsDto = {
  id: Scalars['String'];
  studentIds: Array<Scalars['String']>;
};

export type StoredTransactionsDto = {
  __typename?: 'StoredTransactionsDTO';
  amount: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  family: FamilyDto;
  id: Scalars['String'];
  schoolSystem: SchoolSystemDto;
  schools: Scalars['String'];
  status: Scalars['String'];
  transactionId: Scalars['String'];
  user: UserDto;
};

export type StudentAttendanceStatusDto = {
  __typename?: 'StudentAttendanceStatusDTO';
  createdAt: Scalars['DateTime'];
  droppedOff: Scalars['Boolean'];
  id: Scalars['String'];
  pickedUp: Scalars['Boolean'];
  student: StudentDto;
  studentId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type StudentDto = {
  __typename?: 'StudentDTO';
  accountStatus: AccountStatus;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  school?: Maybe<SchoolDto>;
  schoolSystem?: Maybe<SchoolSystemDto>;
  schoolSystemId?: Maybe<Scalars['String']>;
  studentId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user: UserDto;
  userId: Scalars['String'];
};

export type StudentPathInformationDto = {
  __typename?: 'StudentPathInformationDTO';
  attendance: AttendanceDto;
  busRoute: BusRouteDto;
  path: BusRoutePathDto;
};

export type StudentsPaginatedDto = {
  __typename?: 'StudentsPaginatedDTO';
  nodes?: Maybe<Array<StudentDto>>;
  totalsCount: Scalars['Int'];
};

export type StudentsSelectedSchoolsDto = {
  schoolsIds: Array<Scalars['String']>;
};

export type SubscriptionDto = {
  __typename?: 'SubscriptionDTO';
  amount?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  discount?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  nextProcessingDate?: Maybe<Scalars['DateTime']>;
  paymentMethod?: Maybe<PaymentMethodDto>;
  status: SubscriptionStatus;
  updatedAt: Scalars['DateTime'];
};

export enum SubscriptionStatus {
  Active = 'Active',
  Failed = 'Failed',
  Inactive = 'Inactive',
  MissingPaymentMethod = 'MissingPaymentMethod',
  Paused = 'Paused',
  PausedForVacation = 'PausedForVacation',
  Trial = 'Trial'
}

export type TransactionDto = {
  __typename?: 'TransactionDTO';
  authorizedAmount?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  responseCode?: Maybe<Scalars['String']>;
  responseMessage?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
};

export type TransactionsPaginatedDto = {
  __typename?: 'TransactionsPaginatedDTO';
  nodes?: Maybe<Array<StoredTransactionsDto>>;
  totalsCount: Scalars['Int'];
};

export type UpdateBusDriverDto = {
  busNumber?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  schoolIds?: Maybe<Array<Scalars['String']>>;
  schoolSystemId?: Maybe<Scalars['String']>;
};

export type UpdateBusPathDto = {
  busRouteId?: Maybe<Scalars['String']>;
  busStops: Array<UpdateBusStopDto>;
  endTime: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  startTime: Scalars['DateTime'];
  studentIds: Array<Scalars['String']>;
  type: BusRoutePathType;
};

export type UpdateBusRouteDto = {
  busDriverId: Scalars['String'];
  name: Scalars['String'];
  paths: Array<UpdateBusPathDto>;
  routeId: Scalars['String'];
  schoolId: Scalars['String'];
};

export type UpdateBusStopDto = {
  address: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  order: Scalars['Float'];
};

export type UpdateDriverRoutePeriodDto = {
  busDriverId: Scalars['String'];
  endTime: Scalars['DateTime'];
  startTime: Scalars['DateTime'];
};

export type UpdateFamilyDto = {
  name: Scalars['String'];
  usersIds: Array<Scalars['String']>;
};

export type UpdateFormDto = {
  finalDate: Scalars['DateTime'];
  initialDate: Scalars['DateTime'];
  name: Scalars['String'];
  receivers: Array<Scalars['String']>;
  targetId?: Maybe<Scalars['String']>;
  targetType?: Maybe<Scalars['String']>;
};

export type UpdateFormFieldsDto = {
  checkBoxes?: Maybe<Array<Scalars['String']>>;
  dropdownItems?: Maybe<Array<Scalars['String']>>;
  hintText?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  order?: Maybe<Scalars['Float']>;
  radioButtons?: Maybe<Array<Scalars['String']>>;
  required?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type UpdateManyFormFieldsDto = {
  data: Array<UpdateFormFieldsDto>;
};

export type UpdateMergedRouteDto = {
  endTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  startTime?: Maybe<Scalars['DateTime']>;
};

export type UpdateMergedStopDto = {
  endTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  startTime?: Maybe<Scalars['DateTime']>;
};

export type UpdateParentDto = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  schoolSystemId?: Maybe<Scalars['String']>;
  students?: Maybe<Array<Scalars['String']>>;
};

export type UpdateScheduleDto = {
  deviceId?: Maybe<Scalars['Float']>;
  endDate?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  numberOfPayments?: Maybe<Scalars['Float']>;
  status?: Maybe<ScheduleStatus>;
  subtotalAmount?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
};

export type UpdateSchoolDto = {
  address: Scalars['String'];
  addressLat: Scalars['Float'];
  addressLng: Scalars['Float'];
  code: Scalars['String'];
  name: Scalars['String'];
  schoolSystemId: Scalars['String'];
  state: Scalars['String'];
};

export type UpdateSchoolSystemDto = {
  name: Scalars['String'];
};

export type UpdateSchoolSystemSettingsDto = {
  familiesPaymentMethod: Scalars['String'];
  feePerRoute: Scalars['Float'];
  showBusStops: Scalars['Boolean'];
  showBusTracking: Scalars['Boolean'];
  showSubstituteRoutes: Scalars['Boolean'];
  subscriptionFee: Scalars['Float'];
  timezone: Scalars['String'];
  turnByTurnDirections: Scalars['Boolean'];
};

export type UpdateStudentDto = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  schoolSystemId?: Maybe<Scalars['String']>;
  studentId: Scalars['String'];
};

export type UpdateSubscriptionDto = {
  amount: Scalars['Float'];
  discount: Scalars['Float'];
  trialEndDate?: Maybe<Scalars['DateTime']>;
};

export type UpdateUserDto = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  schoolSystemId?: Maybe<Scalars['String']>;
};

export type UpdateVacationPeriodDto = {
  endDate: Scalars['DateTime'];
  schoolSystemId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
};

export type UserDto = {
  __typename?: 'UserDTO';
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  family?: Maybe<FamilyDto>;
  familyId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['String'];
  isDisabled: Scalars['Boolean'];
  lastName: Scalars['String'];
  name: Scalars['String'];
  paymentMethod?: Maybe<PaymentMethodDto>;
  role: Scalars['String'];
  school?: Maybe<SchoolDto>;
  schoolId?: Maybe<Scalars['String']>;
  schoolSystem?: Maybe<SchoolSystemDto>;
  schoolSystemId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type UsersPaginatedDto = {
  __typename?: 'UsersPaginatedDTO';
  nodes?: Maybe<Array<UserDto>>;
  totalsCount: Scalars['Int'];
};

export type VacationPeriodDto = {
  __typename?: 'VacationPeriodDTO';
  createdAt: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  id: Scalars['String'];
  schoolSystem?: Maybe<SchoolSystemDto>;
  schoolSystemId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type VerifyDataDto = {
  address: SaleAddressDto;
  cvv: Scalars['String'];
  expMonth: Scalars['String'];
  expYear: Scalars['String'];
  nameOnCard: Scalars['String'];
  number: Scalars['String'];
};

export type MergeBusRoutePathDto = {
  endTime?: Maybe<Scalars['DateTime']>;
  mainBusRouteId: Scalars['String'];
  mergeableBusRoutePathId: Scalars['String'];
  originBusRouteId: Scalars['String'];
  pathName: Scalars['String'];
  pathType: BusRoutePathType;
  startTime?: Maybe<Scalars['DateTime']>;
};

export type MergePathStopsDto = {
  endTime?: Maybe<Scalars['DateTime']>;
  mainBusRouteId: Scalars['String'];
  mergeableBusStopId: Scalars['String'];
  pathType: Scalars['String'];
  startTime?: Maybe<Scalars['DateTime']>;
};

export type UpdatePaymentMethodDto = {
  address: CreatePaymentMethodAddressDto;
  expMonth: Scalars['String'];
  expYear: Scalars['String'];
};
