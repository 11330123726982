import { Box, Card, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  DeleteSchoolDocument,
  GetSchoolsPaginatedDocument,
  SchoolsPaginatedItemFragment,
} from "../../apollo/schools/queries.generated";
import SchoolFormModal from "./components/SchoolFormModal";
import SchoolsTable from "./components/SchoolsTable";
import DeleteModal from "../../shared/components/DeleteModal";
import PageContainer from "../../shared/components/PageContainer";
import TableCard from "../../shared/components/TableCard";
import { GetStudentsPaginatedDocument } from "../../apollo/students/queries.generated";
import { GetParentsPaginatedDocument } from "../../apollo/parents/query.generated";
import { GetFamiliesPaginatedDocument } from "../../apollo/families/query.generated";
import { GetBusRoutesPaginatedDocument } from "../../apollo/busRoutes/queries.generated";
import { GetBusDriversPaginatedDocument } from "../../apollo/busDrivers/queries.generated";
import { GetUsersPaginatedDocument } from "../../apollo/users/queries.generated";

interface SchoolsPageProps {}

const SchoolsPage: React.FC<SchoolsPageProps> = ({}) => {
  const [showFormModal, setShowFormModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedSchool, setSelectedSchool] =
    useState<SchoolsPaginatedItemFragment>();

  const handleFormClose = () => {
    setSelectedSchool(undefined);
    setShowFormModal(false);
  };

  const handleDeleteModalClose = () => {
    setSelectedSchool(undefined);
    setShowDeleteModal(false);
  };
  return (
    <PageContainer>
      <SchoolFormModal
        editingSchool={selectedSchool}
        open={showFormModal}
        onClose={handleFormClose}
      />
      <DeleteModal
        open={showDeleteModal}
        mutation={DeleteSchoolDocument}
        updateQueries={[
          GetSchoolsPaginatedDocument,
          GetSchoolsPaginatedDocument,
          GetStudentsPaginatedDocument,
          GetParentsPaginatedDocument,
          GetFamiliesPaginatedDocument,
          GetBusRoutesPaginatedDocument,
          GetBusDriversPaginatedDocument,
          GetBusRoutesPaginatedDocument,
          GetUsersPaginatedDocument,
        ]}
        variables={{ id: selectedSchool?.id }}
        onClose={handleDeleteModalClose}
        title="Delete School"
      />
      <TableCard>
        <Typography
          variant="h5"
          textAlign="start"
          fontWeight="bold"
          color="secondary"
          gutterBottom
        >
          Schools
        </Typography>
        <SchoolsTable
          onAddClick={() => setShowFormModal(true)}
          onEditPress={(item) => {
            setSelectedSchool(item);
            setShowFormModal(true);
          }}
          onDeletePress={(item) => {
            setSelectedSchool(item);
            setShowDeleteModal(true);
          }}
        />
        {/* <DataGrid
        style={{
          border: "none",
        }}
        columns={columns}
        rows={data?.schoolSystemsPaginated.nodes || []}
      /> */}
      </TableCard>
    </PageContainer>
  );
};

export default SchoolsPage;
