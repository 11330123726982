import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  IconButton,
  Divider,
} from "@mui/material";
import React from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";
import { FaTrash } from "react-icons/fa";
import { BusStop } from "./interfaces";

const reorder = <T,>(list: Array<T>, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

interface OrderListProps {
  busStops: BusStop[];
  onReorder: (reorderedList: BusStop[]) => void;
  onDeleteClick: (item: BusStop) => void;
}

const OrderList: React.FC<OrderListProps> = ({
  busStops,
  onReorder,
  onDeleteClick,
}) => {
  // console.log(busStops);
  const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      busStops,
      result.source.index,
      result.destination.index
    );

    onReorder(items);
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <List
            {...provided.droppableProps}
            ref={provided.innerRef}
            sx={{ width: "100%" }}
          >
            {busStops.map((item, index) => (
              <>
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <ListItem
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                    >
                      <ListItemAvatar>
                        <Avatar sx={{ bgcolor: "primary" }}>
                          {index + 1}{" "}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={item.address} />

                      <IconButton onClick={() => onDeleteClick(item)}>
                        <FaTrash />
                      </IconButton>
                    </ListItem>
                  )}
                </Draggable>
                <Divider variant="inset" component="li" />
              </>
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default OrderList;
