import { Box } from "@mui/material";
import { width } from "@mui/system";
import { GoogleMap, Marker } from "@react-google-maps/api";
import React, { useCallback, useEffect, useRef, useState } from "react";
import MapsApiLoader from "../../../shared/components/MapsApiLoader";
import CurrentRoutesList from "./CurrentRoutesList";
import { CurrentRoute } from "./interfaces";
import bus from "./school-bus.svg";

interface CurrentRoutesMapProps {
  currentRoutes: CurrentRoute[];
}

const CurrentRoutesMap: React.FC<CurrentRoutesMapProps> = ({
  currentRoutes,
}) => {
  const map = useRef<google.maps.Map>();
  const [followedBus, setFollowedBus] = useState<string>();
  // const [directionResults, setDirectionResults] =
  //   useState<google.maps.DirectionsResult | null>(null);
  const onListItemClick = useCallback(
    (item: string) =>
      setFollowedBus((f) => {
        if (f === item) {
          return undefined;
        }

        if (map.current) {
          map.current.setZoom(18);
        }
        return item;
      }),
    [map]
  );

  const onMapLoad = (loadedMap: google.maps.Map) => {
    // console.log("Map updated");
    map.current = loadedMap;
    loadedMap.setCenter({ lat: 41.850033, lng: -87.6500523 });
    loadedMap.setZoom(3);
  };

  return (
    <MapsApiLoader>
      <GoogleMap
        mapContainerStyle={{
          height: "500px",
          borderRadius: "15px",
        }}
        // zoom={10}
        onDrag={() => {
          if (followedBus) {
            setFollowedBus(undefined);
          }
        }}
        onLoad={onMapLoad}
      >
        {/* Child components, such as markers, info windows, etc. */}
        {/* {directions}

        {directionResults !== null && (
          <DirectionsRenderer
            options={{
              directions: directionResults,
            }}
          />
        )} */}
        {currentRoutes.map((route, i) => (
          <BusMarker
            key={route.attendanceId}
            onClick={setFollowedBus}
            route={route}
            selected={route.attendanceId === followedBus}
            mapInstance={map.current}
          />
        ))}
      </GoogleMap>

      <Box marginTop="20px">
        <CurrentRoutesList
          currentRoutes={currentRoutes}
          onItemClick={onListItemClick}
          selectedRoute={followedBus}
        />
      </Box>
    </MapsApiLoader>
  );
};

interface BusMarkerProps {
  mapInstance?: google.maps.Map;
  onClick: (id: string) => void;
  route: CurrentRoute;
  selected: boolean;
}
const BusMarker: React.FC<BusMarkerProps> = ({
  route,
  onClick,
  selected,
  mapInstance,
}) => {
  const onMarkerClick = useCallback(() => {
    if (mapInstance) {
      mapInstance.setZoom(18);
    }
    onClick(route.attendanceId);
  }, [mapInstance]);

  useEffect(() => {
    if (selected && mapInstance && route.currentLocation) {
      mapInstance.panTo(route.currentLocation);
    }
  }, [mapInstance, selected, route.currentLocation]);
  if (!route.currentLocation) return null;

  return (
    <Marker
      onClick={onMarkerClick}
      icon={{
        url: "/images/school-bus-2.png",
        // path: bus,
        scaledSize: new google.maps.Size(40, 40),

        // rotation: ,
      }}
      position={route.currentLocation}
      key={route.attendanceId}
      label={{
        text: `${route.routeId} - ${route.busDriverName}`,
        className: "bus-marker-label",
      }}
    />
  );
};
export default CurrentRoutesMap;
