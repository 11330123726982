import React, { useCallback, useEffect } from "react";
import {
  Grid,
  Modal,
  Paper,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useCreateSchoolSystemMutation,
  useUpdateSchoolSystemMutation,
} from "../../../apollo/auth/mutations.generated";
import {
  GetSchoolSystemsPaginatedDocument,
  SchoolSystemPaginatedItemFragment,
} from "../../../apollo/schoolSystems/queries.generated";
import { LoadingButton, LocalizationProvider } from "@mui/lab";
import toast from "react-hot-toast";
import { useNow } from "@mui/lab/internal/pickers/hooks/useUtils";
import useDidMountEffect from "../../../shared/hooks/useDidMountEffect";
import {
  FormsPaginatedItemFragment,
  GetFormByIdDocument,
  GetFormsPaginatedDocument,
} from "../../../apollo/forms/queries.generated";
import {
  useCreateFormMutation,
  useUpdateFormMutation,
} from "../../../apollo/forms/mutations.generated";
import ControlledTextField from "../../../shared/components/ControlledTextField";
import ControlledDatePicker from "../../../shared/components/ControlledDatePicker";
import AdapterDayJs from "@mui/lab/AdapterDayjs";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";

interface FormCreationModalProps {
  open: boolean;
  onClose: () => void;
  editingForm?: FormsPaginatedItemFragment;
}

interface FormInputs {
  name: string;
  targetId: string;
  targetType: string;
  receivers: string[];
  initialDate: any;
  finalDate: any;
}

const schema: Yup.SchemaOf<FormInputs> = Yup.object({
  name: Yup.string().required("This field is required"),
  targetId: Yup.string().required("This field is required"),
  targetType: Yup.string().required("This field is required"),
  receivers: Yup.array().required("This field is required"),
  // initialDate: Yup.object().required("This field is required"),
  // finalDate: Yup.object().required("This field is required"),
  initialDate: Yup.date().required("This field is required"),
  finalDate: Yup.date().required("This field is required"),
});

const FormCreationFormModal: React.FC<FormCreationModalProps> = ({
  open,
  onClose,
  editingForm,
}) => {
  const { control, handleSubmit, setValue, watch, reset } = useForm<FormInputs>(
    {
      resolver: yupResolver(schema),
      shouldFocusError: true,
      defaultValues: {
        name: editingForm?.name || "",
        targetId: editingForm?.targetId || "",
        targetType: editingForm?.targetType || "",
        receivers: editingForm?.receivers || [],
        initialDate: dayjs(editingForm?.initialDate) || null,
        finalDate: dayjs(editingForm?.finalDate) || null,
      },
    }
  );

  const initialDate = watch("initialDate");
  const targetType = watch("targetType");
  const receivers = watch("receivers");
  const history = useHistory();

  const [
    create,
    { data: createData, loading: createLoading, error: createError },
  ] = useCreateFormMutation({
    refetchQueries: [GetFormsPaginatedDocument],
  });

  const [
    update,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useUpdateFormMutation({
    refetchQueries: [
      GetFormsPaginatedDocument,
      { query: GetFormByIdDocument, variables: { id: editingForm?.id } },
    ],
  });

  const onSubmit = (data: FormInputs) => {
    if (editingForm) {
      update({
        variables: {
          id: editingForm.id,
          data,
        },
      });
      return;
    }

    create({ variables: { data } });
  };

  useEffect(() => {
    if (createData) {
      toast.success("Form created successfully");
      reset();
      history.push("/dashboard/forms/" + createData.createForm.id);
      onClose();
    }

    if (createError) {
      toast.error("Error creating Form: " + createError.message);
    }
  }, [createData, createError]);

  useEffect(() => {
    if (updateData) {
      toast.success("Form updated successfully");
      reset();
      onClose();
    }

    if (updateError) {
      toast.error("Error updating Form: " + updateError.message);
    }
  }, [updateData, updateError]);

  useDidMountEffect(() => {
    setValue("name", editingForm ? editingForm.name : "");
    setValue("targetId", editingForm ? (editingForm.targetId as string) : "");
    setValue(
      "targetType",
      editingForm ? (editingForm.targetType as string) : ""
    );
    setValue("receivers", editingForm ? editingForm.receivers : []);
    setValue(
      "initialDate",
      editingForm ? (editingForm.initialDate as Date) : new Date()
    );
    setValue(
      "finalDate",
      editingForm ? (editingForm.finalDate as Date) : new Date()
    );
  }, [editingForm]);

  const targetTypes = [
    {
      value: "Feedback",
      label: "Feedback",
    },
    // {
    //   value: "BusDriver",
    //   label: "Bus Driver",
    // },
    // {
    //   value: "Bus",
    //   label: "Bus",
    // },
    // {
    //   value: "Route",
    //   label: "Route",
    // },
    // {
    //   value: "Other",
    //   label: "Other",
    // },
  ];

  const receiverOptions = [
    "BusDriver",
    //  "Parent", "Student", "Everyone"
  ];

  useDidMountEffect(() => {
    if (targetType === "Feedback") {
      setValue("targetId", "Feedback");
    }
  }, [targetType]);

  const handleSelectedReceivers = useCallback(
    (receivers: any[]) => {
      setValue("receivers", receivers);
    },
    [receivers]
  );

  return (
    <Dialog
      open={open}
      onClose={!createLoading ? onClose : undefined}
      maxWidth="md"
      fullWidth
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{editingForm ? "Update" : "Create"} Form</DialogTitle>
        <DialogContent>
          <Grid container sx={{ marginTop: "10px" }} spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="name"
                render={({ field, formState }) => (
                  <TextField
                    {...field}
                    label="Name"
                    fullWidth
                    error={!!formState.errors.name}
                    helperText={formState.errors.name?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <ControlledTextField
                name="targetType"
                control={control}
                label="Target or Purpose"
                select
              >
                {targetTypes.map((tg) => (
                  <MenuItem value={tg.value} key={tg.value}>
                    {tg.label}
                  </MenuItem>
                ))}
              </ControlledTextField>
            </Grid>
            <Grid item xs={6}>
              <ControlledTextField
                name="targetId"
                control={control}
                label="Choose Target (Unavailable)"
                disabled
                select
              >
                {targetTypes.map((tg) => (
                  <MenuItem value={tg.value} key={tg.value}>
                    {tg.label}
                  </MenuItem>
                ))}
              </ControlledTextField>
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                multiple
                id="receivers"
                options={receiverOptions}
                getOptionLabel={(option) => option}
                filterSelectedOptions
                value={receivers}
                onChange={(e, value) => handleSelectedReceivers(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Receivers"
                    placeholder="Receivers"
                  />
                )}
              />
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDayJs}>
              <Grid item xs={6}>
                <ControlledDatePicker
                  label="Initial Date"
                  control={control}
                  name={`initialDate`}
                />
              </Grid>
              <Grid item xs={6}>
                <ControlledDatePicker
                  label="Final Date"
                  control={control}
                  name={`finalDate`}
                  minDate={dayjs(initialDate)}
                />
              </Grid>
            </LocalizationProvider>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel </Button>
          <LoadingButton loading={createLoading || updateLoading} type="submit">
            {editingForm ? "Submit" : "Submit and continue"}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default FormCreationFormModal;
