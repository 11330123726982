import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { StartSubscriptionMutation } from "../../../apollo/parents/query.generated";
import PrintIcon from "@mui/icons-material/Print";
import { useState } from "react";
import PDFModal from "../../busRoutes/components/PDFModal";

interface TransactionModalProps {
  open: boolean;
  onClose: () => void;
  transactionData?: StartSubscriptionMutation["startSubscription"];
}

const TransactionModal: React.FC<TransactionModalProps> = ({
  onClose,
  open,
  transactionData,
}) => {
  var discountedFee = 0;
  if (Number(transactionData?.subscription?.discount) > 0) {
    discountedFee =
      Number(transactionData?.subscription?.discount) *
      Number(transactionData?.subscription?.amount);
  }

  const [showPdfModal, setShowPdfModal] = useState(false);

  // const handlePdfModalOpen = () => {
  //   setShowPdfModal(true);
  //   return (
  //     <PDFModal
  //       handleClose={handleOnDialogClose}
  //       selectedItems={transactionData}
  //       sourceRequest={"busRoute"}
  //     ></PDFModal>
  //   );
  // };

  const handlePDF = () => {
    setShowPdfModal(true);
  };

  const handleOnDialogClose = () => {
    setShowPdfModal(false);
  };
  const getContent = () => (
    <DialogContent>
      <Typography
        variant="h5"
        marginBottom={"15px"}
        textAlign="start"
        fontWeight="bold"
      >
        View transaction details
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" textAlign="start">
            Transaction number
          </Typography>
          <Typography variant="body1" textAlign="start">
            {transactionData?.transaction?.transactionId}
            {/* 200014530158 */}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" textAlign="start">
            Transaction date
          </Typography>
          <Typography variant="body1" textAlign="start">
            {format(
              new Date(transactionData?.subscription?.updatedAt),
              "MMMM D YYYY h:mm a"
            )}
            {/* April 20 2022 10:41 am */}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" textAlign="start">
            Reference number
          </Typography>
          <Typography variant="body1" textAlign="start">
            {transactionData?.transaction?.referenceNumber}
            {/* 211014051243 */}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" textAlign="start">
            Total
          </Typography>
          <Typography variant="body1" textAlign="start">
            {/* $10 */}${transactionData?.transaction?.authorizedAmount}
          </Typography>
        </Grid>
      </Grid>
      <Divider
        sx={{
          borderColor: "gray",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      />
      <Typography
        variant="h5"
        marginBottom={"15px"}
        textAlign="start"
        fontWeight="bold"
      >
        Payment information
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" textAlign="start">
            Payment Method
          </Typography>
          <Typography variant="body1" textAlign="start">
            Credit Card ending in{" "}
            {transactionData?.subscription?.paymentMethod?.number.slice(-4)}
            {/* 0014 */}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" textAlign="start">
            Billing Address
          </Typography>
          <Typography variant="body1" textAlign="start">
            {
              transactionData?.subscription?.paymentMethod?.address
                ?.streetAddress1
            }
          </Typography>
          <Typography variant="body1" textAlign="start">
            {transactionData?.subscription?.paymentMethod?.address?.city}
            {", "}
            {
              transactionData?.subscription?.paymentMethod?.address?.province
            }{" "}
            {transactionData?.subscription?.paymentMethod?.address?.postalCode}{" "}
          </Typography>
          <Typography variant="body1" textAlign="start">
            {transactionData?.subscription?.paymentMethod?.address?.country}
          </Typography>
        </Grid>
      </Grid>
      <Divider
        sx={{
          borderColor: "gray",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      />
      <Typography
        variant="h5"
        marginBottom={"15px"}
        textAlign="start"
        fontWeight="bold"
      >
        Payment summary
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" textAlign="start">
            Monthly subscription
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Divider color="white" light />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body1" textAlign="start">
            Subscription duration
          </Typography>
          <b />
          <Typography variant="body1" textAlign="start">
            Subscription fee
          </Typography>
          <b />
          {transactionData?.subscription?.discount
            ? Number(transactionData?.subscription?.discount) > 0 && (
                <Typography variant="body1" textAlign="start">
                  Discount
                  <b />
                </Typography>
              )
            : null}
          {/* <Typography variant="body1" textAlign="start">
            Discount
            <b />
          </Typography> */}
          <Typography
            variant="body1"
            fontSize={"20px"}
            textAlign="start"
            fontWeight="bold"
          >
            Total
          </Typography>
          <b />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body1" textAlign="start">
            1 month
          </Typography>
          <b />
          <Typography variant="body1" textAlign="start">
            ${transactionData?.subscription?.amount}
            {/* 10 */}
          </Typography>
          <b />
          {transactionData?.subscription?.discount
            ? Number(transactionData?.subscription?.discount) > 0 && (
                <Typography variant="body1" textAlign="start">
                  -${discountedFee}
                </Typography>
              )
            : null}
          {/* <Typography variant="body1" textAlign="start">
            -$3
          </Typography> */}
          <Typography
            variant="body1"
            textAlign="start"
            fontWeight="bold"
            fontSize={"20px"}
            color={"primary"}
          >
            {/* $7 */}${transactionData?.transaction?.authorizedAmount}
          </Typography>
        </Grid>
      </Grid>
    </DialogContent>
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      {showPdfModal && (
        <PDFModal
          handleClose={handleOnDialogClose}
          selectedItems={transactionData}
          sourceRequest={"transaction"}
        ></PDFModal>
      )}
      {getContent()}
      <DialogActions>
        <IconButton onClick={handlePDF}>
          <PrintIcon color="primary" />
          <Typography variant="caption" color="primary" fontSize={"20px"}>
            Print
          </Typography>
        </IconButton>
        <Button onClick={onClose}>Dismiss</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TransactionModal;
