import { Typography, colors } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { useGetStudentQuery } from "../../apollo/students/queries.generated";
import InfoSection, { InfoItem } from "../../shared/components/InfoSection";
import PageContainer from "../../shared/components/PageContainer";
import LoadingPage from "../home/LoadingPage";

import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import SchoolIcon from "@mui/icons-material/School";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { LocationCityRounded } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import ChromeReaderModeIcon from "@mui/icons-material/ChromeReaderMode";
import GroupIcon from "@mui/icons-material/Group";

interface StudentPageProps {}

const StudentPage: React.FC<StudentPageProps> = ({}) => {
  let { id } = useParams<{ id: string }>();

  const { data, loading, error } = useGetStudentQuery({
    variables: { id: id },
  });

  if (error) return <div>Error</div>;
  if (!data) return <LoadingPage />;

  const isVerified = data.student.accountStatus === "Unverified" ? false : true;

  const items: InfoItem[] = [
    {
      icon: `${data.student.user.name.split(" ")[0][0]}${
        data.student.user.name.split(" ")[1][0]
      }`,
      label: "Name",
      value: `${data.student.user.name}`,
      color: colors.orange[500],
    },
    {
      icon: <EmailRoundedIcon />,
      label: "Email",
      value: `${data.student.user.email}`,
      color: colors.yellow[700],
    },
    {
      icon: <AccountCircleOutlinedIcon />,
      label: "Role",
      value: `${data.student.user.role}`,
      color: colors.lightBlue[200],
    },
    {
      icon: <ChromeReaderModeIcon />,
      label: "Student ID",
      value: `${data.student.studentId || "None"}`,
      color: colors.indigo[200],
    },
  ];

  if (data.student.schoolSystem)
    items.push({
      icon: <LocationCityRounded />,
      label: "SchoolSystem",
      value: `${data.student.schoolSystem.name}`,
      color: colors.lightGreen[500],
    });

  if (data.student.school)
    items.push({
      icon: <SchoolIcon />,
      label: "School ",
      value: `${data.student.school.name}`,
      color: colors.brown[300],
    });

  items.push({
    icon: isVerified ? <CheckCircleIcon /> : <CancelRoundedIcon />,
    label: "Student Verification",
    value: `${data.student.accountStatus}`,
    color: isVerified ? colors.green[500] : colors.red[500],
  });

  if (data.student.user.family)
    items.push({
      icon: <GroupIcon />,
      label: "Family ",
      value: `${data.student.user.family.name}`,
      color: colors.teal[300],
    });

  return (
    <PageContainer>
      <Typography
        variant="h5"
        textAlign="start"
        fontWeight="bold"
        color="secondary"
        sx={{ marginRight: "10px" }}
      >
        Student Information
      </Typography>

      <InfoSection items={items} />
    </PageContainer>
  );
};
export default StudentPage;
