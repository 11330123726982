import { Box, Card, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  DeleteSchoolDocument,
  GetSchoolsPaginatedDocument,
  SchoolsPaginatedItemFragment,
} from "../../apollo/schools/queries.generated";

import DeleteModal from "../../shared/components/DeleteModal";
import {
  DeleteStudentDocument,
  GetStudentsPaginatedDocument,
  StudentsPaginatedItemFragment,
} from "../../apollo/students/queries.generated";
import StudentsTable from "./components/StudentsTable";
import StudentsFormModal from "./components/StudentFormModal";
import PageContainer from "../../shared/components/PageContainer";
import TableCard from "../../shared/components/TableCard";

interface StudentsPageProps {}

const StudentsPage: React.FC<StudentsPageProps> = ({}) => {
  const [showFormModal, setShowFormModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedStudent, setSelectedStudent] =
    useState<StudentsPaginatedItemFragment>();

  const handleFormClose = () => {
    setSelectedStudent(undefined);
    setShowFormModal(false);
  };

  const handleDeleteModalClose = () => {
    setSelectedStudent(undefined);
    setShowDeleteModal(false);
  };
  return (
    <PageContainer>
      <StudentsFormModal
        editingStudent={selectedStudent}
        open={showFormModal}
        onClose={handleFormClose}
      />
      <DeleteModal
        open={showDeleteModal}
        mutation={DeleteStudentDocument}
        updateQueries={[GetStudentsPaginatedDocument]}
        variables={{ id: selectedStudent?.id }}
        onClose={handleDeleteModalClose}
        title="Delete Student"
      />

      <TableCard>
        <Typography
          variant="h5"
          textAlign="start"
          fontWeight="bold"
          color="secondary"
          gutterBottom
        >
          Students
        </Typography>
        <StudentsTable
          onAddClick={() => setShowFormModal(true)}
          onEditPress={(item) => {
            setSelectedStudent(item);
            setShowFormModal(true);
          }}
          onDeletePress={(item) => {
            setSelectedStudent(item);
            setShowDeleteModal(true);
          }}
        />
        {/* <DataGrid
        style={{
          border: "none",
        }}
        columns={columns}
        rows={data?.StudentsystemsPaginated.nodes || []}
      /> */}
      </TableCard>
    </PageContainer>
  );
};

export default StudentsPage;
