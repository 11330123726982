import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Alert,
  LinearProgress,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Slide,
} from "@mui/material";
import {
  MyPaymentMethodFragment,
  MySubscriptionDocument,
  MySubscriptionFragment,
  useStartSubscriptionMutation,
  useStartTrialSubscriptionMutation,
} from "../../../apollo/parents/query.generated";
import { FiPlus } from "react-icons/fi";
import FamilySubscriptionSection from "../../families/components/FamilySubscriptionSection";
import moment from "moment";
import TransactionModal from "./TransactionModal";
import { TransitionProps } from "@mui/material/transitions";
import toast from "react-hot-toast";
import LoadingPage from "../../home/LoadingPage";
import { LoadingButton } from "@mui/lab";

type Props = {
  paymentMethod?: MyPaymentMethodFragment | null;
  loading?: boolean;
  subscriptionStatus?: MySubscriptionFragment | null;
  systemSchoolPaymentMethod?: string | null;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SubscriptionStatusSection = ({
  paymentMethod,
  loading,
  subscriptionStatus,
  systemSchoolPaymentMethod,
}: Props) => {
  const [
    startSubscription,
    { data, error: paidSubError, loading: paidSubLoading },
  ] = useStartSubscriptionMutation();
  const [startTrialSubscription, { error: trialSubError }] =
    useStartTrialSubscriptionMutation();
  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);

  const handleOnClickStartSubscription = async () => {
    setDisabledButton(true);
    try {
      await startSubscription({
        refetchQueries: [{ query: MySubscriptionDocument }],
      });
      setOpenDialog(false);
      setDisabledButton(false);
    } catch (e) {
      const message = paidSubError?.graphQLErrors[0].extensions?.code;
      setOpenDialog(false);
      setDisabledButton(false);
      toast.error(
        `Error encountered trying to start your subscription: ${message}`
      );
    }
  };

  const handleOnClickStartTrialSubscription = async () => {
    setDisabledButton(true);
    try {
      await startTrialSubscription({
        refetchQueries: [{ query: MySubscriptionDocument }],
      });
      setOpenDialog(false);
      setDisabledButton(false);
    } catch (e) {
      const message = trialSubError?.graphQLErrors[0].extensions?.code;
      setOpenDialog(false);
      setDisabledButton(false);
      toast.error(
        `Error encountered trying to start your trial subscription: ${message}`
      );
    }
  };

  const handleTransactionModalClose = () => {
    setShowTransactionModal(false);
  };

  useEffect(() => {
    if (data?.startSubscription) {
      setShowTransactionModal(true);
    }
  }, [data]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClickOpenDialog = useCallback(() => {
    setOpenDialog(true);
  }, []);

  const getContent = () => {
    if (loading) {
      return <LinearProgress />;
    }

    if (!paymentMethod && !subscriptionStatus)
      return (
        <Alert
          sx={{ textAlign: "start" }}
          variant="standard"
          severity="warning"
        >
          You need to add a payment method in order to start a subscripton.
        </Alert>
      );

    if (!subscriptionStatus && paymentMethod) {
      return (
        <Box>
          <Alert
            sx={{ textAlign: "start" }}
            variant="standard"
            severity="warning"
          >
            You need to start a subscription in order to see your subscription
            status.
          </Alert>

          <Typography sx={{ textAlign: "start" }}>
            <Button
              onClick={handleOnClickStartTrialSubscription}
              variant="contained"
              color="primary"
              startIcon={<FiPlus />}
              sx={{ marginTop: "10px" }}
            >
              Start Trial Subscription
            </Button>
          </Typography>
        </Box>
      );
    }

    if (subscriptionStatus) {
      return (
        <Box>
          <Grid item xs={12} md={10}>
            <FamilySubscriptionSection
              familySubscription={subscriptionStatus}
              handleOpenUpdateSubscriptionModal={() => {}}
              inAdminPanel={false}
              propFamilyId={""}
              refetchQ={[MySubscriptionDocument]}
              systemSchoolPaymentMethod={systemSchoolPaymentMethod}
            />
          </Grid>
        </Box>
      );
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "start",
        marginTop: "10px",
      }}
    >
      {getContent()}

      {paymentMethod &&
        moment().isAfter(subscriptionStatus?.nextProcessingDate) && (
          <Box marginTop={"20px"}>
            <Alert
              sx={{ textAlign: "start" }}
              variant="standard"
              severity="warning"
            >
              Your subscription is overdue. Please renew your subscription.
            </Alert>

            <Typography sx={{ textAlign: "start" }}>
              <Button
                onClick={handleClickOpenDialog}
                variant="contained"
                color="primary"
                startIcon={<FiPlus />}
                sx={{ marginTop: "10px" }}
              >
                Renew Subscription
              </Button>
            </Typography>
          </Box>
        )}
      <TransactionModal
        open={showTransactionModal}
        transactionData={data?.startSubscription}
        onClose={handleTransactionModalClose}
      />

      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Confirm this action"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to renew your subscription?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} disabled={disabledButton}>
            Cancel
          </Button>
          {/* <Button
            onClick={handleOnClickStartSubscription}
            disabled={disabledButton}
          >
            Confirm
          </Button> */}
          <LoadingButton
            loading={paidSubLoading}
            onClick={handleOnClickStartSubscription}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SubscriptionStatusSection;
