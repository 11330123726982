import { CheckCircle, CheckOutlined } from "@mui/icons-material";
import { Box, Typography, colors } from "@mui/material";
import React from "react";

type Props = {
  text: string;
};

const SuccessInfoPage = ({ text }: Props) => {
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CheckCircle
        color={"success"}
        sx={{
          fontSize: {
            md: "7rem",

            xs: "6rem",
          },
        }}
      />
      <Typography
        sx={{
          fontWeight: "200",
          fontSize: {
            md: "2rem",
            sm: "1.75rem",
            xs: "1.5rem",
          },
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default SuccessInfoPage;
