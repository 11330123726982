import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type VacationPeriodFragment = { id: string, createdAt: any, startDate: any, endDate: any };

export type GetVacationPeriodsQueryVariables = Types.Exact<{
  schoolSystemId?: Types.Maybe<Types.Scalars['String']>;
}>;


export type GetVacationPeriodsQuery = { vacationPeriods: Array<{ id: string, createdAt: any, startDate: any, endDate: any }> };

export type CreateVacationPeriodMutationVariables = Types.Exact<{
  data: Types.CreateVacationPeriodDto;
}>;


export type CreateVacationPeriodMutation = { createVacationPeriod: { id: string } };

export type UpdateVacationPeriodMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  data: Types.UpdateVacationPeriodDto;
}>;


export type UpdateVacationPeriodMutation = { updateVacationPeriod: { id: string } };

export type DeleteVacationPeriodMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteVacationPeriodMutation = { deleteVacationPeriod: boolean };

export const VacationPeriodFragmentDoc = gql`
    fragment VacationPeriod on VacationPeriodDTO {
  id
  createdAt
  startDate
  endDate
}
    `;
export const GetVacationPeriodsDocument = gql`
    query getVacationPeriods($schoolSystemId: String) {
  vacationPeriods(schoolSystemId: $schoolSystemId) {
    ...VacationPeriod
  }
}
    ${VacationPeriodFragmentDoc}`;

/**
 * __useGetVacationPeriodsQuery__
 *
 * To run a query within a React component, call `useGetVacationPeriodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVacationPeriodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVacationPeriodsQuery({
 *   variables: {
 *      schoolSystemId: // value for 'schoolSystemId'
 *   },
 * });
 */
export function useGetVacationPeriodsQuery(baseOptions?: Apollo.QueryHookOptions<GetVacationPeriodsQuery, GetVacationPeriodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVacationPeriodsQuery, GetVacationPeriodsQueryVariables>(GetVacationPeriodsDocument, options);
      }
export function useGetVacationPeriodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVacationPeriodsQuery, GetVacationPeriodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVacationPeriodsQuery, GetVacationPeriodsQueryVariables>(GetVacationPeriodsDocument, options);
        }
export type GetVacationPeriodsQueryHookResult = ReturnType<typeof useGetVacationPeriodsQuery>;
export type GetVacationPeriodsLazyQueryHookResult = ReturnType<typeof useGetVacationPeriodsLazyQuery>;
export type GetVacationPeriodsQueryResult = Apollo.QueryResult<GetVacationPeriodsQuery, GetVacationPeriodsQueryVariables>;
export const CreateVacationPeriodDocument = gql`
    mutation createVacationPeriod($data: CreateVacationPeriodDTO!) {
  createVacationPeriod(data: $data) {
    id
  }
}
    `;
export type CreateVacationPeriodMutationFn = Apollo.MutationFunction<CreateVacationPeriodMutation, CreateVacationPeriodMutationVariables>;

/**
 * __useCreateVacationPeriodMutation__
 *
 * To run a mutation, you first call `useCreateVacationPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVacationPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVacationPeriodMutation, { data, loading, error }] = useCreateVacationPeriodMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateVacationPeriodMutation(baseOptions?: Apollo.MutationHookOptions<CreateVacationPeriodMutation, CreateVacationPeriodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVacationPeriodMutation, CreateVacationPeriodMutationVariables>(CreateVacationPeriodDocument, options);
      }
export type CreateVacationPeriodMutationHookResult = ReturnType<typeof useCreateVacationPeriodMutation>;
export type CreateVacationPeriodMutationResult = Apollo.MutationResult<CreateVacationPeriodMutation>;
export type CreateVacationPeriodMutationOptions = Apollo.BaseMutationOptions<CreateVacationPeriodMutation, CreateVacationPeriodMutationVariables>;
export const UpdateVacationPeriodDocument = gql`
    mutation updateVacationPeriod($id: String!, $data: UpdateVacationPeriodDTO!) {
  updateVacationPeriod(data: $data, id: $id) {
    id
  }
}
    `;
export type UpdateVacationPeriodMutationFn = Apollo.MutationFunction<UpdateVacationPeriodMutation, UpdateVacationPeriodMutationVariables>;

/**
 * __useUpdateVacationPeriodMutation__
 *
 * To run a mutation, you first call `useUpdateVacationPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVacationPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVacationPeriodMutation, { data, loading, error }] = useUpdateVacationPeriodMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateVacationPeriodMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVacationPeriodMutation, UpdateVacationPeriodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVacationPeriodMutation, UpdateVacationPeriodMutationVariables>(UpdateVacationPeriodDocument, options);
      }
export type UpdateVacationPeriodMutationHookResult = ReturnType<typeof useUpdateVacationPeriodMutation>;
export type UpdateVacationPeriodMutationResult = Apollo.MutationResult<UpdateVacationPeriodMutation>;
export type UpdateVacationPeriodMutationOptions = Apollo.BaseMutationOptions<UpdateVacationPeriodMutation, UpdateVacationPeriodMutationVariables>;
export const DeleteVacationPeriodDocument = gql`
    mutation deleteVacationPeriod($id: String!) {
  deleteVacationPeriod(id: $id)
}
    `;
export type DeleteVacationPeriodMutationFn = Apollo.MutationFunction<DeleteVacationPeriodMutation, DeleteVacationPeriodMutationVariables>;

/**
 * __useDeleteVacationPeriodMutation__
 *
 * To run a mutation, you first call `useDeleteVacationPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVacationPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVacationPeriodMutation, { data, loading, error }] = useDeleteVacationPeriodMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVacationPeriodMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVacationPeriodMutation, DeleteVacationPeriodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteVacationPeriodMutation, DeleteVacationPeriodMutationVariables>(DeleteVacationPeriodDocument, options);
      }
export type DeleteVacationPeriodMutationHookResult = ReturnType<typeof useDeleteVacationPeriodMutation>;
export type DeleteVacationPeriodMutationResult = Apollo.MutationResult<DeleteVacationPeriodMutation>;
export type DeleteVacationPeriodMutationOptions = Apollo.BaseMutationOptions<DeleteVacationPeriodMutation, DeleteVacationPeriodMutationVariables>;