import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetStudentsCountQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetStudentsCountQuery = { systemStudentsCount: number };

export type GetBusDriversCountQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetBusDriversCountQuery = { systemBusDriversCount: number };

export type GetSchoolsCountQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSchoolsCountQuery = { systemSchoolsCount: number };

export type GetParentsCountQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetParentsCountQuery = { systemParentsCount: number };


export const GetStudentsCountDocument = gql`
    query getStudentsCount {
  systemStudentsCount
}
    `;

/**
 * __useGetStudentsCountQuery__
 *
 * To run a query within a React component, call `useGetStudentsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStudentsCountQuery(baseOptions?: Apollo.QueryHookOptions<GetStudentsCountQuery, GetStudentsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentsCountQuery, GetStudentsCountQueryVariables>(GetStudentsCountDocument, options);
      }
export function useGetStudentsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentsCountQuery, GetStudentsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentsCountQuery, GetStudentsCountQueryVariables>(GetStudentsCountDocument, options);
        }
export type GetStudentsCountQueryHookResult = ReturnType<typeof useGetStudentsCountQuery>;
export type GetStudentsCountLazyQueryHookResult = ReturnType<typeof useGetStudentsCountLazyQuery>;
export type GetStudentsCountQueryResult = Apollo.QueryResult<GetStudentsCountQuery, GetStudentsCountQueryVariables>;
export const GetBusDriversCountDocument = gql`
    query getBusDriversCount {
  systemBusDriversCount
}
    `;

/**
 * __useGetBusDriversCountQuery__
 *
 * To run a query within a React component, call `useGetBusDriversCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusDriversCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusDriversCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBusDriversCountQuery(baseOptions?: Apollo.QueryHookOptions<GetBusDriversCountQuery, GetBusDriversCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBusDriversCountQuery, GetBusDriversCountQueryVariables>(GetBusDriversCountDocument, options);
      }
export function useGetBusDriversCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBusDriversCountQuery, GetBusDriversCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBusDriversCountQuery, GetBusDriversCountQueryVariables>(GetBusDriversCountDocument, options);
        }
export type GetBusDriversCountQueryHookResult = ReturnType<typeof useGetBusDriversCountQuery>;
export type GetBusDriversCountLazyQueryHookResult = ReturnType<typeof useGetBusDriversCountLazyQuery>;
export type GetBusDriversCountQueryResult = Apollo.QueryResult<GetBusDriversCountQuery, GetBusDriversCountQueryVariables>;
export const GetSchoolsCountDocument = gql`
    query getSchoolsCount {
  systemSchoolsCount
}
    `;

/**
 * __useGetSchoolsCountQuery__
 *
 * To run a query within a React component, call `useGetSchoolsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSchoolsCountQuery(baseOptions?: Apollo.QueryHookOptions<GetSchoolsCountQuery, GetSchoolsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSchoolsCountQuery, GetSchoolsCountQueryVariables>(GetSchoolsCountDocument, options);
      }
export function useGetSchoolsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSchoolsCountQuery, GetSchoolsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSchoolsCountQuery, GetSchoolsCountQueryVariables>(GetSchoolsCountDocument, options);
        }
export type GetSchoolsCountQueryHookResult = ReturnType<typeof useGetSchoolsCountQuery>;
export type GetSchoolsCountLazyQueryHookResult = ReturnType<typeof useGetSchoolsCountLazyQuery>;
export type GetSchoolsCountQueryResult = Apollo.QueryResult<GetSchoolsCountQuery, GetSchoolsCountQueryVariables>;
export const GetParentsCountDocument = gql`
    query getParentsCount {
  systemParentsCount
}
    `;

/**
 * __useGetParentsCountQuery__
 *
 * To run a query within a React component, call `useGetParentsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParentsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParentsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetParentsCountQuery(baseOptions?: Apollo.QueryHookOptions<GetParentsCountQuery, GetParentsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetParentsCountQuery, GetParentsCountQueryVariables>(GetParentsCountDocument, options);
      }
export function useGetParentsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetParentsCountQuery, GetParentsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetParentsCountQuery, GetParentsCountQueryVariables>(GetParentsCountDocument, options);
        }
export type GetParentsCountQueryHookResult = ReturnType<typeof useGetParentsCountQuery>;
export type GetParentsCountLazyQueryHookResult = ReturnType<typeof useGetParentsCountLazyQuery>;
export type GetParentsCountQueryResult = Apollo.QueryResult<GetParentsCountQuery, GetParentsCountQueryVariables>;