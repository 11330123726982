import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Modal,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import PDFDocument from "./PDFDocument";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { BusRouteDto } from "../../../apollo/types.generated";
import PDFDocumentTransaction from "../../parents/components/PDFDocumentTransaction";

interface PDFModalProps {
  handleClose?: () => void;
  selectedItems: any[] | any;
  sourceRequest?: String;
}

const PDFModal: React.FC<PDFModalProps> = ({
  handleClose,
  selectedItems,
  sourceRequest,
}) => {
  const [loading, setLoading] = useState(true);
  const handleLoadingPage = () => {
    setLoading(false);
  };

  var pdfDocument: any;

  switch (sourceRequest) {
    case "busRoute":
      pdfDocument = (
        <PDFDocument
          selectedItems={selectedItems}
          handleLoadingPage={handleLoadingPage}
        />
      );
      break;

    case "transaction":
      pdfDocument = (
        <PDFDocumentTransaction
          selectedItems={selectedItems}
          handleLoadingPage={handleLoadingPage}
        />
      );
      break;
  }

  return (
    <Dialog open fullScreen onClose={handleClose}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Modal open={loading}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Typography
              variant="h5"
              textAlign="start"
              fontWeight="bold"
              color="#FFFFFF"
              sx={{ marginBottom: "30px" }}
            >
              Generating Document
            </Typography>
            <Box>
              <CircularProgress size={100} />
            </Box>
          </Box>
        </Modal>

        <PDFViewer style={{ height: "100%", width: "100%" }}>
          {pdfDocument}
        </PDFViewer>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
};

export default PDFModal;
