import { Grid } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import React from "react";

import {
  GetDisabledSchoolsPaginatedDocument,
  GetSchoolsPaginatedDocument,
  SchoolsPaginatedItemFragment,
} from "../../../apollo/schools/queries.generated";
import { SchoolSystemPaginatedItemFragment } from "../../../apollo/schoolSystems/queries.generated";
import RemoteDataTable, {
  BasicTableActionFunction,
  getBasicActionColumns,
  RemoteDataTableProps,
} from "../../../shared/components/RemoteDataTable";

interface DisabledSystemSchoolsTableProps
  extends Pick<RemoteDataTableProps, "onAddClick" | "refresherBoolean"> {
  schoolSystemId: string;
}
const muiColumns: GridColDef[] = [
  { flex: 1, field: "code", headerName: "Code", minWidth: 200 },
  { flex: 1, field: "name", headerName: "Name", minWidth: 200 },
  { flex: 1, field: "state", headerName: "State", minWidth: 200 },
];

const DisabledSystemSchoolsTable: React.FC<DisabledSystemSchoolsTableProps> = ({
  schoolSystemId,
  ...props
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} minHeight="300px">
        <RemoteDataTable
          query={GetDisabledSchoolsPaginatedDocument}
          variables={{ schoolSystemId }}
          searchable
          columns={muiColumns}
          {...props}
        />
      </Grid>
    </Grid>
  );
};

export default DisabledSystemSchoolsTable;
