import { GridColDef } from "@mui/x-data-grid";
import React from "react";
import {
  GetDisabledSchoolSystemsDocument,
  SchoolSystemPaginatedItemFragment,
} from "../../../apollo/schoolSystems/queries.generated";
import RemoteDataTable, {
  RemoteDataTableProps,
} from "../../../shared/components/RemoteDataTable";
import Link from "../../../navigation/Link";

interface DisabledSchoolSystemsTableProps
  extends Pick<RemoteDataTableProps, "onAddClick" | "refresherBoolean"> {}
const muiColumns: GridColDef[] = [
  {
    flex: 1,
    field: "name",
    headerName: "Name",
    minWidth: 200,
    renderCell: ({ row }) => {
      const schoolSystem = row as SchoolSystemPaginatedItemFragment;
      return (
        <Link
          to={"disabled/schoolsystems/" + schoolSystem.id}
        >{`${schoolSystem?.name}`}</Link>
      );
    },
  },
  {
    flex: 1,
    field: "feePerRoute",
    headerName: "Fee per Route",
    minWidth: 50,
    sortable: false,
  },
  {
    flex: 1,
    field: "routeCount",
    headerName: "Active Routes",
    minWidth: 50,
    sortable: false,
  },
  {
    flex: 1,
    field: "monthlyFee",
    headerName: "Monthly fee",
    minWidth: 50,
    sortable: false,
  },
  {
    flex: 1,
    field: "familiesSubscriptionFee",
    headerName: "Families Subscription Fee",
    minWidth: 200,
    sortable: false,
  },
];

const DisabledSchoolSystemsTable: React.FC<DisabledSchoolSystemsTableProps> = ({
  ...props
}) => {
  return (
    <RemoteDataTable
      query={GetDisabledSchoolSystemsDocument}
      searchable
      columns={muiColumns}
      {...props}
    />
  );
};

export default DisabledSchoolSystemsTable;
