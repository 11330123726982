import { yupResolver } from "@hookform/resolvers/yup";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
  Button,
  InputAdornment,
  Slide,
  DialogContentText,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import {
  UpdateDriverRoutePeriodDto,
  UpdateSubscriptionDto,
} from "../../../apollo/types.generated";
import useDidMountEffect from "../../../shared/hooks/useDidMountEffect";
import ControlledDatePicker from "../../../shared/components/ControlledDatePicker";
import { LocalizationProvider } from "@mui/lab";
import AdapterDayJs from "@mui/lab/AdapterDayjs";
import {
  BusDriversPaginatedItemFragment,
  DriverRoutePeriodFragment,
  GetRouteDriversDocument,
  useCreateDriverRoutePeriodMutation,
  useDeleteDriverRoutePeriodMutation,
  useUpdateDriverRoutePeriodMutation,
} from "../../../apollo/busDrivers/queries.generated";
import ControlledSelectModalField from "../../../shared/components/ControlledSelectModalField";
import { GetBusDriversPaginatedDocument } from "../../../apollo/busDrivers/queries.generated";
import { FaSave, FaTrashAlt } from "react-icons/fa";
import { TransitionProps } from "@mui/material/transitions";

interface SubstituteDriverModalProps {
  open: boolean;
  onClose: () => void;
  substituteDriver?: DriverRoutePeriodFragment;
  actualDriver?: any;
  routeId: string;
}

interface FormInputs extends UpdateDriverRoutePeriodDto {
  id?: string;
  actualDriverId?: string;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const getSchema = (actualDriver?: any): Yup.SchemaOf<FormInputs> =>
  Yup.object({
    id: Yup.string().optional(),
    actualDriverId: Yup.string().optional(),
    busDriverId: Yup.string()
      .required("This field is required")
      .test(
        "busDriverId",
        "This bus driver is already assigned to this route",
        (value) => {
          return value !== actualDriver?.id;
        }
      ),
    startTime: Yup.date()
      .required("This field is required")
      .default(() => new Date()),
    endTime: Yup.date()
      .required("This field is required")
      .default(() => new Date(Date.now() + 3600 * 1000 * 24))
      .min(Yup.ref("startTime"), "Final date must be later than initial date"),
  });

const getDefaultValues = (substituteDriver?: {
  id: string;
  busDriver: BusDriversPaginatedItemFragment;
  routePeriodStart: Date;
  routePeriodEnd: Date;
}): FormInputs => {
  if (!substituteDriver) {
    return {
      busDriverId: "",
      startTime: new Date(),
      endTime: new Date(Date.now() + 3600 * 1000 * 24),
    };
  }
  return {
    id: substituteDriver.id,
    busDriverId: substituteDriver.busDriver.id,
    startTime: substituteDriver.routePeriodStart,
    endTime: substituteDriver.routePeriodEnd,
  };
};

const SubstituteDriverModal: React.FC<SubstituteDriverModalProps> = ({
  onClose,
  open,
  substituteDriver,
  routeId,
  actualDriver,
}) => {
  const { control, handleSubmit, setValue, reset } = useForm<FormInputs>({
    resolver: yupResolver(getSchema(actualDriver)),
    shouldFocusError: true,
    defaultValues: getDefaultValues(substituteDriver),
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [createDriverRoutePeriodMutation] =
    useCreateDriverRoutePeriodMutation();
  const [updateDriverRoutePeriodMutation] =
    useUpdateDriverRoutePeriodMutation();
  const [deleteDriverRoutePeriodMutation] = useDeleteDriverRoutePeriodMutation({
    variables: {
      id: substituteDriver?.id || "",
    },
    refetchQueries: [
      {
        query: GetRouteDriversDocument,
        variables: { routeId: substituteDriver?.busRoute.id || "" },
      },
    ],
  });

  useEffect(() => {
    if (substituteDriver) {
      setValue(
        "busDriverId",
        substituteDriver.busDriver.user.name +
          " " +
          substituteDriver.busDriver.user.lastName
      );
      setValue("startTime", substituteDriver.routePeriodStart);
      setValue("endTime", substituteDriver.routePeriodEnd);
    }
  }, [setValue]);

  useDidMountEffect(() => {
    reset(getDefaultValues(substituteDriver));
  }, [substituteDriver, reset]);

  const OnSubmit = async (data: FormInputs) => {
    if (substituteDriver) {
      const updateSubstiteDriver: UpdateDriverRoutePeriodDto = {
        busDriverId: data.busDriverId,
        startTime: data.startTime,
        endTime: data.endTime,
      };
      const updateDriverRoutePeriod = await updateDriverRoutePeriodMutation({
        variables: {
          id: substituteDriver.id,
          data: updateSubstiteDriver,
        },
        refetchQueries: [
          {
            query: GetRouteDriversDocument,
            variables: { routeId: routeId || "" },
          },
        ],
      });
      onClose();
      return updateDriverRoutePeriod;
    }
    const createDriverRoutePeriod = await createDriverRoutePeriodMutation({
      variables: {
        data: {
          routeId: routeId,
          busDriverId: data.busDriverId,
          startTime: data.startTime,
          endTime: data.endTime,
        },
      },
      refetchQueries: [
        {
          query: GetRouteDriversDocument,
          variables: { routeId: routeId || "" },
        },
      ],
    });
    onClose();
    return createDriverRoutePeriod;
  };

  const handleClickOpenDialog = useCallback(() => {
    setOpenDialog(true);
  }, []);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDeleteSubstituteDriver = async () => {
    await deleteDriverRoutePeriodMutation();
    setOpenDialog(false);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { width: "400px" } }}
    >
      <DialogTitle>Manage Substitute Bus Driver</DialogTitle>
      <form onSubmit={handleSubmit(OnSubmit)} autoComplete="off">
        <DialogContent>
          <Grid
            container
            sx={{ marginTop: "10px", textAlign: "center" }}
            spacing={2}
          >
            <Grid item xs={12}>
              <ControlledSelectModalField
                name="busDriverId"
                control={control}
                initialValue={
                  substituteDriver?.busDriver as BusDriversPaginatedItemFragment
                }
                textFieldProps={{
                  label: "Bus Driver",
                  fullWidth: true,
                }}
                query={GetBusDriversPaginatedDocument}
                labelsExractor={(item: BusDriversPaginatedItemFragment) => ({
                  primary: `${item.user.firstName} ${item.user.lastName}`,
                })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayJs}>
                <ControlledDatePicker
                  name={`startTime`}
                  control={control}
                  label="Driver Initial Date"
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayJs}>
                <ControlledDatePicker
                  name={`endTime`}
                  control={control}
                  label="Driver Final Date"
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {substituteDriver && (
            <Button
              startIcon={<FaTrashAlt />}
              sx={{ fontSize: "18px", marginRight: "10px" }}
              onClick={handleClickOpenDialog}
            >
              Delete
            </Button>
          )}
          <Button
            type="submit"
            startIcon={<FaSave />}
            sx={{ fontSize: "18px", marginRight: "10px" }}
          >
            Save
          </Button>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </form>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Confirm this action"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this substitute bus driver?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleDeleteSubstituteDriver}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default SubstituteDriverModal;
