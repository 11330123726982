import {
  AppBar,
  Box,
  Divider,
  IconButton,
  Toolbar,
  Typography,
  Drawer as MuiDrawer,
  styled,
  Theme,
  CSSObject,
  Grid,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import DashboardRoutes from "./DashboardRoutes";
import useAuthStore from "../shared/stores/authStore";
import { Close, Home, HomeOutlined, Person } from "@mui/icons-material";
import { FiHome } from "react-icons/fi";
import Drawer, { drawerWidth } from "./Drawer";
import NavBar from "./NavBar";

interface MainProps {}

const Main: React.FC<MainProps> = ({}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  return (
    <Box sx={{ display: "flex", flex: 1 }}>
      <Drawer isOpen={drawerOpen} onCloseClick={() => setDrawerOpen(false)} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          transition: (theme: Theme) =>
            theme.transitions.create("margin", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          marginLeft: {
            md: drawerOpen ? 0 : `-${drawerWidth}px`,
            lg: 0,
          },
          width: {
            // sm: `calc(100% - ${drawerWidth})px`,
            // md: "",
            // lg: `calc(100% - ${drawerWidth})px`,
          },
        }}
      >
        <NavBar onMenuClick={() => setDrawerOpen(true)} isOpen={drawerOpen} />
        <DashboardRoutes />
      </Box>
    </Box>
  );
};

export default Main;
