import { yupResolver } from "@hookform/resolvers/yup";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
  Button,
  InputAdornment,
} from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { UpdateSubscriptionDto } from "../../../apollo/types.generated";
import ControlledTextField from "../../../shared/components/ControlledTextField";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { useUpdateSubscriptionMutation } from "../../../apollo/parents/query.generated";
import useDidMountEffect from "../../../shared/hooks/useDidMountEffect";
import { GetFamilyDocument } from "../../../apollo/families/query.generated";
import ControlledDatePicker from "../../../shared/components/ControlledDatePicker";
import { LocalizationProvider } from "@mui/lab";
import AdapterDayJs from "@mui/lab/AdapterDayjs";
import toast from "react-hot-toast";

interface SubscriptionUpdateModalProps {
  open: boolean;
  onClose: () => void;
  subscription?: {
    famId: string;
    subId: string;
    amount: number;
    discount: number;
    trialEndDate: Date;
    status: string;
  };
}

interface FormInputs extends UpdateSubscriptionDto {
  id?: string;
}

const getSchema = (): Yup.SchemaOf<FormInputs> =>
  Yup.object({
    id: Yup.string().optional(),
    amount: Yup.number().required("This field is required"),
    discount: Yup.number().required("This field is required"),
    trialEndDate: Yup.date().required("This field is required"),
  });

const getDefaultValues = (subscription?: {
  subId: string;
  amount: number;
  discount: number;
  trialEndDate: Date;
}): FormInputs => {
  if (!subscription) {
    return {
      amount: 0,
      discount: 0,
      trialEndDate: new Date(),
    };
  }
  return {
    id: subscription.subId,
    amount: subscription.amount,
    discount: subscription.discount * 100,
    trialEndDate: subscription.trialEndDate,
  };
};

const SubscriptionUpdateModal: React.FC<SubscriptionUpdateModalProps> = ({
  onClose,
  open,
  subscription,
}) => {
  const { control, handleSubmit, setValue, reset } = useForm<FormInputs>({
    resolver: yupResolver(getSchema()),
    shouldFocusError: true,
    defaultValues: getDefaultValues(subscription),
  });

  const [updateSubscriptionMutation] = useUpdateSubscriptionMutation();

  useEffect(() => {
    if (subscription) {
      setValue("amount", subscription.amount);
      setValue("discount", subscription.discount);
      setValue("trialEndDate", subscription.trialEndDate);
    }
  }, [setValue, subscription]);

  useDidMountEffect(() => {
    reset(getDefaultValues(subscription));
  }, [subscription, reset]);

  const onSubmit = async (data: FormInputs) => {
    const updateSubscription: UpdateSubscriptionDto = {
      amount: data.amount,
      discount: data.discount / 100,
      trialEndDate: data.trialEndDate,
    };

    if (data.amount < 1) {
      toast.error("Fee amount must be greater or equal to $1.00");
      return;
    }
    await updateSubscriptionMutation({
      variables: {
        id: data.id || "",
        data: updateSubscription,
      },
      refetchQueries: [
        { query: GetFamilyDocument, variables: { id: subscription?.famId } },
      ],
    });
    reset();
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { width: "400px" } }}
    >
      <DialogTitle>Update Subscription</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <DialogContent>
          <Grid
            container
            sx={{ marginTop: "10px", textAlign: "center" }}
            spacing={2}
          >
            <Grid item xs={12} md={6}>
              <ControlledTextField
                name="amount"
                control={control}
                label="Fee amount"
                value={subscription?.amount}
                // sx={{ m: 1, width: "25ch" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachMoneyIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ControlledTextField
                name="discount"
                control={control}
                label="Discount"
                value={subscription?.discount}
                // sx={{ m: 1, width: "25ch" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </Grid>
            {subscription?.status === "Trial" && (
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayJs}>
                  <ControlledDatePicker
                    name={`trialEndDate`}
                    control={control}
                    label="Trial end date"
                  />
                </LocalizationProvider>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="submit" sx={{ fontSize: "18px", marginRight: "10px" }}>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default SubscriptionUpdateModal;
