import {
  Avatar,
  Grid,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import { Draggable } from "react-beautiful-dnd";

import ArticleIcon from "@mui/icons-material/Article";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PhotoCameraBackIcon from "@mui/icons-material/PhotoCameraBack";
import { Delete, Edit } from "@mui/icons-material";

const useStyles = makeStyles({
  draggingListItem: {
    background: "rgb(235,235,235)",
  },
});

export type DraggableListItemProps = {
  item: any;
  type?: string;
  index: number;
  renderActionButtons?: boolean;
  onEditPress?: (item: any) => void;
  onDeletePress?: (item: any) => void;
};

const DraggableListItem = ({
  item,
  type,
  index,
  renderActionButtons,
  onEditPress,
  onDeletePress,
}: DraggableListItemProps) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item xs={renderActionButtons ? 11 : 12}>
        <Draggable draggableId={item.id} index={index}>
          {(provided, snapshot) => (
            <ListItem
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className={snapshot.isDragging ? classes.draggingListItem : ""}
              sx={{
                border: "1.5px solid rgba(0, 0, 0, 0.12)",
                borderRadius: "10px",
                marginTop: "10px",
              }}
            >
              <ListItemAvatar>
                <Avatar>
                  {type === "infoText" && <ArticleIcon />}
                  {type === "text" && <FormatColorTextIcon />}
                  {type === "number" && <LooksOneIcon />}
                  {type === "phone" && <LocalPhoneIcon />}
                  {type === "email" && <EmailIcon />}
                  {type === "multiline" && <FormatAlignJustifyIcon />}
                  {type === "radio" && <RadioButtonCheckedIcon />}
                  {type === "dropdown" && <ArrowDropDownCircleIcon />}
                  {type === "checkbox" && <CheckBoxIcon />}
                  {type === "date" && <DateRangeIcon />}
                  {type === "time" && <AccessTimeIcon />}
                  {type === "images" && <PhotoCameraBackIcon />}
                </Avatar>
              </ListItemAvatar>
              <ListItemText>
                <span style={{ fontWeight: "bold" }}>{item.primary}</span>
                <span style={{ color: "gray", fontSize: "13px" }}>
                  {item.secondary}
                </span>
              </ListItemText>
            </ListItem>
          )}
        </Draggable>
      </Grid>
      {renderActionButtons && (
        <Grid item xs={1}>
          <IconButton
            onClick={() => {
              if (onEditPress) onEditPress(item.id);
            }}
          >
            <Edit />
          </IconButton>

          <IconButton
            onClick={() => {
              if (onDeletePress) onDeletePress(item.id);
            }}
            color="error"
          >
            <Delete />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

export default DraggableListItem;
