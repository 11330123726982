import React from "react";
import { useRouteMatch, Switch, Route } from "react-router-dom";
import BusDriversPage from "../pages/busDrivers/BusDriversPage";
import BusRouteForm from "../pages/busRoutes/BusRouteFormPage";
import BusRoutePage from "../pages/busRoutes/BusRoutePage";
import BusRoutesPage from "../pages/busRoutes/BusRoutesPage";
import DashboardHomePage from "../pages/home/DashboardHomePage";
import SchoolsPage from "../pages/schools/SchoolsPage";
import SchoolSystemsPage from "../pages/schoolSystems/SchoolSystemsPage";
import StudentsPage from "../pages/students/StudentsPage";
import ParentsPage from "../pages/parents/ParentsPage";
import ParentPage from "../pages/parents/ParentPage";
import UsersPage from "../pages/users/UsersPage";
import UserPage from "../pages/users/UserPage";
import StudentsStopsAssignerPage from "../pages/busRoutes/StudentsStopsAssignerPage";
import SchoolSystemSettingsPage from "../pages/schoolSystems/SchoolSystemSettingsPage";
import CurrentRoutesPage from "../pages/busRoutes/CurrentRoutesPage";
import FamiliesPage from "../pages/families/FamiliesPage";
import VacationPeriodsPage from "../pages/vacationPeriods/VacationPeriodsPage";
import StudentPage from "../pages/students/StudentPage";
import BusDriverPage from "../pages/busDrivers/BusDriverPage";
import MySubscriptionPage from "../pages/parents/MySubscriptionPage";
import FamilyDetailsPage from "../pages/families/components/FamilyDetailsPage";
import TransactionsPage from "../pages/transactions/TransactionsPage";
import DisabledSchoolSystemInfoPage from "../pages/schoolSystems/components/DisabledSchoolSystemInfo";
import FormsPage from "../pages/forms/FormsPage";
import FormPage from "../pages/forms/FormPage";

interface DashboardRoutesProps {}

const DashboardRoutes: React.FC<DashboardRoutesProps> = ({}) => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={path} exact>
        <DashboardHomePage />
      </Route>

      <Route path={path + "/schoolSystem"} exact>
        <SchoolSystemsPage />
      </Route>
      <Route path={path + "/mySubscription"} exact>
        <MySubscriptionPage />
      </Route>
      <Route path={path + "/schoolSystem/settings"} exact>
        <SchoolSystemSettingsPage />
      </Route>
      <Route path={path + "/schools"} exact>
        <SchoolsPage />
      </Route>
      <Route path={path + "/students"} exact>
        <StudentsPage />
      </Route>
      <Route path={path + "/students/:id"} exact>
        <StudentPage />
      </Route>
      <Route path={path + "/busDrivers"} exact>
        <BusDriversPage />
      </Route>
      <Route path={path + "/busDrivers/:id"} exact>
        <BusDriverPage />
      </Route>
      <Route path={path + "/parents"} exact>
        <ParentsPage />
      </Route>
      <Route path={path + "/parents/:id"} exact>
        <ParentPage />
      </Route>
      <Route path={path + "/families"} exact>
        <FamiliesPage />
      </Route>
      <Route path={path + "/families/:id"} exact>
        <FamilyDetailsPage />
      </Route>
      <Route path={path + "/busRoutes"} exact>
        <BusRoutesPage />
      </Route>
      <Route path={path + "/busRoutes/live"} exact>
        <CurrentRoutesPage />
      </Route>
      <Route path={path + "/busRoutes/create"} exact>
        <BusRouteForm />
      </Route>
      <Route path={path + "/busRoutes/:id"} exact>
        <BusRoutePage />
      </Route>
      <Route path={path + "/busRoutes/:id/edit"} exact>
        <BusRouteForm isEdit={true} />
      </Route>
      <Route path={path + "/busRoutes/:id/assign"} exact>
        <StudentsStopsAssignerPage />
      </Route>

      <Route path={path + "/vacationPeriods"} exact>
        <VacationPeriodsPage />
      </Route>
      <Route path={path + "/users"} exact>
        <UsersPage />
      </Route>
      <Route path={path + "/users/:id"} exact>
        <UserPage />
      </Route>
      <Route path={path + "/transactions"} exact>
        <TransactionsPage />
      </Route>
      <Route path={path + "/disabled/schoolsystems/:id"} exact>
        <DisabledSchoolSystemInfoPage />
      </Route>
      <Route path={path + "/forms"} exact>
        <FormsPage />
      </Route>
      <Route path={path + "/forms/:id"} exact>
        <FormPage />
      </Route>
    </Switch>
  );
};

export default DashboardRoutes;
