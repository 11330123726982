import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";

interface FormErrorDialogProps {
  errors?: any;
}

const FormErrorDialog: React.FC<FormErrorDialogProps> = ({ errors }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(!_.isEmpty(errors));

    return () => {};
  }, [_.isEmpty(errors)]);

  return (
    <Dialog open={open}>
      <DialogTitle>Form Errors!</DialogTitle>
      <DialogContent>
        <DialogContentText>
          There are errors in this form. Please verify all fields.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Dismiss</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormErrorDialog;
