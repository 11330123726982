import { Box } from "@mui/material";
import React from "react";

interface PageContainerProps {}

const PageContainer: React.FC<PageContainerProps> = ({ children }) => {
  return (
    <Box
      paddingX={3}
      display="flex"
      flexDirection="column"
      flex="1"
      marginBottom="5%"
    >
      {children}{" "}
    </Box>
  );
};

export default PageContainer;
