import { GridColDef } from "@mui/x-data-grid";
import React, { useMemo } from "react";
import { timezoneAwareFormat } from "../../../shared/components/TimezoneAwareDatetime";
import {
  FormResponseFragment,
  FormsPaginatedItemFragment,
  GetFormResponsesPaginatedDocument,
  GetFormsPaginatedDocument,
} from "../../../apollo/forms/queries.generated";
import RemoteDataTable, {
  BasicTableActionFunction,
  RemoteDataTableProps,
} from "../../../shared/components/RemoteDataTable";
import { FormResponseDto } from "../../../apollo/types.generated";
import { Button, IconButton } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import Link from "../../../navigation/Link";
import { UsersPaginatedItemFragment } from "../../../apollo/users/queries.generated";
import FormResponseDataTable from "./FormsDataTable";

interface FormResponsesTableProps
  extends Pick<RemoteDataTableProps, "onAddClick" | "refresherBoolean"> {
  onNamePress?: BasicTableActionFunction<FormResponseFragment>;
  formId: string;
}
//   {
//   formId: string;
//   onNamePress?: (item: any) => void;

// }
const muiColumns: GridColDef[] = [
  //   {
  //     flex: 1,
  //     field: "name",
  //     headerName: "User",
  //     minWidth: 200,
  //     renderCell: ({ row, value }) => {
  //       const response = row as FormResponseDto;
  //       return <Button
  //       >{response?.user!.name}</Button>;
  //     },
  //   },
];

const FormResponsesTable: React.FC<FormResponsesTableProps> = ({
  formId,
  onNamePress,
  ...props
}) => {
  const actionColumns = useMemo(
    () => getActionColumns(onNamePress),
    [onNamePress]
  );
  return (
    <FormResponseDataTable
      query={GetFormResponsesPaginatedDocument}
      variables={{
        formId: formId,
      }}
      searchable
      columns={muiColumns}
      actionColumns={actionColumns}
      {...props}
    />
  );
};

const getActionColumns = <FormResponseFragment,>(
  onNamePress?: BasicTableActionFunction<FormResponseFragment>
): GridColDef[] => [
  {
    // flex: 1,
    field: "actions",
    align: "left",
    headerName: "User",
    minWidth: 300,
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => (
      <>
        <Button
          onClick={() => {
            if (onNamePress) onNamePress(row as FormResponseFragment);
          }}
        >
          {`${row?.user!.role}:`} {row?.user!.name}
        </Button>
      </>
    ),
  },
];

export default FormResponsesTable;
