import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  RadioGroup,
  FormControlLabelProps as RadioButtonsControlLabelProps,
  styled,
  FormControlLabel,
  useRadioGroup,
  Radio,
  Typography,
  Divider,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import {
  GetTransactionPaginatedDocument,
  useGetTransactionIdQuery,
  useRefundTransactionMutation,
  useVoidTransactionMutation,
} from "../../../apollo/transactions/queries.generated";

interface RefundVoidTransactionsModalProps {
  open: boolean;
  onClose: () => void;
}

interface StyledFormControlLabelProps extends RadioButtonsControlLabelProps {
  checked: boolean;
}

const StyledRadioButtonsControlLabel = styled(
  (props: StyledFormControlLabelProps) => <FormControlLabel {...props} />
)(({ theme, checked }) => ({
  ".MuiFormControlLabel-label": checked && {
    color: theme.palette.primary.main,
  },
}));

const RefundVoidTransactionsModal: React.FC<
  RefundVoidTransactionsModalProps
> = ({ onClose, open }) => {
  const [transactionType, setTransactionType] =
    React.useState<string>("refund");
  const [transactionId, setTransactionId] = React.useState<string>("");
  const [transactionAmount, setTransactionAmount] = React.useState<string>("");
  const getTransaction = useGetTransactionIdQuery({
    variables: { transactionId: transactionId },
  });
  const [refundTransaction, { loading: refundLoading }] =
    useRefundTransactionMutation({
      refetchQueries: [GetTransactionPaginatedDocument],
    });

  const [voidTransaction, { loading: voidLoading }] =
    useVoidTransactionMutation({
      refetchQueries: [GetTransactionPaginatedDocument],
    });

  function TransactionTypeControlLabel(props: RadioButtonsControlLabelProps) {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
      checked = radioGroup.value === props.value;
    }

    useEffect(() => {
      if (radioGroup) {
        setTransactionType(radioGroup.value);
      }
    }, [radioGroup?.value]);

    return <StyledRadioButtonsControlLabel checked={checked} {...props} />;
  }

  useEffect(() => {
    setTransactionId("");
    setTransactionAmount("");
  }, [transactionType]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (transactionType === "refund") {
      if (transactionId === "") {
        toast.error("Error: Please enter a transaction number");
        return;
      }
      if (transactionAmount === "") {
        toast.error("Error: Please enter a transaction amount");
        return;
      }
      getTransaction.refetch();
      if (getTransaction.data?.getTransactionId) {
        if (
          Number(transactionAmount) <=
          getTransaction.data.getTransactionId.amount
        ) {
          refundTransaction({
            variables: {
              transactionId: transactionId,
              amount: Number(transactionAmount),
            },
            refetchQueries: [GetTransactionPaginatedDocument],
          });
          toast.success("Transaction refunded successfully");
          onClose();
        } else {
          toast.error(
            "Error: Refund Amount is greater than the transaction amount"
          );
        }
      } else {
        toast.error(
          "Error: Transaction Number not found or hasn't been approved or already refunded/voided"
        );
      }
    }

    if (transactionType === "void") {
      if (transactionId === "") {
        toast.error("Error: Please enter a transaction number");
        return;
      }
      getTransaction.refetch();
      if (getTransaction.data?.getTransactionId) {
        voidTransaction({
          variables: {
            transactionId: transactionId,
          },
          refetchQueries: [GetTransactionPaginatedDocument],
        });
        toast.success("Transaction voided successfully");
        onClose();
      } else {
        toast.error(
          "Error: Transaction Number not found or already refunded/voided"
        );
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { width: "800px" } }}
      fullWidth
    >
      <DialogTitle>Refund or Void a Transaction</DialogTitle>
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
        autoComplete="off"
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>What do you want to do?</Typography>
              <RadioGroup row name="transactionOptions" defaultValue="refund">
                <TransactionTypeControlLabel
                  value="refund"
                  label="Refund"
                  control={<Radio />}
                />
                <TransactionTypeControlLabel
                  value="void"
                  label="Void"
                  control={<Radio />}
                />
              </RadioGroup>
              <Grid item xs={12}>
                <Divider
                  sx={{
                    marginTop: "8px",
                    marginBottom: "15px",
                  }}
                  color="lightgray"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              {transactionType === "refund" && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      name="transactionId"
                      label="Transaction Number"
                      variant="outlined"
                      type="text"
                      onChange={(e) => {
                        setTransactionId(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      name="amount"
                      label="Refund Amount"
                      variant="outlined"
                      type="text"
                      onChange={(e) => {
                        setTransactionAmount(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              )}
              {transactionType === "void" && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="transactionId"
                    label="Transaction Number"
                    variant="outlined"
                    type="text"
                    onChange={(e) => {
                      setTransactionId(e.target.value);
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            padding: "16px",
          }}
        >
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button
                variant="outlined"
                onClick={onClose}
                sx={{
                  marginRight: "8px",
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                loading={refundLoading || voidLoading}
                variant="contained"
                type="submit"
              >
                Submit
              </LoadingButton>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default RefundVoidTransactionsModal;
