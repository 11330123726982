import { useJsApiLoader } from "@react-google-maps/api";
import React from "react";
import LoadingPage from "../../pages/home/LoadingPage";

const libraries: (
  | "places"
  | "drawing"
  | "geometry"
  | "localContext"
  | "visualization"
)[] = ["places"];
const apiKey = "AIzaSyDVqGO07bBtZCCK9GmymwZSZ_yjj9aufo0";

const MapsApiLoader: React.FC = ({ children }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    libraries: libraries,
    googleMapsApiKey: apiKey, // ,
    // ...otherOptions
  });

  if (!isLoaded) return <LoadingPage />;

  return <>{children}</>;
};
export const DEFAULT_MAP_CENTER = {
  lat: 41.850033,
  lng: -98.5556199,
};

export const DEFAULT_MAP_ZOOM = 4;

export default MapsApiLoader;
