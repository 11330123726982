import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import {
  FormResponseFragment,
  FormsPaginatedItemFragment,
} from "../../../apollo/forms/queries.generated";
import { timezoneAwareFormat } from "../../../shared/components/TimezoneAwareDatetime";

interface ViewResponseModalProps {
  open: boolean;
  onClose: () => void;
  formInfo: FormsPaginatedItemFragment;
  response: FormResponseFragment;
}

const ViewResponseModal: React.FC<ViewResponseModalProps> = ({
  open,
  onClose,
  response,
  formInfo,
}) => {
  const getResponseData = (response: any) => {
    let responseData = [];
    for (let i = 0; i < response.length; i++) {
      if (response[i][0] === "infoText") {
        responseData.push(
          <Grid item xs={12} key={i}>
            <Typography textAlign="start" fontWeight="bold">
              Text:
            </Typography>
            {response[i][1]}
          </Grid>
        );
      } else {
        responseData.push(
          <Grid item xs={12} key={i}>
            <Typography textAlign="start" fontWeight="bold">
              Title:
            </Typography>
            {response[i][1]}
            <Typography textAlign="start" fontWeight="bold">
              Response:
            </Typography>
            {response[i][2] === "" ? "(Empty response)" : response[i][2]}
          </Grid>
        );
      }
    }
    return responseData;
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <Box>
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 3,
            top: 0,
          }}
        >
          X
        </IconButton>
      </Box>

      <DialogTitle
        sx={{
          backgroundColor: "#E9983F",
          color: "white",
          textAlign: "center",
        }}
      >
        {formInfo.name}
      </DialogTitle>
      <DialogContent>
        {response && (
          <Grid container sx={{ marginTop: "10px" }} spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" textAlign="start" fontWeight="bold">
                Completed by: {response.user?.name} on{" "}
                {timezoneAwareFormat(response.createdAt, "MMM D, YYYY hh:mm A")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" textAlign="start" fontWeight="bold">
                Responses:
              </Typography>
            </Grid>

            {getResponseData(response.responseData)}
          </Grid>
        )}
      </DialogContent>
      <Box
        sx={{
          backgroundColor: "#E9983F",
          height: "40px",
          marginTop: "5px",
        }}
      />
    </Dialog>
  );
};

export default ViewResponseModal;
