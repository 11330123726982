import React from "react";

import "./App.css";

import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";

import AppRoutes from "./navigation/AppRoutes";
import { ApolloProvider } from "@apollo/client";
import apolloClient from "./apollo/client";
import theme from "./theme";
import UserLoader from "./pages/home/UserLoader";
import { Toaster } from "react-hot-toast";
import SettingsLoader from "./shared/components/SettingsLoader";

const App: React.FC = () => {
  return (
    <div className="App">
      <CssBaseline />
      <ApolloProvider client={apolloClient}>
        <ThemeProvider theme={theme}>
          <SettingsLoader/>
          <UserLoader>
            <AppRoutes />
          </UserLoader>
          <Toaster position="top-right" />
        </ThemeProvider>
      </ApolloProvider>
    </div>
  );
};

export default App;
