import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo } from "react";
import {
  Control,
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  UseFormReturn,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { PathsDetailedFragment } from "../../../apollo/busRoutes/queries.generated";
import {
  GetStudentsPaginatedDocument,
  StudentSelectItemFragment,
  StudentsPaginatedItemFragment,
  useGetStudentsMultipleSchoolsQuery,
} from "../../../apollo/students/queries.generated";
import {
  BusRoutePathDto,
  BusRoutePathType,
  BusStopDto,
} from "../../../apollo/types.generated";
import ControlledSearchSelect from "../../../shared/components/ControlledSearchSelect";
import ControlledTimePicker from "../../../shared/components/ControlledTimePicker";
import useDidMountEffect from "../../../shared/hooks/useDidMountEffect";
import { BusRouteFormInputs, BusStop } from "./interfaces";
import RouteCreator from "./RouteCreator";
import { mapServerStopsToLocalStops } from "./utils";

export const getBusRoutePathInitialValues = (
  paths?: PathsDetailedFragment[]
): BusRouteFormInputs["paths"] => {
  if (!paths || (paths && !paths.length))
    return [
      {
        type: BusRoutePathType.Am,
        busStops: [],
        endTime: null,
        startTime: null,
        students: [],
      },
      {
        type: BusRoutePathType.Pm,
        busStops: [],
        endTime: dayjs().hour(16).minute(0),
        startTime: dayjs().hour(14).minute(0),
        students: [],
      },
    ];

  return paths.map((path) => ({
    type: path.type,
    students: path.students,
    id: path.id,
    busStops: mapServerStopsToLocalStops(path.stops as BusStopDto[]),
    startTime: dayjs(path.startTime),
    endTime: dayjs(path.endTime),
  }));
};

interface BusRoutePathFormProps {
  editingPath?: PathsDetailedFragment;
  form: UseFormReturn<BusRouteFormInputs, object>;
  index: number;
}

const BusRoutePathForm: React.FC<BusRoutePathFormProps> = ({
  editingPath,
  index,
  form: { watch, setValue, control, getValues },
}) => {
  const type = watch(`paths.${index}.type`);
  // const limit = dayjs().hour(12).minute(0);
  // console.log(limit.format());
  const schoolId = watch("schoolId");
  const additionalSchools = watch("additionalSchools");
  const startTime = watch(`paths.${index}.startTime`);
  const [studentFieldValue, setStudentFieldValue] = React.useState<any[]>([]);
  const [studentList, setStudentList] = React.useState<
    StudentSelectItemFragment[]
  >([]);

  const schoolIds =
    useMemo(() => {
      return [schoolId, ...additionalSchools.map((s) => s.id)];
    }, [schoolId, additionalSchools]) || [];

  const getStudents = useGetStudentsMultipleSchoolsQuery({
    variables: {
      data: {
        schoolsIds: schoolIds,
      },
    },
  });

  useMemo(() => {
    if (editingPath?.students) {
      setStudentFieldValue(editingPath.students);
    }
  }, [editingPath]);

  useEffect(() => {
    if (!schoolId) return;
    if (!getStudents.loading && getStudents.data) {
      getStudents.refetch({
        data: {
          schoolsIds: schoolIds,
        },
      });

      setStudentList(getStudents.data?.studentsMultipleSchools.nodes || []);
    }
  }, [getStudents, schoolIds]);

  const onStudentLoadClick = useCallback(() => {
    const { paths } = getValues();

    const amPath = paths[0];

    setValue(
      `paths.${index}.students`,

      amPath.students
    );
    setStudentFieldValue(amPath.students);
  }, [setValue]);

  const onStopsLoadClick = useCallback(() => {
    const { paths } = getValues();
    const amPath = paths[0];
    if (!editingPath) {
      setValue(
        `paths.${index}.busStops`,

        amPath.busStops
      );
    } else {
      const originalPaths = watch(`paths.${index}.busStops`);

      const newStops = amPath.busStops.map((stop) => {
        const originalStop = originalPaths.find(
          (s) => s.lat === stop.lat && s.lng === stop.lng
        );
        if (originalStop) {
          return originalStop;
        }
        return stop;
      });

      setValue(`paths.${index}.busStops`, newStops);
    }
  }, [setValue]);

  useDidMountEffect(() => {
    const auxList = studentFieldValue;
    const students = studentList.filter((s) =>
      auxList.some((a: StudentSelectItemFragment) => a.id === s.id)
    );
    setValue(`paths.${index}.students`, students);
    setStudentFieldValue(students);
  }, [studentList]);

  const handleSelectStudents = useCallback(
    (students: any[]) => {
      if (studentFieldValue !== students) {
        setStudentFieldValue(students);
        setValue(`paths.${index}.students`, students);
      }
    },
    [studentFieldValue]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" textAlign="start">
          {type}
        </Typography>
      </Grid>

      {type === BusRoutePathType.Pm && (
        <Grid item xs={12} container>
          <Grid item xs={12} md={6}>
            <Button onClick={onStudentLoadClick}>
              Load Students from AM Schedule
            </Button>
          </Grid>
          {/* {!!!editingPath && ( */}
          <Grid item xs={12} md={6}>
            <Button onClick={onStopsLoadClick}>
              Load Stops from AM Schedule
            </Button>
          </Grid>
          {/* )} */}
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={6}>
        <ControlledTimePicker
          label="Start Time"
          control={control}
          // minTime={limit}
          // ampm={false}
          // shouldDisableTime={(timeValue, clockType) => {
          //   if (clockType === "hours") console.log({ timeValue });
          //   return false;
          // }}
          name={`paths.${index}.startTime`}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <ControlledTimePicker
          label="End Time"
          control={control}
          name={`paths.${index}.endTime`}
          minTime={startTime}
        />
      </Grid>
      <Grid item xs={12}>
        {/* <ControlledSearchSelect
          name={`paths.${index}.students`}
          control={control}
          initialValue={editingPath?.students}
          disabled={!schoolId}
          variables={schoolId ? { schoolIds } : undefined}
          multiple
          valueMapper={(items) => {
            return items || [];
          }}
          optionLabelExtractor={(item: StudentsPaginatedItemFragment) =>
            `${item.user.name}`
          }
          TextFieldProps={{
            label: "Assigned Students",
            disabled: !schoolId,
          }}
          query={GetStudentsPaginatedDocument}
        /> */}
        <Autocomplete
          disabled={!studentList.length}
          multiple
          id={`paths.${index}.students`}
          options={studentList}
          getOptionLabel={(option) => option.user.name}
          value={studentFieldValue}
          onChange={(event, value) => {
            handleSelectStudents(value);
          }}
          filterSelectedOptions
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Assigned Students"
              disabled={!studentList.length}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name={`paths.${index}.busStops`}
          control={control}
          render={({ field, fieldState }) => {
            return (
              <StopsArea
                field={field}
                fieldState={fieldState}
                editingPath={editingPath}
              />
            );
          }}
        />
      </Grid>
    </Grid>
  );
};

interface StopsAreaProps {
  editingPath?: PathsDetailedFragment;
  field: ControllerRenderProps<BusRouteFormInputs, `paths.${number}.busStops`>;
  fieldState: ControllerFieldState;
}
const StopsArea: React.FC<StopsAreaProps> = ({
  editingPath,
  field,
  fieldState,
}) => {
  const onStopsChange = useCallback((stops: BusStop[]) => {
    field.onChange({ target: { value: stops } });
  }, []);

  // const onStopsChange = (stops: BusStop[]) => {
  //   field.onChange({ target: { value: stops } });
  // };

  const initialStops = useMemo(
    () => mapServerStopsToLocalStops(editingPath?.stops as BusStopDto[]),
    []
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom textAlign="start">
          Stops
        </Typography>
        {fieldState.error && (
          <Typography variant="body1" color="error" textAlign="start">
            {fieldState.error.message}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "20px" }}>
        <RouteCreator
          stops={field.value}
          initialStops={initialStops}
          onBusStopsChange={onStopsChange}
        />
      </Grid>
    </Grid>
  );
};

// BusRoutePathForm.whyDidYouRender = true;
export default BusRoutePathForm;
