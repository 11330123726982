import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import { StartSubscriptionMutation } from "../../../apollo/parents/query.generated";
import { format } from "date-fns";

interface PDFDocumentTransactionProps {
  selectedItems: StartSubscriptionMutation["startSubscription"];
  handleLoadingPage: () => void;
}

const PDFDocumentTransaction: React.FC<PDFDocumentTransactionProps> = ({
  selectedItems,
  handleLoadingPage,
}) => {
  const styles = StyleSheet.create({
    containerView: {
      width: "90%",
      marginLeft: "5%",
      marginRight: "5%",
    },
    titleView: {
      width: "100%",
      height: "15vh",
      marginTop: "10px",
      marginBottom: "10px",
      display: "flex",
      justifyContent: "flex-start",
      flexDirection: "row",
      alignItems: "center",
    },
    titleNamesView: {
      display: "flex",
      flexDirection: "column",
      padding: 10,
      margin: "auto",
    },
    title: {
      fontFamily: "Poppins",
      fontSize: "3vw",
      fontWeight: 600,
      lineHeight: 1,
      color: "#000000",
      margin: 0,
      marginBottom: 7,
      textAlign: "center",
    },
    titleSubInfo: {
      fontFamily: "Poppins",
      fontSize: "2vw",
      color: "#808080",
      lineHeight: 1,
    },
  });

  Font.register({
    family: "Poppins",
    fonts: [
      {
        src: "/fonts/Poppins-Regular.ttf",
      },

      {
        src: "/fonts/Poppins-SemiBold.ttf",
        fontWeight: 600,
        lineHeight: 1,
      },
    ],
  });

  const invoiceDate = format(
    new Date(selectedItems?.subscription?.updatedAt),
    "MMMM D YYYY"
  );

  const transactionDate = format(
    new Date(selectedItems?.subscription?.updatedAt),
    "MMMM D YYYY h:mm a"
  );

  var discountedFee = 0;
  if (Number(selectedItems?.subscription?.discount) > 0) {
    discountedFee =
      Number(selectedItems?.subscription?.discount) *
      Number(selectedItems?.subscription?.amount);
  }

  return (
    <Document title="Arttie" onRender={handleLoadingPage}>
      {/* {selectedItems && ( */}
      <Page size="A4">
        <View style={styles.containerView}>
          <View //Title View
            style={styles.titleView}
          >
            <Image src="\images\logo.png" style={{ height: "70%" }} />
            <View style={styles.titleNamesView}>
              <Text style={styles.title}>Arttiee</Text>
              <Text
                style={styles.titleSubInfo}
              >{`1911 Grayson Hwy Suite#8-319`}</Text>
              <Text style={styles.titleSubInfo}>{`Grayson GA, 30017`}</Text>
              <Text style={styles.titleSubInfo}>
                {`Contact: +404-635-6874`}
              </Text>
              <Text style={styles.titleSubInfo}>{`Info@arttiee.com`}</Text>
            </View>
          </View>
          <Text style={styles.titleSubInfo}>Invoice Date: {invoiceDate}</Text>
          <View
            style={{
              border: "3px solid #252525",
              borderRadius: "4px",
              marginTop: "10px",
              textAlign: "center",
            }}
          >
            <Text style={styles.title}>Transaction Details</Text>
          </View>
          <View
            style={{
              border: "3px solid #252525",
              borderRadius: "4px",
              textAlign: "center",
              marginTop: "-3.5px",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                padding: 10,
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: 10,
                  marginRight: "auto",
                  alignContent: "flex-start",
                }}
              >
                <Text style={styles.title}>Order Number</Text>
                <Text style={{ marginBottom: "15px" }}>
                  {selectedItems?.transaction?.transactionId}
                </Text>

                <Text style={styles.title}>Reference Number</Text>
                <Text>{selectedItems?.transaction?.referenceNumber}</Text>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: 10,
                  marginLeft: "auto",
                  alignContent: "flex-end",
                }}
              >
                <Text style={styles.title}>Transaction Date</Text>
                <Text style={{ marginBottom: "15px" }}>{transactionDate}</Text>

                <Text style={styles.title}>Total</Text>
                <Text>${selectedItems?.transaction?.authorizedAmount}</Text>
              </View>
            </View>
          </View>
          <View
            style={{
              border: "3px solid #252525",
              borderRadius: "4px",
              marginTop: "-3.5px",
              textAlign: "center",
            }}
          >
            <Text style={styles.title}>Payment Information</Text>
          </View>
          <View
            style={{
              border: "3px solid #252525",
              borderRadius: "4px",
              textAlign: "center",
              marginTop: "-3.5px",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                padding: 10,
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: 10,
                  marginRight: "auto",
                  alignContent: "flex-start",
                }}
              >
                <Text style={styles.title}>Payment Method</Text>
                <Text>
                  Credit Card ending in{" "}
                  {selectedItems?.subscription?.paymentMethod?.number.slice(-4)}
                </Text>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: 10,
                  marginLeft: "auto",
                  alignContent: "flex-end",
                }}
              >
                <Text style={styles.title}>Billing Address</Text>
                <Text>
                  {
                    selectedItems?.subscription?.paymentMethod?.address
                      ?.streetAddress1
                  }{" "}
                </Text>
                <Text>
                  {selectedItems?.subscription?.paymentMethod?.address?.city}
                  {", "}
                  {
                    selectedItems?.subscription?.paymentMethod?.address
                      ?.province
                  }{" "}
                  {
                    selectedItems?.subscription?.paymentMethod?.address
                      ?.postalCode
                  }
                </Text>
                <Text>
                  {selectedItems?.subscription?.paymentMethod?.address?.country}
                </Text>
              </View>
            </View>
          </View>

          <View
            style={{
              border: "3px solid #252525",
              borderRadius: "4px",
              marginTop: "-3.5px",
              textAlign: "center",
            }}
          >
            <Text style={styles.title}>Payment Summary</Text>
          </View>
          <View
            style={{
              border: "3px solid #252525",
              borderRadius: "4px",
              textAlign: "center",
              marginTop: "-3.5px",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                padding: 10,
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: 10,
                  marginRight: "auto",
                  alignContent: "flex-start",
                }}
              >
                <Text style={styles.title}>Monthly subscription</Text>

                <Text style={{ marginTop: "15px" }}>Subscription duration</Text>
                <Text style={{ marginTop: "5px" }}>Subscription fee</Text>
                {selectedItems?.subscription?.discount
                  ? Number(selectedItems?.subscription?.discount) > 0 && (
                      <Text style={{ marginTop: "5px" }}>Discount</Text>
                    )
                  : null}
                <Text style={styles.title}>Total</Text>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: 10,
                  marginRight: "auto",
                  alignContent: "flex-end",
                }}
              >
                <Text style={{ marginTop: "38px" }}>1 month</Text>
                <Text style={{ marginTop: "5px" }}>
                  ${selectedItems?.subscription?.amount}
                </Text>
                {selectedItems?.subscription?.discount
                  ? Number(selectedItems?.subscription?.discount) > 0 && (
                      <Text style={{ marginTop: "5px" }}>
                        -${discountedFee}
                      </Text>
                    )
                  : null}
                <Text style={styles.title}>
                  ${selectedItems?.transaction?.authorizedAmount}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
      {/* )} */}
    </Document>
  );
};

export default PDFDocumentTransaction;
