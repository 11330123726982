import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import { UserFragmentDoc } from '../users/queries.generated';
import { BusRouteDetailedFragmentDoc } from '../busRoutes/queries.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type BusDriversPaginatedItemFragment = { id: string, accountStatus: Types.AccountStatus, busNumber?: string | null | undefined, createdAt: any, updatedAt: any, schoolSystem?: { id: string, name: string } | null | undefined, assignedSchools: Array<{ id: string, name: string, code: string }>, user: { name: string, firstName: string, lastName: string, email: string, role: string, id: string, createdAt: any, updatedAt: any, isDisabled: boolean } };

export type GetBusDriversPaginatedQueryVariables = Types.Exact<{
  search?: Types.Maybe<Types.Scalars['String']>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  sortBy?: Types.Maybe<Types.Scalars['String']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  sortDirection?: Types.Maybe<Types.Scalars['String']>;
  schoolSystemId?: Types.Maybe<Types.Scalars['String']>;
  schoolId?: Types.Maybe<Types.Scalars['String']>;
}>;


export type GetBusDriversPaginatedQuery = { busDriversPaginated: { totalsCount: number, nodes?: Array<{ id: string, accountStatus: Types.AccountStatus, busNumber?: string | null | undefined, createdAt: any, updatedAt: any, schoolSystem?: { id: string, name: string } | null | undefined, assignedSchools: Array<{ id: string, name: string, code: string }>, user: { name: string, firstName: string, lastName: string, email: string, role: string, id: string, createdAt: any, updatedAt: any, isDisabled: boolean } }> | null | undefined } };

export type GetBusDriverQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetBusDriverQuery = { busDriver: { id: string, accountStatus: Types.AccountStatus, busNumber?: string | null | undefined, createdAt: any, updatedAt: any, schoolSystem?: { id: string, name: string } | null | undefined, assignedSchools: Array<{ id: string, name: string, code: string }>, user: { name: string, firstName: string, lastName: string, email: string, role: string, id: string, createdAt: any, updatedAt: any, isDisabled: boolean } } };

export type CreateBusDriverMutationVariables = Types.Exact<{
  data: Types.CreateBusDriverDto;
}>;


export type CreateBusDriverMutation = { createBusDriver: { id: string, createdAt: any, updatedAt: any } };

export type UpdateBusDriverMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  data: Types.UpdateBusDriverDto;
}>;


export type UpdateBusDriverMutation = { updateBusDriver: { id: string } };

export type DeleteBusDriverMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteBusDriverMutation = { deleteBusDriver: boolean };

export type DriverRoutePeriodFragment = { id: string, routePeriodStart: any, routePeriodEnd: any, isSubstituteDriver: boolean, busDriver: { id: string, accountStatus: Types.AccountStatus, busNumber?: string | null | undefined, createdAt: any, updatedAt: any, schoolSystem?: { id: string, name: string } | null | undefined, assignedSchools: Array<{ id: string, name: string, code: string }>, user: { name: string, firstName: string, lastName: string, email: string, role: string, id: string, createdAt: any, updatedAt: any, isDisabled: boolean } }, busRoute: { id: string, routeId?: string | null | undefined, createdAt: any, name: string, paths: Array<{ id: string, startTime: any, endTime: any, type: Types.BusRoutePathType, students: Array<{ id: string, user: { id: string, name: string, firstName: string, lastName: string, email: string } }>, stops: Array<{ id: string, address: string, latitude: number, longitude: number, order: number, students: Array<{ id: string, user: { id: string, name: string, firstName: string, lastName: string, email: string } }> }> }>, busDriver: { id: string, busNumber?: string | null | undefined, user: { lastName: string, id: string, firstName: string } }, school: { id: string, name: string, schoolSystem?: { id: string, name: string } | null | undefined }, schoolSystem: { id: string, name: string }, aditionalSchools?: Array<{ id: string, name: string }> | null | undefined } };

export type GetRouteDriversQueryVariables = Types.Exact<{
  routeId: Types.Scalars['String'];
}>;


export type GetRouteDriversQuery = { getRouteDrivers: Array<{ id: string, routePeriodStart: any, routePeriodEnd: any, isSubstituteDriver: boolean, busDriver: { id: string, accountStatus: Types.AccountStatus, busNumber?: string | null | undefined, createdAt: any, updatedAt: any, schoolSystem?: { id: string, name: string } | null | undefined, assignedSchools: Array<{ id: string, name: string, code: string }>, user: { name: string, firstName: string, lastName: string, email: string, role: string, id: string, createdAt: any, updatedAt: any, isDisabled: boolean } }, busRoute: { id: string, routeId?: string | null | undefined, createdAt: any, name: string, paths: Array<{ id: string, startTime: any, endTime: any, type: Types.BusRoutePathType, students: Array<{ id: string, user: { id: string, name: string, firstName: string, lastName: string, email: string } }>, stops: Array<{ id: string, address: string, latitude: number, longitude: number, order: number, students: Array<{ id: string, user: { id: string, name: string, firstName: string, lastName: string, email: string } }> }> }>, busDriver: { id: string, busNumber?: string | null | undefined, user: { lastName: string, id: string, firstName: string } }, school: { id: string, name: string, schoolSystem?: { id: string, name: string } | null | undefined }, schoolSystem: { id: string, name: string }, aditionalSchools?: Array<{ id: string, name: string }> | null | undefined } }> };

export type CreateDriverRoutePeriodMutationVariables = Types.Exact<{
  data: Types.CreateDriverRoutePeriodDto;
}>;


export type CreateDriverRoutePeriodMutation = { createDriverRoutePeriod: { id: string } };

export type UpdateDriverRoutePeriodMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  data: Types.UpdateDriverRoutePeriodDto;
}>;


export type UpdateDriverRoutePeriodMutation = { updateDriverRoutePeriod: { id: string } };

export type DeleteDriverRoutePeriodMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteDriverRoutePeriodMutation = { deleteDriverRoutePeriod: boolean };

export const BusDriversPaginatedItemFragmentDoc = gql`
    fragment BusDriversPaginatedItem on BusDriverDTO {
  id
  accountStatus
  busNumber
  schoolSystem {
    id
    name
  }
  assignedSchools {
    id
    name
    code
  }
  user {
    ...User
  }
  createdAt
  updatedAt
}
    ${UserFragmentDoc}`;
export const DriverRoutePeriodFragmentDoc = gql`
    fragment DriverRoutePeriod on DriverRoutePeriodDTO {
  id
  routePeriodStart
  routePeriodEnd
  isSubstituteDriver
  busDriver {
    ...BusDriversPaginatedItem
  }
  busRoute {
    ...BusRouteDetailed
  }
}
    ${BusDriversPaginatedItemFragmentDoc}
${BusRouteDetailedFragmentDoc}`;
export const GetBusDriversPaginatedDocument = gql`
    query getBusDriversPaginated($search: String, $limit: Int, $sortBy: String, $offset: Int, $sortDirection: String, $schoolSystemId: String, $schoolId: String) {
  busDriversPaginated(
    schoolSystemId: $schoolSystemId
    schoolId: $schoolId
    search: $search
    limit: $limit
    offset: $offset
    sortBy: $sortBy
    sortDirection: $sortDirection
  ) {
    nodes {
      ...BusDriversPaginatedItem
    }
    totalsCount
  }
}
    ${BusDriversPaginatedItemFragmentDoc}`;

/**
 * __useGetBusDriversPaginatedQuery__
 *
 * To run a query within a React component, call `useGetBusDriversPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusDriversPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusDriversPaginatedQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      offset: // value for 'offset'
 *      sortDirection: // value for 'sortDirection'
 *      schoolSystemId: // value for 'schoolSystemId'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetBusDriversPaginatedQuery(baseOptions?: Apollo.QueryHookOptions<GetBusDriversPaginatedQuery, GetBusDriversPaginatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBusDriversPaginatedQuery, GetBusDriversPaginatedQueryVariables>(GetBusDriversPaginatedDocument, options);
      }
export function useGetBusDriversPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBusDriversPaginatedQuery, GetBusDriversPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBusDriversPaginatedQuery, GetBusDriversPaginatedQueryVariables>(GetBusDriversPaginatedDocument, options);
        }
export type GetBusDriversPaginatedQueryHookResult = ReturnType<typeof useGetBusDriversPaginatedQuery>;
export type GetBusDriversPaginatedLazyQueryHookResult = ReturnType<typeof useGetBusDriversPaginatedLazyQuery>;
export type GetBusDriversPaginatedQueryResult = Apollo.QueryResult<GetBusDriversPaginatedQuery, GetBusDriversPaginatedQueryVariables>;
export const GetBusDriverDocument = gql`
    query getBusDriver($id: String!) {
  busDriver(id: $id) {
    ...BusDriversPaginatedItem
  }
}
    ${BusDriversPaginatedItemFragmentDoc}`;

/**
 * __useGetBusDriverQuery__
 *
 * To run a query within a React component, call `useGetBusDriverQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusDriverQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusDriverQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBusDriverQuery(baseOptions: Apollo.QueryHookOptions<GetBusDriverQuery, GetBusDriverQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBusDriverQuery, GetBusDriverQueryVariables>(GetBusDriverDocument, options);
      }
export function useGetBusDriverLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBusDriverQuery, GetBusDriverQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBusDriverQuery, GetBusDriverQueryVariables>(GetBusDriverDocument, options);
        }
export type GetBusDriverQueryHookResult = ReturnType<typeof useGetBusDriverQuery>;
export type GetBusDriverLazyQueryHookResult = ReturnType<typeof useGetBusDriverLazyQuery>;
export type GetBusDriverQueryResult = Apollo.QueryResult<GetBusDriverQuery, GetBusDriverQueryVariables>;
export const CreateBusDriverDocument = gql`
    mutation createBusDriver($data: CreateBusDriverDTO!) {
  createBusDriver(data: $data) {
    id
    createdAt
    updatedAt
  }
}
    `;
export type CreateBusDriverMutationFn = Apollo.MutationFunction<CreateBusDriverMutation, CreateBusDriverMutationVariables>;

/**
 * __useCreateBusDriverMutation__
 *
 * To run a mutation, you first call `useCreateBusDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBusDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBusDriverMutation, { data, loading, error }] = useCreateBusDriverMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateBusDriverMutation(baseOptions?: Apollo.MutationHookOptions<CreateBusDriverMutation, CreateBusDriverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBusDriverMutation, CreateBusDriverMutationVariables>(CreateBusDriverDocument, options);
      }
export type CreateBusDriverMutationHookResult = ReturnType<typeof useCreateBusDriverMutation>;
export type CreateBusDriverMutationResult = Apollo.MutationResult<CreateBusDriverMutation>;
export type CreateBusDriverMutationOptions = Apollo.BaseMutationOptions<CreateBusDriverMutation, CreateBusDriverMutationVariables>;
export const UpdateBusDriverDocument = gql`
    mutation updateBusDriver($id: String!, $data: UpdateBusDriverDTO!) {
  updateBusDriver(id: $id, data: $data) {
    id
  }
}
    `;
export type UpdateBusDriverMutationFn = Apollo.MutationFunction<UpdateBusDriverMutation, UpdateBusDriverMutationVariables>;

/**
 * __useUpdateBusDriverMutation__
 *
 * To run a mutation, you first call `useUpdateBusDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusDriverMutation, { data, loading, error }] = useUpdateBusDriverMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateBusDriverMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBusDriverMutation, UpdateBusDriverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBusDriverMutation, UpdateBusDriverMutationVariables>(UpdateBusDriverDocument, options);
      }
export type UpdateBusDriverMutationHookResult = ReturnType<typeof useUpdateBusDriverMutation>;
export type UpdateBusDriverMutationResult = Apollo.MutationResult<UpdateBusDriverMutation>;
export type UpdateBusDriverMutationOptions = Apollo.BaseMutationOptions<UpdateBusDriverMutation, UpdateBusDriverMutationVariables>;
export const DeleteBusDriverDocument = gql`
    mutation deleteBusDriver($id: String!) {
  deleteBusDriver(id: $id)
}
    `;
export type DeleteBusDriverMutationFn = Apollo.MutationFunction<DeleteBusDriverMutation, DeleteBusDriverMutationVariables>;

/**
 * __useDeleteBusDriverMutation__
 *
 * To run a mutation, you first call `useDeleteBusDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBusDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBusDriverMutation, { data, loading, error }] = useDeleteBusDriverMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBusDriverMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBusDriverMutation, DeleteBusDriverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBusDriverMutation, DeleteBusDriverMutationVariables>(DeleteBusDriverDocument, options);
      }
export type DeleteBusDriverMutationHookResult = ReturnType<typeof useDeleteBusDriverMutation>;
export type DeleteBusDriverMutationResult = Apollo.MutationResult<DeleteBusDriverMutation>;
export type DeleteBusDriverMutationOptions = Apollo.BaseMutationOptions<DeleteBusDriverMutation, DeleteBusDriverMutationVariables>;
export const GetRouteDriversDocument = gql`
    query getRouteDrivers($routeId: String!) {
  getRouteDrivers(routeId: $routeId) {
    ...DriverRoutePeriod
  }
}
    ${DriverRoutePeriodFragmentDoc}`;

/**
 * __useGetRouteDriversQuery__
 *
 * To run a query within a React component, call `useGetRouteDriversQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRouteDriversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRouteDriversQuery({
 *   variables: {
 *      routeId: // value for 'routeId'
 *   },
 * });
 */
export function useGetRouteDriversQuery(baseOptions: Apollo.QueryHookOptions<GetRouteDriversQuery, GetRouteDriversQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRouteDriversQuery, GetRouteDriversQueryVariables>(GetRouteDriversDocument, options);
      }
export function useGetRouteDriversLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRouteDriversQuery, GetRouteDriversQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRouteDriversQuery, GetRouteDriversQueryVariables>(GetRouteDriversDocument, options);
        }
export type GetRouteDriversQueryHookResult = ReturnType<typeof useGetRouteDriversQuery>;
export type GetRouteDriversLazyQueryHookResult = ReturnType<typeof useGetRouteDriversLazyQuery>;
export type GetRouteDriversQueryResult = Apollo.QueryResult<GetRouteDriversQuery, GetRouteDriversQueryVariables>;
export const CreateDriverRoutePeriodDocument = gql`
    mutation createDriverRoutePeriod($data: CreateDriverRoutePeriodDTO!) {
  createDriverRoutePeriod(data: $data) {
    id
  }
}
    `;
export type CreateDriverRoutePeriodMutationFn = Apollo.MutationFunction<CreateDriverRoutePeriodMutation, CreateDriverRoutePeriodMutationVariables>;

/**
 * __useCreateDriverRoutePeriodMutation__
 *
 * To run a mutation, you first call `useCreateDriverRoutePeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDriverRoutePeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDriverRoutePeriodMutation, { data, loading, error }] = useCreateDriverRoutePeriodMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDriverRoutePeriodMutation(baseOptions?: Apollo.MutationHookOptions<CreateDriverRoutePeriodMutation, CreateDriverRoutePeriodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDriverRoutePeriodMutation, CreateDriverRoutePeriodMutationVariables>(CreateDriverRoutePeriodDocument, options);
      }
export type CreateDriverRoutePeriodMutationHookResult = ReturnType<typeof useCreateDriverRoutePeriodMutation>;
export type CreateDriverRoutePeriodMutationResult = Apollo.MutationResult<CreateDriverRoutePeriodMutation>;
export type CreateDriverRoutePeriodMutationOptions = Apollo.BaseMutationOptions<CreateDriverRoutePeriodMutation, CreateDriverRoutePeriodMutationVariables>;
export const UpdateDriverRoutePeriodDocument = gql`
    mutation updateDriverRoutePeriod($id: String!, $data: UpdateDriverRoutePeriodDTO!) {
  updateDriverRoutePeriod(id: $id, data: $data) {
    id
  }
}
    `;
export type UpdateDriverRoutePeriodMutationFn = Apollo.MutationFunction<UpdateDriverRoutePeriodMutation, UpdateDriverRoutePeriodMutationVariables>;

/**
 * __useUpdateDriverRoutePeriodMutation__
 *
 * To run a mutation, you first call `useUpdateDriverRoutePeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDriverRoutePeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDriverRoutePeriodMutation, { data, loading, error }] = useUpdateDriverRoutePeriodMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDriverRoutePeriodMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDriverRoutePeriodMutation, UpdateDriverRoutePeriodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDriverRoutePeriodMutation, UpdateDriverRoutePeriodMutationVariables>(UpdateDriverRoutePeriodDocument, options);
      }
export type UpdateDriverRoutePeriodMutationHookResult = ReturnType<typeof useUpdateDriverRoutePeriodMutation>;
export type UpdateDriverRoutePeriodMutationResult = Apollo.MutationResult<UpdateDriverRoutePeriodMutation>;
export type UpdateDriverRoutePeriodMutationOptions = Apollo.BaseMutationOptions<UpdateDriverRoutePeriodMutation, UpdateDriverRoutePeriodMutationVariables>;
export const DeleteDriverRoutePeriodDocument = gql`
    mutation deleteDriverRoutePeriod($id: String!) {
  deleteDriverRoutePeriod(id: $id)
}
    `;
export type DeleteDriverRoutePeriodMutationFn = Apollo.MutationFunction<DeleteDriverRoutePeriodMutation, DeleteDriverRoutePeriodMutationVariables>;

/**
 * __useDeleteDriverRoutePeriodMutation__
 *
 * To run a mutation, you first call `useDeleteDriverRoutePeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDriverRoutePeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDriverRoutePeriodMutation, { data, loading, error }] = useDeleteDriverRoutePeriodMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDriverRoutePeriodMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDriverRoutePeriodMutation, DeleteDriverRoutePeriodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDriverRoutePeriodMutation, DeleteDriverRoutePeriodMutationVariables>(DeleteDriverRoutePeriodDocument, options);
      }
export type DeleteDriverRoutePeriodMutationHookResult = ReturnType<typeof useDeleteDriverRoutePeriodMutation>;
export type DeleteDriverRoutePeriodMutationResult = Apollo.MutationResult<DeleteDriverRoutePeriodMutation>;
export type DeleteDriverRoutePeriodMutationOptions = Apollo.BaseMutationOptions<DeleteDriverRoutePeriodMutation, DeleteDriverRoutePeriodMutationVariables>;