import { ListItem, ListItemAvatar, ListItemText, Avatar } from "@mui/material";
import React from "react";

interface DescriptionItemProps {
  icon: React.ReactNode;
  label: string;
  value: string;
  color?: string;
  marginY?: string;
}
const DescriptionItem: React.FC<DescriptionItemProps> = ({
  icon,
  label,
  value,
  color,
  marginY = "5%",
}) => {
  return (
    <ListItem disablePadding sx={{ marginY: `${marginY}` }}>
      <ListItemAvatar>
        <Avatar
          variant="rounded"
          sx={{ width: 50, height: 50, bgcolor: color }}
        >
          {icon}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        sx={{ marginLeft: "10px" }}
        primary={value}
        secondary={label}
      />
    </ListItem>
  );
};

export default DescriptionItem;
