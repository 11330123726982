import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateSchoolSystemMutationVariables = Types.Exact<{
  data: Types.CreateSchoolSystemDto;
}>;


export type CreateSchoolSystemMutation = { createSchoolSystem: { id: string, name: string, createdAt: any, updatedAt: any } };

export type UpdateSchoolSystemMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  data: Types.UpdateSchoolSystemDto;
}>;


export type UpdateSchoolSystemMutation = { updateSchoolSystem: { id: string } };

export type DeleteSchoolSystemMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteSchoolSystemMutation = { deleteSchoolSystem: boolean };

export type EnableSchoolSystemMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type EnableSchoolSystemMutation = { enableSchoolSystem: boolean };


export const CreateSchoolSystemDocument = gql`
    mutation createSchoolSystem($data: CreateSchoolSystemDTO!) {
  createSchoolSystem(data: $data) {
    id
    name
    createdAt
    updatedAt
  }
}
    `;
export type CreateSchoolSystemMutationFn = Apollo.MutationFunction<CreateSchoolSystemMutation, CreateSchoolSystemMutationVariables>;

/**
 * __useCreateSchoolSystemMutation__
 *
 * To run a mutation, you first call `useCreateSchoolSystemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSchoolSystemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSchoolSystemMutation, { data, loading, error }] = useCreateSchoolSystemMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSchoolSystemMutation(baseOptions?: Apollo.MutationHookOptions<CreateSchoolSystemMutation, CreateSchoolSystemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSchoolSystemMutation, CreateSchoolSystemMutationVariables>(CreateSchoolSystemDocument, options);
      }
export type CreateSchoolSystemMutationHookResult = ReturnType<typeof useCreateSchoolSystemMutation>;
export type CreateSchoolSystemMutationResult = Apollo.MutationResult<CreateSchoolSystemMutation>;
export type CreateSchoolSystemMutationOptions = Apollo.BaseMutationOptions<CreateSchoolSystemMutation, CreateSchoolSystemMutationVariables>;
export const UpdateSchoolSystemDocument = gql`
    mutation updateSchoolSystem($id: String!, $data: UpdateSchoolSystemDTO!) {
  updateSchoolSystem(id: $id, data: $data) {
    id
  }
}
    `;
export type UpdateSchoolSystemMutationFn = Apollo.MutationFunction<UpdateSchoolSystemMutation, UpdateSchoolSystemMutationVariables>;

/**
 * __useUpdateSchoolSystemMutation__
 *
 * To run a mutation, you first call `useUpdateSchoolSystemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSchoolSystemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSchoolSystemMutation, { data, loading, error }] = useUpdateSchoolSystemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSchoolSystemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSchoolSystemMutation, UpdateSchoolSystemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSchoolSystemMutation, UpdateSchoolSystemMutationVariables>(UpdateSchoolSystemDocument, options);
      }
export type UpdateSchoolSystemMutationHookResult = ReturnType<typeof useUpdateSchoolSystemMutation>;
export type UpdateSchoolSystemMutationResult = Apollo.MutationResult<UpdateSchoolSystemMutation>;
export type UpdateSchoolSystemMutationOptions = Apollo.BaseMutationOptions<UpdateSchoolSystemMutation, UpdateSchoolSystemMutationVariables>;
export const DeleteSchoolSystemDocument = gql`
    mutation deleteSchoolSystem($id: String!) {
  deleteSchoolSystem(id: $id)
}
    `;
export type DeleteSchoolSystemMutationFn = Apollo.MutationFunction<DeleteSchoolSystemMutation, DeleteSchoolSystemMutationVariables>;

/**
 * __useDeleteSchoolSystemMutation__
 *
 * To run a mutation, you first call `useDeleteSchoolSystemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSchoolSystemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSchoolSystemMutation, { data, loading, error }] = useDeleteSchoolSystemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSchoolSystemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSchoolSystemMutation, DeleteSchoolSystemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSchoolSystemMutation, DeleteSchoolSystemMutationVariables>(DeleteSchoolSystemDocument, options);
      }
export type DeleteSchoolSystemMutationHookResult = ReturnType<typeof useDeleteSchoolSystemMutation>;
export type DeleteSchoolSystemMutationResult = Apollo.MutationResult<DeleteSchoolSystemMutation>;
export type DeleteSchoolSystemMutationOptions = Apollo.BaseMutationOptions<DeleteSchoolSystemMutation, DeleteSchoolSystemMutationVariables>;
export const EnableSchoolSystemDocument = gql`
    mutation enableSchoolSystem($id: String!) {
  enableSchoolSystem(id: $id)
}
    `;
export type EnableSchoolSystemMutationFn = Apollo.MutationFunction<EnableSchoolSystemMutation, EnableSchoolSystemMutationVariables>;

/**
 * __useEnableSchoolSystemMutation__
 *
 * To run a mutation, you first call `useEnableSchoolSystemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableSchoolSystemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableSchoolSystemMutation, { data, loading, error }] = useEnableSchoolSystemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnableSchoolSystemMutation(baseOptions?: Apollo.MutationHookOptions<EnableSchoolSystemMutation, EnableSchoolSystemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnableSchoolSystemMutation, EnableSchoolSystemMutationVariables>(EnableSchoolSystemDocument, options);
      }
export type EnableSchoolSystemMutationHookResult = ReturnType<typeof useEnableSchoolSystemMutation>;
export type EnableSchoolSystemMutationResult = Apollo.MutationResult<EnableSchoolSystemMutation>;
export type EnableSchoolSystemMutationOptions = Apollo.BaseMutationOptions<EnableSchoolSystemMutation, EnableSchoolSystemMutationVariables>;