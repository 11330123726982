import { createTheme } from "@mui/material";

declare module "@mui/material/Paper" {
  interface PaperPropsVariantOverrides {
    modern: true;
  }
}

const theme = createTheme({
  palette: {
    // type: 'light',
    primary: {
      main: "#E9983F",
    },
    secondary: {
      main: "#EE2E25",
    },
    background: {
      default: "#FAFAFE",
    },
  },
  shape: {
    borderRadius: 15,
  },
  typography: {
    fontFamily: "Poppins",
  },

  components: {
    MuiPaper: {
      variants: [
        {
          props: { variant: "modern" },
          style: {
            boxShadow: " 0px 48px 100px 0px rgba(17, 12, 46, 0.15)",
          },
        },
      ],
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "contained", color: "primary" },
          style: { color: "white" },
        },
      ],
    },
    MuiAppBar: {
      styleOverrides: {
        colorInherit: {
          backgroundColor: "#FD8D71",
          color: "white",
        },
      },
    },
  },
});

export default theme;
