import React, { useEffect } from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  MenuItem,
} from "@mui/material";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";

import useDidMountEffect from "../../../shared/hooks/useDidMountEffect";

import { CreateUserDto } from "../../../apollo/types.generated";
import ControlledTextField from "../../../shared/components/ControlledTextField";
import ControlledSelectModalField from "../../../shared/components/ControlledSelectModalField";
import {
  GetSchoolSystemsPaginatedDocument,
  SchoolSystemPaginatedItemFragment,
} from "../../../apollo/schoolSystems/queries.generated";
import {
  GetSchoolsPaginatedDocument,
  SchoolsPaginatedItemFragment,
} from "../../../apollo/schools/queries.generated";
import apolloClient from "../../../apollo/client";
import {
  CheckEmailAvailabilityQuery,
  CheckEmailAvailabilityDocument,
  CheckEmailAvailabilityQueryVariables,
  UsersPaginatedItemFragment,
  useCreateUserMutation,
  GetUsersPaginatedDocument,
  useUpdateUserMutation,
} from "../../../apollo/users/queries.generated";
import useAuthUser from "../../../shared/hooks/useAuthUser";
import useAbility from "../../../shared/hooks/useAbility";
import { AppAbility } from "../../../shared/stores/authStore";

interface UserFormModalProps {
  open: boolean;
  onClose: () => void;
  editingUser?: UsersPaginatedItemFragment;
}

interface FormInputs extends Omit<CreateUserDto, "password"> {
  passwordConfirmation?: string;
  password?: string;
}

const getRoles = (ability: AppAbility) => {
  const roles: {
    label: string;
    value: string;
  }[] = [];
  if (ability.can("manage", "Schools")) {
    roles.push({
      value: "SchoolManager",
      label: "School Manager",
    });
  }
  if (ability.can("manage", "all")) {
    roles.push({
      label: "School System Manager",
      value: "SchoolSystemManager",
    });
  }
  return roles;
};

const getSchema = (
  editingUser?: UsersPaginatedItemFragment
): Yup.SchemaOf<FormInputs> =>
  Yup.object({
    role: Yup.string().required("This field is required"),
    firstName: Yup.string().required("This field is required"),
    lastName: Yup.string().required("This field is required"),

    email: Yup.string()
      .required("This field is required")
      .email("Invalid email")
      .test(
        "test-mail",
        "This email address is not available",
        async (value, context) => {
          if (!value) return false;
          const result = await apolloClient.query<
            CheckEmailAvailabilityQuery,
            CheckEmailAvailabilityQueryVariables
          >({
            query: CheckEmailAvailabilityDocument,
            variables: { email: value, userId: editingUser?.id },
          });

          return result.data.checkEmailAvailability;
        }
      ),
    password: editingUser
      ? Yup.string().optional()
      : Yup.string().required("This field is required"),
    passwordConfirmation: editingUser
      ? Yup.string()
          .optional()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
      : Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required(""),
    schoolId: Yup.string().when("schoolSystemId", {
      is: (val: string) => !val,
      then: Yup.string().required("This field is required"),
      otherwise: Yup.string().optional(),
    }),
    schoolSystemId: Yup.string().test(
      "check school id",
      "This field is required",
      (value, context) => {
        const {
          parent: { schoolId },
        } = context;

        return !!schoolId || !!value;
      }
    ),
  });

const getDefaultValues = (
  user?: UsersPaginatedItemFragment
): FormInputs | undefined => {
  return {
    role: user?.role || "",
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    email: user?.email || "",
    password: "",
    passwordConfirmation: "",
    schoolId: user?.school?.id || "",
  };
};
const UserFormModal: React.FC<UserFormModalProps> = ({
  open,
  onClose,
  editingUser,
}) => {
  const authUser = useAuthUser();
  const ability = useAbility();
  // const ability = useAbility();
  const { control, handleSubmit, reset, setValue, watch } = useForm<FormInputs>(
    {
      resolver: yupResolver(getSchema(editingUser)),
      shouldFocusError: true,
      defaultValues: getDefaultValues(editingUser),
    }
  );
  const selectedRole: string | undefined = watch("role");

  const [
    create,
    { data: createData, loading: createLoading, error: createError },
  ] = useCreateUserMutation({
    refetchQueries: [GetUsersPaginatedDocument],
  });

  const [
    update,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useUpdateUserMutation({
    refetchQueries: [GetUsersPaginatedDocument],
  });

  const onSubmit = (data: FormInputs) => {
    const { passwordConfirmation, ...rest } = data;
    if (editingUser) {
      update({
        variables: {
          id: editingUser.id,
          data: rest,
        },
        refetchQueries: [GetUsersPaginatedDocument],
      });
      return;
    }

    if (rest.password) {
      create({
        variables: {
          data: {
            ...rest,
            password: rest.password,
          },
        },
        refetchQueries: [GetUsersPaginatedDocument],
      });
    }
  };

  useEffect(() => {
    if (createData) {
      toast.success("User created successfully");
      onClose();
    }

    if (createError) {
      toast.error("Error creating User: " + createError.message);
    }
  }, [createData, createError]);

  useEffect(() => {
    if (updateData) {
      toast.success("User updated successfully");
      onClose();
    }

    if (updateError) {
      toast.error("Error updating User: " + updateError.message);
    }
  }, [updateData, updateError]);

  useDidMountEffect(() => {
    reset(getDefaultValues(editingUser));
  }, [editingUser]);

  useEffect(() => {
    if (authUser && authUser.schoolId) {
      setValue("schoolId", authUser.schoolId);
    }
  }, [authUser]);

  useDidMountEffect(() => {
    //if the role changes we should remove the selected school
    //TODO: fix this implementation
  }, [authUser, selectedRole]);

  return (
    <Dialog
      open={open}
      onClose={!createLoading ? onClose : undefined}
      maxWidth="md"
      fullWidth
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <DialogTitle>{editingUser ? "Update" : "Create"} User</DialogTitle>
        <DialogContent>
          <Grid container sx={{ marginTop: "10px" }} spacing={2}>
            <Grid item xs={12}>
              <ControlledTextField
                name="firstName"
                control={control}
                label="First Name"
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                name="lastName"
                control={control}
                label="Last Name"
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                name="email"
                control={control}
                label="Email"
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                name="role"
                control={control}
                label="Role"
                select
              >
                {getRoles(ability).map((r) => (
                  <MenuItem value={r.value} key={r.value}>
                    {r.label}
                  </MenuItem>
                ))}
              </ControlledTextField>
            </Grid>

            <Grid item xs={12}>
              {!!editingUser && (
                <Typography variant="caption" color="GrayText">
                  You can skip these fields to omit changing the user's password
                </Typography>
              )}

              <ControlledTextField
                name="password"
                control={control}
                type="password"
                label="Password"
              />
            </Grid>

            <Grid item xs={12}>
              <ControlledTextField
                type="password"
                name="passwordConfirmation"
                control={control}
                label="Password Confirmation"
              />
            </Grid>

            {ability.can("manage", "Schools") &&
              selectedRole !== "SchoolSystemManager" && (
                <Grid item xs={12}>
                  <ControlledSelectModalField
                    name="schoolId"
                    control={control}
                    variables={
                      authUser?.schoolSystemId
                        ? { schoolSystemId: authUser.schoolSystemId }
                        : undefined
                    }
                    initialValue={
                      editingUser?.school as SchoolsPaginatedItemFragment
                    }
                    textFieldProps={{
                      label: "School",
                      fullWidth: true,
                    }}
                    query={GetSchoolsPaginatedDocument}
                    labelsExractor={(item: SchoolsPaginatedItemFragment) => ({
                      primary: item.name,
                      secondary: item.schoolSystem?.name,
                    })}
                  />
                </Grid>
              )}
            {ability.can("manage", "School Systems") &&
              selectedRole === "SchoolSystemManager" && (
                <Grid item xs={12}>
                  <ControlledSelectModalField
                    name="schoolSystemId"
                    control={control}
                    initialValue={
                      editingUser?.schoolSystem as SchoolSystemPaginatedItemFragment
                    }
                    textFieldProps={{
                      label: "School System",
                      fullWidth: true,
                    }}
                    query={GetSchoolSystemsPaginatedDocument}
                    labelsExractor={(item) => ({
                      primary: item.name,
                    })}
                  />
                </Grid>
              )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel </Button>
          <LoadingButton loading={createLoading || updateLoading} type="submit">
            Submit
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UserFormModal;
