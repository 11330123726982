import { Container } from "@mui/material";
import React from "react";
import useAuthUser from "../../shared/hooks/useAuthUser";
import ParentsContent from "./components/ParentsContent";
import SystemManagersContent from "./components/SystemManagersContent";

interface DashboardHomePageProps {}

const DashboardHomePage: React.FC<DashboardHomePageProps> = ({}) => {
  const authUser = useAuthUser();
  const getContent = () => {
    const role = authUser?.role;
   
    if (role === "Parent") return <ParentsContent />;

    const isSuperAdmin = role === "SchoolSystemManager" || role === "SuperAdmin"
    return <SystemManagersContent showSchoolCard={isSuperAdmin} />;
  };

  return (
    <Container sx={{ paddingTop: 5, paddingBottom: 5 }}>
      {getContent()}
    </Container>
  );
};

export default DashboardHomePage;
