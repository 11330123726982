import { Box, Chip } from "@mui/material";
import React, { useCallback } from "react";
import { LabelsExtractorFunction, MultiSelectItem } from "./interfaces";

interface SelectedItemsListProps<Item> {
  labelsExtractor: LabelsExtractorFunction<Item>;
  selectedItems: Item[];
  onItemDeletePress: (item: Item, index: number) => void;
}

const SelectedItemsList = <Item extends MultiSelectItem>({
  selectedItems,
  labelsExtractor,
  onItemDeletePress,
}: SelectedItemsListProps<Item>) => {
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, marginY: "10px" }}>
      {selectedItems.map((item, index) => (
        <Chip
          key={item.id}
          label={labelsExtractor(item).primary}
          onDelete={() => onItemDeletePress(item, index)}
        />
      ))}
    </Box>
  );
};

export default SelectedItemsList;
