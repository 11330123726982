import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback } from "react";
import { CurrentRoute } from "./interfaces";
import { format } from "date-fns";
import { LocationSearching, MyLocation } from "@mui/icons-material";

interface CurrentRoutesListProps {
  currentRoutes: CurrentRoute[];
  selectedRoute?: string;
  onItemClick: (id: string) => void;
}

const CurrentRoutesList: React.FC<CurrentRoutesListProps> = ({
  currentRoutes,
  selectedRoute,
  onItemClick,
}) => {
  return (
    <Grid container>
      {currentRoutes.map((item) => (
        <Grid item xs={12} sm={6} lg={3} key={item.attendanceId}>
          <CurrentRoutesListItem
            route={item}
            selected={item.attendanceId === selectedRoute}
            onFollowClick={onItemClick}
          />
        </Grid>
      ))}
    </Grid>
  );
};

interface CurrentRoutesListItemProps {
  route: CurrentRoute;
  selected: boolean;
  onFollowClick: (id: string) => void;
}

const CurrentRoutesListItem: React.FC<CurrentRoutesListItemProps> = ({
  route,
  selected,
  onFollowClick,
}) => {
  const onClick = useCallback(() => onFollowClick(route.attendanceId), []);

  const [aditionalSchools, setAditionalSchools] = React.useState(
    route.aditionalSchools
  );

  return (
    <Card variant="outlined">
      <CardContent sx={{ textAlign: "start" }}>
        <Grid container xs={12}>
          <Grid item xs={6}>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              textAlign="start"
            >
              {format(route.attendanceStartTime, "hh:mm aa")}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              textAlign="end"
            >
              {format(route.attendanceStartTime, "MM/DD/YY")}
            </Typography>
          </Grid>
        </Grid>
        <Box display="flex" alignItems="center" marginBottom="10px">
          <Typography variant="h5" component="div">
            {route.routeId || "MISSING ID"}
          </Typography>{" "}
          {!!route.currentLocation && (
            <Checkbox
              onClick={onClick}
              checked={selected}
              icon={<LocationSearching />}
              checkedIcon={<MyLocation />}
            />
          )}
        </Box>

        <Typography sx={{ fontSize: 14 }} color="text.secondary">
          Driver
        </Typography>
        <Typography gutterBottom>{route.busDriverName}</Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary">
          Main School
        </Typography>
        <Typography gutterBottom>{route.schoolName}</Typography>
        {aditionalSchools && aditionalSchools?.length > 0 ? (
          <Typography sx={{ fontSize: 14 }} color="text.secondary">
            Aditional Schools
          </Typography>
        ) : null}
        {aditionalSchools?.map((school) => (
          <Typography key={school.id} gutterBottom>
            ⚬ {school.name}
          </Typography>
        ))}
      </CardContent>
    </Card>
  );
};

export default CurrentRoutesList;
