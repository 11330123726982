import { Typography } from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { DeleteFormDocument } from "../../apollo/forms/mutations.generated";
import {
  FormsPaginatedItemFragment,
  GetFormsPaginatedDocument,
} from "../../apollo/forms/queries.generated";
import Link from "../../navigation/Link";
import DeleteModal from "../../shared/components/DeleteModal";
import PageContainer from "../../shared/components/PageContainer";
import TableCard from "../../shared/components/TableCard";
import FormCreationFormModal from "./components/FormCreationModal";
import FormsTable from "./components/FormsTable";

interface FormsPageProps {}

const FormsPage: React.FC<FormsPageProps> = ({}) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [selectedForm, setSelectedForm] =
    useState<FormsPaginatedItemFragment>();
  const history = useHistory();

  const handleCloseModal = () => {
    setSelectedForm(undefined);
    setOpenModal(false);
  };

  const handleDeleteModalClose = () => {
    setSelectedForm(undefined);
    setshowDeleteModal(false);
  };

  return (
    <PageContainer>
      <DeleteModal
        open={showDeleteModal}
        mutation={DeleteFormDocument}
        updateQueries={[GetFormsPaginatedDocument]}
        variables={
          selectedForm
            ? {
                id: selectedForm.id,
              }
            : undefined
        }
        onClose={handleDeleteModalClose}
        title="Delete Form"
        successMessage="Form deleted successfully"
      />
      <FormCreationFormModal open={openModal} onClose={handleCloseModal} />
      <TableCard>
        <Typography
          variant="h5"
          textAlign="start"
          fontWeight="bold"
          color="secondary"
          gutterBottom
        >
          Forms
        </Typography>
        <FormsTable
          onAddClick={() => setOpenModal(true)}
          onEditPress={(item) => {
            history.push("/dashboard/forms/" + item.id);
          }}
          onDeletePress={(item) => {
            setSelectedForm(item);
            setshowDeleteModal(true);
          }}
        />
      </TableCard>
    </PageContainer>
  );
};

export default FormsPage;
