import React, { useMemo, useReducer } from "react";
import { Grid } from "@mui/material";
import {
  DataGrid,
  GridColumns,
  GridSortDirection,
  GridSortModel,
} from "@mui/x-data-grid";
import SearchField from "../../../shared/components/SearchField";
import { DocumentNode } from "graphql";
import { useQuery } from "@apollo/client";
import useDidMountEffect from "../../../shared/hooks/useDidMountEffect";
import useDebounce from "../../../shared/hooks/useDebounce";

type SingleSortModel = {
  field: string;
  sort: GridSortDirection;
};

type RouteLogsDataTableState = {
  search: string;
  sortModel: SingleSortModel;
  limit: number;
  currentPage: number;
};

type Action =
  | { type: "searchChanged"; search: string }
  | { type: "sortModelChanged"; model: SingleSortModel }
  | { type: "currentPageChanged"; page: number }
  | { type: "limitChanged"; limit: number };

const reducer = (
  state: RouteLogsDataTableState,
  action: Action
): RouteLogsDataTableState => {
  switch (action.type) {
    case "searchChanged":
      return {
        ...state,
        search: action.search,
        currentPage: 0,
      };

    case "currentPageChanged":
      return { ...state, currentPage: action.page };

    case "limitChanged":
      return { ...state, limit: action.limit };

    case "sortModelChanged":
      return { ...state, sortModel: action.model };
    default:
      return state;
  }
};

const initialState: RouteLogsDataTableState = {
  search: "",
  sortModel: { field: "createdAt", sort: "desc" },
  limit: 10,
  currentPage: 0,
};

export interface RouteLogsDataTableProps {
  query: DocumentNode;
  columns: GridColumns;
  actionColumns?: GridColumns;
  variables?: any;
  searchable?: boolean;
  refresherBoolean?: boolean;
  extraComponent?: React.ReactNode;
}

const RouteLogsDataTable = ({
  query,
  columns,
  variables,
  searchable = true,
  refresherBoolean,
}: RouteLogsDataTableProps) => {
  const [{ search, currentPage, limit, sortModel }, dispatch] = useReducer(
    reducer,
    initialState
  );
  const debouncedSearch = useDebounce(search, 500);

  const { data, refetch, loading } = useQuery(query, { variables });

  const { nodes, total } = useMemo(() => {
    if (!data) return { nodes: [] as any[], total: 0 };
    const resultKey = getFirstKey(data);
    if (!resultKey) return { nodes: [] as any[], total: 0 };
    const possibleNodes: any[] = data[resultKey].nodes;
    return { nodes: possibleNodes, total: data[resultKey].totalsCount };
  }, [data]);

  const handleOnSortChange = (model: GridSortModel) => {
    dispatch({
      type: "sortModelChanged",
      model: model.length ? model[0] : { field: "createdAt", sort: "desc" },
    });
  };

  const onLimitChange = (limit: number) => {
    dispatch({
      type: "limitChanged",
      limit,
    });
  };

  const onPageChange = (page: number) => {
    dispatch({
      type: "currentPageChanged",
      page,
    });
  };

  useDidMountEffect(() => {
    refetch({
      ...variables,
      search: debouncedSearch,
      sortBy: sortModel.field,
      sortDirection: sortModel.sort,
      limit: limit,
      offset: limit * currentPage,
    });
  }, [debouncedSearch, sortModel, currentPage, limit, refresherBoolean]);

  return (
    <Grid container justifyContent="flex-end" spacing={2} flex={1}>
      {searchable && (
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <SearchField
            onChange={(e) => {
              dispatch({ search: e.target.value, type: "searchChanged" });
            }}
          />
        </Grid>
      )}

      <Grid item xs={12} height="80%" maxHeight={"800px"}>
        <DataGrid
          rowCount={total}
          loading={loading}
          onSortModelChange={handleOnSortChange}
          columns={columns}
          pageSize={limit}
          onPageSizeChange={onLimitChange}
          rowsPerPageOptions={[10, 20, 50]}
          onPageChange={onPageChange}
          paginationMode="server"
          sortingMode="server"
          rows={nodes || []}
          autoHeight
        />
      </Grid>
    </Grid>
  );
};

export interface PaginationArgs {
  sortDirection?: "asc" | "desc";

  sortBy?: string;

  limit?: number;

  offset?: number;

  search?: string;
}

const getFirstKey = (object: object) => {
  for (let key in object) {
    return key;
  }
};

export default RouteLogsDataTable;
