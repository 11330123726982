import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import ControlledTextField from "../../../shared/components/ControlledTextField";
import {
  GetMyPaymentMethodDocument,
  MyPaymentMethodFragment,
  useAddPaymentMethodMutation,
  useUpdatePaymentMethodMutation,
} from "../../../apollo/parents/query.generated";
import { useForm } from "react-hook-form";

import React, { useEffect } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  CreatePaymentMethodAddressDto,
  CreatePaymentMethodDto,
  UpdatePaymentMethodDto,
} from "../../../apollo/types.generated";
import AddressFormSection, {
  getAddressDefaultValues,
} from "../../../shared/components/AddressFormSection";
import useDidMountEffect from "../../../shared/hooks/useDidMountEffect";
import Cards from "react-credit-cards";
import { Label } from "@mui/icons-material";

interface PaymentFormModalProps {
  open: boolean;
  onClose: () => void;
  editingPayment?: MyPaymentMethodFragment;
}

interface FormInputs extends CreatePaymentMethodDto {
  id?: string;
}

const getSchema = (): Yup.SchemaOf<FormInputs> =>
  Yup.object({
    id: Yup.string().optional(),
    nameOnCard: Yup.string().required("This field is required"),
    number: Yup.string().required("This field is required"),
    expMonth: Yup.string().required("This field is required"),
    expYear: Yup.string().required("This field is required"),
    address: Yup.object({
      city: Yup.string().required("This field is required"),
      country: Yup.string().required("This field is required"),
      postalCode: Yup.string().required("This field is required"),
      state: Yup.string().required("This field is required"),
      streetAddress1: Yup.string().required("This field is required"),
      streetAddress2: Yup.string().optional(),
    }),
  });

const getDefaultValues = (
  payment?: MyPaymentMethodFragment
): FormInputs | undefined => {
  return {
    id: payment?.id || "",
    nameOnCard: payment?.nameOnCard || "",
    number: payment?.number || "",
    expMonth: payment?.expMonth || "",
    expYear: payment?.expYear || "",
    address: {
      city: payment?.address?.city || "",
      country: payment?.address?.country || "",
      postalCode: payment?.address?.postalCode || "",
      state: payment?.address?.province || "",
      streetAddress1: payment?.address?.streetAddress1 || "",
    },
  };
};

const PaymentFormModal: React.FC<PaymentFormModalProps> = ({
  open,
  onClose,
  editingPayment,
}) => {
  //el watch se usa para los cambios que se realizan en el card number para actualizar la fotita de tarjeta
  const { control, handleSubmit, reset, setValue, watch } = useForm<FormInputs>(
    {
      resolver: yupResolver(getSchema()),
      shouldFocusError: true,
      defaultValues: getDefaultValues(editingPayment),
    }
  );

  const [createPaymentMethod] = useAddPaymentMethodMutation();
  const [updatePaymentMethod] = useUpdatePaymentMethodMutation();
  const onSubmit = async (data: FormInputs) => {
    const { nameOnCard, number, expMonth, expYear, address } = data;
    const {
      city,
      country,
      postalCode,
      state,
      streetAddress1,
    }: CreatePaymentMethodAddressDto = address;
    const updatePaymentMethodDto: UpdatePaymentMethodDto = {
      expMonth,
      expYear,
      address: {
        city,
        country,
        postalCode,
        state,
        streetAddress1,
      },
    };

    const createPaymentMethodDto: CreatePaymentMethodDto = {
      nameOnCard,
      number,
      expMonth,
      expYear,
      address: {
        city,
        country,
        postalCode,
        state,
        streetAddress1,
      },
    };

    // console.log(data);
    if (editingPayment) {
      await updatePaymentMethod({
        variables: {
          data: updatePaymentMethodDto,
        },
        refetchQueries: [{ query: GetMyPaymentMethodDocument }],
      });
    } else {
      await createPaymentMethod({
        variables: {
          data: createPaymentMethodDto,
        },
        refetchQueries: [{ query: GetMyPaymentMethodDocument }],
      });
    }
    reset();
    onClose();
  };

  const getYears = () => {
    const lastYear = new Date(
      new Date().setFullYear(new Date().getFullYear() + 10)
    );
    var list = [];
    for (var i = new Date().getFullYear(); i <= lastYear.getFullYear(); i++) {
      list.push(i);
    }

    return list;
  };

  useDidMountEffect(() => {
    reset(getDefaultValues(editingPayment));
  }, [editingPayment]);

  useEffect(() => {
    if (editingPayment && editingPayment.expMonth && editingPayment.expYear) {
      setValue("expMonth", editingPayment.expMonth);
      setValue("expYear", editingPayment.expYear);
    }
  }, [editingPayment, setValue]);

  const cardNumber: string | undefined = watch("number");
  // const cardNumber = "4584458445844584";
  const cardHolder: string | undefined = watch("nameOnCard");
  const cardExpMonth: string | undefined = watch("expMonth");
  const cardExpYear: string | undefined = watch("expYear");
  const expiry: string | undefined = `${cardExpMonth}/${cardExpYear}`;

  const months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  const years = getYears();
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <DialogTitle>
          {editingPayment ? "Update" : "Create"} Payment
          {/* Create Payment */}
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            sx={{ marginTop: "10px" }}
            spacing={2}
            columnSpacing={3}
            columns={12}
          >
            <Grid item xs={12}>
              <Typography variant="subtitle1">Card Information</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={5} justifyItems="center">
              <Cards
                cvc=""
                expiry={expiry}
                // focused=""
                name={cardHolder}
                number={cardNumber}
                preview={true}
              />
            </Grid>

            <Grid container item spacing={2} xs={12} md={7}>
              {!editingPayment && (
                <Grid item xs={12}>
                  <ControlledTextField
                    name="nameOnCard"
                    control={control}
                    label="Card Holder's Name"
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                {editingPayment ? (
                  <Box>
                    <Typography variant="h4" marginBottom={"6px"}>
                      {editingPayment.nameOnCard}
                    </Typography>
                    <Typography variant="h4" marginBottom={"4px"}>
                      {editingPayment.number}
                    </Typography>
                  </Box>
                ) : (
                  <ControlledTextField
                    name="number"
                    control={control}
                    label="Card Number"
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                <ControlledTextField
                  type="number"
                  select
                  name="expMonth"
                  control={control}
                  label="Card's Expiration Month"
                  value={editingPayment ? editingPayment.expMonth : 1}
                >
                  {months.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </ControlledTextField>
              </Grid>
              <Grid item xs={6}>
                <ControlledTextField
                  // onChange={{}}
                  select
                  type="number"
                  name="expYear"
                  control={control}
                  label="Card's Expiration Year"
                >
                  {years.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </ControlledTextField>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">Address Information</Typography>
            </Grid>
            <AddressFormSection control={control} />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel </Button>
          {/* <LoadingButton loading={createLoading || updateLoading} type="submit"> */}
          <Button type="submit">Submit</Button>
          {/* </LoadingButton> */}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default PaymentFormModal;
