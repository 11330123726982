import { Typography, colors, Box } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import InfoSection, { InfoItem } from "../../shared/components/InfoSection";
import PageContainer from "../../shared/components/PageContainer";
import LoadingPage from "../home/LoadingPage";
import { useGetParentQuery } from "../../apollo/parents/query.generated";

import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import SchoolIcon from "@mui/icons-material/School";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { LocationCityRounded } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import ChromeReaderModeIcon from "@mui/icons-material/ChromeReaderMode";
import GroupIcon from "@mui/icons-material/Group";

interface ParentPageProps {}

const ParentPage: React.FC<ParentPageProps> = ({}) => {
  let { id } = useParams<{ id: string }>();

  const { data, loading, error } = useGetParentQuery({
    variables: { id: id },
  });

  if (error) return <div>Error</div>;
  if (!data) return <LoadingPage />;

  const students = data.parent.studentsCount > 0 ? true : false;

  const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const studentsItems: InfoItem[] = [];

  const items: InfoItem[] = [
    {
      icon: `${data.parent.user.name.split(" ")[0][0]}${
        data.parent.user.name.split(" ")[1][0]
      }`,
      label: "Name",
      value: `${data.parent.user.name}`,
      color: colors.orange[500],
    },
    {
      icon: <EmailRoundedIcon />,
      label: "Email",
      value: `${data.parent.user.email}`,
      color: colors.yellow[700],
    },
    {
      icon: <AccountCircleOutlinedIcon />,
      label: "Role",
      value: `${data.parent.user.role}`,
      color: colors.lightBlue[200],
    },
    // {
    //   icon: ``,
    //   label: "",
    //   value: ``,
    //   color: colors.indigo[200],
    // },
  ];

  if (data.parent.schoolSystem)
    items.push({
      icon: <LocationCityRounded />,
      label: "SchoolSystem",
      value: `${data.parent.schoolSystem.name}`,
      color: colors.lightGreen[500],
    });

  if (data.parent.studentsCount >= 1) {
    for (let index = 0; index < data.parent.studentsCount; index++) {
      const element = data.parent.students![index];
      studentsItems.push({
        icon: `${element.user.name.split(" ")[0][0]}${
          element.user.name.split(" ")[1][0]
        }`,
        label: `${element.user.email}`,
        value: `${element.user.name}`,
        color: stringToColor(element.user.name),
      });
    }
  }

  return (
    <PageContainer>
      <Typography
        variant="h5"
        textAlign="start"
        fontWeight="bold"
        color="secondary"
        sx={{ marginRight: "10px" }}
      >
        Parent Information
      </Typography>

      <InfoSection items={items} />

      {students && (
        <Box>
          <Typography
            variant="h6"
            textAlign="start"
            fontWeight="bold"
            color="secondary"
            sx={{ marginRight: "10px", marginTop: "10px" }}
          >
            Assigned Students
          </Typography>
          <InfoSection items={studentsItems} />
        </Box>
      )}
    </PageContainer>
  );
};

export default ParentPage;
