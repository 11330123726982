import { GridColDef } from "@mui/x-data-grid";
import React from "react";
import {
  BusDriversPaginatedItemFragment,
  GetBusDriversPaginatedDocument,
} from "../../../apollo/busDrivers/queries.generated";
import { SchoolSystemPaginatedItemFragment } from "../../../apollo/schoolSystems/queries.generated";

import {
  GetStudentsPaginatedDocument,
  StudentsPaginatedItemFragment,
} from "../../../apollo/students/queries.generated";
import {
  GetParentsPaginatedDocument,
  ParentsPaginatedItemFragment,
} from "../../../apollo/parents/query.generated";
import { SchoolDto } from "../../../apollo/types.generated";

import RemoteDataTable, {
  BasicTableActionFunction,
  getBasicActionColumns,
  RemoteDataTableProps,
} from "../../../shared/components/RemoteDataTable";
import Link from "../../../navigation/Link";

interface ParentsTableProps
  extends Pick<RemoteDataTableProps, "onAddClick" | "refresherBoolean"> {
  onEditPress?: BasicTableActionFunction<ParentsPaginatedItemFragment>;
  onDeletePress?: BasicTableActionFunction<ParentsPaginatedItemFragment>;
}
const muiColumns: GridColDef[] = [
  // {
  //   flex: 1,
  //   minWidth: 200,
  //   field: "id",
  //   sortable: false,
  //   headerName: "View",
  //   renderCell: (params) => {
  //     return <Button>{params.value}</Button>;
  //   },
  // },

  {
    flex: 1,
    field: "name",
    headerName: "Name",
    minWidth: 200,
    renderCell: ({ row }) => {
      const parent = row as ParentsPaginatedItemFragment;
      return (
        <Link
          to={"/dashboard/parents/" + parent.id}
        >{`${parent.user.name}`}</Link>
      );
    },
  },
  {
    flex: 1,
    field: "email",
    headerName: "Email",
    minWidth: 200,
    valueGetter: ({ row }) => {
      const parent = row as BusDriversPaginatedItemFragment;
      return parent.user.email;
    },
  },
  {
    flex: 1,
    field: "studentsCount",
    headerName: "Total Students",
    minWidth: 200,
  },
  {
    flex: 1,
    field: "schoolSystem",
    headerName: "School System",
    valueGetter: ({ value }) => {
      return (value as SchoolSystemPaginatedItemFragment)?.name;
    },
    minWidth: 200,
  },
];

const ParentsTable: React.FC<ParentsTableProps> = ({
  onEditPress,
  onDeletePress,
  ...props
}) => {
  return (
    <RemoteDataTable
      query={GetParentsPaginatedDocument}
      searchable
      renderAddButton
      columns={muiColumns}
      actionColumns={getBasicActionColumns(onEditPress, onDeletePress)}
      {...props}
    />
  );
};

export default ParentsTable;
