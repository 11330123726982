import { GridColDef } from "@mui/x-data-grid";
import React from "react";
import { SchoolSystemPaginatedItemFragment } from "../../../apollo/schoolSystems/queries.generated";

import {
  GetStudentsPaginatedDocument,
  StudentsPaginatedItemFragment,
} from "../../../apollo/students/queries.generated";
import { SchoolDto } from "../../../apollo/types.generated";

import RemoteDataTable, {
  BasicTableActionFunction,
  getBasicActionColumns,
  RemoteDataTableProps,
} from "../../../shared/components/RemoteDataTable";
import Link from "../../../navigation/Link";

interface StudentsTableProps
  extends Pick<RemoteDataTableProps, "onAddClick" | "refresherBoolean"> {
  onEditPress?: BasicTableActionFunction<StudentsPaginatedItemFragment>;
  onDeletePress?: BasicTableActionFunction<StudentsPaginatedItemFragment>;
}
const muiColumns: GridColDef[] = [
  // {
  //   flex: 1,
  //   minWidth: 200,
  //   field: "id",
  //   sortable: false,
  //   headerName: "View",
  //   renderCell: (params) => {
  //     return <Button>{params.value}</Button>;
  //   },
  // },
  {
    flex: 1,
    field: "name",
    headerName: "Name",
    minWidth: 200,
    renderCell: ({ row }) => {
      const student = row as StudentsPaginatedItemFragment;
      return (
        <Link
          to={"/dashboard/students/" + student.id}
        >{`${student.user.firstName} ${student.user.lastName}`}</Link>
      );
      // ${student.user.firstName} ${student.user.lastName}
    },
  },
  {
    flex: 1,
    field: "studentId",
    headerName: "Student ID",
    minWidth: 200,
    renderCell: (params) => {
      return params.value || "Not Set";
    },
  },
  {
    flex: 1,
    field: "email",
    headerName: "Email",
    minWidth: 200,
    valueGetter: ({ row }) => {
      const student = row as StudentsPaginatedItemFragment;
      return student.user.email;
    },
  },

  { flex: 1, field: "accountStatus", headerName: "Status", minWidth: 200 },
  {
    flex: 1,
    field: "school",
    headerName: "School",
    valueGetter: ({ value }) => {
      return (value as SchoolDto).name;
    },
    minWidth: 200,
  },
  {
    flex: 1,
    field: "schoolSystem",
    headerName: "School System",
    valueGetter: ({ value }) => {
      return (value as SchoolSystemPaginatedItemFragment)?.name;
    },
    minWidth: 200,
  },
];

const StudentsTable: React.FC<StudentsTableProps> = ({
  onEditPress,
  onDeletePress,
  ...props
}) => {
  return (
    <RemoteDataTable
      query={GetStudentsPaginatedDocument}
      searchable
      renderAddButton
      columns={muiColumns}
      actionColumns={getBasicActionColumns(onEditPress, onDeletePress)}
      {...props}
    />
  );
};

export default StudentsTable;
