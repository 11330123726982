import { Box, Card, Grid, Typography } from "@mui/material";
import React, { useState } from "react";

import {
  DeleteUserDocument,
  GetUsersPaginatedDocument,
  UsersPaginatedItemFragment,
} from "../../apollo/users/queries.generated";

import DeleteModal from "../../shared/components/DeleteModal";
import PageContainer from "../../shared/components/PageContainer";
import TableCard from "../../shared/components/TableCard";
import UserFormModal from "./components/UserFormModal";
import UsersTable from "./components/UsersTable";

interface UsersPageProps {}

const UsersPage: React.FC<UsersPageProps> = ({}) => {
  const [showFormModal, setShowFormModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedUser, setSelectedStudent] =
    useState<UsersPaginatedItemFragment>();

  const handleFormClose = () => {
    setSelectedStudent(undefined);
    setShowFormModal(false);
  };

  const handleDeleteModalClose = () => {
    setSelectedStudent(undefined);
    setShowDeleteModal(false);
  };
  return (
    <PageContainer>
      <UserFormModal
        editingUser={selectedUser}
        open={showFormModal}
        onClose={handleFormClose}
      />
      <DeleteModal
        open={showDeleteModal}
        mutation={DeleteUserDocument}
        updateQueries={[GetUsersPaginatedDocument]}
        variables={{ id: selectedUser?.id }}
        onClose={handleDeleteModalClose}
        title="Delete User"
      />
      <TableCard>
        <Typography
          variant="h5"
          textAlign="start"
          fontWeight="bold"
          color="secondary"
          gutterBottom
        >
          Users
        </Typography>
        <UsersTable
          onAddClick={() => setShowFormModal(true)}
          onEditPress={(item) => {
            setSelectedStudent(item);
            setShowFormModal(true);
          }}
          onDeletePress={(item) => {
            setSelectedStudent(item);
            setShowDeleteModal(true);
          }}
        />
        {/* <DataGrid
        style={{
          border: "none",
        }}
        columns={columns}
        rows={data?.StudentsystemsPaginated.nodes || []}
      /> */}
      </TableCard>
    </PageContainer>
  );
};

export default UsersPage;
