import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  FormControlLabel,
  Switch,
  DialogActions,
  Button,
  MenuItem,
  Typography,
  Divider,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  SchoolSystemSettingsFragment,
  useGetSchoolSystemSettingsQuery,
  useUpdateSchoolSystemSettingsMutation,
} from "../../../apollo/schoolSystemSettings/queries.generated";
import useAbility from "../../../shared/hooks/useAbility";
import LoadingPage from "../../home/LoadingPage";
import * as Yup from "yup";
import { UpdateSchoolSystemSettingsDto } from "../../../apollo/types.generated";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import ControlledTextField from "../../../shared/components/ControlledTextField";
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import { listTimeZones } from "timezone-support";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
import useSettingsStore from "../../../shared/stores/settingsStore";
import { HelpOutline, InfoRounded } from "@mui/icons-material";
import {
  GetSchoolSystemsPaginatedDocument,
  useGetSchoolSystemsPaginatedQuery,
} from "../../../apollo/schoolSystems/queries.generated";
import {
  GetFamiliesPaginatedDocument,
  useGetFamiliesPaginatedQuery,
} from "../../../apollo/families/query.generated";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
const timezones = listTimeZones().filter((zone) => zone.includes("America"));
const paymentMethods = ["Parent", "SchoolSystem"];
interface SchoolSystemSettingsModalProps {
  schoolSystemId?: string;
  open: boolean;
  onClose: () => void;
}
const SchoolSystemSettingsModal: React.FC<SchoolSystemSettingsModalProps> = ({
  schoolSystemId,
  open,
  onClose,
}) => {
  const ability = useAbility();
  const [openToast, setOpenToast] = useState(false);
  const [submit, { data, error, loading }] =
    useUpdateSchoolSystemSettingsMutation();
  const onSubmit = (data: FormInputs) => {
    if (checkFamiliesFee(data) === false) {
      submit({
        variables: {
          data: data,
          schoolSystemId,
        },
        refetchQueries: [
          GetSchoolSystemsPaginatedDocument,
          GetFamiliesPaginatedDocument,
        ],
      });
    }
  };

  const checkFamiliesFee = (data: FormInputs) => {
    if (data.familiesPaymentMethod === "Parent") {
      if (data.subscriptionFee < 1) {
        setOpenToast(true);
        return true;
      }
      return false;
    }
    return false;
  };

  const { loadTimezone } = useSettingsStore();

  useEffect(() => {
    if (data) {
      loadTimezone(data.updateSchoolSystemSettings.timezone || "");
      toast.success("School System's settings updated successfully. ");
      onClose();
    }
    if (error) {
      toast.error("Error updating School System's settings: " + error.message);
    }
  }, [data, error]);

  useEffect(() => {
    if (openToast) {
      toast.error(
        "Families subscription fee must be greater or equal to $1.00"
      );
      setOpenToast(false);
    }
  }, [openToast]);
  return (
    <Dialog
      open={open}
      //   onClose={onClose}
      onClose={!loading ? onClose : undefined}
      maxWidth="md"
      fullWidth
    >
      {(!ability.can("manage", "School Systems") || schoolSystemId) && (
        <SchoolSystemSettingsFormContainer
          schoolSystemId={schoolSystemId}
          onSubmit={onSubmit}
          actions={
            <DialogActions>
              <Button
                disabled={loading}
                onClick={!loading ? onClose : undefined}
              >
                Cancel{" "}
              </Button>
              <LoadingButton loading={loading} type="submit">
                Submit
              </LoadingButton>
            </DialogActions>
          }
        />
      )}
    </Dialog>
  );
};
interface SchoolSystemSettingsFormContainerProps {
  schoolSystemId?: string;
  actions: React.ReactNode;
  onSubmit: (data: FormInputs) => void;
}
const SchoolSystemSettingsFormContainer: React.FC<
  SchoolSystemSettingsFormContainerProps
> = ({ schoolSystemId, onSubmit, actions }) => {
  const { data, loading, error } = useGetSchoolSystemSettingsQuery({
    variables: { schoolSystemId },
    fetchPolicy: "network-only",
  });
  if (!data?.schoolSystemSettings)
    return (
      <DialogContent>
        <LoadingPage />
      </DialogContent>
    );
  return (
    <>
      <DialogTitle>
        Change {data.schoolSystemSettings.schoolSystem.name} settings
      </DialogTitle>
      <DialogContent>
        <SchoolSystemSettingsForm
          settings={data.schoolSystemSettings}
          onSubmit={onSubmit}
          actions={actions}
        />
      </DialogContent>
    </>
  );
};
interface FormInputs extends UpdateSchoolSystemSettingsDto {}
const schema: Yup.SchemaOf<FormInputs> = Yup.object({
  timezone: Yup.string().required("This field is required"),
  showBusStops: Yup.boolean().required("This field is required"),
  showSubstituteRoutes: Yup.boolean().required("This field is required"),
  familiesPaymentMethod: Yup.string().required("This field is required"),
  feePerRoute: Yup.number().required("This field is required"),
  subscriptionFee: Yup.number().required("This field is required"),
  turnByTurnDirections: Yup.boolean().required("This field is required"),
  showBusTracking: Yup.boolean().required("This field is required"),
});
interface SchoolSystemSettingsFormProps
  extends Pick<SchoolSystemSettingsFormContainerProps, "onSubmit" | "actions"> {
  settings: SchoolSystemSettingsFragment;
}
const SchoolSystemSettingsForm: React.FC<SchoolSystemSettingsFormProps> = ({
  settings,
  actions,
  onSubmit,
}) => {
  const { control, handleSubmit, watch } = useForm<FormInputs>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    defaultValues: {
      showBusStops: settings.showBusStops,
      timezone: settings.timezone || ("" as string | undefined),
      showSubstituteRoutes: settings.showSubstituteRoutes,
      familiesPaymentMethod: settings.familiesPaymentMethod,
      feePerRoute: Number(settings.feePerRoute),
      subscriptionFee: Number(settings.subscriptionFee),
      turnByTurnDirections: settings.turnByTurnDirections || false,
      showBusTracking: settings.showBusTracking || false,
    },
  });
  const ability = useAbility();
  const timezone = watch("timezone");
  const paymentMethod = watch("familiesPaymentMethod");

  const currentTime = !!timezone ? dayjs().tz(timezone) : dayjs();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} sx={{ marginTop: "10px" }}>
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            name="timezone"
            fullWidth
            select
            label="Timezone"
          >
            {timezones.map((zone) => (
              <MenuItem value={zone} key={zone}>
                {zone}
              </MenuItem>
            ))}
          </ControlledTextField>
          <Typography>Current time: {currentTime.format("hh:mm a")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="showBusStops"
            key={"showBusStops"}
            render={({ field, fieldState }) => (
              <FormControlLabel
                control={
                  <Switch
                    value={field.value}
                    onChange={field.onChange}
                    checked={field.value}
                  />
                }
                label="Show bus stops to students and parents"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="showSubstituteRoutes"
            key={"showSubstituteRoutes"}
            render={({ field, fieldState }) => (
              <FormControlLabel
                control={
                  <Switch
                    value={field.value}
                    onChange={field.onChange}
                    checked={field.value}
                  />
                }
                label="Show Substitute Routes"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="turnByTurnDirections"
            key={"turnByTurnDirections"}
            render={({ field, fieldState }) => (
              <FormControlLabel
                control={
                  <Switch
                    value={field.value}
                    onChange={field.onChange}
                    checked={field.value}
                  />
                }
                label="Show Turn by Turn Directions"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="showBusTracking"
            key={"showBusTracking"}
            render={({ field, fieldState }) => (
              <FormControlLabel
                control={
                  <Switch
                    value={field.value}
                    onChange={field.onChange}
                    checked={field.value}
                  />
                }
                label="Show Bus Tracking System"
              />
            )}
          />
        </Grid>
        {ability.can("manage", "all") && (
          <Grid item xs={12}>
            <Typography color={"gray"}>
              These fields are only available to administrators.
            </Typography>
            <Divider color="lightgrey" />
            <ControlledTextField
              control={control}
              name="familiesPaymentMethod"
              fullWidth
              select
              label="Families Subscriptions Payment Method"
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Tooltip
                      title="⚬ Parent: Parents will pay their own family subscriptions with credit card. 
                      ⚬ SchoolSystem: The representative from this school system will pay for their families suscriptions."
                    >
                      <InfoRounded />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            >
              {paymentMethods.map((method) => (
                <MenuItem value={method} key={method}>
                  {method}
                </MenuItem>
              ))}
            </ControlledTextField>
            {paymentMethod === "SchoolSystem" && (
              <ControlledTextField
                control={control}
                name="feePerRoute"
                fullWidth
                label="Fee Per Route"
                margin="normal"
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachMoneyIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            {paymentMethod === "Parent" && (
              <ControlledTextField
                control={control}
                name="subscriptionFee"
                fullWidth
                label="Families subscription fee"
                margin="normal"
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachMoneyIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </Grid>
        )}
      </Grid>
      {actions}
    </form>
  );
};
export default SchoolSystemSettingsModal;
