import { Box, Button, colors, Typography } from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import {
  GetUserDocument,
  UpdateUserStatusDocument,
  useGetUserQuery,
} from "../../apollo/users/queries.generated";

import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import SchoolIcon from "@mui/icons-material/School";
import { LocationCityRounded } from "@mui/icons-material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import DisableUserModal from "./components/DisableUserFormModal";
import PageContainer from "../../shared/components/PageContainer";
import LoadingPage from "../home/LoadingPage";
import { InfoItem } from "../../shared/components/InfoSection";
import InfoSection from "../../shared/components/InfoSection";

interface UserPageProps {}

const UserPage: React.FC<UserPageProps> = ({}) => {
  let { id } = useParams<{ id: string }>();

  const [showDisableModal, setShowDisableModal] = useState(false);

  const { data, loading, error } = useGetUserQuery({
    variables: { id: id },
  });

  if (error) return <div>Error</div>;
  if (!data) return <LoadingPage />;
  const isDisabled = data.User.isDisabled;
  const handleDisableModalClose = () => {
    setShowDisableModal(false);
  };
  const items: InfoItem[] = [
    {
      icon: `${data.User.name.split(" ")[0][0]}${
        data.User.name.split(" ")[1][0]
      }`,
      label: "Name",
      value: `${data.User.name}`,
      color: colors.orange[500],
    },
    {
      icon: <EmailRoundedIcon />,
      label: "Email",
      value: `${data.User.email}`,
      color: colors.yellow[700],
    },
    {
      icon: <AccountCircleOutlinedIcon />,
      label: "Role",
      value: `${data.User.role}`,
      color: colors.lightBlue[200],
    },
  ];

  if (data.User.schoolSystem)
    items.push({
      icon: <LocationCityRounded />,
      label: "SchoolSystem",
      value: `${data.User.schoolSystem.name}`,
      color: colors.lightGreen[500],
    });

  if (data.User.school)
    items.push({
      icon: <SchoolIcon />,
      label: "School ",
      value: `${data.User.school.name}`,
      color: colors.brown[300],
    });

  return (
    <PageContainer>
      <DisableUserModal
        open={showDisableModal}
        mutation={UpdateUserStatusDocument}
        updateQueries={GetUserDocument}
        variables={{ id: id, isDisabled: !isDisabled }}
        onClose={handleDisableModalClose}
        text={
          isDisabled
            ? "Are you sure you want to enable this user? This action can be changed later"
            : "Are you sure you want to disable this user? This action can be changed later"
        }
        btnText={isDisabled ? "Enable" : "Disable"}
        title={isDisabled ? "Enable user" : "Disable user"}
        successMessage={
          isDisabled
            ? "The user was enabled successfully"
            : "The user was disabled successfully"
        }
        errorMessage={
          isDisabled
            ? "There was problem enabling this user: "
            : "There was problem disabling this user: "
        }
      />
      <Box
        sx={{
          display: "flex",
          marginBottom: "10px",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h5"
          textAlign="start"
          fontWeight="bold"
          color="secondary"
          sx={{ marginRight: "10px" }}
        >
          User Information
        </Typography>
        <Button
          variant="outlined"
          onClick={() => setShowDisableModal(true)}
          size="medium"
        >
          {isDisabled ? "Enable" : "Disable"}
        </Button>
      </Box>

      <InfoSection items={items} />
    </PageContainer>
  );
};

export default UserPage;
