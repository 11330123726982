import React, { useEffect } from "react";
import {
  Grid,
  Modal,
  Paper,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";

import useDidMountEffect from "../../../shared/hooks/useDidMountEffect";

import ControlledTextField from "../../../shared/components/ControlledTextField";
import ControlledSelectModalField from "../../../shared/components/ControlledSelectModalField";
import {
  GetSchoolSystemsPaginatedDocument,
  SchoolSystemPaginatedItemFragment,
} from "../../../apollo/schoolSystems/queries.generated";
import {
  GetSchoolsPaginatedDocument,
  SchoolsPaginatedItemFragment,
} from "../../../apollo/schools/queries.generated";
import apolloClient from "../../../apollo/client";
import {
  CheckEmailAvailabilityQuery,
  CheckEmailAvailabilityDocument,
  CheckEmailAvailabilityQueryVariables,
} from "../../../apollo/users/queries.generated";
import { CreateParentDto } from "../../../apollo/types.generated";

import ControlledSearchSelect from "../../../shared/components/ControlledSearchSelect";
import {
  GetStudentsPaginatedDocument,
  StudentsPaginatedItemFragment,
} from "../../../apollo/students/queries.generated";
import useAuthUser from "../../../shared/hooks/useAuthUser";
import {
  GetParentsPaginatedDocument,
  ParentsPaginatedItemFragment,
  useCreateParentMutation,
  useUpdateParentMutation,
} from "../../../apollo/parents/query.generated";
import { StudentItem } from "../../../shared/components/interfaces";
import useAbility from "../../../shared/hooks/useAbility";

interface ParentsFormModalProps {
  open: boolean;
  onClose: () => void;
  editingParent?: ParentsPaginatedItemFragment;
}

interface FormInputs extends Omit<CreateParentDto, "password"> {
  // students: StudentItem[];
  passwordConfirmation?: string;
  password?: string;
}

const getSchema = (editingParent?: ParentsPaginatedItemFragment) =>
  Yup.object({
    schoolId: Yup.string().optional(),
    // students: Yup.array<StudentItem>(
    //   Yup.object({
    //     id: Yup.string().required(),
    //     user: Yup.object<{ [key: string]: any }>({
    //       name: Yup.string().required(),
    //     }).required(),
    //   }).required()
    // )

    //   .min(1, "You must assign a student to this parent")
    //   .required(),
    firstName: Yup.string().required("This field is required"),
    lastName: Yup.string().required("This field is required"),
    email: Yup.string()
      .required("This field is required")
      .email("Invalid email")
      .test(
        "test-mail",
        "This email address is not available",
        async (value, context) => {
          if (!value) return false;
          const result = await apolloClient.query<
            CheckEmailAvailabilityQuery,
            CheckEmailAvailabilityQueryVariables
          >({
            query: CheckEmailAvailabilityDocument,
            variables: { email: value, userId: editingParent?.user.id },
          });

          return result.data.checkEmailAvailability;
        }
      ),
    password: editingParent
      ? Yup.string().optional()
      : Yup.string().required("This field is required"),
    passwordConfirmation: editingParent
      ? Yup.string()
          .optional()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
      : Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required(""),
    schoolSystemId: Yup.string().required("This field is required"),
  }).required();

const getDefaultValues = (
  parent?: ParentsPaginatedItemFragment
): FormInputs | undefined => {
  return {
    firstName: parent?.user.firstName || "",
    lastName: parent?.user.lastName || "",
    email: parent?.user.email || "",
    password: "",
    passwordConfirmation: "",
    // students:
    //   parent?.students.map((s) => ({ ...s, id: s.id, user: { ...s.user } })) ||
    //   [],
    schoolSystemId: parent?.schoolSystem?.id || "",
  };
};
const ParentsFormModal: React.FC<ParentsFormModalProps> = ({
  open,
  onClose,
  editingParent,
}) => {
  const authUser = useAuthUser();
  const ability = useAbility();
  const { control, handleSubmit, reset, setValue } = useForm<FormInputs>({
    resolver: yupResolver(getSchema(editingParent)),
    shouldFocusError: true,
    defaultValues: getDefaultValues(editingParent),
  });

  const [
    create,
    { data: createData, loading: createLoading, error: createError },
  ] = useCreateParentMutation({
    refetchQueries: [GetParentsPaginatedDocument],
  });

  const [
    update,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useUpdateParentMutation({
    refetchQueries: [GetParentsPaginatedDocument],
  });

  const onSubmit = (data: FormInputs) => {
    const { passwordConfirmation, ...rest } = data;
    const dataToSubmit = { ...rest};
    if (editingParent) {
      update({
        variables: {
          id: editingParent.id,
          data: dataToSubmit,
        },
      });
      return;
    }

    if (rest.password) {
      create({
        variables: {
          data: {
            ...dataToSubmit,
            password: rest.password,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (createData) {
      toast.success("Parent created successfully");
      onClose();
    }

    if (createError) {
      toast.error("Error creating Parent: " + createError.message);
    }
  }, [createData, createError]);

  useEffect(() => {
    if (updateData) {
      toast.success("Parent updated successfully");
      onClose();
    }

    if (updateError) {
      toast.error("Error updating Parent: " + updateError.message);
    }
  }, [updateData, updateError]);

  useDidMountEffect(() => {
    reset(getDefaultValues(editingParent));
  }, [editingParent]);

  useEffect(() => {
    if (authUser && authUser.schoolSystemId) {
      setValue("schoolSystemId", authUser.schoolSystemId);
    }
  }, [authUser]);

  return (
    <Dialog
      open={open}
      onClose={!createLoading ? onClose : undefined}
      maxWidth="md"
      fullWidth
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <DialogTitle>{editingParent ? "Update" : "Create"} Parent</DialogTitle>
        <DialogContent>
          <Grid container sx={{ marginTop: "10px" }} spacing={2}>
            <Grid item xs={12}>
              <ControlledTextField
                name="firstName"
                control={control}
                label="First Name"
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                name="lastName"
                control={control}
                label="Last Name"
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                name="email"
                control={control}
                label="Email"
              />
            </Grid>

            <Grid item xs={12}>
              {!!editingParent && (
                <Typography variant="caption" color="GrayText">
                  You can skip this field to omit changing the parent's password
                </Typography>
              )}
              <ControlledTextField
                name="password"
                control={control}
                type="password"
                label="Password"
              />
            </Grid>

            <Grid item xs={12}>
              <ControlledTextField
                type="password"
                name="passwordConfirmation"
                control={control}
                label="Password Confirmation"
              />
            </Grid>
            {!authUser?.schoolSystemId && (
              <Grid item xs={12}>
                <ControlledSelectModalField
                  name="schoolSystemId"
                  control={control}
                  variables={
                    authUser?.schoolSystemId
                      ? { schoolSystemId: authUser.schoolSystemId }
                      : undefined
                  }
                  initialValue={
                    editingParent?.schoolSystem as SchoolSystemPaginatedItemFragment
                  }
                  textFieldProps={{
                    label: "School System",
                    fullWidth: true,
                  }}
                  query={GetSchoolSystemsPaginatedDocument}
                  labelsExractor={(item) => ({
                    primary: item.name,
                  })}
                />
              </Grid>
            )}

            {/* <Grid item xs={12}>
              <ControlledSearchSelect
                name="students"
                control={control}
                initialValue={editingParent?.students}
                multiple
                valueMapper={(items) => {
                  return items;
                }}
                optionLabelExtractor={(item: StudentsPaginatedItemFragment) =>
                  `${item.user.name} ${
                    ability.can("manage", "Schools")
                      ? ` | ${item.school?.name}`
                      : ""
                  }`
                }
                TextFieldProps={{
                  label: "Assigned Students",
                }}
                query={GetStudentsPaginatedDocument}
              />
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel </Button>
          <LoadingButton loading={createLoading || updateLoading} type="submit">
            Submit
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ParentsFormModal;
