import { Box, Button, IconButton, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useMemo, useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import {
  DeleteFormFieldDocument,
  useUpdateFormFieldMutation,
  useUpdateManyFormFieldsMutation,
} from "../../../apollo/forms/mutations.generated";
import {
  FormFieldFragment,
  FormsPaginatedItemFragment,
  GetFormByIdDocument,
  useGetFormByIdQuery,
  useGetFormFieldByIdLazyQuery,
} from "../../../apollo/forms/queries.generated";
import DraggableList from "../../../shared/components/DraggableList";
import { reorder } from "../../../shared/components/DraggableListHelper";
import useDidMountEffect from "../../../shared/hooks/useDidMountEffect";
import AddBoxIcon from "@mui/icons-material/AddBox";
import FormFieldsCreationModal from "./FormFieldsCreationModal";
import DeleteModal from "../../../shared/components/DeleteModal";
import { DocumentNode } from "graphql";

interface FormFieldsToolManageProps {
  formInfo?: FormsPaginatedItemFragment;
}

const FormFieldsToolManage: React.FC<FormFieldsToolManageProps> = ({
  formInfo,
}) => {
  const fieldsInfo = formInfo?.formFields;
  const getItems = (fields: FormFieldFragment[]) =>
    fields.map((field) => ({
      id: field.id,
      type: field.type,
      primary: (
        <Box>
          <Typography variant="body1" fontWeight="bold">
            Title:
          </Typography>
          <Typography variant="body1">{field.title}</Typography>
        </Box>
      ),
      secondary: (
        <Box>
          Required: {field.required === true ? "Yes. " : "No. "}
          {field.type ===
            ("text" ||
              "number" ||
              "phone" ||
              "email" ||
              "multiline" ||
              "dropdown") &&
            `Hint Text: ${field.hintText ? `${field.hintText}. ` : "None."}`}
          {field.type === "radio" &&
            `Options: ${field.radioButtons?.map((option) => ` ${option}`)}`}
          {field.type === "checkbox" &&
            `Options: ${field.checkBoxes?.map((option) => ` ${option}`)}`}
          {field.type === "dropdown" &&
            `Options: ${field.dropdownItems?.map((option) => ` ${option}`)}`}
        </Box>
      ),
    }));

  const [openModal, setOpenModal] = React.useState(false);
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [items, setItems] = React.useState(getItems(fieldsInfo || []));
  const [fieldsMoved, setFieldsMoved] = useState(false);
  const [fieldsOrderInfo, setFieldsOrderInfo] = useState<any[]>([]);
  const [
    update,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useUpdateManyFormFieldsMutation({
    refetchQueries: [
      { query: GetFormByIdDocument, variables: { id: formInfo?.id } },
    ],
  });

  const [fieldId, setFieldId] = useState<string>("");
  const [getFieldById, { data: fieldData }] = useGetFormFieldByIdLazyQuery({
    fetchPolicy: "network-only",
  });

  const onDragEnd = async ({ destination, source }: DropResult) => {
    if (!destination) return;

    const newItems = reorder(items, source.index, destination.index);
    setItems(newItems);
  };

  const updateFieldsOrderOnClick = async () => {
    if (fieldsOrderInfo.length > 0) {
      update({
        variables: {
          data: { data: fieldsOrderInfo },
        },
      });

      setFieldsOrderInfo([]);
      setFieldsMoved(false);
    }
  };

  const checkFieldsInfoOrder = (newFormFieldsOrder: string[]) => {
    const fieldOrderByID = fieldsInfo?.map((field) => field.id);
    if (fieldOrderByID) {
      const sameOrder = newFormFieldsOrder.every((fieldID, index) => {
        return fieldID === fieldOrderByID[index];
      });

      if (!sameOrder) {
        newFormFieldsOrder.forEach((fieldID, index) => {
          const fieldInfo = fieldsInfo?.find((field) => field.id === fieldID);
          if (fieldInfo) {
            const fieldOrderInfo = fieldsOrderInfo.find(
              (field) => field.id === fieldID
            );

            if (fieldOrderInfo) {
              fieldOrderInfo.order = index;
              setFieldsOrderInfo((prev) => [
                ...prev.filter((field) => field.id !== fieldID),
                fieldOrderInfo,
              ]);
            }

            if (!fieldOrderInfo) {
              setFieldsOrderInfo((prev) => [
                ...prev,
                { id: fieldID, order: index },
              ]);
            }
          }
        });

        setFieldsMoved(true);
      } else {
        setFieldsOrderInfo([]);
        setFieldsMoved(false);
      }
    }

    return false;
  };

  const handleCloseModal = async () => {
    setFieldId("");
    getFieldById({
      variables: {
        id: "",
      },
    });
    setOpenModal(false);
  };

  const handleDeleteModalClose = () => {
    setFieldId("");
    setshowDeleteModal(false);
  };

  useEffect(() => {
    setItems(getItems(fieldsInfo || []));
  }, [formInfo]);

  useEffect(() => {
    checkFieldsInfoOrder(items.map((item) => item.id));
  }, [items]);

  useEffect(() => {
    if (fieldId && !showDeleteModal) {
      getFieldById({
        variables: {
          id: fieldId,
        },
      });
    }
  }, [fieldId]);

  useEffect(() => {
    if (fieldData) {
      setOpenModal(true);
    }
  }, [fieldData]);

  return (
    <Box
      sx={{
        paddingLeft: "20px",
      }}
    >
      <FormFieldsCreationModal
        open={openModal}
        onClose={handleCloseModal}
        editingField={fieldData?.getFormFieldById}
        formId={formInfo!.id}
        fieldsLength={formInfo!.formFields.length}
      />
      <DeleteModal
        open={showDeleteModal}
        mutation={DeleteFormFieldDocument}
        updateQueries={
          [
            { query: GetFormByIdDocument, variables: { id: formInfo?.id } },
          ] as any
        }
        variables={fieldId ? { id: fieldId } : undefined}
        onClose={handleDeleteModalClose}
        title="Delete Field"
        successMessage="Field deleted successfully"
      />

      <Box
        sx={{
          display: "flex",
          marginBottom: "20px",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Typography
          textAlign="start"
          fontWeight="bold"
          //   color="secondary"
          sx={{ marginRight: "10px" }}
        >
          Manage Form Fields
        </Typography>
      </Box>

      <Box
        display="flex"
        // flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="start"
          sx={{ marginRight: "20px" }}
          visibility={formInfo!.formFields.length > 1 ? "visible" : "hidden"}
        >
          <Typography variant="body2" fontWeight="bold" color={"gray"}>
            Drag and drop fields to reorder them
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="end">
          <Button
            variant="contained"
            color="inherit"
            onClick={() => setOpenModal(true)}
            startIcon={<AddBoxIcon color="action" />}
            sx={{ marginBottom: "5px", marginLeft: "20px" }}
          >
            Add New Field
          </Button>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="10px"
        sx={{
          overflowY: "scroll",
          width: "100%",
          marginBottom: "10px",
          paddingLeft: "20px",
          paddingRight: "20px",
          height: "50vh",
          border: "2px solid #ccc",
          borderRadius: "5px",
          paddingBottom: "10px",
        }}
      >
        <DraggableList
          items={items}
          onDragEnd={onDragEnd}
          renderActionButtons
          onDeletePress={(item) => {
            setFieldId(item);
            setshowDeleteModal(true);
          }}
          onEditPress={(item) => {
            setFieldId(item);
          }}
        />
      </Box>

      {/* Box to align items to right */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "20px",
        }}
        visibility={fieldsMoved ? "visible" : "hidden"}
      >
        <Button
          variant="contained"
          color="inherit"
          disabled={!fieldsMoved}
          onClick={updateFieldsOrderOnClick}
        >
          Save Reorder Changes
        </Button>
      </Box>
    </Box>
  );
};

export default FormFieldsToolManage;
