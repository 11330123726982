import * as React from "react";
import DraggableListItem from "./DraggableListItem";
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder,
} from "react-beautiful-dnd";
// import { Item } from '../typings';

export type DraggableListProps = {
  //   items: Item[];
  items: any[];
  renderActionButtons?: boolean;
  onEditPress?: (item: any) => void;
  onDeletePress?: (item: any) => void;
  onDragEnd: OnDragEndResponder;
};

const DraggableList = React.memo(
  ({
    items,
    onDragEnd,
    onEditPress,
    onDeletePress,
    renderActionButtons,
  }: DraggableListProps) => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-list">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {items.map((item, index) => (
                <DraggableListItem
                  item={item}
                  type={item.type}
                  index={index}
                  key={item.id}
                  renderActionButtons={renderActionButtons}
                  onEditPress={onEditPress}
                  onDeletePress={onDeletePress}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
);

export default DraggableList;
