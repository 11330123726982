import { GridColDef } from "@mui/x-data-grid";
import React from "react";
import { timezoneAwareFormat } from "../../../shared/components/TimezoneAwareDatetime";
import RouteLogsDataTable from "./RouteLogsDataTable";
import { BusDriversPaginatedItemFragment } from "../../../apollo/busDrivers/queries.generated";
import { AttendanceListPaginatedDocument } from "../../../apollo/busRoutes/queries.generated";

interface RouteLogsTableProps {
  routeId: string;
  pathType: string;
}
const muiColumns: GridColDef[] = [
  {
    flex: 1,
    field: "createdAt",
    headerName: "Date",
    minWidth: 20,
    valueFormatter: ({ value }) =>
      timezoneAwareFormat(value as Date, "MMM D, YYYY"),
  },
  {
    flex: 1,
    field: "startTime",
    headerName: "Start Time",
    minWidth: 20,
    valueFormatter: ({ value }) => timezoneAwareFormat(value as Date, "h:mm A"),
    sortable: false,
  },
  {
    flex: 1,
    field: "completedAt",
    headerName: "End Time",
    minWidth: 20,
    valueFormatter: ({ value }) =>
      value ? timezoneAwareFormat(value as Date, "h:mm A") : "Not Completed",
    sortable: false,
  },
  {
    flex: 1,
    field: "totalTimeInAttendance",
    headerName: "Total Time",
    minWidth: 20,
    sortable: false,
  },
  {
    flex: 1,
    field: "busDriver",
    headerName: "Bus Driver",
    minWidth: 20,
    valueGetter: ({ value }) => {
      return (value as BusDriversPaginatedItemFragment)?.user.name;
    },
  },
];

const RouteLogsTable: React.FC<RouteLogsTableProps> = ({
  routeId,
  pathType,
  ...props
}) => {
  return (
    <RouteLogsDataTable
      query={AttendanceListPaginatedDocument}
      variables={{ busRouteId: routeId, pathType }}
      searchable={false}
      columns={muiColumns}
      {...props}
    />
  );
};

export default RouteLogsTable;
