import { TextField, TextFieldProps } from "@mui/material";
import React, { useState } from "react";
import SelectModal, { SelectItem, SelectModalProps } from "./SelectModal";

export interface SelectModalFieldProps<TItem extends SelectItem>
  extends Omit<SelectModalProps<TItem>, "open" | "onClose" | "onItemChange"> {
  textFieldProps?: TextFieldProps;
}

const SelectModalField = <TItem extends SelectItem>({
  textFieldProps,
  initialValue,
  ...props
}: SelectModalFieldProps<TItem>) => {
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<TItem | undefined>(
    initialValue
  );

  const onClose = () => {
    setOpen(false);
  };

  const onOpen = () => {
    setOpen(true);
  };

  const onItemChange = (item: TItem) => {
    setSelectedItem(item);
    const event = {
      target: { value: item?.id || "" },
    } as React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>;
    if (textFieldProps?.onChange) textFieldProps.onChange(event);
  };

  const getValue = (item?: TItem) => {
    if (!item) return "";
    const { primary } = props.labelsExractor(item);
    return primary;
  };

  return (
    <>
      <TextField
        {...textFieldProps}
        ref={textFieldProps?.ref}
        onClick={textFieldProps?.disabled ? undefined : onOpen}
        contentEditable={false}
        value={getValue(selectedItem)}
      />
      <SelectModal
        initialValue={initialValue}
        onClose={onClose}
        open={open}
        {...props}
        onItemChange={onItemChange}
      />
    </>
  );
};

export default SelectModalField;
