import {
  Button,
  Card,
  Checkbox,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  GetDisabledSchoolSystemsDocument,
  GetSchoolSystemsPaginatedDocument,
  SchoolSystemPaginatedItemFragment,
  useGetSchoolSystemsPaginatedQuery,
} from "../../apollo/schoolSystems/queries.generated";
import { ArrowDownward } from "@mui/icons-material";
import DataTable, { TableColumn } from "react-data-table-component";
import RemoteDataTable from "../../shared/components/RemoteDataTable";
import SchoolSystemsTable from "./components/SchoolSystemsTable";
import SchoolSystemFormModal from "./components/SchoolSystemFormModal";
import DeleteModal from "../../shared/components/DeleteModal";
import { DeleteSchoolSystemDocument } from "../../apollo/auth/mutations.generated";
import SchoolSystemSettingsModal from "./components/SchoolSystemSettingsModal";
import PageContainer from "../../shared/components/PageContainer";
import TableCard from "../../shared/components/TableCard";
import { GetSchoolsPaginatedDocument } from "../../apollo/schools/queries.generated";
import { GetStudentsPaginatedDocument } from "../../apollo/students/queries.generated";
import { GetBusRoutesPaginatedDocument } from "../../apollo/busRoutes/queries.generated";
import { GetParentsPaginatedDocument } from "../../apollo/parents/query.generated";
import { GetFamiliesPaginatedDocument } from "../../apollo/families/query.generated";
import { GetBusDriversPaginatedDocument } from "../../apollo/busDrivers/queries.generated";
import { GetUsersPaginatedDocument } from "../../apollo/users/queries.generated";
import DisabledSchoolSystemsTable from "./components/DisabledSchoolSystemsTable";
import useAbility from "../../shared/hooks/useAbility";

interface SchoolSystemsPageProps {}

const SchoolSystemsPage: React.FC<SchoolSystemsPageProps> = ({}) => {
  const ability = useAbility();
  const [showFormModal, setShowFormModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedSchoolSystem, setSelectedSchoolSystem] =
    useState<SchoolSystemPaginatedItemFragment>();

  const [showDisabledSchoolSystems, setShowDisabledSchoolSystems] =
    useState(false);

  const handleFormClose = () => {
    setSelectedSchoolSystem(undefined);
    setShowFormModal(false);
  };

  const handleSettingsClose = () => {
    setSelectedSchoolSystem(undefined);
    setShowSettingsModal(false);
  };

  const handleDeleteModalClose = () => {
    setSelectedSchoolSystem(undefined);
    setShowDeleteModal(false);
  };

  return (
    <PageContainer>
      <SchoolSystemSettingsModal
        schoolSystemId={selectedSchoolSystem?.id}
        open={showSettingsModal}
        onClose={handleSettingsClose}
      />
      <SchoolSystemFormModal
        editingSystem={selectedSchoolSystem}
        open={showFormModal}
        onClose={handleFormClose}
      />
      <DeleteModal
        open={showDeleteModal}
        mutation={DeleteSchoolSystemDocument}
        updateQueries={[
          GetSchoolSystemsPaginatedDocument,
          GetSchoolsPaginatedDocument,
          GetStudentsPaginatedDocument,
          GetParentsPaginatedDocument,
          GetFamiliesPaginatedDocument,
          GetBusRoutesPaginatedDocument,
          GetBusDriversPaginatedDocument,
          GetBusRoutesPaginatedDocument,
          GetUsersPaginatedDocument,
          GetDisabledSchoolSystemsDocument,
        ]}
        variables={{ id: selectedSchoolSystem?.id }}
        onClose={handleDeleteModalClose}
        title="Delete School System"
      />
      <TableCard>
        <Typography
          variant="h5"
          textAlign="start"
          fontWeight="bold"
          color="secondary"
          gutterBottom
        >
          {showDisabledSchoolSystems
            ? "Disabled School Systems"
            : "School Systems"}
        </Typography>
        {ability.can("manage", "all") && (
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item>
              <Button
                variant="outlined"
                onClick={() =>
                  setShowDisabledSchoolSystems(!showDisabledSchoolSystems)
                }
              >
                {showDisabledSchoolSystems
                  ? "Show Enabled School Systems"
                  : "Show Disabled School Systems"}
              </Button>
            </Grid>
          </Grid>
        )}

        {showDisabledSchoolSystems && <DisabledSchoolSystemsTable />}
        {!showDisabledSchoolSystems && (
          <SchoolSystemsTable
            onAddClick={() => setShowFormModal(true)}
            onEditPress={(item) => {
              setSelectedSchoolSystem(item);
              setShowFormModal(true);
            }}
            onDeletePress={(item) => {
              setSelectedSchoolSystem(item);
              setShowDeleteModal(true);
            }}
            onSettingsPress={(item) => {
              setSelectedSchoolSystem(item);
              setShowSettingsModal(true);
            }}
          />
        )}

        {/* <DataGrid
              style={{
                border: "none",
              }}
              columns={columns}
              rows={data?.schoolSystemsPaginated.nodes || []}
            /> */}
      </TableCard>
    </PageContainer>
  );
};

export default SchoolSystemsPage;
