import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import { StudentSelectItemFragmentDoc } from '../students/queries.generated';
import { UserFragmentDoc } from '../users/queries.generated';
import { AuthUserFragmentDoc } from '../auth/queries.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ParentsPaginatedItemFragment = { id: string, studentsCount: number, createdAt: any, updatedAt: any, schoolSystem: { id: string, name: string }, students?: Array<{ id: string, studentId?: string | null | undefined, user: { id: string, name: string, lastName: string, firstName: string, email: string }, school?: { id: string, name: string } | null | undefined }> | null | undefined, user: { name: string, firstName: string, lastName: string, email: string, role: string, id: string, createdAt: any, updatedAt: any, isDisabled: boolean, family?: { id: string, name: string } | null | undefined } };

export type GetParentsPaginatedQueryVariables = Types.Exact<{
  search?: Types.Maybe<Types.Scalars['String']>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  sortBy?: Types.Maybe<Types.Scalars['String']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  sortDirection?: Types.Maybe<Types.Scalars['String']>;
  schoolSystemId?: Types.Maybe<Types.Scalars['String']>;
  schoolId?: Types.Maybe<Types.Scalars['String']>;
  withoutFamily?: Types.Maybe<Types.Scalars['Boolean']>;
}>;


export type GetParentsPaginatedQuery = { parentsPaginated: { totalsCount: number, nodes?: Array<{ id: string, studentsCount: number, createdAt: any, updatedAt: any, schoolSystem: { id: string, name: string }, students?: Array<{ id: string, studentId?: string | null | undefined, user: { id: string, name: string, lastName: string, firstName: string, email: string }, school?: { id: string, name: string } | null | undefined }> | null | undefined, user: { name: string, firstName: string, lastName: string, email: string, role: string, id: string, createdAt: any, updatedAt: any, isDisabled: boolean, family?: { id: string, name: string } | null | undefined } }> | null | undefined } };

export type GetParentQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetParentQuery = { parent: { id: string, studentsCount: number, createdAt: any, updatedAt: any, schoolSystem: { id: string, name: string }, students?: Array<{ id: string, studentId?: string | null | undefined, user: { id: string, name: string, lastName: string, firstName: string, email: string }, school?: { id: string, name: string } | null | undefined }> | null | undefined, user: { name: string, firstName: string, lastName: string, email: string, role: string, id: string, createdAt: any, updatedAt: any, isDisabled: boolean, family?: { id: string, name: string } | null | undefined } } };

export type CreateParentMutationVariables = Types.Exact<{
  data: Types.CreateParentDto;
}>;


export type CreateParentMutation = { createParent: { id: string, createdAt: any, updatedAt: any } };

export type UpdateParentMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  data: Types.UpdateParentDto;
}>;


export type UpdateParentMutation = { updateParent: { id: string } };

export type DeleteParentMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteParentMutation = { deleteParent: boolean };

export type CreateParentAccountMutationVariables = Types.Exact<{
  data: Types.CreateParentAccountDto;
}>;


export type CreateParentAccountMutation = { createParentAccount: { token: string, parent: { id: string, user: { id: string, role: string, email: string, firstName: string, lastName: string, schoolSystemId?: string | null | undefined, schoolId?: string | null | undefined, schoolSystem?: { name: string } | null | undefined, school?: { name: string } | null | undefined } } } };

export type MyPaymentMethodFragment = { id: string, nameOnCard: string, number: string, expMonth: string, expYear: string, address: { streetAddress1: string, city: string, province: string, postalCode: string, country: string } };

export type MyPaymentMethodAddressFragment = { streetAddress1: string, city: string, province: string, postalCode: string, country: string };

export type GetMyPaymentMethodQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMyPaymentMethodQuery = { myPaymentMethod?: { id: string, nameOnCard: string, number: string, expMonth: string, expYear: string, address: { streetAddress1: string, city: string, province: string, postalCode: string, country: string } } | null | undefined };

export type AddPaymentMethodMutationVariables = Types.Exact<{
  data: Types.CreatePaymentMethodDto;
}>;


export type AddPaymentMethodMutation = { addPaymentMethod: { id: string } };

export type DeleteMyPaymentMethodMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type DeleteMyPaymentMethodMutation = { deleteMyPaymentMethod: boolean };

export type UpdatePaymentMethodMutationVariables = Types.Exact<{
  data: Types.UpdatePaymentMethodDto;
}>;


export type UpdatePaymentMethodMutation = { updatePaymentMethod: { id: string } };

export type MySubscriptionFragment = { id: string, createdAt: any, updatedAt: any, nextProcessingDate?: any | null | undefined, cancellationDate?: string | null | undefined, status: Types.SubscriptionStatus, amount?: string | null | undefined, discount?: string | null | undefined, paymentMethod?: { id: string, nameOnCard: string, number: string, expMonth: string, expYear: string, address: { streetAddress1: string, city: string, province: string, postalCode: string, country: string } } | null | undefined };

export type MySubscriptionQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MySubscriptionQuery = { mySubscription?: { id: string, createdAt: any, updatedAt: any, nextProcessingDate?: any | null | undefined, cancellationDate?: string | null | undefined, status: Types.SubscriptionStatus, amount?: string | null | undefined, discount?: string | null | undefined, paymentMethod?: { id: string, nameOnCard: string, number: string, expMonth: string, expYear: string, address: { streetAddress1: string, city: string, province: string, postalCode: string, country: string } } | null | undefined } | null | undefined };

export type StartSubscriptionMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type StartSubscriptionMutation = { startSubscription: { subscription?: { status: Types.SubscriptionStatus, updatedAt: any, discount?: string | null | undefined, amount?: string | null | undefined, paymentMethod?: { nameOnCard: string, number: string, address: { streetAddress1: string, city: string, province: string, postalCode: string, country: string } } | null | undefined } | null | undefined, transaction: { transactionId?: string | null | undefined, responseCode?: string | null | undefined, responseMessage?: string | null | undefined, authorizedAmount?: string | null | undefined, referenceNumber?: string | null | undefined } } };

export type UpdateSubscriptionMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  data: Types.UpdateSubscriptionDto;
}>;


export type UpdateSubscriptionMutation = { updateSubscription: boolean };

export type PauseSubscriptionMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type PauseSubscriptionMutation = { pauseSubscription: { status: Types.SubscriptionStatus } };

export type ResumeSubscriptionMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type ResumeSubscriptionMutation = { resumeSubscription: { status: Types.SubscriptionStatus } };

export type StartTrialSubscriptionMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type StartTrialSubscriptionMutation = { startTrialSubscription: { status: Types.SubscriptionStatus } };

export const ParentsPaginatedItemFragmentDoc = gql`
    fragment ParentsPaginatedItem on ParentDTO {
  id
  studentsCount
  schoolSystem {
    id
    name
  }
  students {
    ...StudentSelectItem
  }
  user {
    family {
      id
      name
    }
    ...User
  }
  createdAt
  updatedAt
}
    ${StudentSelectItemFragmentDoc}
${UserFragmentDoc}`;
export const MyPaymentMethodAddressFragmentDoc = gql`
    fragment MyPaymentMethodAddress on PaymentMethodAddressDTO {
  streetAddress1
  city
  province
  postalCode
  country
}
    `;
export const MyPaymentMethodFragmentDoc = gql`
    fragment MyPaymentMethod on PaymentMethodDTO {
  id
  nameOnCard
  number
  expMonth
  expYear
  address {
    ...MyPaymentMethodAddress
  }
}
    ${MyPaymentMethodAddressFragmentDoc}`;
export const MySubscriptionFragmentDoc = gql`
    fragment MySubscription on SubscriptionDTO {
  id
  createdAt
  updatedAt
  nextProcessingDate
  cancellationDate
  status
  amount
  discount
  paymentMethod {
    ...MyPaymentMethod
  }
}
    ${MyPaymentMethodFragmentDoc}`;
export const GetParentsPaginatedDocument = gql`
    query getParentsPaginated($search: String, $limit: Int, $sortBy: String, $offset: Int, $sortDirection: String, $schoolSystemId: String, $schoolId: String, $withoutFamily: Boolean) {
  parentsPaginated(
    search: $search
    limit: $limit
    offset: $offset
    sortBy: $sortBy
    sortDirection: $sortDirection
    schoolSystemId: $schoolSystemId
    schoolId: $schoolId
    withoutFamily: $withoutFamily
  ) {
    nodes {
      ...ParentsPaginatedItem
    }
    totalsCount
  }
}
    ${ParentsPaginatedItemFragmentDoc}`;

/**
 * __useGetParentsPaginatedQuery__
 *
 * To run a query within a React component, call `useGetParentsPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParentsPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParentsPaginatedQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      offset: // value for 'offset'
 *      sortDirection: // value for 'sortDirection'
 *      schoolSystemId: // value for 'schoolSystemId'
 *      schoolId: // value for 'schoolId'
 *      withoutFamily: // value for 'withoutFamily'
 *   },
 * });
 */
export function useGetParentsPaginatedQuery(baseOptions?: Apollo.QueryHookOptions<GetParentsPaginatedQuery, GetParentsPaginatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetParentsPaginatedQuery, GetParentsPaginatedQueryVariables>(GetParentsPaginatedDocument, options);
      }
export function useGetParentsPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetParentsPaginatedQuery, GetParentsPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetParentsPaginatedQuery, GetParentsPaginatedQueryVariables>(GetParentsPaginatedDocument, options);
        }
export type GetParentsPaginatedQueryHookResult = ReturnType<typeof useGetParentsPaginatedQuery>;
export type GetParentsPaginatedLazyQueryHookResult = ReturnType<typeof useGetParentsPaginatedLazyQuery>;
export type GetParentsPaginatedQueryResult = Apollo.QueryResult<GetParentsPaginatedQuery, GetParentsPaginatedQueryVariables>;
export const GetParentDocument = gql`
    query getParent($id: String!) {
  parent(id: $id) {
    ...ParentsPaginatedItem
  }
}
    ${ParentsPaginatedItemFragmentDoc}`;

/**
 * __useGetParentQuery__
 *
 * To run a query within a React component, call `useGetParentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetParentQuery(baseOptions: Apollo.QueryHookOptions<GetParentQuery, GetParentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetParentQuery, GetParentQueryVariables>(GetParentDocument, options);
      }
export function useGetParentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetParentQuery, GetParentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetParentQuery, GetParentQueryVariables>(GetParentDocument, options);
        }
export type GetParentQueryHookResult = ReturnType<typeof useGetParentQuery>;
export type GetParentLazyQueryHookResult = ReturnType<typeof useGetParentLazyQuery>;
export type GetParentQueryResult = Apollo.QueryResult<GetParentQuery, GetParentQueryVariables>;
export const CreateParentDocument = gql`
    mutation createParent($data: CreateParentDTO!) {
  createParent(data: $data) {
    id
    createdAt
    updatedAt
  }
}
    `;
export type CreateParentMutationFn = Apollo.MutationFunction<CreateParentMutation, CreateParentMutationVariables>;

/**
 * __useCreateParentMutation__
 *
 * To run a mutation, you first call `useCreateParentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateParentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createParentMutation, { data, loading, error }] = useCreateParentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateParentMutation(baseOptions?: Apollo.MutationHookOptions<CreateParentMutation, CreateParentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateParentMutation, CreateParentMutationVariables>(CreateParentDocument, options);
      }
export type CreateParentMutationHookResult = ReturnType<typeof useCreateParentMutation>;
export type CreateParentMutationResult = Apollo.MutationResult<CreateParentMutation>;
export type CreateParentMutationOptions = Apollo.BaseMutationOptions<CreateParentMutation, CreateParentMutationVariables>;
export const UpdateParentDocument = gql`
    mutation updateParent($id: String!, $data: UpdateParentDTO!) {
  updateParent(id: $id, data: $data) {
    id
  }
}
    `;
export type UpdateParentMutationFn = Apollo.MutationFunction<UpdateParentMutation, UpdateParentMutationVariables>;

/**
 * __useUpdateParentMutation__
 *
 * To run a mutation, you first call `useUpdateParentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateParentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateParentMutation, { data, loading, error }] = useUpdateParentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateParentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateParentMutation, UpdateParentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateParentMutation, UpdateParentMutationVariables>(UpdateParentDocument, options);
      }
export type UpdateParentMutationHookResult = ReturnType<typeof useUpdateParentMutation>;
export type UpdateParentMutationResult = Apollo.MutationResult<UpdateParentMutation>;
export type UpdateParentMutationOptions = Apollo.BaseMutationOptions<UpdateParentMutation, UpdateParentMutationVariables>;
export const DeleteParentDocument = gql`
    mutation deleteParent($id: String!) {
  deleteParent(id: $id)
}
    `;
export type DeleteParentMutationFn = Apollo.MutationFunction<DeleteParentMutation, DeleteParentMutationVariables>;

/**
 * __useDeleteParentMutation__
 *
 * To run a mutation, you first call `useDeleteParentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteParentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteParentMutation, { data, loading, error }] = useDeleteParentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteParentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteParentMutation, DeleteParentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteParentMutation, DeleteParentMutationVariables>(DeleteParentDocument, options);
      }
export type DeleteParentMutationHookResult = ReturnType<typeof useDeleteParentMutation>;
export type DeleteParentMutationResult = Apollo.MutationResult<DeleteParentMutation>;
export type DeleteParentMutationOptions = Apollo.BaseMutationOptions<DeleteParentMutation, DeleteParentMutationVariables>;
export const CreateParentAccountDocument = gql`
    mutation createParentAccount($data: CreateParentAccountDTO!) {
  createParentAccount(data: $data) {
    parent {
      id
      user {
        ...AuthUser
      }
    }
    token
  }
}
    ${AuthUserFragmentDoc}`;
export type CreateParentAccountMutationFn = Apollo.MutationFunction<CreateParentAccountMutation, CreateParentAccountMutationVariables>;

/**
 * __useCreateParentAccountMutation__
 *
 * To run a mutation, you first call `useCreateParentAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateParentAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createParentAccountMutation, { data, loading, error }] = useCreateParentAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateParentAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateParentAccountMutation, CreateParentAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateParentAccountMutation, CreateParentAccountMutationVariables>(CreateParentAccountDocument, options);
      }
export type CreateParentAccountMutationHookResult = ReturnType<typeof useCreateParentAccountMutation>;
export type CreateParentAccountMutationResult = Apollo.MutationResult<CreateParentAccountMutation>;
export type CreateParentAccountMutationOptions = Apollo.BaseMutationOptions<CreateParentAccountMutation, CreateParentAccountMutationVariables>;
export const GetMyPaymentMethodDocument = gql`
    query getMyPaymentMethod {
  myPaymentMethod {
    ...MyPaymentMethod
  }
}
    ${MyPaymentMethodFragmentDoc}`;

/**
 * __useGetMyPaymentMethodQuery__
 *
 * To run a query within a React component, call `useGetMyPaymentMethodQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyPaymentMethodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyPaymentMethodQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyPaymentMethodQuery(baseOptions?: Apollo.QueryHookOptions<GetMyPaymentMethodQuery, GetMyPaymentMethodQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyPaymentMethodQuery, GetMyPaymentMethodQueryVariables>(GetMyPaymentMethodDocument, options);
      }
export function useGetMyPaymentMethodLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyPaymentMethodQuery, GetMyPaymentMethodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyPaymentMethodQuery, GetMyPaymentMethodQueryVariables>(GetMyPaymentMethodDocument, options);
        }
export type GetMyPaymentMethodQueryHookResult = ReturnType<typeof useGetMyPaymentMethodQuery>;
export type GetMyPaymentMethodLazyQueryHookResult = ReturnType<typeof useGetMyPaymentMethodLazyQuery>;
export type GetMyPaymentMethodQueryResult = Apollo.QueryResult<GetMyPaymentMethodQuery, GetMyPaymentMethodQueryVariables>;
export const AddPaymentMethodDocument = gql`
    mutation addPaymentMethod($data: CreatePaymentMethodDTO!) {
  addPaymentMethod(data: $data) {
    id
  }
}
    `;
export type AddPaymentMethodMutationFn = Apollo.MutationFunction<AddPaymentMethodMutation, AddPaymentMethodMutationVariables>;

/**
 * __useAddPaymentMethodMutation__
 *
 * To run a mutation, you first call `useAddPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPaymentMethodMutation, { data, loading, error }] = useAddPaymentMethodMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddPaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<AddPaymentMethodMutation, AddPaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPaymentMethodMutation, AddPaymentMethodMutationVariables>(AddPaymentMethodDocument, options);
      }
export type AddPaymentMethodMutationHookResult = ReturnType<typeof useAddPaymentMethodMutation>;
export type AddPaymentMethodMutationResult = Apollo.MutationResult<AddPaymentMethodMutation>;
export type AddPaymentMethodMutationOptions = Apollo.BaseMutationOptions<AddPaymentMethodMutation, AddPaymentMethodMutationVariables>;
export const DeleteMyPaymentMethodDocument = gql`
    mutation deleteMyPaymentMethod {
  deleteMyPaymentMethod
}
    `;
export type DeleteMyPaymentMethodMutationFn = Apollo.MutationFunction<DeleteMyPaymentMethodMutation, DeleteMyPaymentMethodMutationVariables>;

/**
 * __useDeleteMyPaymentMethodMutation__
 *
 * To run a mutation, you first call `useDeleteMyPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMyPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMyPaymentMethodMutation, { data, loading, error }] = useDeleteMyPaymentMethodMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteMyPaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMyPaymentMethodMutation, DeleteMyPaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMyPaymentMethodMutation, DeleteMyPaymentMethodMutationVariables>(DeleteMyPaymentMethodDocument, options);
      }
export type DeleteMyPaymentMethodMutationHookResult = ReturnType<typeof useDeleteMyPaymentMethodMutation>;
export type DeleteMyPaymentMethodMutationResult = Apollo.MutationResult<DeleteMyPaymentMethodMutation>;
export type DeleteMyPaymentMethodMutationOptions = Apollo.BaseMutationOptions<DeleteMyPaymentMethodMutation, DeleteMyPaymentMethodMutationVariables>;
export const UpdatePaymentMethodDocument = gql`
    mutation updatePaymentMethod($data: updatePaymentMethodDTO!) {
  updatePaymentMethod(data: $data) {
    id
  }
}
    `;
export type UpdatePaymentMethodMutationFn = Apollo.MutationFunction<UpdatePaymentMethodMutation, UpdatePaymentMethodMutationVariables>;

/**
 * __useUpdatePaymentMethodMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentMethodMutation, { data, loading, error }] = useUpdatePaymentMethodMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePaymentMethodMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePaymentMethodMutation, UpdatePaymentMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePaymentMethodMutation, UpdatePaymentMethodMutationVariables>(UpdatePaymentMethodDocument, options);
      }
export type UpdatePaymentMethodMutationHookResult = ReturnType<typeof useUpdatePaymentMethodMutation>;
export type UpdatePaymentMethodMutationResult = Apollo.MutationResult<UpdatePaymentMethodMutation>;
export type UpdatePaymentMethodMutationOptions = Apollo.BaseMutationOptions<UpdatePaymentMethodMutation, UpdatePaymentMethodMutationVariables>;
export const MySubscriptionDocument = gql`
    query mySubscription {
  mySubscription {
    ...MySubscription
  }
}
    ${MySubscriptionFragmentDoc}`;

/**
 * __useMySubscriptionQuery__
 *
 * To run a query within a React component, call `useMySubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useMySubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMySubscriptionQuery({
 *   variables: {
 *   },
 * });
 */
export function useMySubscriptionQuery(baseOptions?: Apollo.QueryHookOptions<MySubscriptionQuery, MySubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MySubscriptionQuery, MySubscriptionQueryVariables>(MySubscriptionDocument, options);
      }
export function useMySubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MySubscriptionQuery, MySubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MySubscriptionQuery, MySubscriptionQueryVariables>(MySubscriptionDocument, options);
        }
export type MySubscriptionQueryHookResult = ReturnType<typeof useMySubscriptionQuery>;
export type MySubscriptionLazyQueryHookResult = ReturnType<typeof useMySubscriptionLazyQuery>;
export type MySubscriptionQueryResult = Apollo.QueryResult<MySubscriptionQuery, MySubscriptionQueryVariables>;
export const StartSubscriptionDocument = gql`
    mutation startSubscription {
  startSubscription {
    subscription {
      status
      updatedAt
      discount
      amount
      paymentMethod {
        nameOnCard
        number
        address {
          streetAddress1
          city
          province
          postalCode
          country
        }
      }
    }
    transaction {
      transactionId
      responseCode
      responseMessage
      authorizedAmount
      referenceNumber
    }
  }
}
    `;
export type StartSubscriptionMutationFn = Apollo.MutationFunction<StartSubscriptionMutation, StartSubscriptionMutationVariables>;

/**
 * __useStartSubscriptionMutation__
 *
 * To run a mutation, you first call `useStartSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startSubscriptionMutation, { data, loading, error }] = useStartSubscriptionMutation({
 *   variables: {
 *   },
 * });
 */
export function useStartSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<StartSubscriptionMutation, StartSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartSubscriptionMutation, StartSubscriptionMutationVariables>(StartSubscriptionDocument, options);
      }
export type StartSubscriptionMutationHookResult = ReturnType<typeof useStartSubscriptionMutation>;
export type StartSubscriptionMutationResult = Apollo.MutationResult<StartSubscriptionMutation>;
export type StartSubscriptionMutationOptions = Apollo.BaseMutationOptions<StartSubscriptionMutation, StartSubscriptionMutationVariables>;
export const UpdateSubscriptionDocument = gql`
    mutation updateSubscription($id: String!, $data: UpdateSubscriptionDTO!) {
  updateSubscription(id: $id, data: $data)
}
    `;
export type UpdateSubscriptionMutationFn = Apollo.MutationFunction<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>;

/**
 * __useUpdateSubscriptionMutation__
 *
 * To run a mutation, you first call `useUpdateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubscriptionMutation, { data, loading, error }] = useUpdateSubscriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>(UpdateSubscriptionDocument, options);
      }
export type UpdateSubscriptionMutationHookResult = ReturnType<typeof useUpdateSubscriptionMutation>;
export type UpdateSubscriptionMutationResult = Apollo.MutationResult<UpdateSubscriptionMutation>;
export type UpdateSubscriptionMutationOptions = Apollo.BaseMutationOptions<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>;
export const PauseSubscriptionDocument = gql`
    mutation pauseSubscription {
  pauseSubscription {
    status
  }
}
    `;
export type PauseSubscriptionMutationFn = Apollo.MutationFunction<PauseSubscriptionMutation, PauseSubscriptionMutationVariables>;

/**
 * __usePauseSubscriptionMutation__
 *
 * To run a mutation, you first call `usePauseSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePauseSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pauseSubscriptionMutation, { data, loading, error }] = usePauseSubscriptionMutation({
 *   variables: {
 *   },
 * });
 */
export function usePauseSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<PauseSubscriptionMutation, PauseSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PauseSubscriptionMutation, PauseSubscriptionMutationVariables>(PauseSubscriptionDocument, options);
      }
export type PauseSubscriptionMutationHookResult = ReturnType<typeof usePauseSubscriptionMutation>;
export type PauseSubscriptionMutationResult = Apollo.MutationResult<PauseSubscriptionMutation>;
export type PauseSubscriptionMutationOptions = Apollo.BaseMutationOptions<PauseSubscriptionMutation, PauseSubscriptionMutationVariables>;
export const ResumeSubscriptionDocument = gql`
    mutation resumeSubscription {
  resumeSubscription {
    status
  }
}
    `;
export type ResumeSubscriptionMutationFn = Apollo.MutationFunction<ResumeSubscriptionMutation, ResumeSubscriptionMutationVariables>;

/**
 * __useResumeSubscriptionMutation__
 *
 * To run a mutation, you first call `useResumeSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResumeSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resumeSubscriptionMutation, { data, loading, error }] = useResumeSubscriptionMutation({
 *   variables: {
 *   },
 * });
 */
export function useResumeSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<ResumeSubscriptionMutation, ResumeSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResumeSubscriptionMutation, ResumeSubscriptionMutationVariables>(ResumeSubscriptionDocument, options);
      }
export type ResumeSubscriptionMutationHookResult = ReturnType<typeof useResumeSubscriptionMutation>;
export type ResumeSubscriptionMutationResult = Apollo.MutationResult<ResumeSubscriptionMutation>;
export type ResumeSubscriptionMutationOptions = Apollo.BaseMutationOptions<ResumeSubscriptionMutation, ResumeSubscriptionMutationVariables>;
export const StartTrialSubscriptionDocument = gql`
    mutation startTrialSubscription {
  startTrialSubscription {
    status
  }
}
    `;
export type StartTrialSubscriptionMutationFn = Apollo.MutationFunction<StartTrialSubscriptionMutation, StartTrialSubscriptionMutationVariables>;

/**
 * __useStartTrialSubscriptionMutation__
 *
 * To run a mutation, you first call `useStartTrialSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartTrialSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startTrialSubscriptionMutation, { data, loading, error }] = useStartTrialSubscriptionMutation({
 *   variables: {
 *   },
 * });
 */
export function useStartTrialSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<StartTrialSubscriptionMutation, StartTrialSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartTrialSubscriptionMutation, StartTrialSubscriptionMutationVariables>(StartTrialSubscriptionDocument, options);
      }
export type StartTrialSubscriptionMutationHookResult = ReturnType<typeof useStartTrialSubscriptionMutation>;
export type StartTrialSubscriptionMutationResult = Apollo.MutationResult<StartTrialSubscriptionMutation>;
export type StartTrialSubscriptionMutationOptions = Apollo.BaseMutationOptions<StartTrialSubscriptionMutation, StartTrialSubscriptionMutationVariables>;