import { Person, SchoolOutlined } from "@mui/icons-material";
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { ParentsPaginatedItemFragment } from "../../../apollo/parents/query.generated";
import { UsersPaginatedItemFragment } from "../../../apollo/users/queries.generated";

interface FamilyPreviewSectionProps {
  users: UsersPaginatedItemFragment[];
}

const FamilyPreviewSection: React.FC<FamilyPreviewSectionProps> = ({
  users,
}) => {
  if (!users.length) {
    return <Typography>No family members to display.</Typography>;
  }

  return (
    <Grid container sx={{ marginTop: "10px" }} spacing={2}>
      <Grid item xs={12}>
        <Typography
          variant="h5"
          textAlign="start"
          fontWeight="bold"
          sx={{ marginRight: "10px", marginBottom: "10px" }}
        >
          Family Members
        </Typography>
        <List>
          {users.map((user) => (
            <ListItem key={user.id}>
              {user.role === "Parent" ? (
                <ListItemIcon>
                  <Person />
                </ListItemIcon>
              ) : (
                <ListItemIcon>
                  <SchoolOutlined />
                </ListItemIcon>
              )}
              <ListItemText
                primary={user.name}
                secondary={user.schoolSystem?.name}
              />
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

interface FamilyPreviewSectionItemProps {
  user: UsersPaginatedItemFragment;
}

const FamilyPreviewSectionItem: React.FC<FamilyPreviewSectionItemProps> = ({
  user,
}) => {
  return <Grid container></Grid>;
};

export default FamilyPreviewSection;
