import { GridColDef } from "@mui/x-data-grid";
import React from "react";

import { SchoolsPaginatedItemFragment } from "../../../apollo/schools/queries.generated";
import { SchoolSystemPaginatedItemFragment } from "../../../apollo/schoolSystems/queries.generated";

import {
  GetSchoolSystemDisabledUsersPaginatedDocument,
  GetUsersPaginatedDocument,
  UsersPaginatedItemFragment,
} from "../../../apollo/users/queries.generated";

import RemoteDataTable, {
  BasicTableActionFunction,
  getBasicActionColumns,
  RemoteDataTableProps,
} from "../../../shared/components/RemoteDataTable";
import Link from "../../../navigation/Link";

interface DisabledSystemUsersTableProps
  extends Pick<RemoteDataTableProps, "onAddClick" | "refresherBoolean"> {
  schoolSystemId: string;
}
const muiColumns: GridColDef[] = [
  {
    flex: 1,
    field: "name",
    headerName: "Name",
    minWidth: 200,
    renderCell: ({ row, value }) => {
      const user = row as UsersPaginatedItemFragment;
      return <Link to={"/dashboard/users/" + user.id}>{value}</Link>;
    },
  },
  {
    flex: 1,
    field: "email",
    headerName: "Email",
    minWidth: 200,
  },
  {
    flex: 1,
    field: "role",
    headerName: "Role",
    minWidth: 200,
  },
  {
    flex: 1,
    field: "school",
    headerName: "School",
    minWidth: 200,
    valueGetter: ({ value }) => {
      if (!value) return "Not Required";
      return (value as SchoolsPaginatedItemFragment)?.name;
    },
  },
];

const DisabledSystemUsersTable: React.FC<DisabledSystemUsersTableProps> = ({
  schoolSystemId,
  ...props
}) => {
  return (
    <RemoteDataTable
      query={GetSchoolSystemDisabledUsersPaginatedDocument}
      variables={{ schoolSystemId }}
      searchable
      columns={muiColumns}
      {...props}
    />
  );
};

export default DisabledSystemUsersTable;
