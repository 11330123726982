import React, { useEffect } from "react";
import { useMeLazyQuery } from "../../apollo/auth/queries.generated";
import useAuthStore from "../../shared/stores/authStore";
import LoadingPage from "./LoadingPage";

interface UserLoaderProps {}

const UserLoader: React.FC<UserLoaderProps> = ({ children }) => {
  const [getUser, { data, error, loading }] = useMeLazyQuery();
  const { authToken, setAuthError, loadUser } = useAuthStore();

  useEffect(() => {
    if (authToken) {
      getUser();
    }
  }, []);

  useEffect(() => {
    if (data?.me) {
      loadUser(data.me);
    }

    if (error) {
      setAuthError(error);
    }
  }, [data, error]);

  if (authToken && loading) {
    return <LoadingPage />;
  }

  return <> {children} </>;
};

export default UserLoader;
