import React from "react";
import { Path, Control, Controller } from "react-hook-form";
import { SelectItem } from "./SelectModal";
import SelectModalField, { SelectModalFieldProps } from "./SelectModalField";

interface ControlledSelectModalFieldProps<
  TFieldValues,
  Context extends object
> {
  name: Path<TFieldValues>;
  control: Control<TFieldValues, Context>;
}
const ControlledSelectModalField = <
  TFieldValues,
  Context extends object,
  TItem extends SelectItem
>({
  control,
  name,
  ...props
}: ControlledSelectModalFieldProps<TFieldValues, Context> &
  SelectModalFieldProps<TItem>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <SelectModalField
          {...props}
          textFieldProps={{
            ...props.textFieldProps,
            ...field,
            ref: field.ref,
            error: !!error,
            helperText: error?.message,
          }}
        />
      )}
    />
  );
};

export default ControlledSelectModalField;
