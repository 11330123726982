import { DocumentNode, useQuery } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  Pagination,
  Typography,
} from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import useDidMountEffect from "../../hooks/useDidMountEffect";
import { getObjectFirstKey } from "../../utils";
import SearchField from "../SearchField";
import {
  LabelsExtractorFunction,
  MultiSelectItem,
  SelectItemLabelProperties,
} from "./interfaces";
import ItemsList from "./ItemsList";
import SelectedItemsList from "./SelectedItemsList";

export interface MultiSelectDialogProps<Item> {
  open: boolean;
  query: DocumentNode;
  onItemsChange: (selected: Item[]) => void;
  title?: string;
  labelsExtractor: LabelsExtractorFunction<Item>;
  variables?: any;
  limit?: number;
  onClose: () => void;
  selectedItems: Item[];
  //   onItemClick: (item: Item) => void;
}

const MultiSelectDialog = <Item extends MultiSelectItem>({
  open,
  title = "Select Items",
  query,
  limit = 5,
  variables,
  onClose,
  onItemsChange,
  selectedItems,
  labelsExtractor,
}: MultiSelectDialogProps<Item>) => {
  const [search, setSearch] = useState("");

  const [currentPage, setCurrentPage] = useState(0);

  const { data, refetch } = useQuery(query, {
    variables: { ...variables, limit },
  });

  const onPageChange = useCallback((value: number) => {
    setCurrentPage(() => value);
  }, []);
  const onSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setSearch(() => e.target.value);
    },
    []
  );
  const onItemClick = useCallback(
    (item: Item) => {
      const temp = [...selectedItems];
      const possibleIndex = temp.findIndex((i) => i.id === item.id);
      possibleIndex > -1 ? temp.splice(possibleIndex, 1) : temp.push(item);
      onItemsChange(temp);
    },
    [selectedItems, onItemsChange]
  );
  const onDeletePress = useCallback(
    (item: Item, index: number) => {
      const temp = [...selectedItems];
      temp.splice(index, 1);
      onItemsChange(temp);
    },
    [selectedItems, onItemsChange]
  );

  const { nodes, total } = useMemo(() => {
    if (!data) return { nodes: [] as Item[], total: 0 };
    const resultKey = getObjectFirstKey(data);
    if (!resultKey) return { nodes: [] as Item[], total: 0 };
    const possibleNodes: Item[] = data[resultKey].nodes;
    return { nodes: possibleNodes, total: data[resultKey].totalsCount };
  }, [data]);

  useDidMountEffect(() => {
    refetch({
      ...variables,
      search,
      offset: limit * (currentPage === 0 ? currentPage : currentPage - 1),
      limit,
    });
  }, [search, currentPage, variables, limit]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <SearchField onChange={onSearchChange} />
        <SelectedItemsList
          labelsExtractor={labelsExtractor}
          onItemDeletePress={onDeletePress}
          selectedItems={selectedItems}
        />
        <ItemsList
          currentPage={currentPage}
          itemsTotal={total}
          labelsExtractor={labelsExtractor}
          limit={limit}
          selectedItems={selectedItems}
          onItemClick={onItemClick}
          onPageChange={onPageChange}
          items={nodes}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Dismiss
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MultiSelectDialog;
