import { Grid, LinearProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  GetFamilyDocument,
  useGetFamilyQuery,
} from "../../../apollo/families/query.generated";
import { useFamilySchoolSystemPaymentMethodQuery } from "../../../apollo/schoolSystemSettings/queries.generated";
import PageContainer from "../../../shared/components/PageContainer";
import SubscriptionUpdateModal from "../../parents/components/SubscriptionUpdateModal";
import FamilyPreviewSection from "./FamilyPreviewSection";
import FamilySubscriptionSection from "./FamilySubscriptionSection";

interface FamilyDetailsPageProps {}

const FamilyDetailsPage: React.FC<FamilyDetailsPageProps> = ({}) => {
  let { id } = useParams<{ id: string }>();
  const { data, loading, error } = useGetFamilyQuery({
    variables: {
      id,
    },
  });
  const [showUpdateSubscription, setShowUpdateSubscription] = useState(false);
  const [updateData, setUpdateData] = useState({
    famId: "",
    subId: "",
    amount: 0,
    discount: 0,
    trialEndDate: new Date(),
    status: "",
  });

  const { data: familySchoolSystemPaymentMethodData } =
    useFamilySchoolSystemPaymentMethodQuery({
      variables: {
        familyId: id,
      },
    });

  useEffect(() => {
    if (data?.getFamily.familySubscriptions) {
      setUpdateData({
        famId: data.getFamily.id,
        subId: data.getFamily.familySubscriptions.id,
        amount: Number(data.getFamily.familySubscriptions.amount),
        discount: Number(data.getFamily.familySubscriptions.discount),
        trialEndDate: (data.getFamily.familySubscriptions.nextProcessingDate ||
          new Date()) as Date,
        status: data.getFamily.familySubscriptions.status,
      });
    }
  }, [data]);

  if (error) return <div>Error</div>;
  if (!data) {
    return <LinearProgress />;
  }
  if (loading) {
    return <LinearProgress />;
  }

  const handleOpenUpdateSubscriptionModal = () => {
    setShowUpdateSubscription(true);
  };
  const handleCloseUpdateSubscriptionModal = () => {
    setShowUpdateSubscription(false);
  };

  return (
    <PageContainer>
      <Grid container sx={{ marginTop: "10px" }} spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h5"
            textAlign={"start"}
            fontWeight="bold"
            color={"secondary"}
            sx={{ marginRight: "10px", marginBottom: "10px" }}
          >
            Family Details
          </Typography>
          {data && (
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <FamilyPreviewSection users={data?.getFamily.members || []} />
              </Grid>
              <Grid item xs={12} md={6}>
                <FamilySubscriptionSection
                  familySubscription={data?.getFamily.familySubscriptions}
                  handleOpenUpdateSubscriptionModal={
                    handleOpenUpdateSubscriptionModal
                  }
                  inAdminPanel={true}
                  propFamilyId={data?.getFamily.id}
                  refetchQ={[
                    {
                      query: GetFamilyDocument,
                      variables: { id: data?.getFamily?.id },
                    },
                  ]}
                  systemSchoolPaymentMethod={
                    familySchoolSystemPaymentMethodData?.getFamilySchoolSystemPaymentMethod
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SubscriptionUpdateModal
                  subscription={updateData}
                  onClose={handleCloseUpdateSubscriptionModal}
                  open={showUpdateSubscription}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default FamilyDetailsPage;
