import { Grid, Typography, Box, Button } from "@mui/material";

import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import { GetSchoolSystemsPaginatedDocument } from "../../../apollo/schoolSystems/queries.generated";
import ControlledSelectModalField from "../../../shared/components/ControlledSelectModalField";
import ControlledTextField from "../../../shared/components/ControlledTextField";
import * as Yup from "yup";
import apolloClient from "../../../apollo/client";
import {
  CheckEmailAvailabilityDocument,
  CheckEmailAvailabilityQuery,
  CheckEmailAvailabilityQueryVariables,
} from "../../../apollo/users/queries.generated";
import { CreateParentAccountDto } from "../../../apollo/types.generated";
import { yupResolver } from "@hookform/resolvers/yup";
import useAuthStore from "../../../shared/stores/authStore";
import { useCreateParentAccountMutation } from "../../../apollo/parents/query.generated";
import toast from "react-hot-toast";
import LoadingBackdrop from "../../../shared/components/LoadingBackdrop";

interface ParentsAccountFormProps {}

interface FormInputs extends CreateParentAccountDto {
  passwordConfirmation: string;
}

const validationSchema = Yup.object({
  // role: Yup.string().required("This field is required"),
  firstName: Yup.string().required("This field is required"),
  lastName: Yup.string().required("This field is required"),

  email: Yup.string()
    .required("This field is required")
    .email("Invalid email")
    .test(
      "test-mail",
      "This email address is not available",
      async (value, context) => {
        if (!value) return false;
        const result = await apolloClient.query<
          CheckEmailAvailabilityQuery,
          CheckEmailAvailabilityQueryVariables
        >({
          query: CheckEmailAvailabilityDocument,
          variables: { email: value },
        });

        return result.data.checkEmailAvailability;
      }
    ),
  password: Yup.string().required("This field is required"),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required(""),

  schoolSystemId: Yup.string().required("This field is required"),
});

const ParentsAccountForm: React.FC<ParentsAccountFormProps> = ({}) => {
  const { loadUser, updateAuthToken } = useAuthStore();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(validationSchema),
    shouldFocusError: true,
  });
  console.log(errors);
  const [create, { data, loading, error }] = useCreateParentAccountMutation();

  const onSubmit = ({ passwordConfirmation, ...data }: FormInputs) => {
    // console.log(data);
    create({
      variables: {
        data,
      },
    });
  };

  useEffect(() => {
    if (error) {
      toast.error(
        "There was an error processing your request: " + error.message
      );
    }

    return () => {};
  }, [error]);

  useEffect(() => {
    if (data) {
      loadUser(data.createParentAccount.parent.user);
      toast.success("Account created successfully");
      updateAuthToken(data.createParentAccount.token);
      // history.
      //The page should redirect to the dashboard once the token is loaded;
    }

    return () => {};
  }, [data]);

  return (
    <Box
      flex={1}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <LoadingBackdrop open={loading} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container sx={{ marginTop: "10px" }} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom textAlign="start">
              Create Parent Account
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              name="firstName"
              control={control}
              label="First Name"
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              name="lastName"
              control={control}
              label="Last Name"
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField name="email" control={control} label="Email" />
          </Grid>

          <Grid item xs={12}>
            <ControlledTextField
              name="password"
              control={control}
              type="password"
              label="Password"
            />
          </Grid>

          <Grid item xs={12}>
            <ControlledTextField
              type="password"
              name="passwordConfirmation"
              control={control}
              label="Password Confirmation"
            />
          </Grid>

          <Grid item xs={12}>
            <ControlledSelectModalField
              name="schoolSystemId"
              control={control}
              textFieldProps={{
                label: "School System",
                fullWidth: true,
              }}
              query={GetSchoolSystemsPaginatedDocument}
              labelsExractor={(item) => ({
                primary: item.name,
              })}
            />
          </Grid>

          <Grid item xs={12} container justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{ paddingX: "50px" }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default ParentsAccountForm;
