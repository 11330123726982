import { Typography, TypographyProps } from "@mui/material";
import * as React from "react";
import useSettingsStore from "../stores/settingsStore";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";

interface TimezoneAwareDatetimeProps extends TypographyProps {
  datetime: Date | string;
  format: string;
}

const TimezoneAwareDatetime: React.FunctionComponent<
  TimezoneAwareDatetimeProps
> = ({ datetime, format, ...props }) => {
  const { timezone } = useSettingsStore();

  return <Typography {...props}>{dayjs(datetime).tz(timezone).format(format)}</Typography>;
};

export const timezoneAwareFormat = (date: Date | string, format: string) => {

    const timezone = useSettingsStore.getState().timezone;
    // console.log("Formatting date: ", date, " with format: ", format, " and timezone: ", timezone);
    return dayjs(date).tz(timezone).format(format);
}

export default TimezoneAwareDatetime;
