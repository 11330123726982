import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateFormMutationVariables = Types.Exact<{
  data: Types.CreateFormDto;
}>;


export type CreateFormMutation = { createForm: { id: string } };

export type UpdateFormMutationVariables = Types.Exact<{
  data: Types.UpdateFormDto;
  id: Types.Scalars['String'];
}>;


export type UpdateFormMutation = { updateForm: boolean };

export type DeleteFormMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteFormMutation = { deleteForm: boolean };

export type CreateFormFieldMutationVariables = Types.Exact<{
  data: Types.CreateFormFieldsDto;
}>;


export type CreateFormFieldMutation = { createFormField: boolean };

export type UpdateFormFieldMutationVariables = Types.Exact<{
  data: Types.UpdateFormFieldsDto;
}>;


export type UpdateFormFieldMutation = { updateFormField: boolean };

export type UpdateManyFormFieldsMutationVariables = Types.Exact<{
  data: Types.UpdateManyFormFieldsDto;
}>;


export type UpdateManyFormFieldsMutation = { updateManyFormField: boolean };

export type DeleteFormFieldMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteFormFieldMutation = { deleteFormField: boolean };

export type CreateFormResponsesMutationVariables = Types.Exact<{
  data: Types.CreateFormResponseDto;
}>;


export type CreateFormResponsesMutation = { createFormResponses: boolean };

export type DeleteUserFormResponsesMutationVariables = Types.Exact<{
  userId: Types.Scalars['String'];
  formId: Types.Scalars['String'];
}>;


export type DeleteUserFormResponsesMutation = { deleteUserFormResponses: boolean };


export const CreateFormDocument = gql`
    mutation createForm($data: CreateFormDTO!) {
  createForm(data: $data) {
    id
  }
}
    `;
export type CreateFormMutationFn = Apollo.MutationFunction<CreateFormMutation, CreateFormMutationVariables>;

/**
 * __useCreateFormMutation__
 *
 * To run a mutation, you first call `useCreateFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFormMutation, { data, loading, error }] = useCreateFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateFormMutation(baseOptions?: Apollo.MutationHookOptions<CreateFormMutation, CreateFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFormMutation, CreateFormMutationVariables>(CreateFormDocument, options);
      }
export type CreateFormMutationHookResult = ReturnType<typeof useCreateFormMutation>;
export type CreateFormMutationResult = Apollo.MutationResult<CreateFormMutation>;
export type CreateFormMutationOptions = Apollo.BaseMutationOptions<CreateFormMutation, CreateFormMutationVariables>;
export const UpdateFormDocument = gql`
    mutation updateForm($data: UpdateFormDTO!, $id: String!) {
  updateForm(data: $data, id: $id)
}
    `;
export type UpdateFormMutationFn = Apollo.MutationFunction<UpdateFormMutation, UpdateFormMutationVariables>;

/**
 * __useUpdateFormMutation__
 *
 * To run a mutation, you first call `useUpdateFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFormMutation, { data, loading, error }] = useUpdateFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateFormMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFormMutation, UpdateFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFormMutation, UpdateFormMutationVariables>(UpdateFormDocument, options);
      }
export type UpdateFormMutationHookResult = ReturnType<typeof useUpdateFormMutation>;
export type UpdateFormMutationResult = Apollo.MutationResult<UpdateFormMutation>;
export type UpdateFormMutationOptions = Apollo.BaseMutationOptions<UpdateFormMutation, UpdateFormMutationVariables>;
export const DeleteFormDocument = gql`
    mutation deleteForm($id: String!) {
  deleteForm(id: $id)
}
    `;
export type DeleteFormMutationFn = Apollo.MutationFunction<DeleteFormMutation, DeleteFormMutationVariables>;

/**
 * __useDeleteFormMutation__
 *
 * To run a mutation, you first call `useDeleteFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFormMutation, { data, loading, error }] = useDeleteFormMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFormMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFormMutation, DeleteFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFormMutation, DeleteFormMutationVariables>(DeleteFormDocument, options);
      }
export type DeleteFormMutationHookResult = ReturnType<typeof useDeleteFormMutation>;
export type DeleteFormMutationResult = Apollo.MutationResult<DeleteFormMutation>;
export type DeleteFormMutationOptions = Apollo.BaseMutationOptions<DeleteFormMutation, DeleteFormMutationVariables>;
export const CreateFormFieldDocument = gql`
    mutation createFormField($data: CreateFormFieldsDTO!) {
  createFormField(data: $data)
}
    `;
export type CreateFormFieldMutationFn = Apollo.MutationFunction<CreateFormFieldMutation, CreateFormFieldMutationVariables>;

/**
 * __useCreateFormFieldMutation__
 *
 * To run a mutation, you first call `useCreateFormFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFormFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFormFieldMutation, { data, loading, error }] = useCreateFormFieldMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateFormFieldMutation(baseOptions?: Apollo.MutationHookOptions<CreateFormFieldMutation, CreateFormFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFormFieldMutation, CreateFormFieldMutationVariables>(CreateFormFieldDocument, options);
      }
export type CreateFormFieldMutationHookResult = ReturnType<typeof useCreateFormFieldMutation>;
export type CreateFormFieldMutationResult = Apollo.MutationResult<CreateFormFieldMutation>;
export type CreateFormFieldMutationOptions = Apollo.BaseMutationOptions<CreateFormFieldMutation, CreateFormFieldMutationVariables>;
export const UpdateFormFieldDocument = gql`
    mutation updateFormField($data: UpdateFormFieldsDTO!) {
  updateFormField(data: $data)
}
    `;
export type UpdateFormFieldMutationFn = Apollo.MutationFunction<UpdateFormFieldMutation, UpdateFormFieldMutationVariables>;

/**
 * __useUpdateFormFieldMutation__
 *
 * To run a mutation, you first call `useUpdateFormFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFormFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFormFieldMutation, { data, loading, error }] = useUpdateFormFieldMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateFormFieldMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFormFieldMutation, UpdateFormFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFormFieldMutation, UpdateFormFieldMutationVariables>(UpdateFormFieldDocument, options);
      }
export type UpdateFormFieldMutationHookResult = ReturnType<typeof useUpdateFormFieldMutation>;
export type UpdateFormFieldMutationResult = Apollo.MutationResult<UpdateFormFieldMutation>;
export type UpdateFormFieldMutationOptions = Apollo.BaseMutationOptions<UpdateFormFieldMutation, UpdateFormFieldMutationVariables>;
export const UpdateManyFormFieldsDocument = gql`
    mutation updateManyFormFields($data: UpdateManyFormFieldsDTO!) {
  updateManyFormField(data: $data)
}
    `;
export type UpdateManyFormFieldsMutationFn = Apollo.MutationFunction<UpdateManyFormFieldsMutation, UpdateManyFormFieldsMutationVariables>;

/**
 * __useUpdateManyFormFieldsMutation__
 *
 * To run a mutation, you first call `useUpdateManyFormFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManyFormFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManyFormFieldsMutation, { data, loading, error }] = useUpdateManyFormFieldsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateManyFormFieldsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateManyFormFieldsMutation, UpdateManyFormFieldsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateManyFormFieldsMutation, UpdateManyFormFieldsMutationVariables>(UpdateManyFormFieldsDocument, options);
      }
export type UpdateManyFormFieldsMutationHookResult = ReturnType<typeof useUpdateManyFormFieldsMutation>;
export type UpdateManyFormFieldsMutationResult = Apollo.MutationResult<UpdateManyFormFieldsMutation>;
export type UpdateManyFormFieldsMutationOptions = Apollo.BaseMutationOptions<UpdateManyFormFieldsMutation, UpdateManyFormFieldsMutationVariables>;
export const DeleteFormFieldDocument = gql`
    mutation deleteFormField($id: String!) {
  deleteFormField(id: $id)
}
    `;
export type DeleteFormFieldMutationFn = Apollo.MutationFunction<DeleteFormFieldMutation, DeleteFormFieldMutationVariables>;

/**
 * __useDeleteFormFieldMutation__
 *
 * To run a mutation, you first call `useDeleteFormFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFormFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFormFieldMutation, { data, loading, error }] = useDeleteFormFieldMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFormFieldMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFormFieldMutation, DeleteFormFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFormFieldMutation, DeleteFormFieldMutationVariables>(DeleteFormFieldDocument, options);
      }
export type DeleteFormFieldMutationHookResult = ReturnType<typeof useDeleteFormFieldMutation>;
export type DeleteFormFieldMutationResult = Apollo.MutationResult<DeleteFormFieldMutation>;
export type DeleteFormFieldMutationOptions = Apollo.BaseMutationOptions<DeleteFormFieldMutation, DeleteFormFieldMutationVariables>;
export const CreateFormResponsesDocument = gql`
    mutation createFormResponses($data: CreateFormResponseDTO!) {
  createFormResponses(data: $data)
}
    `;
export type CreateFormResponsesMutationFn = Apollo.MutationFunction<CreateFormResponsesMutation, CreateFormResponsesMutationVariables>;

/**
 * __useCreateFormResponsesMutation__
 *
 * To run a mutation, you first call `useCreateFormResponsesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFormResponsesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFormResponsesMutation, { data, loading, error }] = useCreateFormResponsesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateFormResponsesMutation(baseOptions?: Apollo.MutationHookOptions<CreateFormResponsesMutation, CreateFormResponsesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFormResponsesMutation, CreateFormResponsesMutationVariables>(CreateFormResponsesDocument, options);
      }
export type CreateFormResponsesMutationHookResult = ReturnType<typeof useCreateFormResponsesMutation>;
export type CreateFormResponsesMutationResult = Apollo.MutationResult<CreateFormResponsesMutation>;
export type CreateFormResponsesMutationOptions = Apollo.BaseMutationOptions<CreateFormResponsesMutation, CreateFormResponsesMutationVariables>;
export const DeleteUserFormResponsesDocument = gql`
    mutation deleteUserFormResponses($userId: String!, $formId: String!) {
  deleteUserFormResponses(userId: $userId, formId: $formId)
}
    `;
export type DeleteUserFormResponsesMutationFn = Apollo.MutationFunction<DeleteUserFormResponsesMutation, DeleteUserFormResponsesMutationVariables>;

/**
 * __useDeleteUserFormResponsesMutation__
 *
 * To run a mutation, you first call `useDeleteUserFormResponsesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserFormResponsesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserFormResponsesMutation, { data, loading, error }] = useDeleteUserFormResponsesMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      formId: // value for 'formId'
 *   },
 * });
 */
export function useDeleteUserFormResponsesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserFormResponsesMutation, DeleteUserFormResponsesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserFormResponsesMutation, DeleteUserFormResponsesMutationVariables>(DeleteUserFormResponsesDocument, options);
      }
export type DeleteUserFormResponsesMutationHookResult = ReturnType<typeof useDeleteUserFormResponsesMutation>;
export type DeleteUserFormResponsesMutationResult = Apollo.MutationResult<DeleteUserFormResponsesMutation>;
export type DeleteUserFormResponsesMutationOptions = Apollo.BaseMutationOptions<DeleteUserFormResponsesMutation, DeleteUserFormResponsesMutationVariables>;