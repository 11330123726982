import { useLazyQuery } from "@apollo/client";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useHistory, useParams } from "react-router-dom";
import { useEnableSchoolSystemMutation } from "../../../apollo/auth/mutations.generated";
import { useGetDisabledSchoolsPaginatedQuery } from "../../../apollo/schools/queries.generated";
import {
  GetDisabledSchoolSystemsDocument,
  GetSchoolSystemsPaginatedDocument,
  useGetDisabledSchoolSystemsQuery,
  useGetSchoolSystemsPaginatedQuery,
} from "../../../apollo/schoolSystems/queries.generated";
import PageContainer from "../../../shared/components/PageContainer";
import TableCard from "../../../shared/components/TableCard";
import DisabledSystemSchoolsTable from "./DisabledSystemSchoolsTable";
import DisabledSystemUsersTable from "./DisabledSystemUsersTable";

type DisabledSchoolSystemInfoPageProps = {};

const DisabledSchoolSystemInfoPage: React.FC<
  DisabledSchoolSystemInfoPageProps
> = (props) => {
  let { id } = useParams<{ id: string }>();
  const { data: schoolSystemData } = useGetDisabledSchoolSystemsQuery();
  const [openDialog, setOpenDialog] = useState(false);
  const history = useHistory();

  const [enableSchoolSystem] = useEnableSchoolSystemMutation({
    variables: {
      id,
    },
    refetchQueries: [
      GetDisabledSchoolSystemsDocument,
      GetSchoolSystemsPaginatedDocument,
    ],
  });

  const handleEnableSchoolSystem = async () => {
    const toastId = toast.loading("Enabling school system...");
    try {
      await enableSchoolSystem();
      toast.success("School system enabled successfully.", { id: toastId });
      setOpenDialog(false);

      history.push("/dashboard/schoolSystem");
    } catch (error) {
      toast.error("Failed to enable school system.", { id: toastId });
      setOpenDialog(false);
    }
  };

  return (
    <PageContainer>
      <Typography
        variant="h5"
        textAlign="start"
        fontWeight="bold"
        color="secondary"
        gutterBottom
      >
        {schoolSystemData?.getDisabledSchoolSystemsPaginated?.nodes?.find(
          (system) => system.id === id
        )?.name + "'s Information"}
      </Typography>
      <Box sx={{ mb: 2 }}>
        <Alert severity="warning">
          This school system is disabled. You can enable it by clicking the
          "Enable" button below.
        </Alert>
      </Box>
      <Grid container spacing={2} marginBottom={"20px"} textAlign="start">
        <Grid item xs={12} md={6}>
          <Button
            variant="contained"
            color="success"
            onClick={() => setOpenDialog(true)}
          >
            Enable School System
          </Button>
        </Grid>
      </Grid>

      <Typography
        variant="h6"
        fontWeight="bold"
        gutterBottom
        textAlign="start"
        color="GrayText"
      >
        Disabled Schools
      </Typography>
      <DisabledSystemSchoolsTable schoolSystemId={id} />

      <Divider sx={{ mb: 2 }} />

      <Typography
        variant="h6"
        fontWeight="bold"
        gutterBottom
        textAlign="start"
        color="GrayText"
      >
        Disabled Users
      </Typography>
      <DisabledSystemUsersTable schoolSystemId={id} />

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Enable School System</DialogTitle>

        <DialogContent>
          <DialogContentText>
            Are you sure you want to enable this school system?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleEnableSchoolSystem} autoFocus>
            Enable
          </Button>
        </DialogActions>
      </Dialog>
    </PageContainer>
  );
};

export default DisabledSchoolSystemInfoPage;
