import {
  Box,
  Button,
  colors,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageContainer from "../../shared/components/PageContainer";
import LoadingPage from "../home/LoadingPage";
import { useGetFormByIdQuery } from "../../apollo/forms/queries.generated";
import { timezoneAwareFormat } from "../../shared/components/TimezoneAwareDatetime";
import { Edit } from "@mui/icons-material";
import FormCreationFormModal from "./components/FormCreationModal";
import FormFieldsTool from "./components/FormFieldsTool";
import FormResponsesTable from "./components/FormResponsesTable";
import ViewResponseModal from "./components/ViewResponseModal";

interface FormPageProps {}

const FormPage: React.FC<FormPageProps> = ({}) => {
  let { id } = useParams<{ id: string }>();
  const [openModal, setOpenModal] = React.useState(false);
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [selectedResponse, setSelectedResponse] = useState<any>();

  const { data, loading, error } = useGetFormByIdQuery({
    variables: { id: id },
  });

  if (error) return <div>Error</div>;
  if (!data) return <LoadingPage />;
  if (data.getForm.deletedAt) return <div>Form deleted</div>;

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleCloseResponseModal = () => {
    setSelectedResponse(undefined);
    setShowResponseModal(false);
  };

  return (
    <PageContainer>
      <FormCreationFormModal
        editingForm={data.getForm}
        open={openModal}
        onClose={handleCloseModal}
      />
      <ViewResponseModal
        response={selectedResponse}
        formInfo={data.getForm}
        open={showResponseModal}
        onClose={handleCloseResponseModal}
      />
      <Box
        sx={{
          display: "flex",
          marginBottom: "20px",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h5"
          textAlign="start"
          fontWeight="bold"
          color="secondary"
          sx={{ marginRight: "10px" }}
        >
          Form Information
        </Typography>
      </Box>

      <Typography fontWeight="bold" textAlign="start" color={"gray"}>
        Basic Information {"  "}
        <IconButton
          onClick={() => {
            setOpenModal(true);
          }}
          size="small"
          sx={{
            border: "0.5px solid",
            "&:hover": {
              backgroundColor: "transparent",
              color: colors.blue[800],
            },
          }}
        >
          <Edit />
        </IconButton>
      </Typography>

      <Box
        display="grid"
        gap={2}
        sx={{
          marginTop: "10px",
          border: "1px solid",
          borderRadius: "10px",
          padding: "10px",
        }}
      >
        <Box
          gridColumn={"span 12"}
          display="flex"
          gap={2}
          // gridAutoColumns={"1fr"} gridAutoFlow={"column"}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              flex: 1,
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              Name
            </Typography>
            <Typography variant="body1">{data.getForm.name}</Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              flex: 1,
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              Target or Purpose
            </Typography>
            <Typography variant="body1">{data.getForm.targetInfo}</Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              flex: 1,
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              Receivers
            </Typography>
            <Typography variant="body1">{data.getForm.receivers}</Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              flex: 1,
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              Initial Date
            </Typography>
            <Typography variant="body1">
              {timezoneAwareFormat(data.getForm.initialDate, "MMM D, YYYY")}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              flex: 1,
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              Final Date
            </Typography>
            <Typography variant="body1">
              {timezoneAwareFormat(data.getForm.finalDate, "MMM D, YYYY")}
            </Typography>
          </Box>
        </Box>
      </Box>

      <FormFieldsTool formInfo={data.getForm} />

      <Box
        sx={{
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <Typography fontWeight="bold" textAlign="start" color={"gray"}>
          Responses
        </Typography>
        <Box
          sx={{
            border: "1px solid",
            borderRadius: "10px",
            padding: "10px",
          }}
        >
          <FormResponsesTable
            formId={id}
            onNamePress={(item) => {
              setSelectedResponse(item);
              setShowResponseModal(true);
            }}
          />
        </Box>
      </Box>
    </PageContainer>
  );
};

export default FormPage;
