import { ApolloError, DocumentNode, useMutation } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import toaster from "react-hot-toast";
import { useGetBusRoutesPaginatedQuery } from "../../apollo/busRoutes/queries.generated";
interface DeleteModalProps {
  mutation: DocumentNode;
  updateQueries?: DocumentNode[];
  title?: string;
  text?: string;
  successMessage?: string;
  errorMessage?: string;
  open: boolean;
  variables: any;
  onSuccess?: () => void;
  onClose: () => void;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  open,
  title = "Delete item",
  text = "Are you sure you want to delete this item? This action is irreversible.",
  mutation,
  updateQueries,
  variables,
  onClose,
  onSuccess,
  successMessage = "The item was deleted successfully",
  errorMessage = "There was problem deleting the item: ",
}) => {
  const [process, {}] = useMutation(mutation, {
    refetchQueries: updateQueries,
  });

  const updateBusRoutes = useGetBusRoutesPaginatedQuery();

  const onDeleteClick = () => {
    toaster.promise(process({ variables }), {
      loading: "Deleting...",
      success: () => {
        if (onSuccess) onSuccess();
        return successMessage;
      },
      error: (error: ApolloError) => {
        return errorMessage + error.message;
      },
    });
    onClose();
  };

  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onDeleteClick}>Delete</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
