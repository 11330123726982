import React, { useEffect } from "react";
import {
  Grid,
  MenuItem,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import useDidMountEffect from "../../../shared/hooks/useDidMountEffect";
import {
  GetSchoolsPaginatedDocument,
  SchoolsPaginatedItemFragment,
  useCreateSchoolMutation,
  useUpdateSchoolMutation,
} from "../../../apollo/schools/queries.generated";
import { CreateSchoolDto } from "../../../apollo/types.generated";
import ControlledTextField from "../../../shared/components/ControlledTextField";
import ControlledSelectModalField from "../../../shared/components/ControlledSelectModalField";
import { GetSchoolSystemsPaginatedDocument } from "../../../apollo/schoolSystems/queries.generated";
import { AuthUserFragment } from "../../../apollo/auth/queries.generated";
import useAuthUser from "../../../shared/hooks/useAuthUser";
import useAbility from "../../../shared/hooks/useAbility";
import MapsApiLoader from "../../../shared/components/MapsApiLoader";
import PlaceSearch, {
  PlaceResult,
} from "../../../shared/components/PlacesSearch";
import { GoogleMap, Marker } from "@react-google-maps/api";
import PlaceSearchMap, {
  PlaceSearchMapValue,
} from "../../../shared/components/PlaceSearchMap";

interface SchoolFormModalProps {
  open: boolean;
  onClose: () => void;
  editingSchool?: SchoolsPaginatedItemFragment;
}

interface FormInputs
  extends Omit<CreateSchoolDto, "address" | "addressLat" | "addressLng"> {
  name: string;
  address: PlaceSearchMapValue | null;
}

const schema: Yup.SchemaOf<FormInputs> = Yup.object({
  name: Yup.string().required("This field is required"),
  code: Yup.string().required("This field is required"),
  schoolSystemId: Yup.string().required("This field is required"),
  state: Yup.string().required("This field is required"),
  address: Yup.object({
    address: Yup.string().required().default(""),
    lat: Yup.number().required().default(0),
    lng: Yup.number().required().default(0),
  })
    .nullable()
    .required("You must specify an address")
    .default(null),
});

const getDefaultValues = (
  school?: SchoolsPaginatedItemFragment,
  authUser?: AuthUserFragment | null
): FormInputs | undefined => {
  return {
    code: school?.code || "",
    name: school?.name || "",
    schoolSystemId: school?.schoolSystem?.id || authUser?.schoolSystemId || "",
    state: school?.state || "",
    address: school?.address
      ? {
          address: school?.address,
          lat: school.addressLat as number,
          lng: school.addressLng as number,
        }
      : null,
  };
};
const SchoolFormModal: React.FC<SchoolFormModalProps> = ({
  open,
  onClose,
  editingSchool,
}) => {
  const authUser = useAuthUser();
  const ability = useAbility();
  const { control, handleSubmit, reset } = useForm<FormInputs>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    defaultValues: getDefaultValues(editingSchool, authUser),
  });

  const [
    create,
    { data: createData, loading: createLoading, error: createError },
  ] = useCreateSchoolMutation({
    refetchQueries: [GetSchoolsPaginatedDocument],
  });

  const [
    update,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useUpdateSchoolMutation({
    refetchQueries: [GetSchoolsPaginatedDocument],
  });

  const onSubmit = (data: FormInputs) => {
    if (!data.address) return;
    const submitData = {
      ...data,
      address: data.address.address,
      addressLat: data.address.lat,
      addressLng: data.address.lng,
    };
    if (editingSchool) {
      update({
        variables: {
          id: editingSchool.id,
          data: submitData,
        },
      });
      return;
    }
    // console.log(data);
    create({ variables: { data: submitData } });
  };

  useEffect(() => {
    if (createData) {
      toast.success("School created successfully");
      reset();
      onClose();
    }

    if (createError) {
      toast.error("Error creating School: " + createError.message);
    }
  }, [createData, createError]);

  useEffect(() => {
    if (updateData) {
      toast.success("School updated successfully");
      reset();
      onClose();
    }

    if (updateError) {
      toast.error("Error updating School: " + updateError.message);
    }
  }, [updateData, updateError]);

  useDidMountEffect(() => {
    reset(getDefaultValues(editingSchool));
  }, [editingSchool]);
  return (
    <Dialog
      open={open}
      onClose={!createLoading ? onClose : undefined}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>{editingSchool ? "Update" : "Create"} School</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container sx={{ marginTop: "10px" }} spacing={2}>
            <Grid item xs={12}>
              <ControlledTextField name="name" control={control} label="Name" />
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                name="code"
                control={control}
                label="Arttiee School Code"
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                name="state"
                control={control}
                label="State"
                select
              >
                {states.map((s) => (
                  <MenuItem key={s} value={s}>
                    {s}
                  </MenuItem>
                ))}
              </ControlledTextField>
            </Grid>
            {ability.can("manage", "School Systems") && (
              <Grid item xs={12}>
                <ControlledSelectModalField
                  name="schoolSystemId"
                  control={control}
                  initialValue={
                    editingSchool?.schoolSystem as SchoolsPaginatedItemFragment
                  }
                  textFieldProps={{
                    label: "School System",
                    fullWidth: true,
                  }}
                  query={GetSchoolSystemsPaginatedDocument}
                  labelsExractor={(item: SchoolsPaginatedItemFragment) => ({
                    primary: item.name,
                  })}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <MapsApiLoader>
                <Controller
                  control={control}
                  name="address"
                  render={({ field, fieldState }) => {
                    return (
                      <PlaceSearchMap
                        error={fieldState.error?.message}
                        onPlaceChange={field.onChange}
                        value={field.value}
                      />
                    );
                  }}
                />
              </MapsApiLoader>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel </Button>
        <LoadingButton
          loading={createLoading || updateLoading}
          type="submit"
          onClick={handleSubmit(onSubmit)}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const states = [
  "Alabama",
  "Alaska",
  "American Samoa",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Federated States of Micronesia",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Marshall Islands",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Northern Mariana Islands",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Palau",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virgin Island",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

export default SchoolFormModal;
