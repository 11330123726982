import {
  FormFieldFragment,
  GetFormByIdDocument,
} from "../../../apollo/forms/queries.generated";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import {
  useCreateFormFieldMutation,
  useUpdateFormFieldMutation,
} from "../../../apollo/forms/mutations.generated";
import { useEffect } from "react";
import toast from "react-hot-toast";
import useDidMountEffect from "../../../shared/hooks/useDidMountEffect";

import ArticleIcon from "@mui/icons-material/Article";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PhotoCameraBackIcon from "@mui/icons-material/PhotoCameraBack";
import React from "react";
import ControlledTextField from "../../../shared/components/ControlledTextField";
import ControlledSelectModalField from "../../../shared/components/ControlledSelectModalField";
import { LoadingButton } from "@mui/lab";

interface FormFieldsCreationModalProps {
  open: boolean;
  onClose: () => void;
  formId: string;
  fieldsLength: number;
  editingField?: FormFieldFragment;
}

interface FormFieldInputs {
  title: string;
  type: string;
  required: boolean;
  hintText?: string;
  // radioButtons?: string[];
  // checkBoxes?: string[];
  // dropdownItems?: string[];
}

const noHintText = ["infoText", "radio", "checkbox", "date", "time", "images"];

const schema: Yup.SchemaOf<FormFieldInputs> = Yup.object({
  title: Yup.string().required("This field is required"),
  type: Yup.string().required("This field is required"),
  required: Yup.boolean().required("This field is required"),
  hintText: Yup.string().when("type", {
    is: (val: string) => !noHintText.includes(val),
    then: Yup.string().required("This field is required"),
  }),
});

const FormFieldsCreationModal: React.FC<FormFieldsCreationModalProps> = ({
  open,
  onClose,
  editingField,
  formId,
  fieldsLength,
}) => {
  const { control, handleSubmit, setValue, watch, reset } =
    useForm<FormFieldInputs>({
      resolver: yupResolver(schema),
      shouldFocusError: true,
      defaultValues: {
        title: editingField?.title || "",
        type: editingField?.type || "",
        required: editingField?.required || false,
        hintText: editingField?.hintText || "",
        // radioButtons: editingField?.radioButtons || [],
        // checkBoxes: editingField?.checkBoxes || [],
        // dropdownItems: editingField?.dropdownItems || [],
      },
    });

  const [type, setType] = React.useState(editingField?.type || "");
  const [isRequired, setIsRequired] = React.useState(false);
  const [options, setOptions] = React.useState<any[]>([]);
  const [optionTextField, setOptionTextField] = React.useState("");
  const [submitClicked, setSubmitClicked] = React.useState(false);

  const [
    create,
    { data: createData, loading: createLoading, error: createError },
  ] = useCreateFormFieldMutation({
    refetchQueries: [{ query: GetFormByIdDocument, variables: { id: formId } }],
  });

  const [
    update,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useUpdateFormFieldMutation({
    refetchQueries: [{ query: GetFormByIdDocument, variables: { id: formId } }],
  });

  const onSubmit = (data: FormFieldFragment) => {
    if (
      options.length <= 1 &&
      (type === "radio" || type === "checkbox" || type === "dropdown")
    )
      return;

    if (type === "radio") {
      data = {
        ...data,
        radioButtons: options,
      };
    }
    if (type === "checkbox") {
      data = {
        ...data,
        checkBoxes: options,
      };
    }
    if (type === "dropdown") {
      data = {
        ...data,
        dropdownItems: options,
      };
    }

    data = {
      ...data,
      formId: formId,
    };

    if (editingField) {
      data = {
        ...data,
        id: editingField.id,
        order: editingField.order,
      };

      const { formId, ...rest } = data;

      update({
        variables: {
          data: rest,
        },
      });

      return;
    }

    data = {
      ...data,
      order: fieldsLength + 1,
    };
    create({ variables: { data } });
  };

  useEffect(() => {
    if (createData) {
      toast.success("Field created successfully");
      setIsRequired(false);
      setOptions([]);
      setOptionTextField("");
      setSubmitClicked(false);
      setType("");
      reset();
      onClose();
    }

    if (createError) {
      toast.error("Error creating Field: " + createError.message);
    }
  }, [createData, createError]);

  useEffect(() => {
    if (updateData) {
      toast.success("Field updated successfully");
      onClose();
    }

    if (updateError) {
      toast.error("Error updating Field: " + updateError.message);
    }
  }, [updateData, updateError]);

  useDidMountEffect(() => {
    if (editingField) {
      console.log("editingField", editingField);
      setValue("title", editingField.title);
      setValue("type", editingField.type);
      setValue("required", editingField.required);
      setValue("hintText", editingField.hintText || "");
      // setValue("radioButtons", editingField.radioButtons || []);
      // setValue("checkBoxes", editingField.checkBoxes || []);
      // setValue("dropdownItems", editingField.dropdownItems || []);
      setType(editingField.type);
      setIsRequired(editingField.required);
      if (editingField.type === "radio") {
        setOptions(editingField.radioButtons || []);
      }
      if (editingField.type === "checkbox") {
        setOptions(editingField.checkBoxes || []);
      }
      if (editingField.type === "dropdown") {
        setOptions(editingField.dropdownItems || []);
      }
    }
  }, [editingField]);

  useDidMountEffect(() => {
    if (!editingField) {
      setIsRequired(false);
      setOptions([]);
      setOptionTextField("");
      setSubmitClicked(false);
      reset();
      setValue("type", type);
    }
  }, [type]);

  useDidMountEffect(() => {
    setValue("required", isRequired);
  }, [isRequired]);

  useDidMountEffect(() => {
    if (!open && editingField) {
      setIsRequired(false);
      setOptions([]);
      setOptionTextField("");
      setSubmitClicked(false);
      setType("");
      reset();
    }
  }, [open]);

  const createTextControlledTextField = () => {
    return (
      <Grid item xs={12}>
        <ControlledTextField
          control={control}
          name="title"
          label={type === "infoText" ? "Text" : "Title"}
          multiline={type === "infoText" ? true : false}
          fullWidth
          sx={{ marginTop: "10px" }}
        />
      </Grid>
    );
  };

  const createHintTextControlledTextField = () => {
    return (
      <Grid item xs={12}>
        <ControlledTextField
          control={control}
          name="hintText"
          label="Hint Text"
          fullWidth
          sx={{ marginTop: "10px" }}
        />
      </Grid>
    );
  };

  const createRequiredControlledTextField = () => {
    return (
      <Grid item xs={12}>
        <Typography variant="body1">
          Required:
          <Checkbox
            checked={isRequired}
            onChange={() => setIsRequired(!isRequired)}
          />
        </Typography>
      </Grid>
    );
  };

  const createOptionsTextField = () => {
    return (
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <TextField
            label="Type an option"
            sx={{ marginTop: "10px", marginRight: "10px", width: "100%" }}
            value={optionTextField}
            onChange={(e) => setOptionTextField(e.target.value)}
            //  if options is empty, send error message and helpertext
            error={submitClicked && options.length <= 1}
            helperText={
              submitClicked && options.length <= 1
                ? "You need to add at least two options"
                : ""
            }
          />
          <Button
            variant="contained"
            color="primary"
            sx={{ marginTop: "10px" }}
            onClick={() => {
              if (optionTextField === "") return;
              setOptions([...options, optionTextField]);
              setOptionTextField("");
            }}
          >
            Add
          </Button>
        </Grid>

        <Grid
          container
          spacing={2}
          direction="column"
          sx={{
            marginTop: "10px",
            marginBottom: "10px",
            background: "#f5f5f5",
            borderRadius: "10px",
            marginLeft: "3px",
          }}
        >
          {options.map((option, index) => (
            <Grid
              item
              key={index}
              sx={{
                padding: "10px",
              }}
            >
              <Grid container>
                <Grid item xs={10}>
                  <Typography variant="body1">{option}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setOptions(options.filter((opt) => opt !== option));
                    }}
                  >
                    Delete
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  };

  const createFieldSettings = () => {
    switch (type) {
      case "infoText":
        return (
          <Grid item xs={12}>
            {createTextControlledTextField()}
          </Grid>
        );
      case "text":
        return (
          <Grid item xs={12}>
            {createTextControlledTextField()}
            {createHintTextControlledTextField()}
            {createRequiredControlledTextField()}
          </Grid>
        );
      case "number":
        return (
          <Grid item xs={12}>
            {createTextControlledTextField()}
            {createHintTextControlledTextField()}
            {createRequiredControlledTextField()}
          </Grid>
        );
      case "phone":
        return (
          <Grid item xs={12}>
            {createTextControlledTextField()}
            {createHintTextControlledTextField()}
            {createRequiredControlledTextField()}
          </Grid>
        );
      case "email":
        return (
          <Grid item xs={12}>
            {createTextControlledTextField()}
            {createHintTextControlledTextField()}
            {createRequiredControlledTextField()}
          </Grid>
        );
      case "multiline":
        return (
          <Grid item xs={12}>
            {createTextControlledTextField()}
            {createHintTextControlledTextField()}
            {createRequiredControlledTextField()}
          </Grid>
        );
      case "radio":
        return (
          <Grid item xs={12}>
            {createTextControlledTextField()}
            {createOptionsTextField()}
            {createRequiredControlledTextField()}
          </Grid>
        );
      case "dropdown":
        return (
          <Grid item xs={12}>
            {createTextControlledTextField()}
            {createHintTextControlledTextField()}
            {createOptionsTextField()}
          </Grid>
        );
      case "checkbox":
        return (
          <Grid item xs={12}>
            {createTextControlledTextField()}
            {createOptionsTextField()}
            {createRequiredControlledTextField()}
          </Grid>
        );
      case "date":
        return (
          <Grid item xs={12}>
            {createTextControlledTextField()}
            {createRequiredControlledTextField()}
          </Grid>
        );
      case "time":
        return (
          <Grid item xs={12}>
            {createTextControlledTextField()}
            {createRequiredControlledTextField()}
          </Grid>
        );
      case "images":
        return (
          <Grid item xs={12}>
            {createTextControlledTextField()}
          </Grid>
        );
      default:
        return <></>;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={!createLoading ? onClose : undefined}
      maxWidth="md"
      fullWidth
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{editingField ? "Update" : "Create"} Field</DialogTitle>
        <DialogContent>
          <Grid container sx={{ marginTop: "10px" }} spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1" fontWeight="bold">
                Select a Field
              </Typography>
              <Grid
                sx={{
                  border: "1.5px solid rgba(0, 0, 0, 0.12)",
                  borderRadius: "10px",
                  padding: "10px",
                  background: "#f5f5f5",
                }}
              >
                <Button
                  variant="contained"
                  color={type === "infoText" ? "primary" : "inherit"}
                  sx={{ marginRight: "5px", marginTop: "5px" }}
                  disabled={type !== "infoText" && editingField ? true : false}
                  onClick={() => setType("infoText")}
                >
                  <ArticleIcon sx={{ marginRight: "3px" }} />
                  <Typography variant="body1" fontSize={"12px"}>
                    Text
                  </Typography>
                </Button>
                <Button
                  variant="contained"
                  color={type === "text" ? "primary" : "inherit"}
                  sx={{ marginRight: "5px", marginTop: "5px" }}
                  disabled={type !== "text" && editingField ? true : false}
                  onClick={() => setType("text")}
                >
                  <FormatColorTextIcon sx={{ marginRight: "3px" }} />
                  <Typography variant="body1" fontSize={"12px"}>
                    Input Text
                  </Typography>
                </Button>

                <Button
                  variant="contained"
                  color={type === "number" ? "primary" : "inherit"}
                  sx={{ marginRight: "5px", marginTop: "5px" }}
                  disabled={type !== "number" && editingField ? true : false}
                  onClick={() => setType("number")}
                >
                  <LooksOneIcon sx={{ marginRight: "3px" }} />
                  <Typography variant="body1" fontSize={"12px"}>
                    Number
                  </Typography>
                </Button>

                <Button
                  variant="contained"
                  color={type === "phone" ? "primary" : "inherit"}
                  sx={{ marginRight: "5px", marginTop: "5px" }}
                  disabled={type !== "phone" && editingField ? true : false}
                  onClick={() => setType("phone")}
                >
                  <LocalPhoneIcon sx={{ marginRight: "3px" }} />
                  <Typography variant="body1" fontSize={"12px"}>
                    Phone
                  </Typography>
                </Button>

                <Button
                  variant="contained"
                  color={type === "email" ? "primary" : "inherit"}
                  sx={{ marginRight: "5px", marginTop: "5px" }}
                  disabled={type !== "email" && editingField ? true : false}
                  onClick={() => setType("email")}
                >
                  <EmailIcon sx={{ marginRight: "3px" }} />
                  <Typography variant="body1" fontSize={"12px"}>
                    Email
                  </Typography>
                </Button>

                <Button
                  variant="contained"
                  color={type === "multiline" ? "primary" : "inherit"}
                  sx={{ marginRight: "5px", marginTop: "5px" }}
                  disabled={type !== "multiline" && editingField ? true : false}
                  onClick={() => setType("multiline")}
                >
                  <FormatAlignJustifyIcon sx={{ marginRight: "3px" }} />
                  <Typography variant="body1" fontSize={"12px"}>
                    Multiline
                  </Typography>
                </Button>

                <Button
                  variant="contained"
                  color={type === "radio" ? "primary" : "inherit"}
                  sx={{ marginRight: "5px", marginTop: "5px" }}
                  disabled={type !== "radio" && editingField ? true : false}
                  onClick={() => setType("radio")}
                >
                  <RadioButtonCheckedIcon sx={{ marginRight: "3px" }} />
                  <Typography variant="body1" fontSize={"12px"}>
                    Radio
                  </Typography>
                </Button>

                <Button
                  variant="contained"
                  color={type === "dropdown" ? "primary" : "inherit"}
                  sx={{ marginRight: "5px", marginTop: "5px" }}
                  disabled={type !== "dropdown" && editingField ? true : false}
                  onClick={() => setType("dropdown")}
                >
                  <ArrowDropDownCircleIcon sx={{ marginRight: "3px" }} />
                  <Typography variant="body1" fontSize={"12px"}>
                    Dropdown
                  </Typography>
                </Button>

                <Button
                  variant="contained"
                  color={type === "checkbox" ? "primary" : "inherit"}
                  sx={{ marginRight: "5px", marginTop: "5px" }}
                  disabled={type !== "checkbox" && editingField ? true : false}
                  onClick={() => setType("checkbox")}
                >
                  <CheckBoxIcon sx={{ marginRight: "3px" }} />
                  <Typography variant="body1" fontSize={"12px"}>
                    Checkbox
                  </Typography>
                </Button>

                <Button
                  variant="contained"
                  color={type === "date" ? "primary" : "inherit"}
                  sx={{ marginRight: "5px", marginTop: "5px" }}
                  disabled={type !== "date" && editingField ? true : false}
                  onClick={() => setType("date")}
                >
                  <DateRangeIcon sx={{ marginRight: "3px" }} />
                  <Typography variant="body1" fontSize={"12px"}>
                    Date
                  </Typography>
                </Button>

                <Button
                  variant="contained"
                  color={type === "time" ? "primary" : "inherit"}
                  sx={{ marginRight: "5px", marginTop: "5px" }}
                  disabled={type !== "time" && editingField ? true : false}
                  onClick={() => setType("time")}
                >
                  <AccessTimeIcon sx={{ marginRight: "3px" }} />
                  <Typography variant="body1" fontSize={"12px"}>
                    Time
                  </Typography>
                </Button>

                <Button
                  variant="contained"
                  color={type === "images" ? "primary" : "inherit"}
                  sx={{ marginRight: "5px", marginTop: "5px" }}
                  // disabled={type !== "images" && editingField ? true : false}
                  disabled
                  onClick={() => setType("images")}
                >
                  <PhotoCameraBackIcon
                    sx={{ marginRight: "3px", marginTop: "5px" }}
                  />
                  <Typography variant="body1" fontSize={"12px"}>
                    Images
                  </Typography>
                </Button>
              </Grid>
            </Grid>

            {type && (
              <Grid item xs={12} visibility={type ? "visible" : "hidden"}>
                <Typography variant="body1" fontWeight="bold">
                  Field Settings
                </Typography>
              </Grid>
            )}

            {type && createFieldSettings()}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel </Button>
          <LoadingButton
            loading={createLoading || updateLoading}
            type="submit"
            onClick={() => setSubmitClicked(true)}
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default FormFieldsCreationModal;
