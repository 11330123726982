import { Container, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  GetBusRouteDocument,
  useAssignStudentsToStopsMutation,
  useGetBusRouteForAssignationQuery,
} from "../../apollo/busRoutes/queries.generated";
import LoadingBackdrop from "../../shared/components/LoadingBackdrop";
import LoadingPage from "../home/LoadingPage";
import StudentsStopsAssigner, {
  AssignerFormInput,
} from "./components/StudentsStopsAssigner";
import toast from "react-hot-toast";
import { CreateBusStopAssigmentDto } from "../../apollo/types.generated";

interface StudentsStopsAssignerPageProps {}

const StudentsStopsAssignerPage: React.FC<
  StudentsStopsAssignerPageProps
> = ({}) => {
  const history = useHistory();
  let { id } = useParams<{ id: string }>();
  const { data, loading, error } = useGetBusRouteForAssignationQuery({
    variables: { id: id },
  });

  const [
    submit,
    { data: submitData, loading: submitting, error: submitError },
  ] = useAssignStudentsToStopsMutation();

  const onSubmit = (data: AssignerFormInput) => {
    const assignations: CreateBusStopAssigmentDto[] = data.paths.reduce(
      (assignations, path, i) => {
        assignations = [
          ...assignations,
          ...path.stops.map((s) => ({
            stopId: s.id,
            studentIds: s.students.map((student) => student.id),
          })),
        ];

        return assignations;
      },
      [] as CreateBusStopAssigmentDto[]
    );

    submit({
      variables: {
        data: assignations,
      },
      refetchQueries: [{ query: GetBusRouteDocument, variables: { id: id } }],
    });
  };
  useEffect(() => {
    if (submitData) {
      toast.success("Bus Route updated successfully");
      history.push("/dashboard/busRoutes/" + id);
    }

    if (submitError) {
      toast.error("Error updating Bus Route: " + submitError.message);
    }
  }, [submitData, submitError]);

  if (error) return <div>error</div>;

  if (!data) return <LoadingPage />;

  return (
    <Container>
      <LoadingBackdrop open={submitting} />
      <Typography variant="h4" sx={{ marginBottom: "20px" }}>
        Assign Students to Bus Stops
      </Typography>
      <StudentsStopsAssigner paths={data.busRoute.paths} onSubmit={onSubmit} />
    </Container>
  );
};

export default StudentsStopsAssignerPage;
