import { Box, Card, Grid, Typography } from "@mui/material";
import React, { useState } from "react";

import DeleteModal from "../../shared/components/DeleteModal";

import BusDriversTable from "./components/BusDriversTable";
import BusDriversFormModal from "./components/BusDriversFormModal";
import {
  BusDriversPaginatedItemFragment,
  DeleteBusDriverDocument,
  GetBusDriversPaginatedDocument,
} from "../../apollo/busDrivers/queries.generated";
import PageContainer from "../../shared/components/PageContainer";
import TableCard from "../../shared/components/TableCard";

interface BusDriversPageProps {}

const BusDriversPage: React.FC<BusDriversPageProps> = ({}) => {
  const [showFormModal, setShowFormModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedBusDriver, setSelectedBusDriver] =
    useState<BusDriversPaginatedItemFragment>();

  const handleFormClose = () => {
    setSelectedBusDriver(undefined);
    setShowFormModal(false);
  };

  const handleDeleteModalClose = () => {
    setSelectedBusDriver(undefined);
    setShowDeleteModal(false);
  };
  return (
    <PageContainer>
      <BusDriversFormModal
        editingBusDriver={selectedBusDriver}
        open={showFormModal}
        onClose={handleFormClose}
      />
      <DeleteModal
        open={showDeleteModal}
        mutation={DeleteBusDriverDocument}
        updateQueries={[GetBusDriversPaginatedDocument]}
        variables={{ id: selectedBusDriver?.id }}
        onClose={handleDeleteModalClose}
        title="Delete Bus Driver"
      />
      <TableCard>
        <Typography
          variant="h5"
          textAlign="start"
          fontWeight="bold"
          color="secondary"
          gutterBottom
        >
          Bus Drivers
        </Typography>
        <BusDriversTable
          onAddClick={() => setShowFormModal(true)}
          onEditPress={(item) => {
            setSelectedBusDriver(item);
            setShowFormModal(true);
          }}
          onDeletePress={(item) => {
            setSelectedBusDriver(item);
            setShowDeleteModal(true);
          }}
        />
        {/* <DataGrid
        style={{
          border: "none",
        }}
        columns={columns}
        rows={data?.StudentsystemsPaginated.nodes || []}
      /> */}
      </TableCard>
    </PageContainer>
  );
};

export default BusDriversPage;
