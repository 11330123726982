import React, { useEffect } from "react";
import {
  Grid,
  Modal,
  Paper,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import { useNow } from "@mui/lab/internal/pickers/hooks/useUtils";
import useDidMountEffect from "../../../shared/hooks/useDidMountEffect";
import {
  GetStudentsPaginatedDocument,
  StudentsPaginatedItemFragment,
  useCreateStudentMutation,
  useUpdateStudentMutation,
} from "../../../apollo/students/queries.generated";
import {
  CreateSchoolDto,
  CreateStudentDto,
  SchoolDto,
} from "../../../apollo/types.generated";
import ControlledTextField from "../../../shared/components/ControlledTextField";
import ControlledSelectModalField from "../../../shared/components/ControlledSelectModalField";
import { GetSchoolSystemsPaginatedDocument } from "../../../apollo/schoolSystems/queries.generated";
import {
  GetSchoolsPaginatedDocument,
  SchoolsPaginatedItemFragment,
} from "../../../apollo/schools/queries.generated";
import apolloClient from "../../../apollo/client";
import {
  CheckEmailAvailabilityQuery,
  CheckEmailAvailabilityDocument,
  CheckEmailAvailabilityQueryVariables,
} from "../../../apollo/users/queries.generated";
import useAuthUser from "../../../shared/hooks/useAuthUser";
import useAbility from "../../../shared/hooks/useAbility";
import { AuthUserFragment } from "../../../apollo/auth/queries.generated";
import { GetFamiliesPaginatedDocument } from "../../../apollo/families/query.generated";

interface StudentsFormModalProps {
  open: boolean;
  onClose: () => void;
  editingStudent?: StudentsPaginatedItemFragment;
}

interface FormInputs extends Omit<CreateStudentDto, "password"> {
  studentId: string;
  passwordConfirmation?: string;
  password?: string;
}

const getSchema = (
  editingStudent?: StudentsPaginatedItemFragment
): Yup.SchemaOf<FormInputs> =>
  Yup.object({
    firstName: Yup.string().required("This field is required"),
    lastName: Yup.string().required("This field is required"),
    studentId: Yup.string().required("This field is required"),
    schoolId: Yup.string().required("This field is required"),
    email: Yup.string()
      .required("This field is required")
      .email("Invalid email")
      .test(
        "test-mail",
        "This email address is not available",
        async (value, context) => {
          if (!value) return false;
          const result = await apolloClient.query<
            CheckEmailAvailabilityQuery,
            CheckEmailAvailabilityQueryVariables
          >({
            query: CheckEmailAvailabilityDocument,
            variables: { email: value, userId: editingStudent?.user.id },
          });

          return result.data.checkEmailAvailability;
        }
      ),
    password: editingStudent
      ? Yup.string().optional()
      : Yup.string().required("This field is required"),
    passwordConfirmation: editingStudent
      ? Yup.string()
          .optional()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
      : Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required(""),
    schoolSystemId: Yup.string().optional(),
  });

const getDefaultValues = (
  student?: StudentsPaginatedItemFragment,
  user?: AuthUserFragment | null
): FormInputs | undefined => {
  return {
    studentId: student?.studentId || "",
    firstName: student?.user.firstName || "",
    lastName: student?.user.lastName || "",

    email: student?.user.email || "",
    password: "",
    passwordConfirmation: "",
    schoolId: student?.school?.id || user?.schoolId || "",
  };
};
const StudentsFormModal: React.FC<StudentsFormModalProps> = ({
  open,
  onClose,
  editingStudent,
}) => {
  const authUser = useAuthUser();
  const ability = useAbility();
  const { control, handleSubmit, reset, setValue } = useForm<FormInputs>({
    resolver: yupResolver(getSchema(editingStudent)),
    shouldFocusError: true,
    defaultValues: getDefaultValues(editingStudent, authUser),
  });

  const [
    create,
    { data: createData, loading: createLoading, error: createError },
  ] = useCreateStudentMutation({
    refetchQueries: [
      GetStudentsPaginatedDocument,
      GetFamiliesPaginatedDocument,
    ],
  });

  const [
    update,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useUpdateStudentMutation({
    refetchQueries: [
      GetStudentsPaginatedDocument,
      GetFamiliesPaginatedDocument,
    ],
  });

  const onSubmit = (data: FormInputs) => {
    const { passwordConfirmation, ...rest } = data;
    if (editingStudent) {
      update({
        variables: {
          id: editingStudent.id,
          data: rest,
        },
        refetchQueries: [GetStudentsPaginatedDocument],
      });
      return;
    }

    if (rest.password) {
      create({
        variables: {
          data: {
            ...rest,
            password: rest.password,
          },
        },
        refetchQueries: [GetStudentsPaginatedDocument],
      });
    }
  };

  useEffect(() => {
    if (createData) {
      toast.success("Students created successfully");
      reset(getDefaultValues());
      onClose();
    }

    if (createError) {
      toast.error("Error creating Students: " + createError.message);
    }
  }, [createData, createError]);

  useEffect(() => {
    if (updateData) {
      toast.success("Students updated successfully");
      reset(getDefaultValues());
      onClose();
    }

    if (updateError) {
      toast.error("Error updating Students: " + updateError.message);
    }
  }, [updateData, updateError]);

  useDidMountEffect(() => {
    reset(getDefaultValues(editingStudent));
  }, [editingStudent]);

  // useEffect(() => {
  //   if (authUser && authUser.schoolId) {
  //     setValue("schoolId", authUser.schoolId);
  //   }
  // }, [authUser]);

  return (
    <Dialog
      open={open}
      onClose={!createLoading ? onClose : undefined}
      maxWidth="md"
      fullWidth
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <DialogTitle>
          {editingStudent ? "Update" : "Create"} Student
        </DialogTitle>
        <DialogContent>
          <Grid container sx={{ marginTop: "10px" }} spacing={2}>
            <Grid item xs={12}>
              <ControlledTextField
                name="studentId"
                control={control}
                label="Student ID"
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                name="firstName"
                control={control}
                label="First Name"
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                name="lastName"
                control={control}
                label="Last Name"
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                name="email"
                control={control}
                label="Email"
              />
            </Grid>

            <Grid item xs={12}>
              {!!editingStudent && (
                <Typography variant="caption" color="GrayText">
                  You can skip this field to omit changing the student's
                  password
                </Typography>
              )}
              <ControlledTextField
                name="password"
                control={control}
                type="password"
                label="Password"
              />
            </Grid>

            <Grid item xs={12}>
              <ControlledTextField
                type="password"
                name="passwordConfirmation"
                control={control}
                label="Password Confirmation"
              />
            </Grid>
            {ability.can("manage", "Schools") && (
              <Grid item xs={12}>
                <ControlledSelectModalField
                  name="schoolId"
                  control={control}
                  variables={
                    authUser?.schoolSystemId
                      ? { schoolSystemId: authUser.schoolSystemId }
                      : undefined
                  }
                  initialValue={
                    editingStudent?.school as SchoolsPaginatedItemFragment
                  }
                  textFieldProps={{
                    label: "School",
                    fullWidth: true,
                  }}
                  query={GetSchoolsPaginatedDocument}
                  labelsExractor={(item: SchoolsPaginatedItemFragment) => ({
                    primary: item.name,
                    secondary: item.schoolSystem?.name,
                  })}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel </Button>
          <LoadingButton loading={createLoading || updateLoading} type="submit">
            Submit
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default StudentsFormModal;
