import { Delete, Edit, Settings } from "@mui/icons-material";
import { Box, Button, Grid, IconButton, TextField } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { useMemo } from "react";
import { TableColumn } from "react-data-table-component";
import { FiPlus } from "react-icons/fi";
import {
  SchoolSystemPaginatedItemFragment,
  useGetSchoolSystemsPaginatedQuery,
  GetSchoolSystemsPaginatedDocument,
} from "../../../apollo/schoolSystems/queries.generated";
import RemoteDataTable, {
  BasicTableActionFunction,
  getBasicActionColumns,
  RemoteDataTableProps,
} from "../../../shared/components/RemoteDataTable";
import SearchField from "../../../shared/components/SearchField";
import useAbility from "../../../shared/hooks/useAbility";

interface SchoolSystemsTableProps
  extends Pick<RemoteDataTableProps, "onAddClick" | "refresherBoolean"> {
  onEditPress?: BasicTableActionFunction<SchoolSystemPaginatedItemFragment>;
  onDeletePress?: BasicTableActionFunction<SchoolSystemPaginatedItemFragment>;
  onSettingsPress?: BasicTableActionFunction<SchoolSystemPaginatedItemFragment>;
}
const muiColumns: GridColDef[] = [
  // {
  //   flex: 1,
  //   minWidth: 200,
  //   field: "id",
  //   sortable: false,
  //   headerName: "View",
  //   renderCell: (params) => {
  //     return <Button>{params.value}</Button>;
  //   },
  // },
  { flex: 1, field: "name", headerName: "Name", minWidth: 200 },
  {
    flex: 1,
    field: "feePerRoute",
    headerName: "Fee per Route",
    minWidth: 50,
    sortable: false,
  },
  {
    flex: 1,
    field: "routeCount",
    headerName: "Active Routes",
    minWidth: 50,
    sortable: false,
  },
  {
    flex: 1,
    field: "monthlyFee",
    headerName: "Monthly fee",
    minWidth: 50,
    sortable: false,
  },
  {
    flex: 1,
    field: "familiesSubscriptionFee",
    headerName: "Families Subscription Fee",
    minWidth: 200,
    sortable: false,
  },
];

const SchoolSystemsTable: React.FC<SchoolSystemsTableProps> = ({
  onEditPress,
  onDeletePress,
  onSettingsPress,
  ...props
}) => {
  const ability = useAbility();
  const actionColumns = useMemo(
    () => getActionColumns(onEditPress, onDeletePress, onSettingsPress),
    [onEditPress, onDeletePress]
  );
  return (
    <RemoteDataTable
      query={GetSchoolSystemsPaginatedDocument}
      searchable
      renderAddButton={ability.can("manage", "all")}
      columns={muiColumns}
      actionColumns={actionColumns}
      {...props}
    />
  );
};

const getActionColumns = <SchoolSystemPaginatedItemFragment,>(
  onEditPress?: BasicTableActionFunction<SchoolSystemPaginatedItemFragment>,
  onDeletePress?: BasicTableActionFunction<SchoolSystemPaginatedItemFragment>,
  onSettingsPress?: BasicTableActionFunction<SchoolSystemPaginatedItemFragment>
): GridColDef[] => [
  // {
  //   flex: 1,
  //   minWidth: 200,
  //   field: "id",
  //   sortable: false,
  //   headerName: "View",
  //   renderCell: (params) => {
  //     return <Button>{params.value}</Button>;
  //   },
  // },
  {
    // flex: 1,
    field: "actions",
    align: "center",
    headerName: "",
    minWidth: 150,
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => (
      <>
        <IconButton
          onClick={() => {
            if (onSettingsPress)
              onSettingsPress(row as SchoolSystemPaginatedItemFragment);
          }}
        >
          <Settings />
        </IconButton>

        <IconButton
          onClick={() => {
            if (onEditPress)
              onEditPress(row as SchoolSystemPaginatedItemFragment);
          }}
        >
          <Edit />
        </IconButton>

        <IconButton
          onClick={() => {
            if (onDeletePress)
              onDeletePress(row as SchoolSystemPaginatedItemFragment);
          }}
        >
          <Delete />
        </IconButton>
      </>
    ),
  },
];

export default SchoolSystemsTable;
