import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SchoolSystemPaginatedItemFragment = { id: string, name: string, createdAt: any, updatedAt: any, monthlyFee: number, feePerRoute: number, routeCount: number, familiesSubscriptionFee: number };

export type GetSchoolSystemsPaginatedQueryVariables = Types.Exact<{
  search?: Types.Maybe<Types.Scalars['String']>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  sortBy?: Types.Maybe<Types.Scalars['String']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  sortDirection?: Types.Maybe<Types.Scalars['String']>;
  schoolSystemId?: Types.Maybe<Types.Scalars['String']>;
}>;


export type GetSchoolSystemsPaginatedQuery = { schoolSystemsPaginated: { totalsCount: number, nodes?: Array<{ id: string, name: string, createdAt: any, updatedAt: any, monthlyFee: number, feePerRoute: number, routeCount: number, familiesSubscriptionFee: number }> | null | undefined } };

export type GetDisabledSchoolSystemsQueryVariables = Types.Exact<{
  search?: Types.Maybe<Types.Scalars['String']>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  sortBy?: Types.Maybe<Types.Scalars['String']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  sortDirection?: Types.Maybe<Types.Scalars['String']>;
}>;


export type GetDisabledSchoolSystemsQuery = { getDisabledSchoolSystemsPaginated: { totalsCount: number, nodes?: Array<{ id: string, name: string, createdAt: any, updatedAt: any, monthlyFee: number, feePerRoute: number, routeCount: number, familiesSubscriptionFee: number }> | null | undefined } };

export const SchoolSystemPaginatedItemFragmentDoc = gql`
    fragment SchoolSystemPaginatedItem on SchoolSystemDTO {
  id
  name
  createdAt
  updatedAt
  monthlyFee
  feePerRoute
  routeCount
  familiesSubscriptionFee
}
    `;
export const GetSchoolSystemsPaginatedDocument = gql`
    query getSchoolSystemsPaginated($search: String, $limit: Int, $sortBy: String, $offset: Int, $sortDirection: String, $schoolSystemId: String) {
  schoolSystemsPaginated(
    search: $search
    limit: $limit
    offset: $offset
    sortBy: $sortBy
    sortDirection: $sortDirection
    schoolSystemId: $schoolSystemId
  ) {
    nodes {
      ...SchoolSystemPaginatedItem
    }
    totalsCount
  }
}
    ${SchoolSystemPaginatedItemFragmentDoc}`;

/**
 * __useGetSchoolSystemsPaginatedQuery__
 *
 * To run a query within a React component, call `useGetSchoolSystemsPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolSystemsPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolSystemsPaginatedQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      offset: // value for 'offset'
 *      sortDirection: // value for 'sortDirection'
 *      schoolSystemId: // value for 'schoolSystemId'
 *   },
 * });
 */
export function useGetSchoolSystemsPaginatedQuery(baseOptions?: Apollo.QueryHookOptions<GetSchoolSystemsPaginatedQuery, GetSchoolSystemsPaginatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSchoolSystemsPaginatedQuery, GetSchoolSystemsPaginatedQueryVariables>(GetSchoolSystemsPaginatedDocument, options);
      }
export function useGetSchoolSystemsPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSchoolSystemsPaginatedQuery, GetSchoolSystemsPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSchoolSystemsPaginatedQuery, GetSchoolSystemsPaginatedQueryVariables>(GetSchoolSystemsPaginatedDocument, options);
        }
export type GetSchoolSystemsPaginatedQueryHookResult = ReturnType<typeof useGetSchoolSystemsPaginatedQuery>;
export type GetSchoolSystemsPaginatedLazyQueryHookResult = ReturnType<typeof useGetSchoolSystemsPaginatedLazyQuery>;
export type GetSchoolSystemsPaginatedQueryResult = Apollo.QueryResult<GetSchoolSystemsPaginatedQuery, GetSchoolSystemsPaginatedQueryVariables>;
export const GetDisabledSchoolSystemsDocument = gql`
    query getDisabledSchoolSystems($search: String, $limit: Int, $sortBy: String, $offset: Int, $sortDirection: String) {
  getDisabledSchoolSystemsPaginated(
    search: $search
    limit: $limit
    offset: $offset
    sortBy: $sortBy
    sortDirection: $sortDirection
  ) {
    nodes {
      ...SchoolSystemPaginatedItem
    }
    totalsCount
  }
}
    ${SchoolSystemPaginatedItemFragmentDoc}`;

/**
 * __useGetDisabledSchoolSystemsQuery__
 *
 * To run a query within a React component, call `useGetDisabledSchoolSystemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDisabledSchoolSystemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDisabledSchoolSystemsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      offset: // value for 'offset'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useGetDisabledSchoolSystemsQuery(baseOptions?: Apollo.QueryHookOptions<GetDisabledSchoolSystemsQuery, GetDisabledSchoolSystemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDisabledSchoolSystemsQuery, GetDisabledSchoolSystemsQueryVariables>(GetDisabledSchoolSystemsDocument, options);
      }
export function useGetDisabledSchoolSystemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDisabledSchoolSystemsQuery, GetDisabledSchoolSystemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDisabledSchoolSystemsQuery, GetDisabledSchoolSystemsQueryVariables>(GetDisabledSchoolSystemsDocument, options);
        }
export type GetDisabledSchoolSystemsQueryHookResult = ReturnType<typeof useGetDisabledSchoolSystemsQuery>;
export type GetDisabledSchoolSystemsLazyQueryHookResult = ReturnType<typeof useGetDisabledSchoolSystemsLazyQuery>;
export type GetDisabledSchoolSystemsQueryResult = Apollo.QueryResult<GetDisabledSchoolSystemsQuery, GetDisabledSchoolSystemsQueryVariables>;