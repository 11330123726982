import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
  Button,
  Slide,
  DialogContentText,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  List,
  ListItem,
  ListItemText,
  Divider,
  TextField,
  Box,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import {
  DateRange,
  DateRangePicker,
  LoadingButton,
  LocalizationProvider,
} from "@mui/lab";
import { FaSave } from "react-icons/fa";
import { TransitionProps } from "@mui/material/transitions";
import {
  BusRoutesMergeableDocument,
  GetBusRouteDocument,
  MergedBusRoutesDocument,
  useCreateMergeBusRoutePathMutation,
  useGetBusRouteQuery,
} from "../../../apollo/busRoutes/queries.generated";
import dayjs, { Dayjs } from "dayjs";
import AdapterDayjs from "@mui/lab/AdapterDayjs";
import { BusRouteDto } from "../../../apollo/types.generated";

interface MergesPathCreatorModalProps {
  open: boolean;
  onClose: () => void;
  mergeableRoutes: BusRouteDto[];
  routeId: string;
  schoolSystemId: string;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MergePathCreatorModal: React.FC<MergesPathCreatorModalProps> = ({
  onClose,
  open,
  routeId,
  schoolSystemId,
  mergeableRoutes,
}) => {
  const [createRoutePathMerge, { loading: createRoutePathMergeLoading }] =
    useCreateMergeBusRoutePathMutation();

  const [checkedRoutes, setCheckedRoutes] = React.useState<any>([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const handleCheckedRoutes = (
    event: React.MouseEvent<HTMLElement>,
    originRouteId: string,
    pathType: string,
    pathName: string
  ) => {
    const dateRange: DateRange<Dayjs> = [
      dayjs(new Date()).startOf("day"),
      dayjs(new Date()).add(1, "day").startOf("day"),
    ];

    if (event.currentTarget.attributes[4].value === "false") {
      event.currentTarget.attributes[6].value = "border-color: orange;";
      setCheckedRoutes([
        ...checkedRoutes,
        {
          pathId: event.currentTarget.attributes[3].value,
          dates: dateRange,
          originRouteId: originRouteId,
          pathType: pathType,
          pathName: pathName,
        },
      ]);
    } else {
      setCheckedRoutes(
        checkedRoutes.filter(
          (item: any) => item.pathId !== event.currentTarget.attributes[3].value
        )
      );
      event.currentTarget.attributes[6].value = "border-color: gray;";
    }
  };

  useEffect(() => {}, [checkedRoutes]);

  const handleClickOpenConfirmDialog = useCallback(() => {
    setOpenConfirmDialog(true);
  }, []);

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleMergeRoutes = async () => {
    checkedRoutes.forEach(async (item: any) => {
      await createRoutePathMerge({
        variables: {
          data: {
            mainBusRouteId: routeId,
            mergeableBusRoutePathId: item.pathId,
            originBusRouteId: item.originRouteId,
            pathType: item.pathType,
            startTime: item.dates[0].toDate(),
            endTime: item.dates[1].toDate(),
            pathName: item.pathName,
          },
        },
        refetchQueries: [
          {
            query: GetBusRouteDocument,
            variables: {
              id: routeId,
            },
          },
          {
            query: BusRoutesMergeableDocument,
            variables: {
              schoolSystemId: schoolSystemId,
              busRouteId: routeId,
            },
          },
          {
            query: MergedBusRoutesDocument,
            variables: {
              busRouteId: routeId,
            },
          },
        ],
      });
    });
    setOpenConfirmDialog(false);
    setTimeout(() => {
      onClose();
    }, 1000);
  };

  useEffect(() => {
    if (!open) {
      setCheckedRoutes([]);
    }
  }, [open]);

  return (
    <Box>
      <Grid container sx={{ marginTop: "5px", textAlign: "left" }} spacing={2}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Divider
              sx={{
                marginTop: "8px",
                marginBottom: "8px",
              }}
              color="lightgray"
            />
            <Typography>Select routes to merge</Typography>

            <List dense={true}>
              {mergeableRoutes !== undefined && mergeableRoutes.length > 0 ? (
                mergeableRoutes.map((route) =>
                  route?.busRoutePathsForMerging!.map((path) => (
                    <ListItem key={path[0]}>
                      <ToggleButtonGroup
                        value={checkedRoutes?.map((item: any) =>
                          item.pathId === path[0] ? path[0] : null
                        )}
                        onChange={(event) =>
                          handleCheckedRoutes(
                            event,
                            route.id,
                            path[1],
                            route.name
                          )
                        }
                        aria-label="Select Routes"
                      >
                        <ToggleButton
                          style={{ borderColor: "gray" }}
                          value={path[0]}
                          color="primary"
                          aria-label="Select Routes"
                        >
                          <Typography textAlign={"start"}>
                            {route.name} - <b>{path[1]}</b>
                          </Typography>
                        </ToggleButton>
                      </ToggleButtonGroup>
                      {checkedRoutes.map((item: any) =>
                        item.pathId === path[0] ? (
                          <Grid item xs={12} md={12} lg={12} marginLeft="10px">
                            <LocalizationProvider
                              key={item.pathId}
                              dateAdapter={AdapterDayjs}
                              localeText={{
                                start: "Start Date",
                                end: "End Date",
                              }}
                            >
                              <DateRangePicker
                                // key={item.pathId}
                                value={item.dates}
                                disablePast
                                onChange={(newValue) => {
                                  setCheckedRoutes(
                                    checkedRoutes.map((item: any) =>
                                      item.pathId === path[0]
                                        ? {
                                            ...item,
                                            dates: newValue.map((date: any) =>
                                              dayjs(date).startOf("day")
                                            ),
                                          }
                                        : item
                                    )
                                  );
                                }}
                                renderInput={(startProps, endProps) => (
                                  <React.Fragment>
                                    <TextField {...startProps} />
                                    <Box sx={{ mx: 1 }}>to </Box>
                                    <TextField {...endProps} />
                                  </React.Fragment>
                                )}
                              />
                            </LocalizationProvider>
                          </Grid>
                        ) : null
                      )}
                    </ListItem>
                  ))
                )
              ) : (
                <ListItem>
                  <ListItemText>
                    <Typography color={"gray"}>
                      There are no routes that can be merged
                    </Typography>
                  </ListItemText>
                </ListItem>
              )}
            </List>
            <DialogActions>
              <Button
                // type="submit"
                startIcon={<FaSave />}
                sx={{ fontSize: "18px", marginRight: "10px" }}
                onClick={handleClickOpenConfirmDialog}
                disabled={checkedRoutes.length === 0}
              >
                Save routes
              </Button>
              <Button onClick={onClose} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Grid>

          <Dialog
            open={openConfirmDialog}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{"Confirm this action"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure you want to merge these routes?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseConfirmDialog}>Cancel</Button>
              <LoadingButton
                loading={createRoutePathMergeLoading}
                onClick={handleMergeRoutes}
              >
                Confirm
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MergePathCreatorModal;
