import { TreeView, TreeItem } from "@mui/lab";
import {
  Avatar,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import {
  DirectionsRenderer,
  DirectionsService,
  GoogleMap,
} from "@react-google-maps/api";
import React, { useMemo, useState } from "react";
import { BusStopDto } from "../../../apollo/types.generated";
import MapsApiLoader from "../../../shared/components/MapsApiLoader";
import { AssignationPath, BusStop } from "./interfaces";
import { getWayPoints, mapServerStopsToLocalStops } from "./utils";

interface RoutePreviewerProps {
  busStops: AssignationPath["stops"];
}

const RoutePreviewer: React.FC<RoutePreviewerProps> = ({ busStops }) => {
  const mapStops = useMemo(
    () => mapServerStopsToLocalStops(busStops as BusStopDto[]),
    [busStops]
  );
  return (
    <Grid container>
      <Grid item xs={12}>
        <Map busStops={mapStops} />
      </Grid>
      <Grid item xs={12}>
        <TreeView
          aria-label="Bus stops"
          // defaultCollapseIcon={<ExpandMoreIcon />}
          // defaultExpandIcon={<ChevronRightIcon />}
          // sx={{ height: 240, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
        >
          {busStops.map((item, index) => (
            <TreeItem
              nodeId={item.id}
              key={item.id}
              sx={{ borderRadius: "10px" }}
              label={
                <Box
                  display="flex"
                  alignItems="center"
                  padding="10px"
                  borderRadius="10px"
                >
                  <Avatar>{index + 1}</Avatar>
                  <Typography
                    textAlign="start"
                    fontSize="18px"
                    sx={{ marginLeft: "20px" }}
                  >
                    {item.address}
                  </Typography>
                </Box>
              }
            >
              {item.students.map((student) => (
                <TreeItem
                  nodeId={student.id}
                  key={student.id}
                  label={
                    <Box display="flex" alignItems="center" padding="10px">
                      <Typography textAlign="start">
                        {student.user.name}
                      </Typography>
                    </Box>
                  }
                />
              ))}
            </TreeItem>
          ))}
        </TreeView>
      </Grid>
    </Grid>
  );
};

interface MapProps {
  busStops: BusStop[];
}
const Map: React.FC<MapProps> = ({ busStops }) => {
  const [directionResults, setDirectionResults] =
    useState<google.maps.DirectionsResult | null>(null);
  const onDirectionsLoad = (
    result: google.maps.DirectionsResult | null,
    status: google.maps.DirectionsStatus
  ) => {
    if (result !== null) {
      if (status === "OK") {
        setDirectionResults(result);
      }
    }
  };

  const directions = useMemo(() => {
    if (busStops.length < 2) {
      return null;
    }

    return (
      <DirectionsService
        callback={onDirectionsLoad}
        options={{
          destination: busStops[busStops.length - 1],
          origin: busStops[0],
          travelMode: google.maps.TravelMode.DRIVING,
          waypoints: getWayPoints(busStops),
        }}
      />
    );
  }, [busStops.length]);
  return (
    <GoogleMap
      mapContainerStyle={{
        height: "500px",
        borderRadius: "15px",
      }}
      center={{
        lat: 41.850033,
        lng: -87.6500523,
      }}
      zoom={3}
    >
      {directions}
      {directionResults !== null && (
        <DirectionsRenderer
          options={{
            directions: directionResults,
          }}
        />
      )}
    </GoogleMap>
  );
};

export default RoutePreviewer;
