import { GridColDef } from "@mui/x-data-grid";
import React from "react";
import {
  FamilyPaginatedItemFragment,
  GetFamiliesPaginatedDocument,
} from "../../../apollo/families/query.generated";
import { SchoolSystemPaginatedItemFragment } from "../../../apollo/schoolSystems/queries.generated";
import Link from "../../../navigation/Link";
import RemoteDataTable, {
  BasicTableActionFunction,
  getBasicActionColumns,
  RemoteDataTableProps,
} from "../../../shared/components/RemoteDataTable";

interface FamiliesTableProps
  extends Pick<RemoteDataTableProps, "onAddClick" | "refresherBoolean"> {
  onViewClick: BasicTableActionFunction<FamilyPaginatedItemFragment>;
  onEditPress?: BasicTableActionFunction<FamilyPaginatedItemFragment>;
  onDeletePress?: BasicTableActionFunction<FamilyPaginatedItemFragment>;
}

const getMuiColumns = (
  onViewClick: BasicTableActionFunction<FamilyPaginatedItemFragment>
): GridColDef[] => [
  // {
  //   flex: 1,
  //   minWidth: 200,
  //   field: "id",
  //   sortable: false,
  //   headerName: "View",
  //   renderCell: (params) => {
  //     return <Button>{params.value}</Button>;
  //   },
  // },

  {
    flex: 1,
    field: "details",
    headerName: "Details",
    minWidth: 200,
    sortable: false,
    renderCell: ({ row }) => (
      <Link to={"/dashboard/families/" + row.id}>More Details</Link>
    ),
  },
  {
    flex: 1,
    field: "name",
    headerName: "Name",
    minWidth: 200,
  },
  {
    flex: 1,
    field: "membersCount",
    headerName: "# Members",
    minWidth: 200,
    //   valueGetter: ({ row }) => {
    //     const student = row as BusDriversPaginatedItemFragment;
    //     return student.user.email;
    //   },
  },

  {
    flex: 1,
    field: "schoolSystem",
    headerName: "School System",
    valueGetter: ({ value }) => {
      return (value as SchoolSystemPaginatedItemFragment)?.name;
    },
    minWidth: 200,
  },
];

const FamiliesTable: React.FC<FamiliesTableProps> = ({
  onEditPress,
  onDeletePress,
  onViewClick,
  ...props
}) => {
  return (
    <RemoteDataTable
      query={GetFamiliesPaginatedDocument}
      searchable
      renderAddButton
      columns={getMuiColumns(onViewClick)}
      actionColumns={getBasicActionColumns(onEditPress, onDeletePress)}
      {...props}
    />
  );
};

export default FamiliesTable;
