import { Check, ContentCopy, CopyAll } from "@mui/icons-material";
import { Button, ButtonProps } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

interface CopyButtonProps extends ButtonProps {
  textToCopy: string;
  buttonText?: string;
  buttonTextSuccess?: string;
}

const CopyButton: React.FC<CopyButtonProps> = ({
  textToCopy,
  buttonText = "Copy",
  buttonTextSuccess = "Copied",
  ...props
}) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => {
        setCopied(false);
      }, 5000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [copied]);

  return (
    <CopyToClipboard text={textToCopy} onCopy={() => setCopied(true)}>
      <Button
        variant="contained"
        {...props}
        startIcon={!copied ? <ContentCopy /> : <Check />}
        color={copied ? "success" : "primary"}
      >
        {copied ? buttonTextSuccess : buttonText}
      </Button>
    </CopyToClipboard>
  );
};

export default CopyButton;
