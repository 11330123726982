import { Grid, Typography } from "@mui/material";
import { GoogleMap, Marker } from "@react-google-maps/api";
import React from "react";
import { ObjectShape } from "yup/lib/object";
import { DEFAULT_MAP_CENTER, DEFAULT_MAP_ZOOM } from "./MapsApiLoader";
import PlaceSearch, { PlaceResult } from "./PlacesSearch";

export interface PlaceSearchMapValue {
  address: string;
  lat: number;
  lng: number;
}
export interface PlaceSearchMapProps {
  title?: string;
  error?: string;
  onPlaceChange: (result: PlaceResult) => void;
  value?: PlaceSearchMapValue | null;
}

const PlaceSearchMap: React.FC<PlaceSearchMapProps> = ({
  title = "Address",
  error,
  onPlaceChange,
  value,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography fontWeight="bold" color={error ? "red" : undefined}>
          {title}{" "}
        </Typography>
        {error && <Typography color="red">{error}</Typography>}
      </Grid>
      <Grid item xs={12}>
        <PlaceSearch onPlaceChange={onPlaceChange} />
      </Grid>
      {value?.address && (
        <Grid item xs={12}>
          <Typography>{value.address}</Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <GoogleMap
          mapContainerStyle={{ height: 300 }}
          center={value || DEFAULT_MAP_CENTER}
          zoom={value ? 18 : DEFAULT_MAP_ZOOM}
        >
          {value && <Marker position={value} title={value.address} />}
        </GoogleMap>
      </Grid>
    </Grid>
  );
};

export default PlaceSearchMap;
