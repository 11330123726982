import { GridColDef } from "@mui/x-data-grid";
import React, { useMemo } from "react";
import { timezoneAwareFormat } from "../../../shared/components/TimezoneAwareDatetime";
import {
  FormsPaginatedItemFragment,
  GetFormsPaginatedDocument,
} from "../../../apollo/forms/queries.generated";
import RemoteDataTable, {
  BasicTableActionFunction,
  RemoteDataTableProps,
} from "../../../shared/components/RemoteDataTable";
import { FormResponseDto } from "../../../apollo/types.generated";
import { IconButton } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import Link from "../../../navigation/Link";

interface FormsTableProps
  extends Pick<RemoteDataTableProps, "onAddClick" | "refresherBoolean"> {
  onEditPress?: BasicTableActionFunction<FormsPaginatedItemFragment>;
  onDeletePress?: BasicTableActionFunction<FormsPaginatedItemFragment>;
}
const muiColumns: GridColDef[] = [
  {
    flex: 1,
    field: "name",
    headerName: "Name",
    minWidth: 200,
    renderCell: ({ row, value }) => {
      const form = row as FormsPaginatedItemFragment;
      return <Link to={"/dashboard/forms/" + form.id}>{value}</Link>;
    },
  },
  // {
  //   flex: 1,
  //   field: "description",
  //   headerName: "Description",
  //   minWidth: 200,
  // },
  {
    flex: 1,
    field: "targetInfo",
    headerName: "Target or Purpose",
    minWidth: 150,
  },
  {
    flex: 1,
    field: "receivers",
    headerName: "Receivers",
    minWidth: 100,
  },
  {
    flex: 1,
    field: "initialDate",
    headerName: "Initial Date",
    minWidth: 100,
    valueFormatter: ({ value }) =>
      timezoneAwareFormat(value as Date, "MMM D, YYYY"),
  },
  {
    flex: 1,
    field: "finalDate",
    headerName: "Final Date",
    minWidth: 100,
    valueFormatter: ({ value }) =>
      timezoneAwareFormat(value as Date, "MMM D, YYYY"),
  },
  {
    flex: 1,
    field: "formResponses",
    headerName: "Responses Count",
    minWidth: 150,
    valueFormatter: ({ value }) => (value as FormResponseDto[]).length,
  },
];

const FormsTable: React.FC<FormsTableProps> = ({
  onEditPress,
  onDeletePress,
  ...props
}) => {
  const actionColumns = useMemo(
    () => getActionColumns(onEditPress, onDeletePress),
    [onEditPress, onDeletePress]
  );
  return (
    <RemoteDataTable
      query={GetFormsPaginatedDocument}
      searchable
      columns={muiColumns}
      renderAddButton
      actionColumns={actionColumns}
      {...props}
    />
  );
};

const getActionColumns = <FormsPaginatedItemFragment,>(
  onEditPress?: BasicTableActionFunction<FormsPaginatedItemFragment>,
  onDeletePress?: BasicTableActionFunction<FormsPaginatedItemFragment>
): GridColDef[] => [
  {
    // flex: 1,
    field: "actions",
    align: "center",
    headerName: "",
    minWidth: 150,
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => (
      <>
        <IconButton
          onClick={() => {
            if (onEditPress) onEditPress(row as FormsPaginatedItemFragment);
          }}
        >
          <Edit />
        </IconButton>

        <IconButton
          onClick={() => {
            if (onDeletePress) onDeletePress(row as FormsPaginatedItemFragment);
          }}
        >
          <Delete />
        </IconButton>
      </>
    ),
  },
];

export default FormsTable;
